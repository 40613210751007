import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { HighLevelStatus } from 'types/Integrations'
import { integrationsAPI } from 'api/controllers'
import { HIGH_LEVEL_STATUS_QUERY, HIGH_LEVEL_VALIDATION_CODE_QUERY } from 'api/constants'
import { route } from 'constants/routes'

export const useHighLevelValidationCodeQuery = (
    code?: string | null,
    options?: UseQueryOptions<HighLevelStatus, unknown, HighLevelStatus>,
) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    return useQuery<HighLevelStatus>(
        HIGH_LEVEL_VALIDATION_CODE_QUERY,
        () =>
            integrationsAPI.sendHighLevelValidationCode({
                code: String(code),
            }),
        {
            enabled: Boolean(code),
            ...options,
            onSettled: () => {
                queryClient.resetQueries(HIGH_LEVEL_STATUS_QUERY)
                navigate(route.integrations.highLevel, { replace: true })
            },
        },
    )
}
