import { EMAIL, PASSWORD } from 'constants/validations/user.constants'

export const GENERAL_STEP = 1
export const CARD_STEP = 2

export const CUSTOMER = 'customer'
export const GUID = 'guid'
export const FIRSTNAME = 'firstname'
export const LASTNAME = 'lastname'
export const DATE_CREATED = 'dateCreated'
export const CREDIT_FIRSTNAME = 'creditFirstname'
export const CREDIT_LASTNAME = 'creditLastname'
export const REMEMBER_ME = 'rememberMe'
export const CREDENTIAL = 'credential'
export const ACCESS_TOKEN = 'accessToken'
export const TOKEN = 'token'
export const BUSINESSNAME = 'businessName'

export const FULL = 'full'
export const PARTIAL = 'partial'

export const SOURCE = 'source'

// Depricated
export const CREDIT_CARD = 'creditCard'
export const CARD_NUMBER = 'cardNumber'
export const EXPIRATION_DATE = 'expirationDate'
export const EXPIRATION_MONTH = 'expirationMonth'
export const EXPIRATION_YEAR = 'expirationYear'
export const CVV = 'cvv'
export const ZIPCODE = 'zipcode'
// Depricated

export const CREDIT_CARD_TOKEN = 'creditCardToken'

export const PLAN = 'plan'
export const API_HANDLE = 'apiHandle'
export const PRICEPOINT = 'pricepoint'
export const COUPON_CODES = 'couponCodes'
export const AGENCY_INVITATION_TOKEN = 'agencyInvitationToken'

export const UTM = 'utm'
export const REFERRER = 'referrer'

export const TOKENS = 'tokens'
export const REDIRECT_URL = 'redirectUrl'
export const UPSELL_TOKEN = 'upsellToken'
export const UPSELL_TOKEN_COOKIE = 'upsell'
export const PROM_REF = '_fprom_ref'

export const NAME = 'name'
export const FEATURES = 'features'

export { EMAIL, PASSWORD }

export const INITIAL_VALUES = {
    [FIRSTNAME]: '',
    [BUSINESSNAME]: '',
    [LASTNAME]: '',
    [EMAIL]: '',
    [PASSWORD]: '',
    [CREDIT_FIRSTNAME]: '',
    [CREDIT_LASTNAME]: '',
    [CARD_NUMBER]: '',
    [EXPIRATION_DATE]: '',
    [CVV]: '',
    [ZIPCODE]: '',
    [SOURCE]: null,
}
