import { SegmentType } from 'design/pages/VidStats/types'

export const SegmentRequest = {
    [SegmentType.item]: 'segment.all',
    [SegmentType.tags]: 'segment.tags',
    [SegmentType.trafficSource]: 'segment.traffic-source',
    [SegmentType.converted]: 'segment.conversions',
    [SegmentType.domain]: 'segment.domain',
    [SegmentType.geo]: 'segment.geolocation',
    [SegmentType.device]: 'segment.device',
    [SegmentType.browser]: 'segment.browser',
    [SegmentType.newVsReturning]: 'segment.new-vs-returning',
    [SegmentType.urlParams]: 'segment.url_params',
    [SegmentType.timeOfDay]: 'segment.time-of-day',
    [SegmentType.dayOfWeek]: 'segment.day-of-week',
}

export const SegmentGroupTitle = {
    behavior: 'Behavior',
    technology: 'Technology',
    demographic: 'Demographic',
    tags: 'Tags',
    time: 'Time',
    urlTracking: 'URL Tracking',
}

export const SegmentTitle: Record<SegmentType, string> = {
    [SegmentType.trafficSource]: 'Traffic Source',
    [SegmentType.tags]: 'Tags',
    [SegmentType.converted]: 'Converted',
    [SegmentType.domain]: 'Embed Location',
    [SegmentType.geo]: 'Geo',
    [SegmentType.device]: 'Device',
    [SegmentType.browser]: 'Browser',
    [SegmentType.newVsReturning]: 'New vs Returning',
    [SegmentType.urlParams]: 'URL Tracking',
    [SegmentType.timeOfDay]: 'Time of Day',
    [SegmentType.dayOfWeek]: 'Day of Week',
    [SegmentType.item]: '',
}

export const AutoplayDisabledSettings = {
    enabled: false,
    mobile: false,
    onlyMuted: false,
    keepMutedAfterDomainInteraction: true,
}
