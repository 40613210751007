import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { CREATE_PLAY_GATE_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'
import { VideoPlayGateConfig } from 'types/VideoPlayGate'

type Payload = {
    videoGuid: string
    playGate: VideoPlayGateConfig
}

export const useCreatePlayGateMutation = (options?: Options<VideoPlayGateConfig, Payload, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoPlayGateConfig, Payload, string>(
        CREATE_PLAY_GATE_MUTATION,
        async (payload: Payload) => {
            return mutate(
                settingsAPI.createPlayGate(payload.videoGuid, payload.playGate),
                apiLocale.settings.playGate.create,
            )
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
