import { useQueryClient, UseMutationOptions } from 'react-query'

import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { SubUser, SubUserPayload } from 'types/SubUser'
import { subUserAPI } from 'api/controllers'
import { SUB_USERS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const useCreateSubUserMutation = (options?: UseMutationOptions<SubUser, any, SubUserPayload>) => {
    const queryClient = useQueryClient()
    const key = SUB_USERS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<SubUser, SubUserPayload>(
        key,
        async (payload: SubUserPayload) => mutate(subUserAPI.createSubUser(payload), apiLocale.subUser.create),
        {
            ...options,
            onMutate: async (...args) => {
                await queryClient.cancelQueries(key)

                const [data] = args
                const previousData = queryClient.getQueryData(key) as SubUser[]

                if (previousData) {
                    queryClient.setQueryData(key, [
                        ...previousData,
                        {
                            ...data,
                            created: new Date().toISOString(),
                            updated: null,
                        },
                    ])
                }

                return previousData
            },
            // @ts-ignore
            onError: async (err, variables, previousValue) => queryClient.setQueryData(key, previousValue),
            onSettled: () => {
                queryClient.invalidateQueries(key)
            },
            invalidateOn: InvalidateOn.NEVER,
        },
    )
}
