import { MouseEventHandler } from 'react'
import { useAppDispatch } from 'App'

import {
    SubscriptionCtaSource,
    openSubscriptionModal,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import useIsLockedAccount from 'hooks/user/useIsLockedAccount'
import useAccountSubscription from 'hooks/user/useAccountSubscription'

const useUpgradeCallback = (source: SubscriptionCtaSource = SubscriptionCtaSource.LOCKED_ACCOUNT) => {
    const { isFree } = useAccountSubscription()
    const isLockedAccount = useIsLockedAccount()
    const dispatch = useAppDispatch()

    const handleClick =
        (originalHandler?: MouseEventHandler<unknown>): MouseEventHandler<unknown> =>
        (e) => {
            if (
                (source === SubscriptionCtaSource.LOCKED_ACCOUNT && !isLockedAccount) ||
                (source === SubscriptionCtaSource.VID_CONDITIONS && !isFree)
            ) {
                originalHandler?.(e)

                return
            }

            dispatch(openSubscriptionModal({ ctaSource: source }))
            e?.stopPropagation()
        }

    return handleClick
}

export default useUpgradeCallback
