import { Box } from '@mui/material'

import './index.scss'

type NoDataProps = {
    content?: string
}

const NoData = ({ content }: NoDataProps) => {
    if (!content) {
        return null
    }

    return (
        <Box className="NoData">
            <Box>{content}</Box>
        </Box>
    )
}

export default NoData
