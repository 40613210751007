import { useCallback, useMemo } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Typography } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {
    get as _get,
    some as _some,
    values as _values,
    replace as _replace,
    toLower as _toLower,
    isEqual as _isEqual,
} from 'lodash'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    ADVANCED_PLAYBACK_TURNED_OFF,
    PLAYBACK_AUTOPLAY_ENABLED,
    PLAYBACK_AUTOPLAY_MOBILE,
    PLAYBACK_AUTOPLAY_ONLY_MUTED,
    PLAYBACK_NO_PAUSE,
    UI_OVERLAY_UNMUTE_COLOR_BACKGROUND,
    UI_OVERLAY_UNMUTE_COLOR_FOREGROUND,
    UI_OVERLAY_UNMUTE_DO_REPLAY,
    UI_OVERLAY_UNMUTE_GO_FULLSCREEN_DEFAULT_ENABLED,
    UI_OVERLAY_UNMUTE_GO_FULLSCREEN_MOBILE_ENABLED,
    UI_OVERLAY_UNMUTE_MOBILE_ENABLED,
    UI_OVERLAY_UNMUTE_MOBILE_TEXT_BOTTOM_TEXT,
    UI_OVERLAY_UNMUTE_MOBILE_TEXT_TOP_TEXT,
    UI_OVERLAY_UNMUTE_TEXT_BOTTOM_TEXT,
    UI_OVERLAY_UNMUTE_TEXT_TOP_TEXT,
    UI_OVERLAY_UNMUTE_TYPE,
    UI_SHADOW,
} from 'design/pages/VidSettings/constants'
import { UnmuteType } from 'types/Video'
import { PlaybackSectionFormData } from 'types/VidSettings'
import { SwitchTooltipPlacement, SwitchForm } from 'design/atoms/Switch'
import { Input, Select, ColorPicker } from 'design/atoms/Form'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import './index.scss'

const autoplayMessageStyleOptions = [
    {
        label: 'Front & Center',
        value: UnmuteType.box,
    },
    {
        label: 'Classy in the Corner',
        value: UnmuteType.button,
    },
    {
        label: 'Loud & in Your Face',
        value: UnmuteType.overlay,
    },
    {
        label: 'None',
        value: UnmuteType.none,
    },
]

type MessagesType = {
    top: string
    bottom: string
}
const HORIZONTAL = 'horizontal'
const VERTICAL = 'vertical'

const defaultMessages = {
    // Front & Center
    [UnmuteType.box]: {
        [HORIZONTAL]: {
            top: 'Your Video is Playing',
            bottom: 'Click to Unmute',
        },
        [VERTICAL]: {
            top: 'Your Video is Playing',
            bottom: 'Click to Unmute',
        },
    },
    // Classy in the Corner
    [UnmuteType.button]: {
        [HORIZONTAL]: {
            top: 'Click to Unmute',
            bottom: 'Your Video is Playing',
        },
        [VERTICAL]: {
            top: 'Click to <br>Unmute',
            bottom: 'Your Video <br>is Playing',
        },
    },
    // Loud & in Your Face
    [UnmuteType.overlay]: {
        [HORIZONTAL]: {
            top: 'Your Video is Playing',
            bottom: 'Click here to Unmute',
        },
        [VERTICAL]: {
            top: 'Your Video <br>is Playing',
            bottom: 'Click here <br>to Unmute',
        },
    },
}

const FUNNEL_TOOLTIP =
    'Go Fullscreen on Unmute cannot be enabled on this video because it is being used in a Smart Vid. If you want to enable it, please delete the video from the funnel first.'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const SmartAutoplayContent = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        formik: { values, setValues },
        video,
        isLoading,
        onChange,
    } = useSectionContext<PlaybackSectionFormData>()

    const commonProps = {
        afterChange: onChange,
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const
    const autoplayOverlaySelected = values[UI_OVERLAY_UNMUTE_TYPE]
    const mobileMessageChecked = values[UI_OVERLAY_UNMUTE_MOBILE_ENABLED]
    const isAutoplayOverlaySelectedNone = autoplayOverlaySelected === UnmuteType.none
    const isVideoHorizontal = Number(video?.settings?.player_height) < Number(video?.settings?.player_width)

    const replaceTags = (value: string) => _toLower(_replace(value, /<[^>]*>/g, ''))
    const replaceBrToLineBreak = (value: string) => _replace(value, /<br\s*\/?>/gi, '\n')

    const isDefaultMessages = useCallback(() => {
        const direction = isVideoHorizontal ? HORIZONTAL : VERTICAL
        const current: MessagesType = {
            top: replaceTags(values[UI_OVERLAY_UNMUTE_TEXT_TOP_TEXT]),
            bottom: replaceTags(values[UI_OVERLAY_UNMUTE_TEXT_BOTTOM_TEXT]),
        }

        return _some(_values(defaultMessages), (value: MessagesType) => {
            const messagesToCheck: MessagesType = {
                top: replaceTags(_get(value, [direction, 'top'])),
                bottom: replaceTags(_get(value, [direction, 'bottom'])),
            }

            return _isEqual(messagesToCheck, current)
        })
    }, [values, isVideoHorizontal])

    const onInputBlur = (name: keyof PlaybackSectionFormData) => () => {
        if (values[name] === video?.settings[name]) {
            return
        }

        onChange()
    }

    const renderTopText = !isAutoplayOverlaySelectedNone
    const renderBottomText =
        autoplayOverlaySelected === UnmuteType.box || autoplayOverlaySelected === UnmuteType.overlay
    const renderMobileTopText = mobileMessageChecked && !isAutoplayOverlaySelectedNone
    const renderMobileBottomText =
        (mobileMessageChecked && autoplayOverlaySelected === UnmuteType.box) ||
        autoplayOverlaySelected === UnmuteType.overlay

    const getDefaultAdvancedOptions = () => ({
        [UI_OVERLAY_UNMUTE_DO_REPLAY]: true,
        [PLAYBACK_AUTOPLAY_MOBILE]: true,
    })

    const onSmartAutoplayChange = (name: string, value: boolean) => {
        setValues({
            ...values,
            [name]: value,
            ...(value ? getDefaultAdvancedOptions() : ADVANCED_PLAYBACK_TURNED_OFF),
        })
        onChange()
    }

    const setDefaultUnmuteText = (_name: string, selected: string) => {
        let textTop = values[UI_OVERLAY_UNMUTE_TEXT_TOP_TEXT]
        let textBottom = values[UI_OVERLAY_UNMUTE_TEXT_BOTTOM_TEXT]
        const direction = isVideoHorizontal ? HORIZONTAL : VERTICAL

        if (!textTop || !textBottom) {
            setValues({ ...values, [UI_OVERLAY_UNMUTE_TYPE]: selected as UnmuteType })
            onChange()
            return
        }

        // if default - adopt text to horizontal / vertical
        if (isDefaultMessages()) {
            textTop = _get(defaultMessages, [selected, direction, 'top'])
            textBottom = _get(defaultMessages, [selected, direction, 'bottom'])
        }

        setValues({
            ...values,
            [UI_OVERLAY_UNMUTE_TYPE]: selected as UnmuteType,
            [UI_OVERLAY_UNMUTE_TEXT_TOP_TEXT]: textTop,
            [UI_OVERLAY_UNMUTE_TEXT_BOTTOM_TEXT]: textBottom,
        })
        onChange()
    }

    const renderTextInput = ({
        shouldRender,
        name,
        label,
    }: {
        shouldRender: boolean
        name: keyof PlaybackSectionFormData
        label: string
    }) =>
        shouldRender && (
            <Input
                name={name}
                label={label}
                placeholder="Type here"
                variant="outlined"
                displayMaxLength
                inputProps={{ maxLength: 50, value: replaceBrToLineBreak(String(values[name])) }}
                disabled={isLoading}
                multiline
                onBlur={onInputBlur(name)}
            />
        )

    return (
        <Box className="SmartAutoplayContent">
            <Accordion expanded className="sectionSubAccordion">
                <Box className="container" sx={{ mb: values[PLAYBACK_AUTOPLAY_ENABLED] ? 3 : 0 }}>
                    <SwitchForm
                        name={PLAYBACK_AUTOPLAY_ENABLED}
                        label="Smart Autoplay"
                        labelPlacement="start"
                        tooltipPlacement={SwitchTooltipPlacement.inside}
                        helpInfo="Smart Autoplay will play your video on mute with an Autoplay Message to prompt your viewers to unmute if a browser blocks the video from autoplaying with sound. If the browser allows autoplay, it will autoplay like normal."
                        labelClassName="dropdownSwitch"
                        onChange={onSmartAutoplayChange}
                        {...commonProps}
                    />
                </Box>

                {values[PLAYBACK_AUTOPLAY_ENABLED] && (
                    <AccordionDetails>
                        <Box className="content">
                            <Box className="container">
                                <Stack gap={8}>
                                    <Select
                                        name={UI_OVERLAY_UNMUTE_TYPE}
                                        label="Autoplay Message style"
                                        options={autoplayMessageStyleOptions}
                                        selectProps={{ disabled: isLoading }}
                                        {...commonProps}
                                        afterChange={setDefaultUnmuteText}
                                    />
                                </Stack>

                                {(renderTopText ||
                                    renderBottomText ||
                                    renderMobileTopText ||
                                    renderMobileBottomText) && (
                                    <Box className="overlayInputs">
                                        {renderTextInput({
                                            shouldRender: renderTopText,
                                            name: UI_OVERLAY_UNMUTE_TEXT_TOP_TEXT,
                                            label: 'Top Text',
                                        })}
                                        {renderTextInput({
                                            shouldRender: renderBottomText,
                                            name: UI_OVERLAY_UNMUTE_TEXT_BOTTOM_TEXT,
                                            label: 'Bottom Text',
                                        })}
                                    </Box>
                                )}

                                <Box className="colorsContainer">
                                    <ColorPicker
                                        name={UI_OVERLAY_UNMUTE_COLOR_FOREGROUND}
                                        prefixed
                                        label="Text Color"
                                        {...commonProps}
                                        disabled={isAutoplayOverlaySelectedNone || commonProps.disabled}
                                    />
                                    {video?.settings[UI_SHADOW] && (
                                        <ColorPicker
                                            name={UI_OVERLAY_UNMUTE_COLOR_BACKGROUND}
                                            prefixed
                                            label="Shadow Color"
                                            {...commonProps}
                                            disabled={isAutoplayOverlaySelectedNone || commonProps.disabled}
                                        />
                                    )}
                                </Box>
                            </Box>

                            <Divider sx={{ my: 4, borderWidth: 0 }} />

                            <Accordion className="advancedOptions">
                                <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                                    <Typography variant="body2">Advanced Options</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SwitchForm
                                        name={UI_OVERLAY_UNMUTE_DO_REPLAY}
                                        labelPlacement="start"
                                        labelClassName="advancedOptions"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                        label="Unmute & Restart"
                                        helpInfo="Your video will restart once the viewer clicks to unmute it. This has been shown to boost conversions ;)"
                                        {...commonProps}
                                    />
                                    <SwitchForm
                                        name={PLAYBACK_NO_PAUSE}
                                        labelPlacement="start"
                                        labelClassName="advancedOptions"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                        label="Disable Pausing"
                                        helpInfo="Prevent your viewers from pausing your videos. Must be used with autoplay."
                                        {...commonProps}
                                    />
                                    <SwitchForm
                                        name={PLAYBACK_AUTOPLAY_ONLY_MUTED}
                                        labelPlacement="start"
                                        labelClassName="advancedOptions"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                        label="Always Muted"
                                        helpInfo="Your video will ALWAYS start muted, even if the browser allows autoplay with sound."
                                        {...commonProps}
                                    />
                                    <SwitchForm
                                        name={PLAYBACK_AUTOPLAY_MOBILE}
                                        labelPlacement="start"
                                        labelClassName="advancedOptions"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                        label="Mobile Autoplay"
                                        helpInfo="This enables your video to autoplay on mobile devices. Mobile autoplay is always muted until the viewer taps for sound."
                                        {...commonProps}
                                    />
                                    <SwitchForm
                                        name={UI_OVERLAY_UNMUTE_GO_FULLSCREEN_DEFAULT_ENABLED}
                                        labelPlacement="start"
                                        labelClassName="advancedOptions"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                        label="Desktop Fullscreen on Unmute"
                                        helpInfo={`Automatically switch to fullscreen when unmuting on desktop. ${
                                            video?.funnel ? FUNNEL_TOOLTIP : ''
                                        }`}
                                        {...commonProps}
                                        disabled={Boolean(video?.funnel) || commonProps.disabled}
                                    />
                                    <SwitchForm
                                        name={UI_OVERLAY_UNMUTE_GO_FULLSCREEN_MOBILE_ENABLED}
                                        labelPlacement="start"
                                        labelClassName="advancedOptions"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                        label="Mobile Fullscreen on Unmute"
                                        helpInfo={`Automatically switch to fullscreen when unmuting on mobile. ${
                                            video?.funnel ? FUNNEL_TOOLTIP : ''
                                        }`}
                                        {...commonProps}
                                        disabled={Boolean(video?.funnel) || commonProps.disabled}
                                    />
                                    {autoplayOverlaySelected !== UnmuteType.none && (
                                        <SwitchForm
                                            name={UI_OVERLAY_UNMUTE_MOBILE_ENABLED}
                                            labelPlacement="start"
                                            labelClassName="advancedOptions"
                                            tooltipPlacement={SwitchTooltipPlacement.inside}
                                            label="Different Mobile Message"
                                            {...commonProps}
                                        />
                                    )}
                                    <Box className="mobileOverlayInputs">
                                        {renderTextInput({
                                            shouldRender: renderMobileTopText,
                                            name: UI_OVERLAY_UNMUTE_MOBILE_TEXT_TOP_TEXT,
                                            label: 'Top Text',
                                        })}
                                        {renderTextInput({
                                            shouldRender: renderMobileBottomText,
                                            name: UI_OVERLAY_UNMUTE_MOBILE_TEXT_BOTTOM_TEXT,
                                            label: 'Bottom Text',
                                        })}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                    </AccordionDetails>
                )}
            </Accordion>
        </Box>
    )
}

export default withErrorBoundary(SmartAutoplayContent)
