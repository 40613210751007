import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { SetStateAction } from 'react'
import { State } from './AnalyticsSwitch.types'

const initialState: State = {
    isAnalyticMode: false,
}

export const ANALYTICS_SWITCH_KEY = 'analyticsSwitch'

export const analyticsSwitchSlice = createSlice({
    name: ANALYTICS_SWITCH_KEY,
    initialState,
    reducers: {
        setIsAnalyticMode: (state, action: PayloadAction<SetStateAction<boolean>>) => {
            if (typeof action.payload === 'function') {
                state.isAnalyticMode = action.payload(state.isAnalyticMode)
            } else {
                state.isAnalyticMode = action.payload
            }
        },
    },
})

export const { setIsAnalyticMode } = analyticsSwitchSlice.actions

export const selectAnalyticsSwitchState = (state: RootState) => state.analyticsSwitch

export * from './AnalyticsSwitch.types'

export const analyticsSwitch = analyticsSwitchSlice.reducer
