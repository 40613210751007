import { endpoints } from 'api/constants'
import { CreateAgencyClientRequest } from 'api/contracts/agency/endpoints/createAgencyClient.contract'
import { UpdateAgencyClientRequest } from 'api/contracts/agency/endpoints/updateAgencyClient.contract'
import { GetAgencyClientListQuery } from 'api/contracts/agency/endpoints/getAgencyClientList.contract'
import { AcceptAgencyInvitationRequest } from 'api/contracts/agency/endpoints/acceptAgencyInvitation.contract'
import { ValidateAgencyClientRequest } from 'api/contracts/agency/endpoints/validateAgencyClient.contract'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'
import { http } from 'api/utils/http.utils'

export const agencyController = {
    getAgencyInfo: () => http.get('/agencies/my'),
    revokeAccess: () => http.post('/agencies/revoke-access'),
    client: {
        hasAgency: () => http.get('/customer/is-agency-client'),
        cancelAccount: {
            init: (clientId: AgencyClientId) => http.post(`/agency-clients/${clientId}/account-cancellation`),
            resendEmail: (clientId: AgencyClientId) =>
                http.post(`/agency-clients/${clientId}/account-cancellation-resend`),
            revoke: (clientId: AgencyClientId) =>
                http.post(endpoints.agency.client.revokeAccountCancellation(clientId)),
        },
        create: (payload: CreateAgencyClientRequest) => http.post('/agency-clients', payload),
        getList: (query: GetAgencyClientListQuery) =>
            http.get(`/agency-clients-paginate?page=${query.page}&perPage=${query.perPage}`),
        invitation: {
            accept: (payload: AcceptAgencyInvitationRequest) => http.post(endpoints.agency.invitation.accept, payload),
            cancel: (clientId: AgencyClientId) => http.post(`/agency-clients/${clientId}/invitation-cancel`),
            getInfo: (token: string) => http.get(endpoints.agency.invitation.getInfo(token)),
            resend: (clientId: AgencyClientId) => http.post(`/agency-clients/${clientId}/invitation-resend`),
        },
        remove: (clientId: AgencyClientId) => http.delete(`/agency-clients/${clientId}`),
        validate: (payload: ValidateAgencyClientRequest) => http.post(endpoints.agency.client.validate, payload),
        tariffInfo: (clientId: AgencyClientId) => http.get(endpoints.agency.client.tariffInfo(clientId)),
        update: (clientId: AgencyClientId, payload: UpdateAgencyClientRequest) =>
            http.post(endpoints.agency.client.update(clientId), payload, {
                headers: { 'Content-Type': 'multipart/form-data' },
            }),
    },
}
