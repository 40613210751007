import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_CLIENT_VALIDATE } from 'api/constants'
import { useApiErrorToast, useInvalidateMutation } from 'api/hooks'
import {
    ValidateAgencyClientRequest,
    ValidateAgencyClientResponse,
} from 'api/contracts/agency/endpoints/validateAgencyClient.contract'

export const useValidateAgencyClientMutation = () => {
    const { showApiErrorToast } = useApiErrorToast()

    return {
        validateAgencyClient: useInvalidateMutation<ValidateAgencyClientResponse, ValidateAgencyClientRequest>(
            AGENCY_CLIENT_VALIDATE,
            async (payload) => agencyController.client.validate(payload),
            { onError: (e) => showApiErrorToast(e) },
        ),
    }
}
