import { Box } from '@mui/material'
import { forwardRef, useEffect, useMemo, useRef } from 'react'
import _get from 'lodash/get'
import { HexColorPicker, RgbaColor, RgbaColorPicker } from 'react-colorful'

import { hexToRgb, rgbToHex } from 'utils/colors'
import { Color, ColorPickerProps } from '../ColorPicker'
import { useColorPickerContext } from '../ColorPicker.context'
import { PERCENTAGE_ADAPTER } from '../Inputs/Opacity'
import style from './Picker.style'

interface PickerProps extends Pick<ColorPickerProps, 'opacityMode'> {
    visible: boolean
    handleChange: (value: Color) => void
}
export const Picker = forwardRef(({ visible, opacityMode, handleChange }: PickerProps, ref) => {
    const timer = useRef<ReturnType<typeof setTimeout>>()
    const { localColor, localOpacity, setLocalColor, setLocalOpacity } = useColorPickerContext()
    const localRgba = useMemo(
        () => ({ ...hexToRgb(localColor), a: Number(localOpacity) / PERCENTAGE_ADAPTER }),
        [localColor, localOpacity],
    )

    const onChange = (c: Color) => {
        clearTimeout(timer.current)

        if (opacityMode) {
            setLocalColor(rgbToHex(c as RgbaColor))
            const a = _get(c, 'a')

            setLocalOpacity(a ? String(Math.round(a * PERCENTAGE_ADAPTER)) : '0')
        }

        timer.current = setTimeout(async () => {
            handleChange(c)
        })
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer.current)
        }
    }, [])

    if (!visible) {
        return null
    }

    return (
        <Box sx={style.container} ref={ref}>
            {opacityMode ? (
                <RgbaColorPicker color={localRgba} onChange={onChange} />
            ) : (
                <HexColorPicker
                    color={localColor}
                    onChange={(c: string) => {
                        // there is a specific scenario
                        // when it sets wrong value
                        // it happens once you use
                        // picker with an empty value
                        onChange(c === '#NaNNaNNaN' ? '#ffffff' : c)
                    }}
                />
            )}
        </Box>
    )
})
