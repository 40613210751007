import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { Link } from 'design/atoms/Link'
import { route } from 'constants/routes'

import style from './style'

const Terms = () => {
    return (
        <Box sx={style.container} data-testid="signUpTerms">
            <Typography variant="caption2" color="text.disabled">
                By clicking &quot;Next Step&quot;, you agree to Vidalytics{' '}
                <Link
                    to={route.static.terms}
                    target="_blank"
                    rel="noopener noreferrer"
                    external
                    data-testid="termsLink"
                >
                    Terms
                </Link>{' '}
                and that you have read our{' '}
                <Link
                    to={route.static.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    external
                    data-testid="policyLink"
                >
                    Privacy Policy
                </Link>
                . Your info is safe with us, we will never share or sell it.
            </Typography>
        </Box>
    )
}

export default Terms
