import { UseMutationOptions } from 'react-query'

import { authAPI } from 'api/controllers'
import { CUSTOMER_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { SignInPayload, SignInResponse } from 'types/Auth'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useSignInMutation = (
    challengeParam: string,
    options?: UseMutationOptions<SignInResponse, any, SignInPayload>,
) => {
    const { mutate } = useMutationToastDecorator({ showPendingToast: false, showSuccessToast: false })

    return useInvalidateMutation<SignInResponse, SignInPayload>(
        CUSTOMER_QUERY,
        async (payload: SignInPayload) => mutate(authAPI.signIn(challengeParam, payload), apiLocale.auth.signIn),
        options,
    )
}
