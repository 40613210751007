import { settingsAPI } from 'api/controllers'
import { VideoDetails } from 'types/Video'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DISCARD_SETTINGS_MUTATION, CLOSED_CAPTIONS_TEXT, VIDEOS_QUERY, CLOSED_CAPTIONS_DRAFT } from 'api/constants'
import { queryClient } from 'api/queries'
import { useInvalidateMutation, Options } from 'api/hooks'

export const useDiscardSettingsMutation = (options?: Options<VideoDetails, string, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoDetails, string, string>(
        DISCARD_SETTINGS_MUTATION,
        async (videoGuid: string) => {
            return mutate(settingsAPI.discardSettings(videoGuid), apiLocale.settings.settings.discard)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, CLOSED_CAPTIONS_DRAFT, CLOSED_CAPTIONS_TEXT],
            onSuccess: (response) => {
                queryClient.setQueriesData([VIDEOS_QUERY, response.video.guid], response)
            },
        },
    )
}
