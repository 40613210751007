import { http } from 'api/utils/http.utils'
import { get } from 'lodash'
import {
    CallToActionConfig,
    ThumbnailSourceType,
    ThumbnailType,
    ThumbnailVideoType,
    Video,
    VideoDetails,
    VideoGuid,
} from 'types/Video'
import { VideoPlayGateConfig } from 'types/VideoPlayGate'
import { TagConfig } from 'types/VideoTag'

export const settingsAPI = {
    loadSettings: (guid: string, fromGuid: string) =>
        http.post<VideoDetails>(`video/${guid}/load-settings/${fromGuid}`),
    discardSettings: (guid: string) => http.post<VideoDetails>(`/video/${guid}/discard-settings`),
    publishSettings: (guid: string) => http.post<VideoDetails>(`/video/${guid}/publish`),

    createCTA: (videoGuid: string, callToAction: CallToActionConfig) =>
        http.post<{ callToAction: CallToActionConfig }>(`/video/${videoGuid}/call-to-action`, { callToAction }),
    deleteCTA: (callToAction: CallToActionConfig) => http.delete<boolean>(`/video/call-to-action/${callToAction.guid}`),
    updateCTA: (callToAction: CallToActionConfig) =>
        http.post<{ callToAction: CallToActionConfig }>(`/video/call-to-action/${callToAction.guid}`, { callToAction }),
    createTag: (videoGuid: string, tag: TagConfig) => http.post<{ tag: TagConfig }>(`/video/${videoGuid}/tag`, { tag }),
    deleteTag: (tag: TagConfig) => http.delete<boolean>(`/video/tag/${tag.guid}`),
    updateTag: (tag: TagConfig) => http.post<{ tag: TagConfig }>(`/video/tag/${tag.guid}`, { tag }),
    createPlayGate: (videoGuid: string, playGate: VideoPlayGateConfig) =>
        http.post<{ playGate: VideoPlayGateConfig }>(`/video/${videoGuid}/play-gate`, { playGate }),
    deletePlayGate: (videoPlayGate: VideoPlayGateConfig) =>
        http.delete<boolean>(`/video/play-gate/${videoPlayGate.guid}`),
    updatePlayGate: (playGate: VideoPlayGateConfig) =>
        http.post<{ playGate: VideoPlayGateConfig }>(`/video/play-gate/${playGate.guid}`, { playGate }),
    updateVideoThumbnail: (videoGuid: VideoGuid, from: number, to: number, type?: ThumbnailVideoType) => {
        return http.post('/video/preview', {
            guid: videoGuid,
            from,
            to,
            type,
        })
    },
    updateThumbnail: (videoGuid: VideoGuid, file: Blob | File, thumbnailType: ThumbnailType) => {
        const input = new FormData()
        const name = get(file, 'name') as string

        if (name) {
            //eslint-disable-next-line
            const fileName = name.replace(/[&\/\\#,+()$~%'":*?<>{} ]/g, '')
            input.append('fileName', file, fileName)
        } else {
            input.append('fileName', file)
        }

        let url: string
        switch (thumbnailType) {
            case ThumbnailType.mobileThumbnail:
                url = `/video/${videoGuid}/thumbnail/mobile`
                break
            case ThumbnailType.customPauseScreen:
                url = `/video/${videoGuid}/thumbnail/custom-pause-screen`
                break
            default:
                url = `/video/${videoGuid}/thumbnail`
        }

        return http.post<FormData>(url, input)
    },
    setSameMobileThumbnail: ({
        videoGuid,
        thumbnailType,
    }: {
        videoGuid: VideoGuid
        thumbnailType: ThumbnailSourceType
    }) => {
        return http.post(`/video/${videoGuid}/thumbnail/use`, { type: thumbnailType })
    },
    deleteThumbnail: (videoGuid: VideoGuid, thumbnailType: ThumbnailType) => {
        let url: string

        switch (thumbnailType) {
            case ThumbnailType.mobileThumbnail:
                url = `/video/${videoGuid}/thumbnail/mobile`
                break
            case ThumbnailType.customPauseScreen:
                url = `/video/${videoGuid}/thumbnail/custom-pause-screen`
                break
            default:
                url = `/video/${videoGuid}/thumbnail`
        }

        return http.delete(url)
    },
    updateClosedCaptions: (video: Video, file: string | Blob) => {
        const input = new FormData()
        input.append('fileName', file)

        return http.post<FormData>(`/video/${video.guid}/closed-captions`, input)
    },
    postClosedCaptions: (video: Video, file: string | Blob) => {
        const input = new FormData()
        input.append('fileName', file)

        return http.post<FormData>(`/video/${video.guid}/closed-captions/edit`, input)
    },
    editClosedCaptions: (video: Video, file: string | Blob) => {
        const input = new FormData()
        input.append('fileName', file)

        return http.put<FormData>(`/video/${video.guid}/closed-captions/edit`, input)
    },
    generateClosedCaptions: (guid: string) => {
        return http.post(`/video/generate-closed-captions${guid ? `/${guid}` : ''}`)
    },
    generateClosedCaptionsCancel: (guid: string) => {
        return http.delete(`/video/generate-closed-captions${guid ? `/${guid}` : ''}`)
    },
    readClosedCaptionsText: (url: string) => {
        return http.get(url, { withCredentials: false })
    },
    readClosedCaptionsDraft: (guid: string) => {
        return http.get(`/video/${guid}/closed-captions/draft`)
    },
    deleteClosedCaptions: (video: Video) => {
        return http.delete(`/video/${video.guid}/closed-captions`)
    },
    removeClosedCaptions: (video: Video) => {
        return http.delete(`/video/${video.guid}/closed-captions/edit`)
    },
}
