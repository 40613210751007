import { FormikContextType, useFormikContext } from 'formik'
import { cloneDeep, isEqual, once } from 'lodash'
import { createContext, ReactNode, useContext, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { useVideoQuery } from 'api/queries'
import { useSmartVidPageContext } from 'design/pages/SmartVid/contexts/smartVidPageContext'
import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import { VideoDetails } from 'types/Video'

interface ContextState<T> {
    video?: VideoDetails
    formik: FormikContextType<T>
    isLoading: boolean
    onChange: () => void
}

const createSettingsSectionContext = once(<T,>() => createContext({} as ContextState<T>))
const useSettingsSectionContext = <T,>() => useContext(createSettingsSectionContext<T>())

type Props = {
    children?: ReactNode
}

function SettingsSectionProvider<T>({ children }: Props) {
    const formik = useFormikContext<T>()
    const valuesRef = useRef(formik.values)
    const { videoGuid, stepVidId, smartVidId } = useParams()
    const { data: video, isLoading: isVideoQueryLoading } = useVideoQuery(videoGuid || stepVidId)
    const { updateVideoMutation } = useVidSettingsContext()

    const { editingDisabled } = smartVidId ? useSmartVidPageContext() : { editingDisabled: false }

    const timer = useRef<ReturnType<typeof setTimeout>>()

    valuesRef.current = formik.values

    const replaceNewlinesWithBr = (inputString: string) => {
        return inputString?.replace(/\n/g, '<br>')
    }

    const getUpdatedVideoDetails = (videoDetails: VideoDetails): VideoDetails => {
        const background = videoDetails.settings.ui_color_background
        const foreground = videoDetails.settings.ui_color_foreground

        return {
            ...videoDetails,
            settings: {
                ...videoDetails.settings,
                ui_overlay_unmute_color_background: background,
                ui_overlay_unmute_color_foreground: foreground,
                ui_overlay_resume_color_background: background,
                ui_overlay_resume_color_foreground: foreground,
                ui_overlay_redirect_color_foreground: foreground,
                ui_overlay_redirect_color_background: background,
                ui_overlay_redirect_text: replaceNewlinesWithBr(videoDetails?.settings?.ui_overlay_redirect_text),
                ui_overlay_resume_button_replay_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_resume_button_replay_text,
                ),
                ui_overlay_resume_button_resume_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_resume_button_resume_text,
                ),
                ui_overlay_resume_text: replaceNewlinesWithBr(videoDetails?.settings?.ui_overlay_resume_text),
                ui_overlay_unmute_mobile_textBottom_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_mobile_textBottom_text,
                ),
                ui_overlay_unmute_mobile_textTop_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_mobile_textTop_text,
                ),
                ui_overlay_unmute_textBottom_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_textBottom_text,
                ),
                ui_overlay_unmute_textTop_text: replaceNewlinesWithBr(
                    videoDetails?.settings?.ui_overlay_unmute_textTop_text,
                ),
                ui_overlay_expire_textTop_text: replaceNewlinesWithBr(
                    String(videoDetails?.settings?.ui_overlay_expire_textTop_text),
                ),
                ui_overlay_expire_textBottom_text: replaceNewlinesWithBr(
                    String(videoDetails?.settings?.ui_overlay_expire_textBottom_text),
                ),
            },
        }
    }

    const runColorDependency = (current: VideoDetails, prev: VideoDetails): VideoDetails => {
        const currentIsColorForce = current.settings.ui_color_force

        if (currentIsColorForce) {
            let currentUpdated = cloneDeep(current)
            const prevIsColorForce = prev?.settings.ui_color_force

            if (prevIsColorForce) {
                const prevColorSettings = {
                    ui_overlay_unmute_color_background: prev?.settings.ui_overlay_unmute_color_background,
                    ui_overlay_unmute_color_foreground: prev?.settings.ui_overlay_unmute_color_foreground,
                    ui_overlay_resume_color_background: prev?.settings.ui_overlay_resume_color_background,
                    ui_overlay_resume_color_foreground: prev?.settings.ui_overlay_resume_color_foreground,
                    ui_overlay_redirect_color_foreground: prev?.settings.ui_overlay_redirect_color_foreground,
                    ui_overlay_redirect_color_background: prev?.settings.ui_overlay_redirect_color_background,
                }

                const currentColorSettings = {
                    ui_overlay_unmute_color_background: current.settings.ui_overlay_unmute_color_background,
                    ui_overlay_unmute_color_foreground: current.settings.ui_overlay_unmute_color_foreground,
                    ui_overlay_resume_color_background: current.settings.ui_overlay_resume_color_background,
                    ui_overlay_resume_color_foreground: current.settings.ui_overlay_resume_color_foreground,
                    ui_overlay_redirect_color_foreground: current.settings.ui_overlay_redirect_color_foreground,
                    ui_overlay_redirect_color_background: current.settings.ui_overlay_redirect_color_background,
                }

                if (isEqual(prevColorSettings, currentColorSettings)) {
                    currentUpdated = getUpdatedVideoDetails(currentUpdated)
                } else {
                    currentUpdated.settings.ui_color_force = false
                }
            } else {
                currentUpdated = getUpdatedVideoDetails(currentUpdated)
            }

            return currentUpdated
        }

        return current
    }

    const vidStatsProps = {
        video,
        formik,
        isLoading: isVideoQueryLoading || updateVideoMutation?.isLoading || editingDisabled,
        onChange: () => {
            clearTimeout(timer.current)

            if (!video) return

            timer.current = setTimeout(() => {
                const payload = runColorDependency(
                    {
                        ...video,
                        settings: {
                            ...video.settings,
                            ...valuesRef.current,
                        },
                    },
                    video,
                )
                updateVideoMutation.mutate(payload)
            }, 500)
        },
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer.current)
        }
    }, [])

    const SettingsSectionContext = createSettingsSectionContext<T>()

    return <SettingsSectionContext.Provider value={vidStatsProps}>{children}</SettingsSectionContext.Provider>
}

export { SettingsSectionProvider, useSettingsSectionContext }
