import { VideoStartupQuality } from 'types/Player'
import locale from './PlayOptionsContent.locale'

export const videoStartupQualityOptions = [
    {
        value: VideoStartupQuality.AUTO,
        ...locale.startupQuality.qualityOptions.auto,
    },
    {
        value: VideoStartupQuality.LOW,
        ...locale.startupQuality.qualityOptions.low,
    },
    {
        value: VideoStartupQuality.MEDIUM,
        ...locale.startupQuality.qualityOptions.medium,
    },
    {
        value: VideoStartupQuality.HIGH,
        ...locale.startupQuality.qualityOptions.high,
    },
]

export const replaceBrToLineBreak = (value: string) => value?.replace(/<br\s*\/?>/gi, '\n')
