import { UseMutationOptions, useQueryClient } from 'react-query'

import { FOLDER } from 'design/atoms/Table/constants'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { Folder, FolderAPI, CreateFolderPayload } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEO_FOLDERS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'

export const useAddFolderMutation = (options?: UseMutationOptions<FolderAPI, unknown, CreateFolderPayload>) => {
    const queryClient = useQueryClient()
    const key = VIDEO_FOLDERS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<FolderAPI, CreateFolderPayload>(
        key,
        async (payload: CreateFolderPayload) => mutate(videoAPI.createFolder(payload), apiLocale.video.createFolder),
        {
            ...options,
            onSuccess: async (...args) => {
                await queryClient.cancelQueries(key)

                const [data] = args

                const previousData = queryClient.getQueryData(key) as Folder[]

                queryClient.setQueryData(key, [{ ...data, type: FOLDER, autoFocus: true }, ...(previousData || [])])

                options?.onSuccess?.(...args)
            },
        },
    )
}
