import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

export const useMixpanelInit = () => {
    useEffect(() => {
        const projectToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN
        const proxyDomain = process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN
        if (!projectToken || !proxyDomain) return

        mixpanel.init(projectToken, {
            api_host: proxyDomain,
            persistence: 'cookie',
            cross_subdomain_cookie: true,
            // whether to ignore or respect the web browser's Do Not Track setting
            ignore_dnt: true,
        })
    }, [])
}
