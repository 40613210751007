import { valueFormatter, valueOrDefault, currencyOrPercentageOrDefault, formatAsPercentageOrDefault } from 'utils'

export enum MetricKeys {
    plays = 'plays',
    playRate = 'playRate',
    impressions = 'impressions',
    playsUnique = 'playsUnique',
    unmuteRate = 'unmuteRate',
    engagement = 'engagement',
    conversionCount = 'conversionCount',
    conversionRate = 'conversionRate',
    revenueAverage = 'revenueAverage',
    pgOptInRate = 'pgOptInRate',
    revenue = 'revenue',
    revenuePerViewer = 'revenuePerViewer',
    bounceRate = 'bounceRate',
}

export type Metrics = {
    [MetricKeys.plays]?: number
    [MetricKeys.playRate]?: number
    [MetricKeys.impressions]?: number
    [MetricKeys.playsUnique]?: number | boolean
    [MetricKeys.unmuteRate]: number
    [MetricKeys.engagement]?: number
    [MetricKeys.conversionCount]?: number | boolean
    [MetricKeys.conversionRate]: number
    [MetricKeys.revenueAverage]?: number
    [MetricKeys.pgOptInRate]: number
    [MetricKeys.revenue]?: number
    [MetricKeys.revenuePerViewer]?: number
    [MetricKeys.bounceRate]?: number
}

export type ColsVisibilityState = {
    [key in MetricKeys]: boolean
}

export const StatsTableColumns = [
    {
        field: MetricKeys.plays,
        headerName: 'Plays',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.playRate,
        headerName: 'Play Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.impressions,
        headerName: 'Impressions',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.playsUnique,
        headerName: 'Unique Viewers',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.unmuteRate,
        headerName: 'Unmute Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.engagement,
        headerName: 'Avg % Watched',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.conversionCount,
        headerName: 'Conversions',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.conversionRate,
        headerName: 'Conversions Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.revenueAverage,
        headerName: 'Average Order Value',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: MetricKeys.pgOptInRate,
        headerName: 'Opt-In Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(formatAsPercentageOrDefault),
    },
    {
        field: MetricKeys.revenue,
        headerName: 'Revenue',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: MetricKeys.revenuePerViewer,
        headerName: 'Revenue per Viewer',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number | string>(currencyOrPercentageOrDefault),
    },
    {
        field: MetricKeys.bounceRate,
        headerName: 'Bounce Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
]

export interface SetColVisibilityPayload {
    key: MetricKeys
    value: boolean
}

export enum DayOfWeek {
    Monday = 1,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday,
}
