import { Shadows, createTheme } from '@mui/material'

const theme = createTheme()

const shadows: Shadows = [...theme.shadows]
const shadowsToReplace = {
    1: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
    2: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    4: '0px 2px 5px 0px rgba(16, 24, 40, 0.22)',
    6: '0px 6px 14px 0px rgba(0, 0, 0, 0.16)',
}

Object.entries(shadowsToReplace).forEach(([key, value]) => {
    shadows[Number(key)] = value
})

export { shadows }
