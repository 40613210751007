import { Dispatch, SetStateAction, useState } from 'react'
import { useFormikContext } from 'formik'
import { useGoogleLogin } from '@react-oauth/google'
import { useToast } from 'design/organisms/ToastProvider'
import mixpanel from 'mixpanel-browser'

import { Button } from '@mui/material'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { GoogleAccount, SignUpFormData, PlanPayload } from 'types/Auth'
import { useGoogleAccountQuery } from 'api/queries'
import { generateRandomPassword } from 'utils'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { EMAIL, FIRSTNAME, LASTNAME, PASSWORD } from 'constants/validations/user.constants'
import useSuccessHandleForExtraModule from '../hooks/useSuccessHandleForExtraModule'
import locale from './google-button.locale'

import style from './style'

type GoogleButtonProps = {
    onSuccess: () => void
    disabled: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
    plan: PlanPayload
}

const GoogleButton = ({ onSuccess, disabled, setIsLoading, plan }: GoogleButtonProps) => {
    const [accessToken, setAccessToken] = useState('')
    const { values, setValues } = useFormikContext<SignUpFormData>()
    const formValuesRef = useSuccessHandleForExtraModule(onSuccess)
    const { showToast } = useToast()

    const onAccountRequestSuccess = async (data: GoogleAccount | null) => {
        if (!data?.email) {
            showToast({
                message: locale.notifications.errors.dataUnavailable,
                type: 'error',
            })

            return
        }

        try {
            formValuesRef.current = {
                ...values,
                [FIRSTNAME]: data.given_name || '',
                [LASTNAME]: data.family_name || '',
                [EMAIL]: data.email || '',
                [PASSWORD]: generateRandomPassword(),
            }

            setValues(formValuesRef.current)
        } catch (_) {
            showToast({
                message: locale.notifications.errors.parseFailure,
                type: 'error',
            })
        }
    }

    useGoogleAccountQuery(accessToken, {
        onSuccess: onAccountRequestSuccess,
        onError: (error) =>
            showToast({
                message: error.message,
                type: 'error',
            }),
    })

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setIsLoading(false)

            if (!codeResponse.access_token) {
                showToast({
                    message: locale.notifications.errors.genericWithCta,
                    type: 'error',
                })

                return
            }

            setAccessToken(codeResponse.access_token)
        },
        onError: (errorResponse) => {
            setIsLoading(false)

            showToast({
                message: errorResponse.error || locale.notifications.errors.generic,
                type: 'error',
            })
        },
    })

    const handleClick = () => {
        mixpanel.track(MIXPANEL_EVENTS.SIGN_UP_WITH_GOOGLE_CLICKED, {
            subscription_tier: plan.tier,
            subscription_id: plan.apiHandle,
            subscription_istrial: plan.hasTrial,
        })
        login()
        setIsLoading(true)
    }

    return (
        <Button
            onClick={handleClick}
            disabled={disabled}
            variant="outlined"
            color="tertiary"
            startIcon={<img src="/img/icons/google.svg" />}
            fullWidth
            sx={style.button}
            data-testid="googleSSO"
        >
            {locale.cta}
        </Button>
    )
}

export default withErrorBoundary(GoogleButton)
