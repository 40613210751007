import { SxProps, Theme } from '@mui/material'

const container: SxProps<Theme> = {
    zIndex: 9999,
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
}

const backdrop: SxProps<Theme> = {
    background: 'rgba(0, 0, 0, 0.50)',
    flexGrow: 1,
}

const wrapper: SxProps<Theme> = {
    position: 'absolute',
    textAlign: 'center',
    background: 'white',
    left: 0,
    bottom: 0,
    right: 0,
    pt: 12,
    px: 8,
    pb: 8,
    borderTopLeftRadius: (theme) => theme.shape.borderRadius * 4,
    borderTopRightRadius: (theme) => theme.shape.borderRadius * 4,
}

const title: SxProps<Theme> = {
    pb: 4,
    fontWeight: 700,
}

const subtitle: SxProps<Theme> = {
    pb: 8,
}

const button: SxProps<Theme> = {
    mb: 3,
}

const close: SxProps<Theme> = {
    position: 'absolute',
    top: 4,
    right: 4,
}

export default {
    backdrop,
    container,
    wrapper,
    title,
    subtitle,
    button,
    close,
}
