/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Cookies } from 'react-cookie'

import { PROM_REF, UPSELL_TOKEN_COOKIE } from 'design/pages/SignUp/constants'
import { authAPI } from 'api/controllers'
import { queryClient } from 'api/queries'
import { CUSTOMER_QUERY, X_CLIENT_ID } from 'api/constants'
import { AGENCY_CLIENT_ID } from 'constants/local-storage.constants'
import { route } from 'constants/routes'
import { ACCESS_TOKEN, REFRESH_TOKEN, COOKIES_OPTIONS } from 'constants/cookies.constants'

export enum StatusCode {
    Unauthorized = 401,
    Forbidden = 403,
    TooManyRequests = 429,
    InternalServerError = 500,
}

const retryCount = 10
const retryDelay = 1000

export const headers: Readonly<Record<string, string | boolean>> = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Credentials': true,
    'X-Requested-With': 'XMLHttpRequest',
}

const interceptorsRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    try {
        if (/^https?:\/\//.test(config.url || '')) {
            config.baseURL = ''
        } else {
            config.baseURL = process.env.REACT_APP_BASE_API_URL
        }

        const token = new Cookies().get(ACCESS_TOKEN)
        const agencyClientId = localStorage.getItem(AGENCY_CLIENT_ID)

        if (agencyClientId && config.headers && !config.url?.includes('agenc')) {
            config.headers[X_CLIENT_ID] = agencyClientId
        }

        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`
        }

        if (config.headers?.AuthorizationOverride) {
            config.headers.Authorization = config.headers.AuthorizationOverride
            delete config.headers.AuthorizationOverride
        }

        if (config.withCredentials === false) {
            delete config.headers
        }

        return config
    } catch (error) {
        throw new Error(error as string)
    }
}

type axiosError = {
    status: StatusCode
    response?: {
        status: StatusCode
    }
}

class Http {
    private instance: AxiosInstance | null = null
    private count = retryCount
    private get http(): AxiosInstance {
        return this.instance != null ? this.instance : this.initHttp()
    }
    initHttp() {
        const instance = axios.create({
            baseURL: process.env.REACT_APP_BASE_API_URL,
            headers,
            withCredentials: true,
        })

        instance.interceptors.request.use(interceptorsRequest, (error) => Promise.reject(error))

        instance.interceptors.response.use(
            (response) => {
                if (this.count < retryCount) {
                    this.count = retryCount
                }
                return response?.data
            },
            async (error) => {
                const originalConfig = error.config
                if (error.code === 'ERR_NETWORK') {
                    if (!this.count) {
                        return Promise.reject(error)
                    }
                    this.count--
                    const delayRetryRequest = new Promise<void>((resolve) => {
                        setTimeout(() => {
                            resolve()
                        }, retryDelay)
                    })
                    return delayRetryRequest.then(() => instance(originalConfig))
                }

                if (originalConfig?.url !== '/auth/signin' && error.response) {
                    const cookies = new Cookies()
                    const refreshToken = cookies.get(REFRESH_TOKEN)
                    // Access Token was expired for remember me
                    if (error.response.status === 401 && !originalConfig._retry && refreshToken) {
                        originalConfig._retry = true
                        try {
                            cookies.remove(ACCESS_TOKEN, COOKIES_OPTIONS)
                            const response = await authAPI.refreshToken({
                                ...originalConfig,
                                headers: {
                                    ...originalConfig?.headers,
                                    Authorization: `Bearer ${refreshToken}`,
                                },
                            })
                            if (!response) {
                                return this.handleError(error, true)
                            }

                            cookies.set(ACCESS_TOKEN, response.access, COOKIES_OPTIONS)
                            cookies.set(REFRESH_TOKEN, response.refresh, COOKIES_OPTIONS)

                            return instance(originalConfig)
                        } catch (_error) {
                            return this.handleError(error, true)
                        }
                    }
                }
                return this.handleError(error, originalConfig?.url === 'customer')
            },
        )

        this.instance = instance
        return instance
    }

    request<R = any>(config: AxiosRequestConfig): Promise<R> {
        return this.http.request(config)
    }

    get<T = any, R = any>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(url, config)
    }

    post<T = any, R = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
        return this.http.post<T, R>(url, data, config)
    }

    put<T = any, R = any>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
        return this.http.put<T, R>(url, data, config)
    }

    delete<T = any, R = any>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.delete<T, R>(url, config)
    }

    private async handleError(error: axiosError, resetAuth = false) {
        const status = error.status || error.response?.status

        if (
            resetAuth ||
            (status === StatusCode.Unauthorized &&
                ![route.auth.signIn, route.auth.signUp.index].some((loc) => location.pathname.includes(loc)))
        ) {
            queryClient.setQueryData(CUSTOMER_QUERY, null)

            const cookies = new Cookies()

            cookies.remove(ACCESS_TOKEN, COOKIES_OPTIONS)
            cookies.remove(REFRESH_TOKEN, COOKIES_OPTIONS)
            cookies.remove(UPSELL_TOKEN_COOKIE, COOKIES_OPTIONS)
            cookies.remove(PROM_REF, COOKIES_OPTIONS)
        }

        return Promise.reject(error)
    }
}

export const http = new Http()
