import { SignUpFormData } from 'types/Auth'
import { URLParams } from 'utils'
import { EMAIL, PASSWORD } from 'constants/validations/user.constants'

import {
    FIRSTNAME,
    LASTNAME,
    BUSINESSNAME,
    CREDIT_FIRSTNAME,
    CREDIT_LASTNAME,
    CARD_NUMBER,
    EXPIRATION_DATE,
    EXPIRATION_MONTH,
    EXPIRATION_YEAR,
    CVV,
    ZIPCODE,
    PLAN,
    CUSTOMER,
    CREDIT_CARD,
    API_HANDLE,
    FULL,
    CREDIT_CARD_TOKEN,
    UTM,
    COUPON_CODES,
    REFERRER,
    SOURCE,
    AGENCY_INVITATION_TOKEN,
} from './constants'

type Cookies = {
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    [x: string]: any
}

type GeneratePayloadData = {
    values: SignUpFormData
    cookies: Cookies
    type: string
    pricepoint: string | null
    utm: URLParams | null
    referrer: string | undefined
    couponCodes: string[] | null
    token?: string
    agencyInvitationToken?: string | null
}

export const getCreditCardInfo = (values: SignUpFormData) => {
    const [month, year] = values[EXPIRATION_DATE].split('/')

    return {
        [FIRSTNAME]: values[CREDIT_FIRSTNAME],
        [LASTNAME]: values[CREDIT_LASTNAME],
        [CARD_NUMBER]: values[CARD_NUMBER].replace(/\s/g, ''),
        [CVV]: values[CVV],
        [EXPIRATION_MONTH]: Number(month),
        [EXPIRATION_YEAR]: Number(year),
        [ZIPCODE]: values[ZIPCODE],
    }
}

export const getCreditCardInfoWithStringDates = (values: SignUpFormData) => {
    const creditCard = getCreditCardInfo(values)

    return {
        ...creditCard,
        [EXPIRATION_MONTH]: String(creditCard[EXPIRATION_MONTH]),
        [EXPIRATION_YEAR]: `${String(new Date().getFullYear()).slice(0, 2)}${creditCard[EXPIRATION_YEAR]}`,
    }
}

export const generatePayload = ({
    values,
    cookies,
    type,
    pricepoint,
    utm,
    referrer,
    couponCodes,
    token,
    agencyInvitationToken,
}: GeneratePayloadData) => {
    return {
        [PLAN]: {
            [API_HANDLE]: cookies[API_HANDLE],
            pricePoint: pricepoint,
        },
        [CUSTOMER]: {
            ...(values[BUSINESSNAME] && { [BUSINESSNAME]: values[BUSINESSNAME] }),
            [FIRSTNAME]: values[FIRSTNAME],
            [LASTNAME]: values[LASTNAME],
            [EMAIL]: values[EMAIL],
            [PASSWORD]: values[PASSWORD],
        },
        [SOURCE]: values[SOURCE],
        ...(token
            ? { [CREDIT_CARD_TOKEN]: token }
            : { [CREDIT_CARD]: type === FULL && !token ? getCreditCardInfo(values) : null }),
        ...(utm && { [UTM]: utm }),
        ...(referrer && { [REFERRER]: referrer }),
        ...(couponCodes && { [COUPON_CODES]: couponCodes }),
        ...(agencyInvitationToken && { [AGENCY_INVITATION_TOKEN]: agencyInvitationToken }),
    }
}
