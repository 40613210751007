import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { UPDATE_TAG_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { TagConfig } from 'types/VideoTag'

export const useUpdateTagMutation = (options?: Options<TagConfig, TagConfig, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<TagConfig, TagConfig, string>(
        UPDATE_TAG_MUTATION,
        async (tag: TagConfig) => {
            return mutate(settingsAPI.updateTag(tag), apiLocale.settings.tag.update)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
