import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { UPDATE_CTA_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { CallToActionConfig } from 'types/Video'

export const useUpdateCTAMutation = (options?: Options<CallToActionConfig, CallToActionConfig, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<CallToActionConfig, CallToActionConfig, string>(
        UPDATE_CTA_MUTATION,
        async (callToAction: CallToActionConfig) => {
            return mutate(settingsAPI.updateCTA(callToAction), apiLocale.settings.cta.update)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
