import { useQuery, UseQueryOptions } from 'react-query'

import { Invoice } from 'types/Customer'
import { userAPI } from 'api/controllers'
import { INVOICES_QUERY } from 'api/constants'

export const useInvoicesQuery = (options?: UseQueryOptions<Invoice[], unknown>) => {
    return useQuery<Invoice[]>(INVOICES_QUERY, () => userAPI.getInvoices(), {
        ...options,
    })
}
