export default {
    creditCard: {
        number: {
            label: 'Card Number',
            placeholder: '**** **** **** ****',
        },
        cvv: {
            label: 'CVV',
            placeholder: '•••',
        },
        expiry: {
            month: {
                label: 'Month',
                placeholder: 'MM',
            },
            year: {
                label: 'Year',
                placeholder: 'YYYY',
            },
        },
    },
    user: {
        email: {
            label: 'Email',
            placeholder: 'Enter your email',
        },
        name: {
            label: 'Name',
            placeholder: 'Enter your name',
        },
        firstName: {
            label: 'First Name',
            placeholder: 'Enter your name',
        },
        lastName: {
            label: 'Last Name',
            placeholder: 'Enter your last name',
        },
        password: {
            label: 'Password',
            placeholder: 'Enter your password',
        },
        newPassword: {
            label: 'New Password',
            placeholder: 'Enter your new password',
        },
        confirmPassword: {
            label: 'Confirm Password',
            placeholder: 'Enter your password confirmation',
        },
    },
    address: {
        zip: {
            label: 'ZIP Code',
            placeholder: '#####',
        },
    },
    common: {
        placeholder: 'Type here...',
    },
}
