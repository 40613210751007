import { SxProps, Theme } from '@mui/material'
import { ReactNode } from 'react'

const gradient = {
    ukraine: 'logo-gradient__Ukraine',
    smooth: 'logo-gradient__smooth',
}

export interface LogoTheme {
    root?: SxProps<Theme>
    logo?: SxProps<Theme>
    sign?: SxProps<Theme>
    letter?: {
        [K in 'v' | 'i' | 'd' | 'a' | 'l' | 'y' | 't' | 'c' | 's']?: SxProps<Theme>
    }
    gradient?: ReactNode
}

const basic: LogoTheme = {
    root: {
        fill: (theme) => theme.palette.primary.main,
    },
}

const light: LogoTheme = {
    root: {
        fill: (theme) => theme.palette.common.white,
    },
}

const dark: LogoTheme = {
    root: {
        fill: (theme) => theme.palette.text.primary,
    },
}

const Ukraine: LogoTheme = {
    logo: {
        fill: `url(#${gradient.ukraine})`,
    },
    sign: {
        fill: (theme) => theme.palette.text.primary,
    },
    gradient: (
        <defs>
            <linearGradient
                id={gradient.ukraine}
                x1="13.4738"
                y1="4"
                x2="13.5001"
                y2="28.0027"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.0548612" stopColor="#0158B9" />
                <stop offset="0.364583" stopColor="#0158B9" />
                <stop offset="0.364683" stopColor="#FFD801" />
                <stop offset="1" stopColor="#FFD801" />
            </linearGradient>
        </defs>
    ),
}

const smooth: LogoTheme = {
    logo: {
        fill: `url(#${gradient.smooth})`,
    },
    sign: {
        fill: (theme) => theme.palette.text.primary,
    },
    gradient: (
        <defs>
            <linearGradient
                id={gradient.smooth}
                x1="-9.30675"
                y1="4.58891"
                x2="-3.14204"
                y2="29.1416"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#55AFEF" />
                <stop offset="1" stopColor="#376EFF" />
            </linearGradient>
        </defs>
    ),
}

const theme = {
    basic,
    light,
    dark,
    Ukraine,
    smooth,
}

export type LogoThemes = keyof typeof theme

export default theme
