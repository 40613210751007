import {
    CONTROLS_SECTION_INITIAL_VALUES,
    PLAYBACK_SECTION_INITIAL_VALUES,
    PLAY_OPTIONS_INITIAL_VALUES,
    CALLS_TO_ACTION_SECTION_INITIAL_VALUES,
    PLAY_GATES_SECTION_INITIAL_VALUES,
    TAGS_SECTION_INITIAL_VALUES,
    THUMBNAILS_SECTION_INITIAL_VALUES,
    CLOSED_CAPTIONS_SECTION_INITIAL_VALUES,
    EXPIRE_VIDEO_SECTION_INITIAL_VALUES,
} from 'design/pages/VidSettings/constants'

export type ControlsSectionFormData = Omit<typeof CONTROLS_SECTION_INITIAL_VALUES, 'ui_controlBar_settings'>
export type ThumbnailsSectionFormData = typeof THUMBNAILS_SECTION_INITIAL_VALUES
export type ClosedCaptionsSectionFormData = typeof CLOSED_CAPTIONS_SECTION_INITIAL_VALUES

export type PlaybackSectionFormData = typeof PLAYBACK_SECTION_INITIAL_VALUES
export type PlayOptionsFormData = typeof PLAY_OPTIONS_INITIAL_VALUES
export type ExpireVideoSectionFormData = typeof EXPIRE_VIDEO_SECTION_INITIAL_VALUES

export type CallsToActionsSectionFormData = typeof CALLS_TO_ACTION_SECTION_INITIAL_VALUES
export type PlayGatesSectionFormData = typeof PLAY_GATES_SECTION_INITIAL_VALUES
export type TagsSectionFormData = typeof TAGS_SECTION_INITIAL_VALUES

export enum ThumbnailSource {
    image = 'image',
    video = 'video',
}
