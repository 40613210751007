import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import locale from './ClosedCaptionsZoneLoader.locale'
import style from './ClosedCaptionsZoneLoader.style'

type ClosedCaptionsZoneLoaderPropsType = {
    isGenerating?: boolean
    text?: string
    handleCancel?: () => void
}

const ClosedCaptionsZoneLoader = ({ handleCancel, text, isGenerating = false }: ClosedCaptionsZoneLoaderPropsType) => {
    return (
        <Box sx={style.root}>
            <Box textAlign="center">
                <CircularProgress />
                {!text && <Typography mt={6}>{isGenerating ? locale.generating : locale.loading}</Typography>}
                {text && <Typography mt={6}>{text}</Typography>}
                {isGenerating && (
                    <>
                        <Typography color="text.secondary">{locale.takeSomeTime}</Typography>
                        <Button
                            onClick={() => handleCancel?.()}
                            sx={style.cancelBtn}
                            variant="contained"
                            color="action"
                            size="small"
                        >
                            {locale.cancel}
                        </Button>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default ClosedCaptionsZoneLoader
