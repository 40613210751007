import { SvgIcon, Components } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const MuiCheckbox: Components<Omit<Theme, 'components'>>['MuiCheckbox'] = {
    defaultProps: {
        checkedIcon: (
            <SvgIcon viewBox="0 0 18 18">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4ZM13.5893 7.58928C13.9147 7.26386 13.9147 6.73624 13.5893 6.41082C13.2639 6.08539 12.7362 6.08539 12.4108 6.41082L7.50005 11.3216L5.58928 9.41082C5.26386 9.08539 4.73624 9.08539 4.41082 9.41082C4.08539 9.73624 4.08539 10.2639 4.41082 10.5893L6.91082 13.0893C7.23624 13.4147 7.76386 13.4147 8.08928 13.0893L13.5893 7.58928Z"
                />
            </SvgIcon>
        ),
        indeterminateIcon: (
            <SvgIcon viewBox="0 0 18 18">
                <path d="M14 0H4.04932C1.5 0 0 2 0 4.13133V14.1914C0 16.3078 2 18 4.05 18H14C17 18 18 15.29 18 14.19V4.13C18 2 16.5 0 14 0ZM14 9C14 9.55229 13.5523 10 13 10H5C4.44772 10 4 9.55229 4 9C4 8.44771 4.44772 8 5 8H13C13.5523 8 14 8.44771 14 9Z" />
            </SvgIcon>
        ),
        icon: (
            <SvgIcon viewBox="0 0 18 18">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 4C0 1.79086 1.79086 0 4 0H14C16.2091 0 18 1.79086 18 4V14C18 16.2091 16.2091 18 14 18H4C1.79086 18 0 16.2091 0 14V4ZM1 4.10504C1 2.10504 2.5 1 4 1H13.9023C15.9023 1 17 2.5 17 4.05859V13.9102C17 15.5 15.9555 17 13.8555 17H4.08934C2.08934 17 1 15.5 1 13.91V4.10504Z"
                    fill="#2E3B54"
                    fillOpacity="0.56"
                />
            </SvgIcon>
        ),
    },
    styleOverrides: {
        root: ({ ownerState }) => ({
            padding: '12px',
            marginTop: '-12px',
            marginBottom: '-12px',

            ...(ownerState.size === 'small' && {
                svg: {
                    fontSize: 15,
                },
            }),

            ...(ownerState.size === 'medium' && {
                svg: {
                    fontSize: 18,
                },
            }),

            ...(ownerState.size === 'large' && {
                svg: {
                    fontSize: 21,
                },
            }),
        }),
    },
}
