export const COLUMN = {
    ID: 'id',
    GUID: 'guid',
    DATA: 'data',
    DATE_CREATED: 'dateCreated',
    DATE_UPDATED: 'dateUpdated',
    DATE_PUBLISHED: 'datePublished',
} as const

export const PAGE_SIZE_STATS = [100, 250, 500]
