export type Conversion = {
    guid: string
    title: string
    value: string
    count: number
    countType: ConversionCountType
    conversionWindow: string
    dateCreated: string
    dateUpdated: string | null
}

export type ConversionFormData = Omit<Conversion, 'guid' | 'count' | 'dateCreated' | 'dateUpdated'>

export type ConversionPayload = {
    conversion: ConversionFormData
}

export type UpdateConversionPayload = {
    conversion: Omit<Conversion, 'count' | 'dateCreated' | 'dateUpdated'>
}

export enum ConversionCountType {
    EVERY = 'every',
    ONE_PER_VIEWER = 'one_per_viewer',
}

export enum ConversionWindow {
    Week = 7,
    '30 days' = 30,
    '60 days' = 60,
    '90 days' = 90,
    Year = 365,
}

export type ConversionEmbedCode = {
    code: string
}
