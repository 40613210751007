import { UseMutationOptions } from 'react-query'
import mixpanel from 'mixpanel-browser'

import { integrationsAPI } from 'api/controllers'
import { ZapierStatus, ZapierStatusUpdatePayload, IntegrationName } from 'types/Integrations'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { ZAPPIER_STATUS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useChangeZapierStatusMutation = (
    options?: UseMutationOptions<ZapierStatus, any, ZapierStatusUpdatePayload>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<ZapierStatus, ZapierStatusUpdatePayload>(
        ZAPPIER_STATUS_QUERY,
        async (payload: ZapierStatusUpdatePayload) =>
            mutate(integrationsAPI.changeZapierStatus(payload), apiLocale.integration.changeStatus),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
            onSuccess: (...args) => {
                const [{ isEnabled }] = args
                if (isEnabled) {
                    mixpanel.track(MIXPANEL_EVENTS.INTEGRATION_ENABLED, { type: IntegrationName.Zapier })
                }
                options?.onSuccess?.(...args)
            },
        },
    )
}
