import { SxProps, Theme } from '@mui/material'

const container: SxProps<Theme> = {
    borderRadius: 4,
    backgroundColor: (theme) => theme.palette.action.hover,
    border: (theme) => `1px solid ${theme.palette.action.selected}`,
    overflow: 'hidden',
}

const name: SxProps<Theme> = {
    fontWeight: 'bold',
    pl: 2,
    pt: '2px',
    maxWidth: 270,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}

const switchForm: SxProps<Theme> = {
    width: '100%',
    justifyContent: 'space-between',
    ml: 0,
}

const info: SxProps<Theme> = {
    p: 4,
    mt: 2,
    mb: 5,
    borderRadius: 4,
    borderColor: 'info.main',
    borderWidth: '1px',
    borderStyle: 'solid',
}

export default {
    container,
    name,
    switchForm,
    info,
}
