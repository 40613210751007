import { UseMutationOptions } from 'react-query'

import { setFiles } from 'design/pages/VideoUpload/uploader.slice'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { videoAPI } from 'api/controllers'
import { useAppDispatch } from 'App'
import { VIDEOS_QUERY, VIDEO_FOLDERS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'

type Payload = Id[]

export const useDeleteVideosMutation = (options?: UseMutationOptions<null, unknown, Payload>) => {
    const dispatch = useAppDispatch()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, Payload>(
        VIDEOS_QUERY,
        async (payload: Payload) => {
            const videosText = `ideo${payload.length > 1 ? 's' : ''}`

            return mutate(Promise.all(payload.map((id) => videoAPI.deleteVideo(id))), {
                pending: `Deleting v${videosText}`,
                error: `Failed to delete v${videosText}`,
                success: `V${videosText} deleted`,
            })
        },
        {
            invalidate: [VIDEO_FOLDERS_QUERY],
            onSettled: () => {
                dispatch(setFiles((files) => files.filter((f) => !f.state?.uploaded)))
            },
            ...options,
        },
    )
}
