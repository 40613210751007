import { useQuery } from 'react-query'

import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_CLIENTS } from 'api/constants'

import {
    GetAgencyClientListQuery,
    GetAgencyClientListResponse,
} from 'api/contracts/agency/endpoints/getAgencyClientList.contract'
import { useApiErrorToast } from 'api/hooks'
import { ApiError } from 'api/contracts/utilitary/error'

export const useGetAgencyClientsQuery = (
    query: GetAgencyClientListQuery = { page: 1, perPage: 100 },
    options?: any,
) => {
    const { showApiErrorToast } = useApiErrorToast()
    const getAgencyClientsQuery = useQuery<GetAgencyClientListResponse, ApiError>(
        AGENCY_GET_CLIENTS,
        () => agencyController.client.getList(query),
        { onError: (e) => showApiErrorToast(e), ...options },
    )

    return {
        agencyClients: getAgencyClientsQuery.data?.data ? getAgencyClientsQuery.data?.data : [],
        getAgencyClientsQuery,
    }
}
