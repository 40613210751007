import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import AddIcon from '@mui/icons-material/Add'
import InputLabel from '@mui/material/InputLabel'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import _set from 'lodash/set'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _reduce from 'lodash/reduce'
import _values from 'lodash/values'
import _isEqual from 'lodash/isEqual'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import VideoSelect from 'design/molecules/VideoSelect'
import VideoSelectControlItem from 'design/molecules/VideoSelect/VideoSelectControlItem'
import { useLayout } from 'hooks/utilities/useLayout'
import { Video, VideoGuid, VidConditionTargets, VidConditionSegments, WidthValues } from 'types/Video'
import { useVideoQuery } from 'api/queries'

import locale from './VidConditionsForm.locale'
import style from './VidConditionsForm.style'

const MAX_SEGMENTS = 2

const CONDITIONS = [
    {
        label: 'Screen Width',
        value: 'width',
        children: [
            {
                value: 'Mobile (<768px)',
                rules: [{ maxWidth: 768 }],
                conditionId: 1,
            },
            {
                value: 'Tablet (768-1024px)',
                rules: [{ minWidth: 768, maxWidth: 1024 }],
                conditionId: 2,
            },
            {
                value: 'Desktop (>1024px)',
                rules: [{ minWidth: 1024 }],
                conditionId: 3,
            },
        ],
    },
    {
        label: 'Is Returning',
        value: 'isReturning',
        children: [
            {
                value: 'true',
                rules: [{ isReturning: true }],
                conditionId: 4,
            },
            {
                value: 'false',
                rules: [{ isReturning: false }],
                conditionId: 5,
            },
        ],
    },
] as const

type ConditionVariants = 'width' | 'isReturning'
type IsReturningValues = 'true' | 'false'
type AllValues = WidthValues | IsReturningValues

type VidConditionStateRow = {
    video: VideoGuid | null
    condition: ConditionVariants | null
    target: AllValues | null
}

export type VidConditionState = VidConditionStateRow[]

type VidConditionsFormProps = {
    onChange: (value: VidConditionTargets, segments: VidConditionSegments, valid: boolean) => void
    segments?: VidConditionSegments
    fallback: VideoGuid
    disabled?: boolean
}

type Touched = {
    [key: string]: boolean
}

enum COLS {
    VIDEO,
    CONDITION,
    TARGET,
}

const emptyRow = { video: null, condition: null, target: null }

const segmentsToState = (segments: VidConditionSegments | undefined) => {
    if (!segments) return [{ ...emptyRow }]

    return _reduce(
        segments,
        (acc: VidConditionState, { rules }) => {
            CONDITIONS.forEach(({ children, value: conditionValue }) =>
                children?.forEach(({ value: targetValue, rules: conditionRules }) => {
                    const row = _get(rules, 0)
                    const videoGuid = _get(row, 'videoGuid') as VideoGuid
                    const rowConditionRules = [_omit(row, 'videoGuid')]

                    if (_isEqual(rowConditionRules, conditionRules)) {
                        acc.push({ video: videoGuid, condition: conditionValue, target: targetValue as AllValues })
                    }
                }),
            )

            return acc
        },
        [],
    )
}

const stateToSegments = (state: VidConditionState) => {
    return _reduce(
        state,
        (acc: VidConditionSegments, { video, condition, target }) => {
            const children = CONDITIONS.find(({ value }) => value === condition)?.children
            const found = _find(children, ({ value }: { value: string }) => value === (target as string))

            if (found && video) {
                _set(acc, [video], { rules: _get(found, 'rules') || [] })
            }

            return acc
        },
        {},
    )
}

const stateToVidConditions = (state: VidConditionState) => {
    return _reduce(
        state,
        (acc: VidConditionTargets, { video, condition, target }) => {
            const children = CONDITIONS.find(({ value }) => value === condition)?.children
            const found = _find(children, ({ value }: { value: string }) => value === (target as string))

            if (found && video) {
                acc.push({
                    target_video_id: video,
                    condition_id: _get(found, 'conditionId') || 0,
                })
            }

            return acc
        },
        [],
    )
}

const VidConditionsForm = ({ onChange, fallback, segments, disabled }: VidConditionsFormProps) => {
    const deleteRef = useRef<(HTMLDivElement | null)[]>([])
    const [state, setState] = useState<VidConditionState>([...segmentsToState(segments)])
    const [touched, setTouched] = useState<Touched>({})
    const { isTablet } = useLayout()

    const { data, isLoading } = useVideoQuery(fallback)

    const duplicates = useMemo(() => {
        const targets = state.map(({ target }) => target)
        return state?.reduce((acc: number[], { target }, index) => {
            const found = _filter(targets, (el: VideoGuid) => el && el === target)
            if (found && found?.length > 1) {
                acc.push(index)
            }

            return acc
        }, [])
    }, [state])

    const isStateValid = useMemo(() => {
        // check some attribute in state null
        const allFilled = state?.map((row) => _values(row).some((v) => v === null)).filter((v) => !!v).length === 0

        return allFilled && duplicates?.length === 0
    }, [state, duplicates])

    useEffect(() => {
        if (disabled) {
            setState([{ ...emptyRow }])
            setTouched({})
            onChange([], {}, true)
        }
    }, [disabled])

    useEffect(() => {
        onChange(stateToVidConditions(state), stateToSegments(state), isStateValid)
    }, [state])

    useEffect(() => {
        deleteRef.current = deleteRef.current.slice(0, state.length)
        state?.forEach((_, index) => {
            if (!deleteRef.current[index]) {
                deleteRef.current[index] = null
            }
        })
    }, [state])

    const handleChange = useCallback(
        (value: Video, index: number) => {
            _set(state, [index, 'video'], value.guid)
            if (index > 0 && baseCondition) {
                _set(state, [index, 'condition'], baseCondition)
            }
            setState([...state])
        },
        [state],
    )

    const handleChangeCondition = (value: ConditionVariants, index: number) => {
        _set(state, [index, 'condition'], value)
        _set(state, [index, 'target'], null)
        setState([...state])
    }

    const handleChangeTarget = (value: WidthValues | IsReturningValues, index: number) => {
        setState([..._set(state, [index, 'target'], value)])
    }

    const setAllTouched = (max = state?.length, initial = touched) => {
        let current = { ...initial }
        state?.forEach((_, index) => {
            if (index < max) {
                current = {
                    ...current,
                    [`${index}${COLS.VIDEO}`]: true,
                    [`${index}${COLS.CONDITION}`]: true,
                    [`${index}${COLS.TARGET}`]: true,
                }
            }
        })

        setTouched(current)
    }

    const handleAddRow = () => {
        setAllTouched(state.length, {})
        setState([...state, { ...emptyRow }])
    }

    const handleDeleteRow = (index: number) => {
        setAllTouched(state.length, {})
        setState(state.filter((_, i) => index !== i))
    }

    const usedVideos = [data?.video?.guid, ...(state?.map(({ video }) => video) || null)]
    const baseCondition = _get(state, [0, 'condition'])

    return (
        <Stack spacing={3}>
            <Stack
                direction={isTablet ? 'column' : 'row'}
                alignItems="center"
                gap={2}
                sx={{ opacity: disabled ? 0.6 : 1 }}
            >
                <Stack direction="row" alignItems="center" width={isTablet ? 1 : 'auto'} gap={2}>
                    <Typography variant="caption2" fontWeight="bold">
                        {locale.show}
                    </Typography>
                    {isLoading && <Skeleton variant="rectangular" sx={{ width: 1, height: 42, borderRadius: 3 }} />}
                    {!isLoading && (
                        <Box sx={[style.fallback, ...(disabled ? [style.disabled] : [])]}>
                            <VideoSelectControlItem item={data?.video} />
                        </Box>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="caption2" fontWeight="bold">
                        {locale.byDefault}
                    </Typography>
                    <HelpTooltip title={locale.defaultVideo} />
                </Stack>
            </Stack>
            {state.map(({ video, condition, target }, index: number) => {
                const isVideoError = !disabled && touched?.[`${index}${COLS.VIDEO}`] && !video
                const isConditionError = !disabled && touched?.[`${index}${COLS.CONDITION}`] && !condition
                const isTargetError =
                    !disabled && ((touched?.[`${index}${COLS.TARGET}`] && !target) || duplicates?.includes(index))
                const restricted = state?.map(({ condition }) => condition)?.filter((v) => !!v)?.length > 1
                const conditionsByBase = _filter(CONDITIONS, ({ value }) => value === baseCondition) || []
                const conditions = restricted ? conditionsByBase : CONDITIONS

                return (
                    <Stack
                        onMouseEnter={() =>
                            !disabled && deleteRef.current[index]?.style?.setProperty('display', 'block')
                        }
                        onMouseLeave={() =>
                            !isTablet && deleteRef.current[index]?.style?.setProperty('display', 'none')
                        }
                        key={index}
                        direction={isTablet ? 'column' : 'row'}
                        gap={2}
                        sx={{ position: 'relative' }}
                    >
                        {isTablet && <Divider />}
                        <Stack direction="row" alignItems="center">
                            <Typography variant="caption2" sx={{ fontWeight: 'bold', opacity: disabled ? 0.6 : 1 }}>
                                {locale.show}
                            </Typography>
                            <Box sx={style.rowVideo}>
                                <VideoSelect
                                    onChange={(value: Video) => handleChange(value, index)}
                                    onBlur={() => setAllTouched(index, { [`${index}${COLS.VIDEO}`]: true })}
                                    value={video}
                                    omit={usedVideos as VideoGuid[]}
                                    disabled={disabled}
                                    error={isVideoError}
                                />
                            </Box>
                        </Stack>
                        <Stack direction={isTablet ? 'column' : 'row'} gap={2}>
                            <Stack direction="row" alignItems="center" sx={style.rowCondition}>
                                <Typography
                                    variant="caption2"
                                    sx={{ fontWeight: 'bold', mr: 2, opacity: disabled ? 0.6 : 1 }}
                                >
                                    {locale.if}
                                </Typography>
                                <FormControl sx={{ width: 1 }} size="small" error={isConditionError}>
                                    {!condition && (
                                        <InputLabel sx={style.label} error={isConditionError}>
                                            {locale.selectAttribute}
                                        </InputLabel>
                                    )}
                                    <Select
                                        onChange={(e) =>
                                            handleChangeCondition(e?.target?.value as ConditionVariants, index)
                                        }
                                        onBlur={() => setAllTouched(index, { [`${index}${COLS.CONDITION}`]: true })}
                                        value={condition || ''}
                                        disabled={disabled || !video}
                                        sx={style.select}
                                        error={isConditionError}
                                    >
                                        {conditions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Stack direction="row" alignItems="center" sx={style.rowCondition}>
                                <Typography
                                    variant="caption2"
                                    sx={{ fontWeight: 'bold', mr: 2, opacity: disabled ? 0.6 : 1 }}
                                >
                                    =
                                </Typography>
                                <FormControl sx={{ width: 1, position: 'relative' }} size="small">
                                    {!target && (
                                        <InputLabel sx={style.label} error={isTargetError}>
                                            {locale.selectValue}
                                        </InputLabel>
                                    )}
                                    <Select
                                        onChange={(e) =>
                                            handleChangeTarget(
                                                e?.target?.value as WidthValues | IsReturningValues,
                                                index,
                                            )
                                        }
                                        onBlur={() => setAllTouched(index, { [`${index}${COLS.TARGET}`]: true })}
                                        value={target || ''}
                                        disabled={disabled || !condition}
                                        sx={style.select}
                                        notched={!isTargetError}
                                        error={isTargetError}
                                    >
                                        {CONDITIONS.find(({ value }) => value === condition)?.children?.map(
                                            ({ value }) => (
                                                <MenuItem key={value} value={value}>
                                                    {value}
                                                </MenuItem>
                                            ),
                                        )}
                                    </Select>
                                </FormControl>
                            </Stack>
                            <Box
                                ref={(el: HTMLDivElement) => (deleteRef.current[index] = el)}
                                sx={{ display: !isTablet || disabled ? 'none' : 'block' }}
                            >
                                <IconButton onClick={() => handleDeleteRow(index)} disabled={disabled} sx={style.trash}>
                                    <DeleteOutlineIcon />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Stack>
                )
            })}
            <Stack direction="row" justifyContent={isTablet ? 'flex-end' : 'flex-start'}>
                <Button
                    onClick={handleAddRow}
                    disabled={
                        disabled ||
                        state.length >=
                            (CONDITIONS.find(({ value }) => value === baseCondition)?.children?.length || MAX_SEGMENTS)
                    }
                    startIcon={<AddIcon />}
                    variant="contained"
                    color="secondary"
                    size="xSmall"
                >
                    {locale.addSegment}
                </Button>
            </Stack>
        </Stack>
    )
}

export default VidConditionsForm
