import { SxProps } from '@mui/material'

const button: SxProps = {
    p: 2.5,
    textTransform: 'none',
}

export default {
    button,
}
