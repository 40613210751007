import { useState, useEffect, useMemo } from 'react'
import { useVideoQuery } from 'api/queries'
import { VideoThumbGeneration } from 'types/Video'
import { useStopThumbnailEncodingMutation } from 'api/mutations/video/useStopThumbnailEncoding'

const ENCODING_WAITING_TIME = 5
const useVideoPolling = (videoGuid: string) => {
    const [shouldFetch, setShouldFetch] = useState(true)
    const stopMutation = useStopThumbnailEncodingMutation()
    const { data: video } = useVideoQuery(String(videoGuid), {
        refetchInterval: shouldFetch ? 5000 : undefined,
        enabled: shouldFetch,
    })

    const endcodingTime = useMemo(() => {
        const thumbEncodingDate =
            video?.video.thumbnailEncodingStarted && new Date(video?.video.thumbnailEncodingStarted.date)

        if (thumbEncodingDate) {
            return new Date(Date.now() - thumbEncodingDate.getTime()).getMinutes()
        }
        return 0
    }, [video])
    useEffect(() => {
        if (
            endcodingTime > ENCODING_WAITING_TIME &&
            video?.video.videoThumbnailGeneration === VideoThumbGeneration.pending
        ) {
            stopMutation.mutate({ guid: video.video.guid })
            setShouldFetch(false)
        }
    }, [endcodingTime])

    useEffect(() => {
        if (video?.video.videoThumbnailGeneration !== VideoThumbGeneration.pending) {
            setShouldFetch(false)
        } else {
            setShouldFetch(true)
        }
    }, [video])
}

export default useVideoPolling
