import { UseQueryOptions, useQuery } from 'react-query'

import { settingsAPI } from 'api/controllers'
import { CLOSED_CAPTIONS_DRAFT } from 'api/constants'

import { ClosedCaptionsDraftResponse } from 'api/contracts/closedCaptions/closedCaptionsDraft.contract'

export const useClosedCaptionsDraftQuery = (
    guid: string,
    options?: UseQueryOptions<ClosedCaptionsDraftResponse, unknown>,
) =>
    useQuery<ClosedCaptionsDraftResponse>(
        [CLOSED_CAPTIONS_DRAFT, guid],
        () => settingsAPI.readClosedCaptionsDraft(guid),
        options,
    )
