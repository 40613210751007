import { useQuery, UseQueryOptions } from 'react-query'

import { VideoEmbedPreview } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEO_EMBED_PREVIEW_QUERY } from 'api/constants'

export const useEmbedVideoPreview = (
    id: string,
    options?: UseQueryOptions<VideoEmbedPreview, unknown>,
    newPlayer?: boolean,
) =>
    useQuery<VideoEmbedPreview>(
        [VIDEO_EMBED_PREVIEW_QUERY, id],
        () => videoAPI.getEmbedVideoPreview(id, newPlayer),
        options,
    )
