import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { TrendColor } from './MetricCard'

const container: SxProps<Theme> = {
    py: 3,
    borderRadius: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
}

/**
 * Calculates the rotation degree for a trend icon based on the given trend color.
 */
export const calculateTrendIconRotation = (trendColor: TrendColor): number =>
    trendColor === 'error' ? 180 : trendColor === 'info' ? 90 : 0

const trendIconContainer: SxProps<Theme> = {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
    backgroundColor: (theme) => theme.palette.action.selected,
}

const trendIcon: (trendColor: TrendColor) => SxProps<Theme> = (trendColor) => ({
    transform: `rotate(${calculateTrendIconRotation(trendColor)}deg)`,
    fill: (theme) => theme.palette[trendColor].main,
})

export default {
    container,
    trend: {
        icon: trendIcon,
        iconContainer: trendIconContainer,
    },
}
