import { SxProps, Theme } from '@mui/material'

const header: SxProps = {
    px: 6,
    pt: 5,
}

const folderName: SxProps<Theme> = (theme) => ({
    'input, .resizeHelper': {
        ...theme.typography.h4,
    },
    input: {
        height: theme.typography.h4.lineHeight,
    },
})

const folderNameHeaderLink: SxProps<Theme> = {
    cursor: 'pointer',
    color: (theme) => theme.palette.text.primary,
    m: 0,

    '&:hover': {
        color: (theme) => theme.palette.primary.main,
    },
}

export default {
    header,
    folderName,
    folderNameHeader: {
        link: folderNameHeaderLink,
    },
}
