import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_CLIENTS, AGENCY_INVITATION_CANCEL } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

export const useCancelInvitationMutation = () => ({
    cancelInvitation: useInvalidateMutation<undefined, AgencyClientId>(
        AGENCY_INVITATION_CANCEL,
        async (id: AgencyClientId) => agencyController.client.invitation.cancel(id),
        { invalidate: [AGENCY_GET_CLIENTS] },
    ),
})
