import { SxProps, Theme } from '@mui/material'

const actionDelete: SxProps<Theme> = (theme) => ({
    color: theme.palette.error.main,
})

const selectedActionDelete: SxProps<Theme> = (theme) => ({
    color: theme.palette.primary.contrastText,
})

export default {
    actionDelete,
    selectedActionDelete,
}
