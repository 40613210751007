import { PaletteMode, SxProps, Theme } from '@mui/material'
import { castArray } from 'lodash'

export const toRem = (value: number) => `${value / 16}rem`

export const textStyle = (fontSize: number, lineHeight: number) => ({
    fontSize: toRem(fontSize),
    lineHeight: toRem(lineHeight),
})

export const getPropByTheme = (light: string, dark: string, mode: PaletteMode) => (mode === 'light' ? light : dark)

export const getSx = (sx: SxProps<Theme>, propsSx?: SxProps<Theme>) =>
    propsSx ? [...castArray(sx), ...castArray(propsSx)] : [...castArray(sx)]
