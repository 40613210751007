import { alpha, SxProps, Theme } from '@mui/material'

const root: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    color: 'accent.main',
    fontSize: 'caption.fontSize',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: 1,
    px: '6px',
    py: 1,
    backgroundColor: (theme) => alpha(theme.palette.accent.main, 0.12),
    borderRadius: 2,
}

export default {
    root,
}
