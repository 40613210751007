import { useQueryClient } from 'react-query'

import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { VideoEmbed, PublicPreviewPermission } from 'types/Video'
import { apiLocale } from 'api/api.locale'
import { useInvalidateMutation } from 'api/hooks/useInvalidateMutation'
import { videoAPI } from 'api/controllers'
import { Id } from 'types'
import { VIDEO_EMBED_QUERY, SMART_VID_EMBED_QUERY } from 'api/constants'

export const useSetVideoEmbedPublicShareMutation = (id?: Id, funnelId?: string, smartVid?: boolean) => {
    const queryClient = useQueryClient()
    const key = [VIDEO_EMBED_QUERY, id]
    const smartVidKey = [SMART_VID_EMBED_QUERY, funnelId]
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoEmbed, PublicPreviewPermission>(
        key,
        async (payload: PublicPreviewPermission) =>
            mutate(videoAPI.setVideoEmbedPublicShare(String(id), payload), apiLocale.video.embedPublicShare),
        {
            onSuccess: async (...args) => {
                await queryClient.cancelQueries(smartVid ? smartVidKey : key)

                const [data] = args

                if (!smartVid) {
                    return queryClient.setQueryData(key, data)
                }

                const oldData = queryClient.getQueryData(smartVidKey)

                if (!oldData) {
                    return
                }

                queryClient.setQueryData(smartVidKey, {
                    ...oldData,
                    isPublicPreviewAllowed: data.isPublicPreviewAllowed,
                })
            },
        },
    )
}
