import { CONVERSION_COUNT, TITLE } from '../Conversions.constants'
import { COLUMN } from '../../../../constants/table.constants'

export default {
    columns: {
        [TITLE]: 'Name',
        [CONVERSION_COUNT]: 'Conversions',
        [COLUMN.DATE_UPDATED]: 'Updated',
        [COLUMN.DATE_CREATED]: 'Created',
    },
    actions: {
        actionEdit: 'Edit',
        actionGetCode: 'Get code',
        actionDelete: 'Delete',
    },
    noRows: {
        title: 'Get started on your first Conversion!',
        description: 'Click the button below to create one.',
        action: 'New Conversion',
    },
}
