import { Dispatch, SetStateAction, MouseEvent } from 'react'

import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

export type PasswordVisibilityProps = {
    onChange: Dispatch<SetStateAction<boolean>>
    isVisible: boolean
}

export const PasswordVisibility = ({ onChange, isVisible }: PasswordVisibilityProps) => {
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
    }

    return (
        <IconButton
            onClick={() => onChange(!isVisible)}
            onMouseDown={handleMouseDownPassword}
            aria-label="toggle password visibility"
            size="small"
            edge="end"
        >
            {isVisible ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
        </IconButton>
    )
}
