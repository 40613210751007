import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const metricsContainer: SxProps<Theme> = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 3,
    height: '100%',

    '@container (width > 500px)': {
        gridTemplateColumns: '1fr 1fr 1fr',
    },
}

const accountInfoMenu: SxProps<Theme> = {
    mt: 1,
    p: 5,
    borderRadius: 3,
}

const jumpIn: SxProps<Theme> = {
    position: 'absolute',
    bottom: -10,
    width: 160,
    mx: 'auto',
    left: 0,
    right: 0,
    py: 3,
}

const cancellationNotification: SxProps<Theme> = {
    width: 224,

    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 16,

    // TODO: [VID-6781] Set global Alert appStyle?
    borderRadius: 4,
    border: (theme) => `1px solid ${theme.palette.error.light}`,
}

export default {
    jumpIn,
    accountInfo: {
        menu: accountInfoMenu,
    },
    metrics: {
        container: metricsContainer,
    },
    notification: {
        cancellation: cancellationNotification,
    },
}
