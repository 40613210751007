import { locale } from 'locales'

export default {
    invalidFile: 'Invalid file type. Supported extensions: vtt, srt',
    loading: 'Loading Captions...',
    generating: 'Generating Captions...',
    dragAndDrop: 'Drag & Drop or',
    chooseFiles: 'Choose Files',
    toUpload: 'to Upload',
    uploadCc: 'Upload CC',
    generateCc: 'Generate CC',
    takeSomeTime: 'It could take a few minutes to generate captions',
    ready: 'You can now edit closed captions. The changes will be saved automatically.',
    failed: 'Our system encountered an issue while generating your closed captions. Please try again. If the problem persists, reach out to our support team.',
    cancel: locale.operations.generic.cancel,
}
