import { createTestId } from 'utils/create-test-id.utils'
import { kebabCase } from 'lodash'

export const testIdFactory = (label: string) => ({
    container: createTestId(`${kebabCase(label)}__acc-info-item__container`),
    info: {
        label: createTestId(`${kebabCase(label)}__acc-info-item__kebabCase(label)`),
        data: createTestId(`${kebabCase(label)}__acc-info-item__data`),
    },
})
