import { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { useToast } from 'design/organisms/ToastProvider'
import mixpanel from 'mixpanel-browser'

import { Button } from '@mui/material'

import { ALLOW_MICROSOFT_ACCOUNT_USAGE } from 'design/molecules/MicrosoftAuth/constants'
import { getLatestSignedInAccount } from 'design/pages/SignIn/MicrosoftButton'
import usePlan from 'design/pages/SignUp/hooks/usePlan'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SignUpFormData, PlanPayload } from 'types/Auth'
import { generateRandomPassword } from 'utils'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { EMAIL, PASSWORD, LASTNAME, FIRSTNAME } from 'constants/validations/user.constants'
import useSuccessHandleForExtraModule from '../hooks/useSuccessHandleForExtraModule'

import locale from './microsoft-button.locale'
import style from './style'

type MicrosoftButtonProps = {
    onSuccess: () => void
    disabled: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
    plan: PlanPayload
}

const scopes = {
    scopes: ['User.Read'],
}

const MicrosoftButton = ({ onSuccess, disabled, setIsLoading, plan }: MicrosoftButtonProps) => {
    const { instance, accounts, inProgress } = useMsal()
    const { values, setValues } = useFormikContext<SignUpFormData>()
    const formValuesRef = useSuccessHandleForExtraModule(onSuccess)
    const { isLoading } = usePlan()
    const { showToast } = useToast()

    useEffect(() => {
        if (
            accounts?.length &&
            localStorage.getItem(ALLOW_MICROSOFT_ACCOUNT_USAGE) === 'true' &&
            inProgress === InteractionStatus.None &&
            !isLoading
        ) {
            const account = getLatestSignedInAccount(accounts)

            if (account) {
                const firstName = account.name?.split(' ')[0] || ''
                const lastName = account.name?.split(' ')[1] || ''

                formValuesRef.current = {
                    ...values,
                    [FIRSTNAME]: firstName,
                    [LASTNAME]: lastName,
                    [EMAIL]: account.username || '',
                    [PASSWORD]: generateRandomPassword(),
                }

                const newValues = formValuesRef.current

                setValues(newValues)
            } else {
                showToast({
                    message: locale.notification.dataUnavailable,
                    type: 'error',
                })
            }

            setIsLoading(false)

            localStorage.removeItem(ALLOW_MICROSOFT_ACCOUNT_USAGE)
        }
    }, [inProgress, isLoading])

    const handleLogin = () => {
        mixpanel.track(
            MIXPANEL_EVENTS.SIGN_UP_WITH_MICROSOFT_CLICKED,
            {
                subscription_tier: plan.tier,
                subscription_id: plan.apiHandle,
                subscription_istrial: plan.hasTrial,
            },
            { transport: 'sendBeacon' },
        )
        setIsLoading(true)
        try {
            instance.acquireTokenRedirect(scopes)
        } catch (error: unknown) {
            console.error(error)
            showToast({
                message: locale.notification.error,
                type: 'error',
            })
        }
    }

    return (
        <Button
            color="tertiary"
            variant="outlined"
            onClick={handleLogin}
            startIcon={<img src="/img/icons/microsoft.svg" alt="Microsoft" />}
            sx={style.button}
            disabled={disabled}
            fullWidth
            data-testid="microsoftSSO"
        >
            {locale.signUp}
        </Button>
    )
}

export default withErrorBoundary(MicrosoftButton)
