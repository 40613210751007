import { SxProps, Theme } from '@mui/material'

const container: SxProps<Theme> = {
    alignItems: 'center',
    columnGap: 2,
    maxWidth: 1,
}

export default {
    container,
}
