import { Components, Theme } from '@mui/material'

export const MuiTextField: Components<Omit<Theme, 'components'>>['MuiTextField'] = {
    defaultProps: {
        variant: 'outlined',
        InputLabelProps: {
            shrink: true,
        },
        InputProps: {
            notched: false,
        },
    },
}
