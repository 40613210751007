export const getRandomArbitrary = (min: number, max: number) => Math.random() * (max - min) + min

export const numberWithCommas = (x: number) => x.toLocaleString()

export const convertCentsToDollars = (n?: number | string) =>
    Number(n) > 0 || n === 0
        ? (Number(n) / 100).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
          })
        : '$0.00'

export const convertValueToDollars = (n?: number | string) =>
    Number(n) > 0 || n === 0
        ? Number(n).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
          })
        : '$0.00'

export const formatAsPercentage = (number = 0, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits,
        maximumFractionDigits,
    }).format(number)
}

export const formatAsPercentageOrDefault = (
    value: string | number = 0,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
) => {
    if (typeof value === 'string') {
        return value
    }

    return formatAsPercentage(value, minimumFractionDigits, maximumFractionDigits)
}

/**
 * Output:
 * 1000 => 1 000
 * 987654321 => 987 654 321
 * 1234567890 => 1 234 567 890
 */
export function prettifyNumber(value: number): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

/**
 * Output:
 * 950 => 950
 * 1234 => 1.2K
 * 15000 => 15K
 * 1500000 => 1.5M
 */
export function simplifyNumber(value: number): string {
    if (value < 1000) {
        return value.toString()
    } else if (value < 1_000_000) {
        return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
    } else {
        return (value / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
    }
}
