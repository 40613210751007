import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { PlanPayload } from 'types/Auth'
import { usePlanQuery } from 'api/queries'
import { PlanId } from 'types/Plan'

const usePlan = () => {
    const { planId } = useParams<{ planId: PlanId }>()
    const { data, isLoading, isError } = usePlanQuery(String(planId), { enabled: Boolean(planId) })

    const plan = useMemo(() => data || ({} as PlanPayload), [data])

    return { planId, plan, isLoading, isError }
}

export default usePlan
