import { subDays } from 'date-fns'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { StatsDateRangeState, SetDateRangePayload, StatsDateRange } from './types'

const initialState: StatsDateRangeState = {
    dates: [subDays(new Date(), 29), new Date()],
}

export const statsDateRangeSlice = createSlice({
    name: 'statsDateRange',
    initialState,
    reducers: {
        setDateRange: (state, action: PayloadAction<SetDateRangePayload>) => {
            const dateOrAction = action.payload

            if (typeof dateOrAction === 'function') {
                state.dates = dateOrAction(state.dates)
            } else {
                state.dates = dateOrAction
            }
        },
    },
})

export const { setDateRange } = statsDateRangeSlice.actions

export const selectStatsDateRangeState = (state: RootState) => state.statsDateRange
export const selectStatsDateRange = (state: RootState) => {
    const dates = state.statsDateRange.dates

    return [new Date(dates[0]), new Date(dates[1])] as StatsDateRange
}

export * from './types'

export const statsDateRange = statsDateRangeSlice.reducer
