import { SxProps } from '@mui/material'
import { gridPtSpacing } from './constants'

export const linesLimit = (lines: number): SxProps => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines,
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    overflow: 'hidden',
})

export const unifiedInputPadding = `${gridPtSpacing * 2}px ${gridPtSpacing * 3}px`
