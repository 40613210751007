import { Components, Theme } from '@mui/material'

export const MuiMenu: Components<Omit<Theme, 'components'>>['MuiMenu'] = {
    defaultProps: {
        PaperProps: {
            sx: {
                borderRadius: 3,
            },
        },
        MenuListProps: {
            sx: {
                p: 0,
            },
        },
    },
}
