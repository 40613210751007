import { ChangeEvent, useMemo, useRef, useState } from 'react'
import { Box, Button, Typography, Stack } from '@mui/material'

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    UI_CLOSED_CAPTIONS_DEFAULT_ENABLED,
    UI_CLOSED_CAPTIONS_MOBILE_ENABLED,
} from 'design/pages/VidSettings/constants'
import { Modal } from 'design/templates/Modal'
import { SwitchForm } from 'design/atoms/Switch'
import { ClosedCaptionsSectionFormData } from 'types/VidSettings'
import { useDeleteClosedCaptionsMutation, useUpdateClosedCaptionsMutation } from 'api/mutations'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'

import style from './ClosedCaptionsContent.style'

export const ClosedCaptionsContent = () => {
    const {
        video,
        isLoading,
        formik: { values, setValues },
        onChange,
    } = useSettingsSectionContext<ClosedCaptionsSectionFormData>()
    const [deleteCCOpen, setDeleteCCOpen] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const updateClosedCaptionsMutation = useUpdateClosedCaptionsMutation({
        onSuccess: () => {
            setValues({
                ...values,
                [UI_CLOSED_CAPTIONS_MOBILE_ENABLED]: true,
                [UI_CLOSED_CAPTIONS_DEFAULT_ENABLED]: true,
            })
            onChange()
        },
    })
    const deleteClosedCaptionsMutation = useDeleteClosedCaptionsMutation()

    const commonProps = { afterChange: onChange, disabled: isLoading }

    const handleButtonClick = () => {
        fileInputRef.current?.click()
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!video) {
            return
        }

        const file = event.target.files?.[0]

        if (!file) {
            return
        }

        updateClosedCaptionsMutation.mutate({ video: video.video, file })

        if (fileInputRef.current) {
            fileInputRef.current.value = ''
        }
    }

    const fileName = useMemo(() => {
        if (!video) {
            return ''
        }

        const lastEqualSignIndex = video.settings.ui_closedCaptions_source?.lastIndexOf('/')

        return video.settings.ui_closedCaptions_source?.substr(lastEqualSignIndex + 1)
    }, [video])

    return (
        <Section mb={4}>
            {fileName ? (
                <>
                    <Box sx={style.cc.container}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} p={3}>
                            <Typography variant="body2" fontWeight="bold">
                                {fileName}
                            </Typography>
                            <Button
                                color="error"
                                variant="text"
                                disabled={isLoading}
                                onClick={() => setDeleteCCOpen(true)}
                            >
                                <DeleteRoundedIcon />
                            </Button>
                        </Stack>
                        <Typography variant="body2" textAlign="center" sx={style.cc.description}>
                            Uploaded File
                        </Typography>
                    </Box>

                    <SwitchForm
                        sx={style.switch}
                        name={UI_CLOSED_CAPTIONS_MOBILE_ENABLED}
                        label="On by Default (Mobile)"
                        {...commonProps}
                    />
                    <SwitchForm
                        sx={style.switch}
                        name={UI_CLOSED_CAPTIONS_DEFAULT_ENABLED}
                        label="On by Default (Desktop)"
                        {...commonProps}
                    />
                </>
            ) : (
                <Stack gap={4} direction="row" alignItems="center">
                    <Button variant="outlined" fullWidth onClick={handleButtonClick} disabled={isLoading}>
                        Upload CC
                    </Button>
                    {
                        // TODO: [VID-6583] Clarify help button size issue
                    }
                    <HelpTooltip
                        title="It is recommended to use WebVTT or SRT formats. Other formats are usually not
                        supported on iOS devices."
                    />
                </Stack>
            )}

            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />

            <Modal open={deleteCCOpen} onClose={() => setDeleteCCOpen(false)}>
                <Modal.Header
                    title="Are you sure you want to delete this Closed Captions?"
                    onClose={() => setDeleteCCOpen(false)}
                />
                <Modal.Body>
                    <Typography>This action cannot be undone.</Typography>
                </Modal.Body>
                <Modal.Actions>
                    <Button variant="text" onClick={() => setDeleteCCOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            if (!video) {
                                return
                            }
                            setDeleteCCOpen(false)
                            deleteClosedCaptionsMutation.mutate(video?.video)
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal>
        </Section>
    )
}
