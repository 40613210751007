import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_CTA_ROW_DATA } from 'api/constants'
import { GetCtaRowDataQuery, GetCtaRowDataResponse } from 'api/contracts/dashboard/endpoints/getCtaRowData.contract'
import { addIdToRows } from 'api/utils/dashboard.utils'

const ctaRowDataMock: GetCtaRowDataResponse = {
    request: {
        customerId: 24495,
        dateFrom: '2024-08-01T00:00:00Z',
        dateTo: '2024-08-03T00:00:00Z',
    },
    data: [
        {
            name: 'User 1',
            videoTitle: 'How to Learn TypeScript',
            displayRate: 85.4,
            clickRate: 12.5,
        },
        {
            name: 'User 2',
            videoTitle: 'Introduction to React',
            displayRate: 90.1,
            clickRate: 15.2,
        },
        {
            name: 'User 3',
            videoTitle: 'Advanced JavaScript Techniques',
            displayRate: 78.3,
            clickRate: 10.9,
        },
        {
            name: 'User 4',
            videoTitle: 'Understanding CSS Grid',
            displayRate: 82.7,
            clickRate: 13.8,
        },
        {
            name: 'User 5',
            videoTitle: 'Mastering Python',
            displayRate: 88.2,
            clickRate: 14.6,
        },
    ],
}

export const useGetCtaRowDataQuery = (query: GetCtaRowDataQuery) => {
    const getCtaRowDataQuery = useQuery<GetCtaRowDataResponse>(DASHBOARD_GET_CTA_ROW_DATA, () =>
        dashboardController.getCtaRowData(query),
    )

    return {
        ctaRowData: addIdToRows(getCtaRowDataQuery.data?.data),
        getCtaRowDataQuery,
        ctaRowDataMock,
    }
}
