import { pxToRem } from '../typography'

export const MuiSvgIcon = {
    variants: [
        {
            props: { fontSize: 'large' as const },
            style: () => ({ fontSize: pxToRem(32) }),
        },
    ],
}
