import classNames from 'classnames'
import Box from '@mui/material/Box'

import './index.scss'

type AwardsProps = {
    size?: string
}

const Awards = ({ size = 'sm' }: AwardsProps) => {
    const awardsSize = classNames({
        [`awards-${size}`]: size,
    })

    return (
        <Box className={`awards ${awardsSize}`}>
            <img
                src="/img/auth/badges/g2-high-performer.svg"
                className="awardsItem"
                alt="G2 High Performer Winter 2023"
            />
            <img src="/img/auth/badges/users-love-vidalytics.svg" className="awardsItem" alt="Users love Vidalytics" />
            <img
                src="/img/auth/badges/g2-high-performer-small-business.svg"
                className="awardsItem"
                alt="G2 High Performer Small Business Winter 2023"
            />
        </Box>
    )
}

export default Awards
