import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { UploaderState, ExtendedFiles, SetFilesPayload, UpdateFilePayload } from './types'

const initialState: UploaderState = {
    files: [],
}

export const uploaderSlice = createSlice({
    name: 'uploader',
    initialState,
    reducers: {
        setFiles: (state, action: PayloadAction<SetFilesPayload>) => {
            const filesOrAction = action.payload

            if (typeof filesOrAction === 'function') {
                state.files = filesOrAction(state.files)
            } else {
                state.files = filesOrAction
            }
        },
        updateFile: (state, action: PayloadAction<UpdateFilePayload>) => {
            state.files = state.files.reduce((result, current) => {
                if (current.id === action.payload.id) {
                    result.push({
                        ...current,
                        ...action.payload,
                        state: {
                            ...current.state,
                            ...action.payload.state,
                        },
                    })
                } else {
                    result.push(current)
                }

                return result
            }, [] as ExtendedFiles)
        },
    },
})

export const { setFiles, updateFile } = uploaderSlice.actions

export const selectUploaderState = (state: RootState) => state.uploader

export * from './types'

export const uploader = uploaderSlice.reducer
