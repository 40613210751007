import { useQuery, UseQueryOptions } from 'react-query'
import { enableQueryHelper } from 'utils'

import { Customer } from 'types/Customer'
import { userAPI } from 'api/controllers'
import { CUSTOMER_QUERY } from 'api/constants'

const defaults = {
    refetchInterval: 3600000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
}

type Error = {
    status: number
}

export type CustomerQueryData = Customer | null

export const useCustomerQuery = (options?: UseQueryOptions<CustomerQueryData, Error>) => {
    return useQuery<CustomerQueryData, Error>(CUSTOMER_QUERY, () => userAPI.getCustomer(), {
        ...defaults,
        ...options,
        enabled: enableQueryHelper(),
    })
}
