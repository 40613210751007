import { useToast } from 'design/organisms/ToastProvider'
import { locale } from 'locales'
import { AxiosError } from 'axios'

interface ToastOptions {
    error?: string
    pending?: string
    success?: string
}

interface UseMutationToastDecorator {
    showPendingToast?: boolean
    showSuccessToast?: boolean
    showErrorToast?: boolean
}

export const useMutationToastDecorator = ({
    showPendingToast = true,
    showSuccessToast = true,
    showErrorToast = true,
}: UseMutationToastDecorator = {}) => {
    const { showToast } = useToast()

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const mutate = (mutation: Promise<any>, toast: ToastOptions) => {
        const {
            error = locale.messages.errors.generic,
            pending = locale.messages.info.loading,
            success = locale.messages.info.loadingCompleted,
        } = toast

        if (showPendingToast) {
            showToast({ message: pending, duration: null })
        }

        mutation
            .then(() => {
                if (showSuccessToast) {
                    showToast({ message: success, type: 'success' })
                }
            })
            .catch(({ response }: AxiosError<{ message?: string; detail?: string }>) => {
                if (showErrorToast) {
                    showToast({
                        message: response?.data?.message || response?.data?.detail || error,
                        type: 'error',
                    })
                }
            })

        return mutation
    }

    return { mutate }
}
