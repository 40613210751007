import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { SET_SAME_MOBILE_THUMBNAIL_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { ThumbnailSourceType, VideoGuid } from 'types/Video'

export type Payload = {
    videoGuid: VideoGuid
    thumbnailType: ThumbnailSourceType
}

export const useSameMobileThumbnailMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoGuid, Payload, string>(
        SET_SAME_MOBILE_THUMBNAIL_MUTATION,
        async (payload: Payload) => {
            return mutate(settingsAPI.setSameMobileThumbnail(payload), apiLocale.settings.thumbnail.useSameMobile)
        },
        {
            invalidate: [VIDEOS_QUERY],
        },
    )
}
