import { SxProps, Theme, alpha } from '@mui/material'
import { getPropByTheme } from 'styles/theme/utils'

const container: SxProps = {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    gap: 2,
    width: 1,
}

const previewRoot: SxProps<Theme> = {
    width: 20,
    height: 20,
    border: (theme) => `1px solid ${alpha(getPropByTheme('#2E476F', '#FFF', theme.palette.mode), 0.23)}`,
    borderRadius: 1,
    flexShrink: 0,
    flexDirection: 'row',
    position: 'relative',
}

const previewColor: SxProps = {
    width: 20,
    height: 20,
    position: 'absolute',
    borderRadius: 1,
    top: -1,
    left: -1,
}

const previewOpacityModeCommon: SxProps = {
    width: '50%',
    height: '100%',
}

const previewOpacityModeColor: SxProps = {
    borderRadius: 1,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
}

const previewOpacity: SxProps = {
    borderRadius: 1,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
}

const infoContainer: SxProps = {
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    gap: 2,
}

const inputIdle: SxProps<Theme> = (theme) => ({
    '& > input': {
        width: 100,
        px: 3,
        py: 2,
        borderRadius: 3,
        border: `1px solid ${alpha(getPropByTheme('#2E476F', '#FFF', theme.palette.mode), 0.23)}`,
        background: getPropByTheme('#FFF', alpha('#FFF', 0.09), theme.palette.mode),
        boxShadow: theme.shadows[2],

        color: theme.palette.text.primary,
        textAlign: 'center',
        textTransform: 'uppercase',
        ...theme.typography.body1,
    },
})

const inputDisabled: SxProps<Theme> = (theme) => ({
    '& > input': {
        background: theme.palette.action.disabledBackground,
        color: theme.palette.action.disabled,
    },
})

export default {
    container,
    input: {
        idle: inputIdle,
        disabled: inputDisabled,
    },
    preview: {
        root: previewRoot,
        opacity: previewOpacity,
        color: previewColor,
        opacityMode: {
            common: previewOpacityModeCommon,
            color: previewOpacityModeColor,
        },
    },
    infoContainer,
}
