import { useParams } from 'react-router-dom'
import { useQuery, UseQueryOptions } from 'react-query'

import { UrlParamsResponse } from 'design/pages/VidStats/types'
import { statsAPI } from 'api/controllers'
import { STATS_URL_PARAMS_QUERY } from 'api/constants'

export const useStatsUrlParams = (options?: UseQueryOptions<UrlParamsResponse, unknown>) => {
    const { videoGuid } = useParams()

    return useQuery<UrlParamsResponse>(
        [STATS_URL_PARAMS_QUERY, videoGuid],
        () => statsAPI.getStatsUrlParams(String(videoGuid)),
        {
            enabled: Boolean(videoGuid),
            ...options,
        },
    )
}
