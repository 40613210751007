import { Box, Stack, Typography, Skeleton } from '@mui/material'

import style from './SignUpSteps.style'
import locale from './SignUpSteps.locale'

const STEPS_COUNT = 2

type SignUpStepsProps = {
    onChange?: (step: number) => void
    isLoading?: boolean
    step: number
    total?: number
}

export const SignUpSteps = ({ onChange, isLoading, step, total = STEPS_COUNT }: SignUpStepsProps) => {
    if (isLoading) {
        return <Skeleton sx={style.stepLineSkeleton} animation="wave" />
    }

    return (
        <Box textAlign="left" data-testId={`step-${step}`}>
            <Typography variant="caption2">{locale.steps(step, total)}</Typography>
            <Stack direction="row" pt={2} gap={1}>
                {Array.from({ length: total }, (_, index: number) => (
                    <Box
                        key={index}
                        onClick={() => onChange?.(index + 1)}
                        bgcolor={index >= step ? 'secondary.main' : 'primary.main'}
                        sx={style.stepLine}
                    />
                ))}
            </Stack>
        </Box>
    )
}
