import { statsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DOWNLOAD_STATS_MUTATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { ExportType } from 'types'

interface DownloadVideoStatsMutationPayload {
    videoGuid: string
    segment: string
    segmentParamName?: string
    dateFrom: string
    dateTo: string
    compareVideoGuid?: string
    exportFileType: string
}

type Response = string | BlobPart

export const useDownloadVideoStatsMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Response, DownloadVideoStatsMutationPayload>(
        DOWNLOAD_STATS_MUTATION,
        async ({
            videoGuid,
            segment,
            segmentParamName,
            dateFrom,
            dateTo,
            compareVideoGuid,
            exportFileType,
        }: DownloadVideoStatsMutationPayload) => {
            const params = {
                videoGuid: videoGuid,
                segment: segment,
                from: dateFrom,
                to: dateTo,
                format: exportFileType === ExportType.CSV ? 'csv' : 'xlsx',
                ...(segmentParamName ? { paramName: segmentParamName } : {}),
                ...(compareVideoGuid ? { compareVideoGuid } : {}),
            }
            const responseType = exportFileType === ExportType.CSV ? 'text' : 'blob'

            return mutate(
                statsAPI.downloadVideoStats({
                    params,
                    responseType,
                }),
                apiLocale.stats.download,
            )
        },
    )
}
