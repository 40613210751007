import { ChangeEvent, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import {
    Box,
    CircularProgress,
    TextField,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Skeleton,
    Tooltip,
    Typography,
    InputAdornment,
} from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

import { Modal, ReplaceVidSuccessModal } from 'design/templates/Modal'
import { Link } from 'design/atoms/Link'
import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { useVideoQuery, useVideosQuery } from 'api/queries'
import { getRandomArbitrary } from 'utils'

import { useUpdateVideoMutation, useReplaceVideoMutation } from 'api/mutations'
import { Video } from 'types/Video'
import { route } from 'constants/routes'

import './index.scss'

const emptyData = Array.from({ length: 7 }, (_, i) => i)

const ReplaceVidSection = () => {
    const [filter, setFilter] = useState('')
    const { videoGuid } = useParams()
    const [selectedVideo, setSelectedVideo] = useState({} as Video)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [successModal, setSuccessModal] = useState(false)
    const [loadingResult, setLoadingResult] = useState(false)
    const { data: videos, isLoading } = useVideosQuery()
    const { data: video } = useVideoQuery(videoGuid)
    const replaceVideoMutation = useReplaceVideoMutation()
    const updateVideoMutation = useUpdateVideoMutation(String(videoGuid))

    const videoNameChangedReplaced = () => {
        const today = new Date()
        const dd = String(today.getDate()).padStart(2, '0')
        const mm = String(today.getMonth() + 1).padStart(2, '0')
        const yyyy = today.getFullYear()
        const replacedVideoDate = ` REPLACED ${yyyy} ${mm} ${dd}`

        if (video) {
            updateVideoMutation.mutate({
                ...video,
                video: {
                    ...video.video,
                    title: video.video.title + replacedVideoDate,
                },
            })
        }
    }

    const handleReplaceVideo = async (payload: { oldId: string; newId: string }) => {
        setLoadingResult(true)
        try {
            const result = await replaceVideoMutation.mutateAsync(payload)
            if (result.status) {
                videoNameChangedReplaced()
            }
        } catch (error) {
            console.error('Mutation error:', error)
        } finally {
            if (!replaceVideoMutation.isError) {
                setSuccessModal(true)
                setLoadingResult(false)
            }
        }
    }

    const filtered = useMemo(
        () =>
            videos
                ?.filter((v) => v.title.toLocaleLowerCase().includes(filter?.toLocaleLowerCase()))
                ?.filter((v) => v.guid !== videoGuid) || [],
        [videos, filter],
    )

    const getVideosContent = () => {
        if (isLoading) {
            return emptyData.map((i) => (
                <ListItem className="skeleton" key={i} disablePadding>
                    <ListItemButton>
                        <Skeleton variant="rectangular" width={52} height={30} />

                        <Box className="videoDetails">
                            <Skeleton width={`${getRandomArbitrary(5, 50)}%`} animation="wave" />
                            <Skeleton width="5%" animation="wave" />
                        </Box>
                    </ListItemButton>
                </ListItem>
            ))
        }

        if (filter && !filtered.length) {
            return (
                <Box className="emptyList">
                    <Typography className="highlighted">Sorry, no videos were found 🙁</Typography>
                    <Typography>Please try a different title.</Typography>
                </Box>
            )
        }

        if (!videos?.length) {
            return (
                <Box className="emptyList">
                    <Typography className="highlighted">Sorry, no videos were found 🙁</Typography>
                </Box>
            )
        }

        return filtered.map((video) => {
            const thumbnailSrc = video.thumbnail?.dashboardSrc

            return (
                <Tooltip
                    title={video.title}
                    placement="top"
                    arrow
                    componentsProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -7],
                                    },
                                },
                            ],
                        },
                    }}
                    key={video.guid}
                >
                    <ListItem disablePadding>
                        <ListItemButton
                            className={`${video.guid === selectedVideo.guid && 'selected'}`}
                            onClick={() => {
                                setSelectedVideo(video)
                            }}
                        >
                            <ImgWithErrorHandler src={thumbnailSrc} alt={video.title} loading="lazy" />

                            <Box className="videoDetails">
                                <ListItemText className="videoTitle" primary={video.title} />
                                <ListItemText className="videoLength" primary={video.length} />
                            </Box>
                        </ListItemButton>
                    </ListItem>
                </Tooltip>
            )
        })
    }

    return (
        <Box className="ReplaceVidSection">
            {loadingResult ? (
                <Box className="loaderSection">
                    <CircularProgress size={40} />
                </Box>
            ) : (
                <Box>
                    <Typography>Choose a video from your library below to replace with this one</Typography>
                    <TextField
                        placeholder="Search by typing"
                        fullWidth
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setFilter(event.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchOutlinedIcon />
                                </InputAdornment>
                            ),
                            notched: false,
                        }}
                    />

                    <Box className="ReplaceVidSectionVideosList">
                        <List
                            className={classNames({
                                filled: !isLoading && videos?.length && !(filter && !filtered.length),
                            })}
                        >
                            {getVideosContent()}
                        </List>
                    </Box>
                    <Box className="action">
                        <Button
                            className="redesign ico addBtn"
                            onClick={() => {
                                setShowConfirmationModal(true)
                            }}
                            disabled={!selectedVideo.guid}
                            variant="outlined"
                            fullWidth={true}
                        >
                            <AddRoundedIcon />
                            {'Replace selected vid with this one'}
                        </Button>
                    </Box>
                    <Modal open={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
                        <Modal.Header
                            title="Are you sure you want to replace your current video with this one?"
                            onClose={() => setShowConfirmationModal(false)}
                        />
                        <Modal.Body>
                            <Typography>This will replace this video in all pages it is currently embedded.</Typography>
                        </Modal.Body>
                        <Modal.Actions>
                            <Button variant="text" onClick={() => setShowConfirmationModal(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                    setShowConfirmationModal(false)
                                    handleReplaceVideo({
                                        oldId: String(videoGuid),
                                        newId: selectedVideo.guid,
                                    })
                                }}
                            >
                                Replace
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <ReplaceVidSuccessModal title="Great Success! Your video has been replaced!" open={successModal}>
                        <Link
                            onClick={() => setSuccessModal(false)}
                            rel="noreferrer"
                            to={route.video.settings.byId({ videoGuid: selectedVideo.guid })}
                        >
                            Edit Your Replacement Video
                        </Link>
                        <Button
                            onClick={() => {
                                setSuccessModal(false)
                            }}
                            style={{ marginTop: 30, width: '20%', minWidth: 110 }}
                            variant="contained"
                        >
                            Done
                        </Button>
                    </ReplaceVidSuccessModal>
                </Box>
            )}
        </Box>
    )
}

export default withErrorBoundary(ReplaceVidSection)
