import { Stack, Typography, StackProps, SxProps, Theme } from '@mui/material'
import { ReactNode, Ref, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import { ErrorBoundary } from 'react-error-boundary'
import { Fallback } from 'design/atoms/Fallback'
import { getSx } from 'styles/theme/utils'
import { BannerController } from 'design/organisms/BannerController'
import { ModalController } from 'design/templates/Modal'

import style from './Page.style'

interface PageProps extends StackProps {
    containerSx?: SxProps<Theme>
    title?: string
    children: ReactNode
    header?: ReactNode
    head?: ReactNode
    stackRef?: Ref<unknown>
    redesigned?: boolean
}

/**
 * Generic page component
 *
 * This component intended to:
 * - Provide unified layout across the App
 * - Provide error boundaries
 * - Manage page head
 *
 * Recommended for usage as a wrapper for page-level components
 */
export const Page = ({
    title,
    header,
    sx,
    children,
    head,
    stackRef,
    containerSx,
    redesigned = false, // This is a temporary setting for the redesign, remove it when all pages have moved to the new design
    ...stackProps
}: PageProps) => {
    const renderHeader = useCallback(() => {
        if (header) {
            return header
        }

        if (title) {
            return (
                <Typography variant="h4" component="h1" sx={getSx(style.title, style.offset(redesigned))}>
                    {title}
                </Typography>
            )
        }

        return null
    }, [title, header])

    return (
        <ErrorBoundary fallbackRender={() => <Fallback />}>
            <Helmet>{head ? head : <title>{title ? `${title} | Vidalytics` : `Vidalytics`}</title>}</Helmet>

            <ModalController />

            <Stack
                ref={stackRef}
                gap={5}
                justifyContent="space-between"
                sx={getSx(style.container(redesigned), containerSx)}
                {...stackProps}
            >
                <BannerController />
                <ErrorBoundary fallbackRender={() => <Fallback />}>{renderHeader()}</ErrorBoundary>
                <Stack
                    component="main"
                    sx={sx ? sx : getSx(style.offset(redesigned), style.main)}
                    flexDirection="column"
                >
                    <ErrorBoundary fallbackRender={() => <Fallback />}>{children}</ErrorBoundary>
                </Stack>
            </Stack>
        </ErrorBoundary>
    )
}
