import { Stack, Typography, Divider, ButtonBase, Skeleton } from '@mui/material'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { ReactNode, useMemo } from 'react'

import { usePlanQuery } from 'api/queries'
import { ApiHandle } from 'types/Customer'

import locale from './PlanCard.locale'
import style, { HighlightColor } from './PlanCard.styles'

export type PlanCardPlans = ApiHandle.Free | ApiHandle.ProNc | ApiHandle.PremiumNc

export interface PlanCardProps {
    plan: PlanCardPlans
    onClick: () => void
    label?: string
    cta?: ReactNode
    highlight?: boolean
    highlightColor?: HighlightColor
}

export const PlanCard = ({ label, cta, highlight, highlightColor = 'primary', plan, onClick }: PlanCardProps) => {
    const getPlanQuery = usePlanQuery(plan)

    const data = useMemo(
        () => ({
            name: getPlanQuery.isLoading ? (
                <Skeleton variant="text" sx={style.skeleton.name} />
            ) : (
                getPlanQuery.data?.name
            ),
            price: getPlanQuery.isLoading ? (
                <Skeleton variant="text" sx={style.skeleton.price} />
            ) : (
                parseFloat(getPlanQuery.data?.price || '')
            ),
            subtitle: getPlanQuery.isLoading
                ? [1, 2].map((item) => <Skeleton variant="text" key={item} sx={style.skeleton.subtitle} />)
                : getPlanQuery.data?.subtitle,
            features: getPlanQuery.isLoading
                ? [1, 2, 3, 4].map((item) => <Skeleton variant="text" key={item} sx={style.skeleton.feature} />)
                : getPlanQuery.data?.features || [],
        }),
        [getPlanQuery, plan],
    )

    return (
        <ButtonBase
            sx={[style.container, { borderColor: highlight ? `${highlightColor}.main` : 'divider' }]}
            onClick={onClick}
        >
            <Stack sx={style.meta.container}>
                <Typography variant="subtitle1" textAlign="left">
                    {data.name}
                </Typography>
                <Typography variant="h3" textAlign="left">
                    ${data.price}
                    <Typography variant="subtitle2" component="span" color="text.secondary">
                        /{locale.month}
                    </Typography>
                </Typography>
                {data.subtitle && (
                    <Typography variant="caption2" textAlign="left" color="text.secondary">
                        {data.subtitle}
                    </Typography>
                )}
                {cta}
            </Stack>
            <Divider sx={style.divider} />
            <Stack sx={style.feature.container}>
                {data.features.map((feature, index) => (
                    <Typography variant="body2" fontWeight={500} key={index} sx={style.feature.typography}>
                        <CheckCircleOutlineRoundedIcon sx={style.feature.checkmark} color="primary" />
                        {feature}
                    </Typography>
                ))}
            </Stack>
            {!!label && (
                <Typography variant="body2" sx={style.highlight(highlight ? highlightColor : undefined)}>
                    {label}
                </Typography>
            )}
        </ButtonBase>
    )
}
