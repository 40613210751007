import { useQuery } from 'react-query'

import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_TARIFF_INFO } from 'api/constants'
import { GetTariffInfoResponse } from 'api/contracts/agency/endpoints/getTariffInfo.contract'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'
import { useApiErrorToast } from 'api/hooks'
import { ApiError } from 'api/contracts/utilitary/error'

export const useGetTariffInfoQuery = (clientId: AgencyClientId, options?: any) => {
    const { showApiErrorToast } = useApiErrorToast()
    const getTariffInfoQuery = useQuery<GetTariffInfoResponse, ApiError>(
        [AGENCY_GET_TARIFF_INFO, clientId],
        () => agencyController.client.tariffInfo(clientId),
        { onError: (e) => showApiErrorToast(e), ...options },
    )

    return {
        getTariffInfoQuery,
        tariffInfo: getTariffInfoQuery.data,
    }
}
