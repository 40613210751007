import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { GoogleAccount } from 'types/Auth'
import { authAPI } from 'api/controllers'
import { GOOGLE_ACCOUNT } from 'api/constants'

export const useGoogleAccountQuery = (
    token?: string,
    options?: UseQueryOptions<GoogleAccount | null, AxiosError, GoogleAccount | null>,
) =>
    useQuery<GoogleAccount | null, AxiosError>(
        [GOOGLE_ACCOUNT, token],
        async () => {
            const response = await authAPI.getGoogleInformation(String(token))
            return response.data || null
        },
        {
            enabled: Boolean(token),
            ...options,
        },
    )
