import { UseQueryOptions, useQuery } from 'react-query'

import { SMART_VID_STEP_QUERY } from 'api/constants'

import { GetSmartVidStepsResponse } from 'api/contracts/smartVids/endpoints/getSmartVidSteps.contract'
import { ApiError } from 'api/contracts/utilitary/error'
import { smartVidConroller } from 'api/controllers'
import { Id } from 'types'

export const useSmartVidStepQuery = (
    svId?: Id,
    vidId?: Id,
    options?: UseQueryOptions<GetSmartVidStepsResponse, ApiError>,
) =>
    useQuery<GetSmartVidStepsResponse, ApiError>(
        [SMART_VID_STEP_QUERY, svId, vidId],
        () => smartVidConroller.steps.get(String(svId), String(vidId)),
        {
            enabled: Boolean(svId && vidId),
            ...options,
        },
    )
