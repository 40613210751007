import { SxProps, Theme } from '@mui/material'

const button: SxProps<Theme> = {
    border: (theme) => `1px solid ${theme.palette.action.disabled}`,
    color: 'text.primary',
    borderRadius: 3,
    height: 44,
    textTransform: 'none',
}

export default {
    button,
}
