import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
    openSubscriptionModal,
    SubscriptionSteps,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { ApiHandle } from 'types/Customer'
import { useAppDispatch } from 'App'

const forbiddenPlans = [ApiHandle.Unlimited, ApiHandle.SmartVsl]

export const usePlanFromParameter = () => {
    const [searchParams] = useSearchParams()
    const planApiHandle = searchParams.get('plan') as ApiHandle | null
    const directUpgrade = searchParams.get('direct-upgrade')
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!planApiHandle || forbiddenPlans.includes(planApiHandle) || directUpgrade === 'true') {
            return
        }

        dispatch(
            openSubscriptionModal({
                step: SubscriptionSteps.ACTIVATION_STEP,
                lookForApiHandle: planApiHandle,
                hideBreadcrumb: true,
            }),
        )
    }, [planApiHandle, directUpgrade])
}
