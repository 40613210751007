import useAccountSubscription from 'hooks/user/useAccountSubscription'
import useUserStatuses from 'hooks/user/useUserStatuses'

const useIsLockedAccount = () => {
    const { isUserInactive } = useUserStatuses()
    const { planTransitionRequired } = useAccountSubscription()

    return isUserInactive || planTransitionRequired
}
export default useIsLockedAccount
