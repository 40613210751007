export default {
    startupQuality: {
        title: 'Video Startup Quality',
        titleTooltip:
            'Enabling this setting will prioritize higher video quality during startup. However, please note that the player may adjust the quality based on an available buffer and other predefined settings. In some cases, predefined quality is not guaranteed.',
        qualityOptions: {
            auto: {
                label: 'Auto',
                tooltip:
                    'The player will find the best startup quality automatically, to match your viewer’s internet speed.',
            },
            low: {
                label: 'Fastest (Low Quality)',
                tooltip: '480p. Player will be able to play at a better quality after 40 seconds, if available.',
            },
            medium: {
                label: 'Medium Quality',
                tooltip:
                    '720p in most cases, but for some videos, it could be 640p or 1920p (because of adaptive bitrate streaming).',
            },
            high: {
                label: 'High Quality',
                tooltip: '1920p.',
            },
        },
        lowerBandwidthUsage: {
            label: 'Lower Bandwidth Usage',
            tooltip:
                'Enabling this option limits video resolution to enhance bandwidth efficiency, potentially affecting video quality.',
        },
    },
    resumePlay: {
        title: 'Resume Play',
        titleTooltipDefault:
            'Your video will start from where your viewer previously left off. To change the message shown, click the ‘Advanced Options’ below.',
        titleTooltipFunnel:
            'This option cannot be enabled on this video because it is being used in a Smart Vid. If you want to enable them, please delete the video from the funnel first.',
        resumePlayMessage: {
            label: 'Resume Play Message',
            tooltip:
                'Gives your viewers the option to start over or not. When disabled, your video will start from wherever a returning visitor watched to last with no option to start over.',
        },
        headline: 'Headline',
        resumeText: 'Resume Text',
        replayText: 'Replay Text',
        textColor: 'Text Color',
        shadowColor: 'Shadow Color',
    },
    endOfVideoActions: {
        title: 'End of Video Actions',
        actions: {
            pause: {
                label: 'Pause',
            },
            displayThumbnail: {
                label: 'Display Thumbnail',
                tooltip:
                    'If you have only one thumbnail set up, we will show it on all devices. If you have a mobile thumbnail set up, we will show that one on mobile devices',
            },
            replay: {
                label: 'Loop',
                tooltip:
                    'This option cannot be enabled on this video because it is being used in a Smart Vid. If you want to enable them, please delete the video from the funnel first.',
            },
            redirect: {
                label: 'Countdown Timer / Redirect',
                tooltip:
                    'This option cannot be enabled on this video because it is being used in a Smart Vid. If you want to enable them, please delete the video from the funnel first.',
                link: 'Link',
                delay: 'Delay',
                messageLabel: 'Redirect Message',
                messageTooltip:
                    'This message is shown to your viewers before the page is redirected. You may customize it below. Use %d to denote the seconds left before the page redirects, which will count down in real time. It will use the amount of time from the delay above. For example: “You Will Be Redirected in %d Seconds”',
                headline: 'Headline',
                textColor: 'Text Color',
                shadowColor: 'Shadow Color',
            },
        },
    },
    smartPause: {
        title: 'Smart Pause',
        tooltip:
            'Pause your video when your viewer switches to a different tab, to make sure you always have their full attention.',
        autoplayOnReturn: {
            title: 'Autoplay on Return',
            tooltip: 'Your video will continue playing automatically from where your viewer previously left off.',
        },
    },
}
