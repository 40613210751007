import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const container: SxProps<Theme> = {
    p: 6,
    borderRadius: 3,

    display: 'flex',
    flexDirection: 'column',

    position: 'relative',
    containerType: 'inline-size',

    minHeight: '360px',
}

export default {
    container,
}
