export const VIDALYTICS_DOMAIN = process.env.REACT_APP_DOMAIN || 'vidalytics.com'

export const DEVELOPMENT = 'development'

export const PRODUCTION = 'production'

export const ENVIRONMENT = process.env.REACT_APP_BASE_API_URL?.includes?.('api.vidalytics') ? PRODUCTION : DEVELOPMENT

export const IS_DEVELOPMENT = process.env.NODE_ENV === DEVELOPMENT

export const IS_TEST = process.env.NODE_ENV === 'test'

export enum Flow {
    system = 'system',
    test = 'test',
}
