import { authAPI } from 'api/controllers'
import { EMAIL_VERIFICATION_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useVerifyEmailMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, string>(EMAIL_VERIFICATION_QUERY, async (email: string) =>
        mutate(
            // this block of code might be confusing
            // as BE returns 200 if email is used
            // and returns 404 if email can be used
            // so we need to reject Promise on 200
            // and resolve on 404
            new Promise((resolve, reject) => {
                authAPI
                    .verifyEmail(email)
                    .then(() => reject('Already in use'))
                    .catch(() => resolve(''))
            }),
            apiLocale.auth.verifyEmail,
        ),
    )
}
