import { ReactNode } from 'react'

type ConditionalWrapperProps = {
    condition: boolean
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    wrapper: (node: ReactNode) => any
    children: ReactNode
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
    condition ? wrapper(children) : children
