import { SxProps, Theme } from '@mui/material'

const animation = {
    logo: 'pulse',
    upperLine: 'draw-upper-line',
    bottomLine: 'draw-bottom-line',
}

const container: SxProps<Theme> = {
    height: '100%',

    [`@keyframes ${animation.logo}`]: {
        '0%': {
            transform: 'scale(1)',
        },
        '36%': {
            transform: 'scale(1.2)',
        },
        '57%': {
            transform: 'scale(1.2)',
        },
        '93%': {
            transform: 'scale(1)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },

    [`@keyframes ${animation.upperLine}`]: {
        '0%': {
            strokeDashoffset: 46,
        },
        '36%': {
            strokeDashoffset: 0,
        },
        '57%': {
            strokeDashoffset: 0,
        },
        '93%': {
            strokeDashoffset: 46,
        },
        '100%': {
            strokeDashoffset: 46,
        },
    },

    [`@keyframes ${animation.bottomLine}`]: {
        '0%': {
            strokeDashoffset: 61,
        },
        '36%': {
            strokeDashoffset: 0,
        },
        '57%': {
            strokeDashoffset: 0,
        },
        '93%': {
            strokeDashoffset: 61,
        },
        '100%': {
            strokeDashoffset: 61,
        },
    },
}

const wrapper = (theme: Theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(calc(-50% + 6px), calc(-50% - 15px))',
    width: 118,
    height: 112,

    [theme.breakpoints.down('tablet')]: {
        transform: 'translate(calc(-50% + 4px), calc(-50% - 10px))',
        width: 79,
        height: 75,
    },
})

const animationSettings: SxProps<Theme> = {
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
    animationDuration: '2.3s',
}

const logoContainer: SxProps<Theme> = {
    animationName: animation.logo,
    width: '100%',
    height: '100%',
    fill: 'none',
}

const upperLine: SxProps<Theme> = {
    animationName: animation.upperLine,
    strokeDasharray: '46 46',
    strokeDashoffset: 46,
    stroke: (theme) => theme.palette.primary.light,
    strokeWidth: 2.5,
}

const bottomLine: SxProps<Theme> = {
    animationName: animation.bottomLine,
    strokeDasharray: '61 61',
    strokeDashoffset: 61,
    stroke: (theme) => theme.palette.primary.light,
    strokeWidth: 2.5,
}

export default {
    container,
    wrapper,
    animationSettings,
    logo: {
        container: logoContainer,
        upperLine,
        bottomLine,
    },
}
