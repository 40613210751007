export default {
    action: {
        createNewAccount: 'Create New Account',
    },
    cta: {
        createSubAccount: 'please create sub-account.',
        startTrial: 'please start trial.',
        upgrade: 'please upgrade your plan.',
    },
    message: 'To keep uploading videos,',
    title: 'Videos upload limit reached',
}
