import { Stack, Typography } from '@mui/material'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import style from './ConvWindowHeader.style'
import locale from './ConvWindowHeader.locale'

export const ConvWindowHeader = () => {
    return (
        <Stack flexDirection="row" sx={style.container}>
            <Typography color="inherit" noWrap>
                {locale.title}
            </Typography>

            <HelpTooltip iconColor="inherit" title={locale.tooltip} />
        </Stack>
    )
}
