import { Stack, Typography, ButtonBase } from '@mui/material'
import { AddRounded } from '@mui/icons-material'
import { useAppDispatch } from 'App'

import { openModal } from 'design/templates/Modal'
import { AgencyDashboardCard } from 'design/pages/AgencyAccount/ClientCard/AgencyDashboardCard/AgencyDashboardCard'

import style from './AddNewClientCard.styles'

export const AddNewClientCard = () => {
    const dispatch = useAppDispatch()

    return (
        <AgencyDashboardCard
            CardProps={{
                sx: style.container,
            }}
        >
            <ButtonBase
                sx={{ height: 1, width: 1 }}
                onClick={() => dispatch(openModal({ type: 'Create agency client modal' }))}
            >
                <Stack gap={2} justifyContent="center" alignItems="center">
                    <AddRounded sx={{ fontSize: 48 }} />
                    <Typography variant="body2" fontWeight="bold">
                        Add New Client
                    </Typography>
                </Stack>
            </ButtonBase>
        </AgencyDashboardCard>
    )
}
