import { MouseEvent } from 'react'
import { To } from 'react-router-dom'
import Button from '@mui/material/Button'
import { ConditionalLinkWrapper } from 'design/molecules/ConditionalLinkWrapper'

export type HelperButtonType = {
    label: string
    disabled?: boolean
    link?: To
    onClick?: ((event: MouseEvent<HTMLButtonElement>) => void) | undefined
}

type HelperButtonProps = {
    button: HelperButtonType
    fullWidth?: boolean
}

export default function HelperButton({ button, fullWidth }: HelperButtonProps) {
    if (!button) {
        return null
    }

    return (
        <ConditionalLinkWrapper link={button.link} linkProps={{ disabled: button.disabled }}>
            <Button
                className="helperButton"
                fullWidth={fullWidth}
                variant="contained"
                onClick={button.onClick}
                disabled={button.disabled}
            >
                {button.label}
            </Button>
        </ConditionalLinkWrapper>
    )
}
