import CloudDoneRoundedIcon from '@mui/icons-material/CloudDoneRounded'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import mixpanel from 'mixpanel-browser'

import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import useUpgradeCallback from 'hooks/user/useUpgradeCallback'
import { MIXPANEL_EVENTS, getAnalyticsVideoSettings, getVideoProperties } from 'thirdPartyServices/mixpanel'
import { usePublishSettingsMutation } from 'api/mutations'
import { useVideoQuery } from 'api/queries'
import { VideoGuid } from 'types/Video'

import { locale } from 'locales'

type PublishButtonProps = {
    onClick?: () => void
    videoGuid: VideoGuid
    title?: string
    propsButton?: LoadingButtonProps
}

const PublishButton = ({ onClick, videoGuid, title, propsButton }: PublishButtonProps) => {
    const { data } = useVideoQuery(videoGuid)
    const upgradeCallback = useUpgradeCallback()
    const { setNotificationMessage } = useVidSettingsContext()
    const { mutate, isLoading } = usePublishSettingsMutation()

    const publishSettings = upgradeCallback(() => {
        if (!data?.video.guid) {
            return
        }

        setNotificationMessage?.('')
        mutate(data.video.guid)
        onClick?.()

        mixpanel.track(MIXPANEL_EVENTS.VID_SETTINGS_PUBLISHED, {
            ...getVideoProperties(data.video),
            ...getAnalyticsVideoSettings(data),
        })
    })

    return (
        <LoadingButton
            variant="contained"
            size="medium"
            onClick={publishSettings}
            startIcon={<CloudDoneRoundedIcon />}
            loadingPosition="start"
            {...propsButton}
            loading={isLoading || propsButton?.loading}
            disabled={!data?.video.isReady || propsButton?.disabled}
        >
            {title || locale.operations.entity.publish}
        </LoadingButton>
    )
}

export default PublishButton
