import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography, Stack } from '@mui/material'

import EditableName from 'design/atoms/Table/Row/Folder/EditableName'
import usePageTitle from 'hooks/navigation/usePageTitle'
import { Page } from 'design/templates/Page'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { Link } from 'design/atoms/Link'
import { Folder } from 'types/Video'
import { useFolderNameChangeMutation } from 'api/mutations'
import { InputFocusHandler } from 'types'
import { VIDEOS_QUERY, VIDEO_FOLDERS_QUERY } from 'api/constants'
import { queryClient, useCustomerQuery } from 'api/queries'
import { trackAppCuesEventOnce, APPCUES_EVENTS } from 'thirdPartyServices/appCues'
import { PLAYS_LOG_THRESHOLD } from 'thirdPartyServices/appCues/constants'
import { route } from 'constants/routes'
import { ROOT } from 'constants/api.constants'

import { VidsTable } from './VidsTable'
import { useFolderChildrenWithPath } from './hooks/useFolderChildrenWithPath'
import { useVideosWithFolders } from './hooks/useVideosWithFolders'

import style from './MyVids.style'
import './index.scss'

export const MyVids = withErrorBoundary(() => {
    usePageTitle('My Vids | Vidalytics')

    const { videosWithFolders, fullList, videos, isLoading } = useVideosWithFolders()
    const { path } = useFolderChildrenWithPath(videosWithFolders)
    const { data: customer } = useCustomerQuery()

    const { pathname } = useLocation()
    const folderNameChangeMutation = useFolderNameChangeMutation()

    useEffect(() => {
        queryClient.invalidateQueries(VIDEOS_QUERY)
        queryClient.invalidateQueries(VIDEO_FOLDERS_QUERY)
    }, [pathname])

    useEffect(() => {
        const list = videos?.data || []
        if (list?.length === 0) return

        const hasPreviews = list?.map((video) => video.plays)?.some((item) => item > PLAYS_LOG_THRESHOLD)
        if (hasPreviews) {
            trackAppCuesEventOnce(APPCUES_EVENTS.FIRST_PLAYS_RECEIVED, customer?.guid || '')
        }
    }, [videos, customer])

    const changeFolderName =
        (folder: Folder, title?: string): InputFocusHandler =>
        (event) => {
            const newTitle = event.target.value

            if (newTitle === title) return

            folderNameChangeMutation.mutate({
                folder: {
                    ...folder,
                    title: newTitle,
                },
            })
        }

    const renderHeader = () => {
        const folders = [{ title: 'My Vids', guid: ROOT }, ...path]
        const editableFolderIndex = folders.length > 1 ? folders.length - 1 : -1

        return (
            <Stack alignItems="center" direction="row" flexWrap="wrap" sx={style.header}>
                {folders.map((folder, i) => {
                    const { guid, title } = folder

                    return (
                        <span key={guid} className="folderBreadcrumb">
                            {editableFolderIndex === i ? (
                                <EditableName
                                    onBlur={changeFolderName(folder as Folder, title)}
                                    initialName={title}
                                    style={style.folderName}
                                    className="EditableNameHeader"
                                />
                            ) : (
                                <Link
                                    sx={style.folderNameHeader.link}
                                    to={guid === ROOT ? route.video.catalog : route.video.byId({ guid: guid! })}
                                >
                                    <Typography variant="h4" component="h1">
                                        {title}
                                    </Typography>
                                </Link>
                            )}
                            {i !== folders.length - 1 ? <span className="divider">{'>'}</span> : ''}
                        </span>
                    )
                })}
            </Stack>
        )
    }

    return (
        <Page title="My Vids" header={renderHeader()} className="MyVids">
            <VidsTable data={fullList} isLoading={isLoading} />
        </Page>
    )
})

export default MyVids
