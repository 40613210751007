import { HeaderGroup } from 'react-table'

import Box from '@mui/material/Box'

import { SELECTION_COL, HEADLINE } from 'design/atoms/Table/constants'
import { ToolbarProps } from 'design/atoms/Table/Toolbar'

import './index.scss'

type MobileTHeadProps<T extends object = {}> = {
    headerGroups: HeaderGroup<T>[]
    toolbarConfig: ToolbarProps
}

type Cols<T extends object = {}> = {
    [key: string]: HeaderGroup<T>
}

function MobileTHead<T extends object = {}>({ headerGroups, toolbarConfig }: MobileTHeadProps<T>) {
    const headerGroup = headerGroups[0]
    const cols = headerGroup.headers.reduce((acc, column) => {
        if ([SELECTION_COL, HEADLINE].includes(column.id)) {
            acc[column.id] = column
        }

        return acc
    }, {} as Cols<T>)

    return (
        <Box className="MobileTHead">
            {cols[HEADLINE].render('Header', { searchOnly: true })}

            <Box className="menusContainer">
                {cols[SELECTION_COL]?.render('Header')}
                {cols[HEADLINE].render('Header', { actionsOnly: true, toolbarConfig })}
            </Box>
        </Box>
    )
}

export default MobileTHead
