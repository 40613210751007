import {
    NAME,
    FEATURES,
    GUID,
    FIRSTNAME,
    DATE_CREATED,
    CREDIT_FIRSTNAME,
    CREDIT_LASTNAME,
    LASTNAME,
    REMEMBER_ME,
    CREDENTIAL,
    ACCESS_TOKEN,
    BUSINESSNAME,
    TOKEN,
    CARD_NUMBER,
    EXPIRATION_DATE,
    EXPIRATION_MONTH,
    EXPIRATION_YEAR,
    CVV,
    ZIPCODE,
    PLAN,
    CUSTOMER,
    CREDIT_CARD,
    API_HANDLE,
    PRICEPOINT,
    COUPON_CODES,
    TOKENS,
    UPSELL_TOKEN,
    REDIRECT_URL,
    CREDIT_CARD_TOKEN,
    UTM,
    SOURCE,
} from 'design/pages/SignUp/constants'
import { ApiHandle, BillingMode, CustomerSubscriptionPlanTier, PlanName } from 'types/Customer'
import { URLParams } from 'utils'
import { EMAIL, PASSWORD, ACCESS, REFRESH, REDIRECT, CONFIRM_PASSWORD } from 'constants/validations/user.constants'

export type SignInPayload = {
    [EMAIL]: string
    [PASSWORD]: string
    [REMEMBER_ME]?: boolean
}

export type SignInResponse = {
    [ACCESS]: string
    [REFRESH]?: string
    [REDIRECT]?: string
}

type SignInWithGooglePayloadAccessTokenRequired = {
    [ACCESS_TOKEN]: string
    [REMEMBER_ME]?: boolean
}

type SignInWithGooglePayloadCredentialRequired = {
    [CREDENTIAL]: string
    [REMEMBER_ME]?: boolean
}

export type SignInWithGooglePayload =
    | SignInWithGooglePayloadAccessTokenRequired
    | SignInWithGooglePayloadCredentialRequired

export type SignInWithMicrosoftPayload = {
    [TOKEN]: string
    [REMEMBER_ME]?: boolean
}

export type RefreshTokenPayload = {
    access: string
    refresh: string
}

export type Plan = {
    [API_HANDLE]: string
    [PRICEPOINT]?: string | null
}

export type Customer = {
    [FIRSTNAME]: string
    [LASTNAME]: string
    [EMAIL]: string
    [PASSWORD]: string
}

export type CustomerResponse = Customer & {
    [GUID]: string
    [DATE_CREATED]: string
}

export type CreditCard = {
    [FIRSTNAME]: string
    [LASTNAME]: string
    [CARD_NUMBER]: string
    [CVV]: string
    [EXPIRATION_YEAR]: number
    [EXPIRATION_MONTH]: number
    [ZIPCODE]: string
}

export type SignUpFormData = {
    [FIRSTNAME]: string
    [LASTNAME]: string
    [BUSINESSNAME]: string
    [CREDIT_FIRSTNAME]: string
    [CREDIT_LASTNAME]: string
    [EMAIL]: string
    [PASSWORD]: string
    [CARD_NUMBER]: string
    [EXPIRATION_DATE]: string
    [CVV]: string
    [ZIPCODE]: string
    [SOURCE]: SignUpSource | null
}

export type SignUpPayload = {
    [PLAN]: Plan
    [CUSTOMER]: Customer
    [CREDIT_CARD]?: CreditCard | null
    [CREDIT_CARD_TOKEN]?: string
    [UTM]?: URLParams
    [COUPON_CODES]?: string[]
}

export type SignUpResponse = {
    [CUSTOMER]: CustomerResponse
    [TOKENS]: SignInResponse
    [SOURCE]: SignUpSource | null
    [REDIRECT_URL]?: string
    [UPSELL_TOKEN]?: string
}

export type AuthForm = {
    [FIRSTNAME]: string
    [LASTNAME]: string
    [EMAIL]: string
    [PASSWORD]: string
    [CARD_NUMBER]: string
    [EXPIRATION_DATE]: string
    [CVV]: string
    [ZIPCODE]: string

    [FIRSTNAME]: string
    [LASTNAME]: string
    [CARD_NUMBER]: string
    [EXPIRATION_DATE]: string
    [CVV]: string
    [ZIPCODE]: string
    [EMAIL]: string
    [PASSWORD]: string
}

export enum PlanStatus {
    active = 'active',
    archive = 'archive',
}

export enum DiscountType {
    percentage = 'percentage',
    amount = 'amount',
}

export type CouponsDetails = {
    amount: number | null
    code: string
    discounted_price: string
    duration_period: number | null
    percentage: number | null
    period: string
    type: DiscountType
}

export type PlanPayload = {
    id: number
    chargifyId: number
    status?: PlanStatus
    [API_HANDLE]: ApiHandle
    [NAME]: PlanName
    subtitle: null
    [FEATURES]: string[]
    price: string
    trialPrice: string
    creditCardRequired: boolean
    setupFeeInCents: number | null
    skipBillingPageAtSignup: boolean
    hasTrial: boolean
    isFree: boolean
    isPublished: boolean
    billingPeriod: string
    trialPeriod: string | null
    bandwidth: number
    videoSpace: number
    encoding: string
    extraBandwidthPrice: number
    extraPlaysPrice: number | null
    extraBandwidthUnit: string
    extraVideoSpacePrice: number
    tier: CustomerSubscriptionPlanTier
    upsellPage: null
    upsellMainProducts: null
    billingMode?: BillingMode
    coupons?: {
        totalDiscount: string
        couponsDetails: Array<CouponsDetails>
    }
}

export interface GoogleAccount {
    email: string
    family_name: string
    given_name: string
    hd: string
    id: string
    locale: string
    name: string
    picture: string
    verified_email: boolean
}

export type ForgotPasswordPayload = {
    [EMAIL]: string
}

export type ForgotPasswordResponse = {
    description: string
}

export type ResetPasswordEmailResponse = {
    email: string
}

export type ResetPasswordForm = {
    [PASSWORD]: string
    [CONFIRM_PASSWORD]: string
}

export type ResetPasswordPayload = ResetPasswordForm & {
    resetPasswordToken: string
}

export enum SignUpSource {
    google = 'Google',
    microsoft = 'Microsoft',
}
