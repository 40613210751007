import { useState, useEffect, useRef, SyntheticEvent } from 'react'

export default function useComponentVisible(
    initialIsVisible: boolean,
    onClickOutside?: () => void,
    verifyCloseOutside?: (event: Event | SyntheticEvent) => boolean,
) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible)
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const ref = useRef<any>(null)

    const handleClickOutside = (event: Event | SyntheticEvent) => {
        const skip = verifyCloseOutside ? verifyCloseOutside(event) : false

        if (skip) {
            return
        }

        if (ref.current && !ref.current?.contains(event.target)) {
            onClickOutside?.()
            setIsComponentVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true)
        }
    }, [])

    return { ref, isComponentVisible, setIsComponentVisible }
}
