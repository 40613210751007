import { CustomerSubscriptionPlanTier, PlanName } from 'types/Customer'
import { useCustomerSubscriptionQuery } from 'api/queries'

const useAccountSubscription = () => {
    const { isLoading, data } = useCustomerSubscriptionQuery()
    const tier = data?.plan.tier
    const isAgency = data?.plan.name === PlanName.agency
    const isFree = Boolean(data?.plan.isFree)
    const isEnterprise = tier === CustomerSubscriptionPlanTier.enterprise
    const isProWithIV = tier === CustomerSubscriptionPlanTier.proIv
    return {
        data,
        isFree,
        isEnterprise,
        tier,
        lockedFeatures: tier ? isFree : false,
        isLoading,
        isInteractiveVidsLocked: tier ? !isEnterprise && !isProWithIV && !isAgency : false,
        transition: data?.transition,
        planTransitionRequired: data?.transition?.require && data?.transition.apiHandle,
    }
}

export default useAccountSubscription
