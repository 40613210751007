import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_CANCEL_ACCOUNT, AGENCY_GET_CLIENTS } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

export const useCancelAccountMutation = () => ({
    cancelAccount: useInvalidateMutation<undefined, AgencyClientId>(
        AGENCY_CANCEL_ACCOUNT,
        async (id: AgencyClientId) => agencyController.client.cancelAccount.init(id),
        { invalidate: [AGENCY_GET_CLIENTS] },
    ),
})
