import { useMemo } from 'react'
import { Column } from 'react-table'

const useSkeletonData = <T extends object>(length: number, columns: ReadonlyArray<Column<T>>): T[] => {
    return useMemo(() => {
        const columnData = columns.reduce(
            (acc, curr) => ({
                ...acc,
                [String(curr.accessor)]: '',
            }),
            {},
        ) as T

        return Array.from({ length }, () => columnData)
    }, [columns])
}

export default useSkeletonData
