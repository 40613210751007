import { SxProps, Theme } from '@mui/material'
import { keyframes } from '@mui/system'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const gridItem: SxProps = {
    position: 'relative',
    minHeight: 60,
}

const cardIco: SxProps<Theme> = {
    position: 'absolute',
    top: 49,
    left: 30,
    color: 'text.secondary',
    opacity: 0,
    animation: `${fadeIn} 0.25s ease-in forwards`,
}

const cardMethods: SxProps<Theme> = (theme: Theme) => ({
    position: 'absolute',
    top: 16,
    right: 2,
    opacity: 0,
    animation: `${fadeIn} 0.25s ease-in forwards`,

    [theme.breakpoints.up('tablet')]: {
        top: 54,
        right: 12,
    },
})

const cvv: SxProps = {
    position: 'absolute',
    top: 52,
    right: 12,
    opacity: 0,
    animation: `${fadeIn} 0.25s ease-in forwards`,
}

export default {
    gridItem,
    cardIco,
    cvv,
    cardMethods,
}
