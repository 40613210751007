import { UseMutationOptions, useQueryClient } from 'react-query'

import { integrationsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { HIGH_LEVEL_LINK_QUERY, HIGH_LEVEL_STATUS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks/useInvalidateMutation'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useDeleteHighLevelIntegrationMutation = (options?: UseMutationOptions<null, any, null>) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, null>(
        HIGH_LEVEL_LINK_QUERY,
        async () => mutate(integrationsAPI.deleteHighLevel(), apiLocale.integration.delete),
        {
            ...options,
            onSuccess: () => {
                queryClient.resetQueries(HIGH_LEVEL_STATUS_QUERY)
            },
        },
    )
}
