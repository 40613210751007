import { useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { ExpandedVideo } from 'design/pages/MyVids/hooks/useVideosWithFolders'
import { Modal } from 'design/templates/Modal'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { Switch } from 'design/atoms/Switch'
import { PlayerSettings } from 'types/Video'
import { useChangePlayerType } from 'api/mutations'
import { Id } from 'types'

import './index.scss'

export type SwitchTypes = {
    id: Id
    player: PlayerSettings
    vidsInsideFolder: ExpandedVideo[]
    head?: boolean
    folder?: boolean
}

export const SwitchNewPlayer = ({ player: { name }, id, head, folder, vidsInsideFolder }: SwitchTypes) => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const { swithNewPlayerFlag } = useFeatureFlags()

    const isNew = useMemo(
        () => (folder ? vidsInsideFolder.every((v) => v.player.name === 'new') : name === 'new'),
        [folder, vidsInsideFolder],
    )

    const changePlayerType = useChangePlayerType()
    const switchPlayerApi = () => {
        if (isNew) {
            return setOpenConfirmModal(true)
        }

        if (folder) {
            changePlayerType.mutate(
                folder
                    ? vidsInsideFolder
                          .filter((v) => v.player.name === 'legacy')
                          .map((v) => ({ id: v.guid, player: 'new' }))
                    : [{ id: id, player: 'new' }],
            )
        } else {
            changePlayerType.mutate([{ id: id, player: 'new' }])
        }
    }

    if (!swithNewPlayerFlag) {
        return null
    }

    return (
        <>
            <Box className="SwitchNewPlayer">
                {head ? (
                    <Box className="newPlayerContainer">
                        <Typography className="newPlayerTitle">
                            New Player
                            <Typography>(Beta)</Typography>
                        </Typography>
                        <Typography>🚀</Typography>
                    </Box>
                ) : (
                    <>
                        <Switch
                            checked={isNew}
                            label=""
                            onChange={switchPlayerApi}
                            helpInfo={
                                <>
                                    <span className="bold">Enable Vidalytics’ New Player</span> Toggle this On to enable
                                    the new Vidalytics player. You can always return to the previous player version –
                                    even though we would not recommend it. The new player is faster and leaner! 😉
                                </>
                            }
                        />
                    </>
                )}
            </Box>
            <Modal
                open={openConfirmModal}
                onClose={() => {
                    setOpenConfirmModal(false)
                }}
            >
                <Modal.Header
                    title="Really?! You want to switch back to the legacy player? 🤯"
                    onClose={() => {
                        setOpenConfirmModal(false)
                    }}
                />
                <Modal.Body>
                    <Typography>
                        We do not recommend this❗The new player is faster and leaner, which should positively affect
                        your bottomline. The legacy player will be removed by Jan, 31st 2024.
                    </Typography>
                </Modal.Body>
                <Modal.Actions>
                    <Button
                        variant="text"
                        onClick={() => {
                            setOpenConfirmModal(false)
                        }}
                    >
                        No
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            changePlayerType.mutate(
                                folder
                                    ? vidsInsideFolder
                                          .filter((v) => v.player.name === 'new')
                                          .map((v) => ({ id: v.guid, player: 'legacy' }))
                                    : [{ id: id, player: 'legacy' }],
                            )
                            setOpenConfirmModal(false)
                        }}
                    >
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}
