import { agencyController } from 'api/controllers/agency.controller'

import { AGENCY_CREATE_CLIENT, AGENCY_GET_CLIENTS } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import {
    CreateAgencyClientRequest,
    CreateAgencyClientResponse,
} from 'api/contracts/agency/endpoints/createAgencyClient.contract'

export const useCreateClientMutation = () => ({
    createClient: useInvalidateMutation<CreateAgencyClientResponse, CreateAgencyClientRequest>(
        AGENCY_CREATE_CLIENT,
        async (payload: CreateAgencyClientRequest) => agencyController.client.create(payload),
        {
            invalidate: [AGENCY_GET_CLIENTS],
        },
    ),
})
