import { AxiosRequestConfig } from 'axios'
import { UseMutationOptions } from 'react-query'

import { Video, GoogleDriveFileUploadPayload, DropboxFileUploadPayload } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEOS_QUERY, VIDEO_UPLOAD_MUTATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'

type Settings = {
    payload: GoogleDriveFileUploadPayload | DropboxFileUploadPayload
    config: AxiosRequestConfig
}

export const useVideoUploadByLinkMutation = (id: string, options?: UseMutationOptions<Video, unknown, Settings>) => {
    return useInvalidateMutation<Video, Settings>(
        [VIDEO_UPLOAD_MUTATION, id],
        async ({ payload, config }: Settings) => videoAPI.uploadVideoByLink(payload, config),
        {
            invalidate: [VIDEOS_QUERY],
            ...options,
        },
    )
}
