import { Components, Theme } from '@mui/material'
import { typography } from '../typography'

export const MuiInputLabel: Components<Omit<Theme, 'components'>>['MuiInputLabel'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            display: 'flex',
            alignItems: 'center',
            lineHeight: theme.spacing(5),
            fontSize: typography.body2?.fontSize,
            marginBottom: theme.spacing(1),
        }),
        shrink: ({ theme }) => ({
            position: 'static',
            transform: 'none',
            marginBottom: theme.spacing(1),
        }),
    },
}
