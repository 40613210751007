import Skeleton from '@mui/material/Skeleton'

import { useVideosQuery } from 'api/queries'
import VideoSelectControl from 'design/molecules/VideoSelect/VideoSelectControl'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { VideoGuid, Video } from 'types/Video'

type VideoSelectProps = {
    onChange: (value: Video) => void
    onBlur?: () => void
    value: VideoGuid | null
    omit?: VideoGuid[]
    disabled?: boolean
    error?: boolean
}

const VideoSelect = ({ onChange, onBlur, value, omit, disabled, error }: VideoSelectProps) => {
    const { data, isLoading } = useVideosQuery()

    if (isLoading || !data) {
        return <Skeleton variant="rectangular" sx={{ minWidth: 120, width: 1, height: 40, borderRadius: 3 }} />
    }

    const filtered = data?.filter(({ guid }) => guid === value || !omit?.includes(guid))

    return (
        <VideoSelectControl
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            options={filtered}
            disabled={disabled}
            error={error}
        />
    )
}

export default withErrorBoundary(VideoSelect)
