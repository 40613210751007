import { red, orange, green, pink, blueGrey } from '@mui/material/colors'
import { PaletteOptions, alpha } from '@mui/material/styles'

export const paletteLight: PaletteOptions = {
    mode: 'light',
    background: {
        surface: '#f2f4f7',
    },
    divider: 'rgb(27, 36, 50, 0.12)',
    common: {
        tertiaryOutlinedBorder: alpha(blueGrey[100], 0.5),
    },
    primary: {
        main: '#1059FB',
        dark: '#0445D7',
        light: '#6594FC',
        contrastText: '#FFF',
    },
    accent: {
        main: '#FB5198',
        dark: pink[400],
        light: pink[200],
        contrastText: '#FFF',
    },
    secondary: {
        main: '#D5E0FB',
        dark: '#BDD1FE',
        light: '#E7EDFC',
        contrastText: '#1059FB',
    },
    tertiary: {
        main: '#263238',
        dark: '#37474F',
        light: '#60718B',
        contrastText: '#FFF',
    },
    text: {
        primary: '#2E3B54',
        secondary: alpha('#001238', 0.6),
        disabled: alpha('#2E3B54', 0.38),
    },
    action: {
        active: alpha('#2E3B54', 0.56),
        hover: alpha('#2E3B54', 0.04),
        selected: alpha('#2E3B54', 0.08),
        focus: alpha('#2E3B54', 0.12),
        disabled: alpha('#002060', 0.38),
        disabledBackground: alpha('#002060', 0.12),
    },
    error: {
        main: red[700],
        dark: red[800],
        light: red[400],
        contrastText: '#FFF',
    },
    warning: {
        main: orange[800],
        dark: orange[900],
        light: orange[500],
        contrastText: '#FFF',
    },
    info: {
        main: '#809FF5',
        dark: '#5C71A2',
        light: '#D5E0FB',
        contrastText: '#FFF',
    },
    success: {
        main: green[800],
        dark: green[900],
        light: green[500],
        contrastText: '#FFF',
    },
}
