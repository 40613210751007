import { PaletteOptions, SxProps, Theme } from '@mui/material'

export type HighlightColor = keyof Pick<PaletteOptions, 'primary' | 'success' | 'error' | 'warning' | 'info'>

const spacing: SxProps = {
    mx: 8,
    my: 4,
    width: 'fill-available',
} as const

const divider: SxProps = { width: 1, height: '1px' } as const

const container: SxProps<Theme> = {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',

    maxWidth: 300,
    width: 1,

    border: '2px solid',
    borderRadius: 4,

    transition: (theme) => `${theme.transitions.duration.standard}ms`,
    transitionProperty: 'border-color',
} as const

const metaContainer: SxProps<Theme> = {
    ...spacing,
    gap: 3,
} as const

const feature: { [key: string]: SxProps<Theme> } = {
    checkmark: { alignSelf: 'start' },
    container: {
        ...spacing,
        gap: 2,
    },
    typography: {
        textAlign: 'left',
        display: 'flex',
        gap: 2,
        alignItems: 'center',
    },
} as const

const highlight: (color?: HighlightColor) => SxProps<Theme> = (color) =>
    ({
        textTransform: 'uppercase',
        borderRadius: 2,
        position: 'absolute',
        width: 'max-content',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: (theme) => theme.palette.common.white,
        padding: (theme) => theme.spacing(1, 5),
        boxSizing: 'border-box',
        backgroundColor: color ? `${color}.main` : '#e7e7e8',

        transition: (theme) => `${theme.transitions.duration.standard}ms`,
        transitionProperty: 'background-color',
    } as const)

const skeleton: { [key: string]: SxProps<Theme> } = {
    feature: { fontSize: (theme) => theme.typography.body2.fontSize, flexGrow: 1 },
    name: { fontSize: (theme) => theme.typography.body2.fontSize, width: 1 / 3 },
    price: {
        fontSize: (theme) => theme.typography.h3.fontSize,
        width: 1 / 3,
        display: 'inline-block',
    },
    subtitle: { fontSize: (theme) => theme.typography.body2.fontSize },
} as const

export default {
    container,
    divider,
    feature,
    highlight,
    meta: {
        container: metaContainer,
    },
    skeleton,
}
