export const clients = [
    { src: '/img/clients/frank-kern.png', alt: 'Frank Kern' },
    { src: '/img/clients/todd-brown.png', alt: 'Todd Brown' },
    { src: '/img/clients/perry-belcher.png', alt: 'Perry Belcher' },
    { src: '/img/clients/chris-haddad.png', alt: 'Chris Haddad' },
    { src: '/img/clients/jon-benson.png', alt: 'Jon Benson' },
    { src: '/img/clients/organifi.png', alt: 'Organifi' },
    { src: '/img/clients/click-bank.png', alt: 'ClickBank' },
    { src: '/img/clients/primal-labs.png', alt: 'Primal Labs' },
    { src: '/img/clients/performance-golf.png', alt: 'Performance Golf' },
    { src: '/img/clients/copy-accelerator.png', alt: 'Copy Accelerator' },
    { src: '/img/clients/roi-machines.png', alt: 'Roi Machines' },
    { src: '/img/clients/kevin-harrington.png', alt: 'Kevin Harrington' },
    { src: '/img/clients/darren-hardy.png', alt: 'Darren Hardy' },
]
