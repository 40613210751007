const SECONDS_IN_MINUTE = 60

export const transformSecondsToString = (seconds?: number | null, initial = false) => {
    if (!seconds) {
        return initial ? '00:00' : ''
    }

    const minutes = Math.floor(seconds / SECONDS_IN_MINUTE)
    const remainingSeconds = seconds % SECONDS_IN_MINUTE

    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

    return `${formattedMinutes}:${formattedSeconds}`
}

export const transformStringToSeconds = (time: string) => {
    if (!time) {
        return 0
    }

    const [minutes, seconds] = time.split(':')

    return Number(minutes) * 60 + Number(seconds)
}
