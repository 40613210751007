import { Components, Theme } from '@mui/material'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

export const MuiSelect: Components<Omit<Theme, 'components'>>['MuiSelect'] = {
    styleOverrides: {
        select: ({ theme }) => ({
            padding: theme.spacing(2, 3),
            minWidth: theme.spacing(40),
        }),
    },
    defaultProps: {
        IconComponent: ArrowDropDownRoundedIcon,
        MenuProps: {
            MenuListProps: {
                disablePadding: true,
            },
        },
    },
}
