import { ReactNode, useEffect } from 'react'
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined'
import { CSSObject } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { useToast } from 'design/organisms/ToastProvider'

import styles from './Fallback.styles'
import locale from './Fallback.locale'

interface FallbackProps {
    message?: string
    icon?: ReactNode
    customBlock?: ReactNode
    customStyle?: CSSObject
    redirect?: boolean
}

export const Fallback = ({
    message = locale.message.error,
    icon = <ReportOutlinedIcon sx={styles.icon} />,
    customBlock,
    customStyle,
    redirect,
}: FallbackProps) => {
    const { showToast } = useToast()

    useEffect(() => {
        if (redirect) {
            showToast({
                message: locale.message.redirect(5),
                type: 'info',
            })
        }
    }, [])

    return (
        <Stack gap={5} justifyContent="center" alignContent="center" sx={customStyle ? customStyle : styles.container}>
            {icon}
            <Typography textAlign="center">{message}</Typography>
            {customBlock}
        </Stack>
    )
}
