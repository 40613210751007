import { Components, Theme } from '@mui/material'

export const MuiIconButton: Components<Omit<Theme, 'components'>>['MuiIconButton'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            borderRadius: theme.shape.borderRadius * 2,

            '@keyframes animation-scale': {
                '0%': {
                    transform: 'scale(0)',
                    opacity: 0.1,
                },
                '100%': {
                    transform: 'scale(1.4)',
                    opacity: 0.3,
                },
            },

            '.MuiTouchRipple-root .MuiTouchRipple-ripple': {
                transform: 'scale(1.4)',
                animationName: 'animation-scale',
            },
        }),
    },
    variants: [
        {
            props: { size: 'x2Large' },
            style: { padding: 18 },
        },
        {
            props: { size: 'medium' },
            style: { padding: 8 },
        },
        {
            props: { size: 'small' },
            style: { padding: 5 },
        },
        {
            props: { size: 'large' },
            style: { padding: 12 },
        },
    ],
}
