import { get } from 'lodash'
import { UseMutationOptions, useQueryClient } from 'react-query'

import { authAPI } from 'api/controllers'
import { CUSTOMER_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { SignInWithGooglePayload, SignInResponse } from 'types/Auth'
import { ToastParams, useToast } from 'design/organisms/ToastProvider'

/* eslint-disable @typescript-eslint/no-explicit-any */
type MutationOptions<T> = UseMutationOptions<SignInResponse, any, T>

export function onError<T>(
    options?: MutationOptions<T>,
    enqueueSnackbar?: (params: ToastParams) => void,
): MutationOptions<T>['onError'] {
    return (...details) => {
        if (get(details, '[0].response.data.message') === 'email not found') {
            window.open('https://www.vidalytics.com/pricing', '_self', 'noopener,noreferrer')
        } else {
            enqueueSnackbar?.({ message: 'Sign in failed', type: 'error' })
            options?.onError?.(...details)
        }
    }
}

export const useSignInWithGoogleMutation = (
    challengeParam: string,
    options?: MutationOptions<SignInWithGooglePayload>,
) => {
    const queryClient = useQueryClient()
    const { showToast } = useToast()

    return useInvalidateMutation<SignInResponse, SignInWithGooglePayload>(
        CUSTOMER_QUERY,
        async (payload: SignInWithGooglePayload) => {
            if (queryClient.getQueryData(CUSTOMER_QUERY)) return

            return authAPI.signInWithGoogle(challengeParam, payload)
        },

        {
            ...options,
            onError: onError(options, showToast),
        },
    )
}
