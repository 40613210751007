import { CUDEventType } from 'types/Video'

export enum VideoPlayGateType {
    time = 'time',
    exit = 'exit',
}

export interface VideoPlayGateConfig {
    guid?: string
    time?: string
    type: VideoPlayGateType
    textTop: string
    textBottom: string
    textSkip: string
    buttonText: string
    buttonColorBackground: string
    buttonColorForeground?: string
    colorBackground?: string
    colorForeground: string
    nameShow: boolean
    emailShow: boolean
    phoneShow: boolean
    skippable: boolean
    forceAlwaysDisplay?: boolean
    hubspotContactListId?: number
    switchValidate?: boolean
    playGateOnlyOne?: boolean
}

export interface PlayGateEvent {
    playGate: VideoPlayGateConfig
    event: CUDEventType
}
