import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useQuery, UseQueryOptions } from 'react-query'

import { selectStatsDateRange } from 'design/pages/VidStats/StatsOld/DatePicker/statsDateRange.slice'
import { SegmentRequest } from 'design/pages/VidStats/constants'
import { StatisticsData, SegmentType } from 'design/pages/VidStats/types'
import { statsAPI } from 'api/controllers'
import { VIDEO_STATS_QUERY } from 'api/constants'
import { useAppSelector } from 'App'

export const useVideoStatsQuery = (selectedGuid?: string, options?: UseQueryOptions<StatisticsData, unknown>) => {
    const { videoGuid, segmentType, segmentParamName } = useParams()
    const dates = useAppSelector(selectStatsDateRange)

    const [firstSegmentType, secondSegmentType] = segmentType?.split('&') || []
    const firstSegment = SegmentRequest[(firstSegmentType as SegmentType) || SegmentType.item]
    const secondSegment = secondSegmentType ? SegmentRequest[secondSegmentType as SegmentType] : ''
    const segment = secondSegment ? `${firstSegment},${secondSegment}` : firstSegment

    const segmentParam = segmentParamName ? `&paramName=${segmentParamName}` : ''
    const dateFrom = format(dates[0], 'yyyy-MM-dd')
    const dateTo = format(dates[1], 'yyyy-MM-dd')

    const params = `segment=${segment}&metrics[]=metric.watches&metrics[]=metric.conversions&metrics[]=metric.rewinds&metrics[]=metric.skips&dateFrom=${dateFrom}&dateTo=${dateTo}${segmentParam}`

    const id = selectedGuid || videoGuid
    return useQuery<StatisticsData>(
        [VIDEO_STATS_QUERY, id, segment, dateFrom, dateTo, segmentParam],
        () => statsAPI.getVideoStats(String(id), params),
        {
            enabled: Boolean(id),
            select: (data) => ({
                ...data,
                response: {
                    ...data.response,
                    data: {
                        ...data.response.data,
                        segments: data.response.data.segments.sort(
                            (a, b) => Number(b.metrics.plays) - Number(a.metrics.plays),
                        ),
                    },
                },
            }),
            ...options,
        },
    )
}
