import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type RootState } from 'App'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

type RefreshingTokenPayload = {
    isTokenRefreshing: boolean
}

type AccountState = {
    isTokenRefreshing: boolean
    isAgency: boolean
    managedClientId?: AgencyClientId
}

const initialState: AccountState = {
    isTokenRefreshing: false,
    isAgency: false,
}

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setTokenRefreshing: (state, action: PayloadAction<RefreshingTokenPayload | undefined>) => {
            const newState = state as AccountState
            newState.isTokenRefreshing = action.payload?.isTokenRefreshing || false
        },
        assignAgencyAccountStatus: (state) => ({ ...state, isAgency: true }),
        clearAgencyAccountStatus: (state) => ({ ...state, isAgency: false }),
        actAsClient: (state, { payload }: PayloadAction<{ clientId: AgencyClientId }>) => ({
            ...state,
            managedClientId: payload.clientId,
        }),
        stopActingAsClient: (state) => ({ ...state, managedClientId: undefined }),
    },
})

export const {
    setTokenRefreshing,
    assignAgencyAccountStatus,
    clearAgencyAccountStatus,
    actAsClient,
    stopActingAsClient,
} = accountSlice.actions

export const selectAccount = (state: RootState) => state.account

export const account = accountSlice.reducer
