import { ReactNode, WheelEvent } from 'react'
import { useFormikContext, Field, FieldProps, FieldAttributes } from 'formik'
import get from 'lodash/get'

import Box from '@mui/material/Box'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import style from './Input.style'

type CommonProps = TextFieldProps &
    OutlinedInputProps & {
        name: string
        icon?: ReactNode
        shrink?: boolean
        options?: {
            creditCard?: boolean
            date?: boolean
            datePattern?: string[]
        }
    }

export type InputProps = CommonProps & {
    formControlClassName?: string
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    fieldAttributes?: FieldAttributes<any>
    displayMaxLength?: boolean
    tooltip?: string | ReactNode
    hideError?: boolean
}

export const Input = withErrorBoundary(
    ({
        name,
        value: propValue,
        fullWidth = true,
        shrink = true,
        placeholder,
        disabled,
        variant = 'outlined',
        rows,
        multiline,
        fieldAttributes,
        InputProps,
        displayMaxLength,
        tooltip,
        hideError,
        onChange,
        ...rest
    }: InputProps) => {
        const formik = useFormikContext()

        const value = propValue ?? get(formik.values, name)
        const error = get(formik.errors, name)
        const touched = get(formik.touched, name)

        const commonProps = {
            ...rest,
            variant,
            id: name,
            name,
            value,
            fullWidth,
            error: touched && Boolean(error),
            helperText: touched && error,
            onChange: onChange || formik.handleChange,
            InputProps: {
                ...InputProps,
                onWheel: (e: WheelEvent<HTMLElement>) => e.target instanceof HTMLElement && e.target.blur(),
            },
        }

        if (variant === 'outlined') {
            const { helperText, InputProps, sx, ...commonPropsRest } = commonProps

            return (
                <Box sx={[fullWidth && style.wrapperFullWidth]}>
                    <Field name={name} {...fieldAttributes}>
                        {({ field }: FieldProps) => (
                            <FormControl error={commonProps.error} fullWidth={fullWidth} variant={variant}>
                                {(commonProps.label || tooltip) && (
                                    <InputLabel error={commonProps.error} shrink={shrink} htmlFor={name}>
                                        {commonProps.label}
                                        {tooltip && <HelpTooltip title={tooltip} />}
                                    </InputLabel>
                                )}
                                <OutlinedInput
                                    sx={[
                                        style.input,
                                        multiline && Number(rows) > 1 && style.multiline,
                                        ...(Array.isArray(sx) ? sx : [sx]),
                                    ]}
                                    {...field}
                                    {...commonPropsRest}
                                    {...InputProps}
                                    {...(rows ? { rows } : {})}
                                    multiline={multiline}
                                    notched={shrink}
                                    disabled={disabled}
                                    placeholder={placeholder}
                                />
                                {!hideError && helperText && (
                                    <FormHelperText sx={style.error} error={commonProps.error} data-testid="errorText">
                                        {helperText}
                                    </FormHelperText>
                                )}
                                {displayMaxLength && commonProps.inputProps?.maxLength && (
                                    <Box className="maxLength">
                                        {field.value ? String(field.value)?.length : 0}/
                                        {commonProps.inputProps.maxLength}
                                    </Box>
                                )}
                            </FormControl>
                        )}
                    </Field>
                </Box>
            )
        }

        return (
            <Field name={name} {...fieldAttributes}>
                {({ field }: FieldProps) => (
                    <TextField
                        className="formControlOutlined"
                        {...field}
                        {...commonProps}
                        InputLabelProps={{ shrink }}
                    />
                )}
            </Field>
        )
    },
)
