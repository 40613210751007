import { useQueryClient } from 'react-query'

import { Video } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEO_DUPLICATE_QUERY, VIDEOS_QUERY, VIDEO_FOLDERS_QUERY } from 'api/constants'
import { getVidWithFilter } from 'api/queries'
import { useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

type Payload = Id[]

export const useDuplicateVideoMutation = () => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Video[], Payload>(
        VIDEO_DUPLICATE_QUERY,
        async (payload: Payload) => {
            const videosText = `ideo${payload.length > 1 ? 's' : ''}`

            return mutate(Promise.all(payload.map((id) => videoAPI.duplicateVideo(id))), {
                pending: `Duplicating v${videosText}`,
                success: `V${videosText} duplicated`,
                error: `V${videosText} duplication failed`,
            })
        },
        {
            onSuccess: async (...args) => {
                const key = [VIDEOS_QUERY, false]

                await queryClient.cancelQueries(key)

                const [data] = args

                const previousVids = queryClient.getQueryData(key) as Video[]

                queryClient.setQueryData(key, [...previousVids, ...data.map(getVidWithFilter)])
            },
            invalidate: [VIDEOS_QUERY, VIDEO_FOLDERS_QUERY],
        },
    )
}
