import { Conversion, ConversionPayload, UpdateConversionPayload, ConversionEmbedCode } from 'types/Conversion'
import { Id } from 'types'
import { http } from 'api/utils/http.utils'

export const conversionAPI = {
    getConversions: () => http.get<Conversion>('conversions'),
    createConversion: (payload: ConversionPayload): Promise<Conversion> =>
        http.post<ConversionPayload, Conversion>('conversion', payload),
    updateConversion: (payload: UpdateConversionPayload): Promise<Conversion> =>
        http.post<UpdateConversionPayload, Conversion>(`conversion/${payload.conversion.guid}`, payload),
    deleteConversion: (guid: Id) => http.delete(`conversion/${guid}`),
    getConversionEmbedCode: (guid: Id) => http.get<ConversionEmbedCode>(`conversion/${guid}/embed`),
}
