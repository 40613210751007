import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const resizable: SxProps<Theme> = {
    resize: 'vertical',
    overflowY: 'auto',
    width: 1,
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: 3,
    height: 225,
    minHeight: 90,
    maxHeight: 425,
}

const snippet: SxProps<Theme> = {
    whiteSpace: 'pre',

    '& fieldset': {
        outline: 'none',
        border: 'none',
    },
}

const input: SxProps<Theme> = {
    color: 'text.secondary',
    fontFamily: 'monospace',
    fontSize: 'body2.fontSize',
}

export default {
    resizable,
    snippet,
    input,
}
