import get from 'lodash/get'
import { Row } from 'react-table'
import { TableCell, TableRow } from '@mui/material'
import { FOLDER } from 'design/atoms/Table/constants'
import { RowActions } from '../RowActions/RowActions'

import './index.scss'

type TableHeadRowProps = {
    rows: Row[]
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function TableHeadRow({ rows }: TableHeadRowProps) {
    if (!rows?.some((r) => get(r, 'original.type') !== FOLDER)) {
        return null
    }

    return (
        <TableRow className="TableHeadRow">
            <TableCell />
            <TableCell>
                <RowActions
                    row={
                        {
                            original: { guid: '', isReady: false, player: { name: 'new', id: 0, title: '' } },
                        } as any
                    }
                    cell={{} as any}
                    head
                />
            </TableCell>
        </TableRow>
    )
}
