import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { videoAPI } from 'api/controllers'
import { VIDEO_PUBLISH_QUERY, VIDEOS_QUERY, VIDEO_FOLDERS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'

export const useMassRepublishMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation(
        VIDEO_PUBLISH_QUERY,
        async () => mutate(videoAPI.massRepublish(), apiLocale.video.republish),
        {
            invalidate: [VIDEOS_QUERY, VIDEO_FOLDERS_QUERY],
        },
    )
}
