import { authAPI } from 'api/controllers'
import { ResetPasswordEmailResponse } from 'types/Auth'
import { RESET_PASSWORD_EMAIL_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useResetPasswordEmailMutation = () => {
    const { mutate } = useMutationToastDecorator({ showPendingToast: false, showSuccessToast: false })

    return useInvalidateMutation<ResetPasswordEmailResponse, string>(
        RESET_PASSWORD_EMAIL_QUERY,
        async (token: string) => mutate(authAPI.resetPasswordEmail(token), apiLocale.auth.resetPasswordEmail),
    )
}
