import { SxProps } from '@mui/material'

const container: SxProps = {
    lineHeight: 1,
    textAlign: 'center',
}

export default {
    container,
}
