import { styled } from '@mui/material/styles'
import { Tooltip, SxProps } from '@mui/material'
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

type TooltipStyledProps = {
    sxTooltip?: SxProps
}

export const TooltipStyled = styled(({ className, ...props }: TooltipProps & TooltipStyledProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ sxTooltip }: TooltipStyledProps) => ({
    [`& .${tooltipClasses.tooltip}`]: sxTooltip,
}))
