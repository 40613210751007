import { ChangeEvent, Dispatch, SetStateAction, useRef } from 'react'
import { cloneDeep } from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack, Typography } from '@mui/material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

import {
    CALLS_TO_ACTION_SECTION_INITIAL_VALUES,
    CALL_TO_ACTIONS,
    COLOR_BACKGROUND,
    COLOR_FOREGROUND,
    COLOR_HOVER_BACKGROUND,
    COLOR_HOVER_FOREGROUND,
    DISPLAY_MODE,
    HTML_SELECTOR,
    HTML_SELECTOR_TYPE,
    LINK,
    LINK_IN_NEW_WINDOW,
    SHADOW,
    SHOW_ONLY_WHEN_TRIGGERED_BEFORE,
    SHOW_TO_RETURNING_VIEWERS,
    TIME_FROM,
    TIME_TO,
    TITLE,
    TYPE,
} from 'design/pages/VidSettings/constants'
import CheckboxForm from 'design/atoms/Checkbox/CheckboxForm/CheckboxForm'
import { Input, Radio, ColorPicker } from 'design/atoms/Form'

import './index.scss'
import {
    CallToActionType,
    VideoCallToActionCustomHTMLSelectorType,
    VideoCallToActionDisplayMode,
} from 'types/VideoCallToAction'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { CallsToActionsSectionFormData } from 'types/VidSettings'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { CallToActionConfig } from 'types/Video'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { EXAMPLE_URL_PLACEHOLDER } from 'design/organisms/VidSettingsSidebar/settings.contants'
import { GUID } from 'constants/api.constants'

import { displayModes } from '../index'
import CallToActionCard from './CallToActionCard'

const belowVideoOptions = [
    {
        label: 'Reserve Space',
        value: VideoCallToActionDisplayMode.reserveSpace,
        tooltip:
            'Reserve the space on your page for the button, so your page elements below the video do not move when it appears.',
    },
    {
        label: 'Expand Container',
        value: VideoCallToActionDisplayMode.expandContainer,
        tooltip:
            'This will NOT reserve the space below your video for the button. Thus, when your CTA button appears, it will move your page elements below the video down.',
    },
]

const otherPlacementOptions = [
    {
        label: 'On Top',
        value: VideoCallToActionDisplayMode.onTop,
        tooltip:
            'Show your button on top of your video. Important note: When your video goes full screen on iOS devices, this option will not appear. To avoid this, use the Below Video CTA option.',
    },
    {
        label: 'Custom HTML',
        value: VideoCallToActionDisplayMode.customHTML,
        tooltip:
            "Add any kind of content you'd like to display below your video at a specific in-video time – such as order boxes. Make sure your hidden container uses the attribute ‘display: ”none”’. Vidalytics will overwrite this and unhide the content block at the specified time.",
    },
]

interface CallsToActionsContentProps {
    formExpanded: boolean
    setFormExpanded: Dispatch<SetStateAction<boolean>>
}

export const CallsToActionsContent = ({ formExpanded, setFormExpanded }: CallsToActionsContentProps) => {
    const {
        video,
        isLoading,
        formik: { values, setValues, setFieldValue, resetForm },
    } = useSettingsSectionContext<CallsToActionsSectionFormData>()
    const scrollRef = useRef<HTMLDivElement>()
    const { timedExitCTA } = useFeatureFlags()

    const commonDisplayModeProps = {
        redesign: true,
        controlled: true,
        onChange: (_: ChangeEvent<HTMLInputElement>, value: string) => {
            setValues({
                ...values,
                [DISPLAY_MODE]: value as VideoCallToActionDisplayMode,
            })
        },
    }

    const hasActions = Boolean(video?.[CALL_TO_ACTIONS].length)
    const ctaWithEnabledShowToReturningViewersGuid = video?.[CALL_TO_ACTIONS].find(
        (callToAction) =>
            displayModes.includes(callToAction.displayMode) &&
            (callToAction.showOnlyWhenTriggeredBefore || callToAction.showToReturningViewers),
    )?.guid

    const enableCustomByDefault = () => {
        if (ctaWithEnabledShowToReturningViewersGuid) {
            setFieldValue(DISPLAY_MODE, VideoCallToActionDisplayMode.customHTML)
        }
    }

    const handleCancel = () => {
        setFormExpanded(false)
        resetForm()
        enableCustomByDefault()
    }

    const handleEditClick = (cta: CallToActionConfig) => {
        const copy = cloneDeep(cta)
        setValues({ ...CALLS_TO_ACTION_SECTION_INITIAL_VALUES, ...copy, [TIME_TO]: copy[TIME_TO] || '' })
        setFormExpanded(true)

        // 500ms transition before opening completely
        setTimeout(() => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }), 500)
    }

    return (
        <Box className="CallsToActionsContent">
            {hasActions && (
                <Box className="callToActionCards">
                    {video?.[CALL_TO_ACTIONS].map((cta) => (
                        <CallToActionCard key={cta.guid} cta={cta} disabled={isLoading} onEditClick={handleEditClick} />
                    ))}
                </Box>
            )}

            <Accordion expanded={formExpanded} className="sectionSubAccordion">
                <AccordionSummary>
                    {!formExpanded && (
                        <Button
                            className="redesign ico addBtn"
                            onClick={() => {
                                enableCustomByDefault()

                                setFormExpanded(true)
                            }}
                            variant="outlined"
                            disabled={isLoading}
                            fullWidth={!hasActions}
                        >
                            <AddRoundedIcon />
                            {hasActions ? 'Add CTA' : 'Create new CTA'}
                        </Button>
                    )}
                </AccordionSummary>

                <AccordionDetails>
                    <Box className="formSection" ref={scrollRef}>
                        <Accordion
                            expanded={formExpanded && values[DISPLAY_MODE] !== VideoCallToActionDisplayMode.customHTML}
                            className="formSectionAccordion"
                        >
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Box className="subSection">
                                    <Typography className="subSectionTitle">Fields</Typography>
                                    <Box className="fieldWrapper">
                                        <Input
                                            name={TITLE}
                                            label="Text"
                                            placeholder="Type here"
                                            inputProps={{ maxLength: 62 }}
                                            variant="outlined"
                                            multiline
                                            displayMaxLength
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    <Box className="fieldWrapper">
                                        <Input
                                            name={LINK}
                                            label="Link"
                                            placeholder={EXAMPLE_URL_PLACEHOLDER}
                                            inputProps={{ maxLength: 2048 }}
                                            variant="outlined"
                                            displayMaxLength
                                            disabled={isLoading}
                                        />
                                    </Box>
                                    <Box className="fieldWrapper">
                                        <CheckboxForm
                                            name={LINK_IN_NEW_WINDOW}
                                            label="Open link in a new tab"
                                            disabled={isLoading}
                                        />
                                    </Box>
                                </Box>
                                <Divider className="dividerSection" />
                            </AccordionDetails>
                        </Accordion>

                        <Box className="subSection placementSection">
                            <Typography className="subSectionTitle">Placement</Typography>
                            <Box className="displayMode">
                                <Radio
                                    options={[
                                        {
                                            label: 'Below Video (Recommended)',
                                            value: 'belowVideo',
                                            tooltip:
                                                'This will show your CTA button below your video as an HTML button. This is the most reliable way to have a CTA button, and therefore is recommended.',
                                        },
                                    ]}
                                    name="belowVideo"
                                    controlled
                                    value={
                                        belowVideoOptions.some((o) => o.value === values[DISPLAY_MODE]) && 'belowVideo'
                                    }
                                    disabled={isLoading}
                                    onChange={() => {
                                        setValues({
                                            ...values,
                                            [DISPLAY_MODE]: VideoCallToActionDisplayMode.reserveSpace,
                                        })
                                    }}
                                    labelVariant="body2"
                                />
                                <Box className="marginLeft">
                                    <Radio
                                        options={belowVideoOptions}
                                        name="belowVideo"
                                        {...commonDisplayModeProps}
                                        value={
                                            belowVideoOptions.find((o) => o.value === values[DISPLAY_MODE])?.value ||
                                            null
                                        }
                                        disabled={isLoading}
                                        labelVariant="body2"
                                    />
                                </Box>
                                <Radio
                                    options={otherPlacementOptions}
                                    name="otherPlacement"
                                    value={
                                        otherPlacementOptions.find((o) => o.value === values[DISPLAY_MODE])?.value ||
                                        null
                                    }
                                    disabled={isLoading}
                                    labelVariant="body2"
                                    {...commonDisplayModeProps}
                                />
                                {values[DISPLAY_MODE] === VideoCallToActionDisplayMode.customHTML && (
                                    <Box className="marginLeft fieldWrapper">
                                        <Radio
                                            name={HTML_SELECTOR_TYPE}
                                            className="htmlSelectorType"
                                            labelVariant="body2"
                                            disabled={isLoading}
                                            options={[
                                                {
                                                    label: (
                                                        <Box className="htmlSelectorTypeContainer">
                                                            Class
                                                            {values[HTML_SELECTOR_TYPE] ===
                                                                VideoCallToActionCustomHTMLSelectorType.class && (
                                                                <Input
                                                                    name={HTML_SELECTOR}
                                                                    variant="outlined"
                                                                    placeholder="Type here"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Box>
                                                    ),
                                                    value: VideoCallToActionCustomHTMLSelectorType.class,
                                                },
                                                {
                                                    label: (
                                                        <Box className="htmlSelectorTypeContainer">
                                                            Id
                                                            {values[HTML_SELECTOR_TYPE] ===
                                                                VideoCallToActionCustomHTMLSelectorType.id && (
                                                                <Input
                                                                    name={HTML_SELECTOR}
                                                                    variant="outlined"
                                                                    placeholder="Type here"
                                                                    fullWidth
                                                                />
                                                            )}
                                                        </Box>
                                                    ),
                                                    value: VideoCallToActionCustomHTMLSelectorType.id,
                                                },
                                            ]}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Divider className="dividerSection" />

                        <Box className="subSection">
                            <Typography className="subSectionTitle">Display time</Typography>
                            <Box className="marginLeft">
                                {timedExitCTA ? (
                                    <>
                                        <Stack direction="row" gap={4} mt={2}>
                                            <Input
                                                name={TIME_FROM}
                                                variant="outlined"
                                                label="From"
                                                disabled={isLoading}
                                            />
                                            <Input name={TIME_TO} variant="outlined" label="To" disabled={isLoading} />
                                        </Stack>
                                        <SwitchForm
                                            sx={{ width: '100%', justifyContent: 'space-between' }}
                                            name={TYPE}
                                            value={values[TYPE] === CallToActionType.exit}
                                            tooltipPlacement={SwitchTooltipPlacement.inside}
                                            label={<Typography variant="body2">Exit CTA</Typography>}
                                            helpInfo="Leave the From - To times above empty if you want to same Exit CTA to pop up whenever your video is paused. If you want this CTA to show up only when your video is paused within a certain timeframe, add those times above."
                                            switchProps={{ size: 'small' }}
                                            disabled={isLoading}
                                            onChange={(name, value) => {
                                                setFieldValue(
                                                    name,
                                                    value ? CallToActionType.exit : CallToActionType.time,
                                                )
                                            }}
                                        />
                                    </>
                                ) : (
                                    <Radio
                                        className="callToActionType"
                                        labelVariant="body2"
                                        disabled={isLoading}
                                        options={[
                                            {
                                                label: (
                                                    <Box className="container">
                                                        <Box className="label">
                                                            Time{' '}
                                                            <HelpTooltip
                                                                id="callToActionTypeInfo"
                                                                title="If no end time is specified, your CTA will be visible until the video ends."
                                                            />
                                                        </Box>
                                                        <Box
                                                            className="inputs"
                                                            style={{ display: 'flex', alignItems: 'top', gap: '10px' }}
                                                        >
                                                            <span className="alignment">from</span>
                                                            <Input
                                                                style={{ maxWidth: '140px' }}
                                                                name={TIME_FROM}
                                                                variant="outlined"
                                                            />
                                                            <span className="alignment">to</span>
                                                            <Input
                                                                style={{ maxWidth: '140px' }}
                                                                name={TIME_TO}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </Box>
                                                ),
                                                value: CallToActionType.time,
                                                propsRadio: { sx: { p: '6px', mt: '5px' } },
                                            },
                                            { label: 'Exit', value: CallToActionType.exit },
                                        ]}
                                        name={TYPE}
                                    />
                                )}

                                <Box>
                                    <SwitchForm
                                        sx={{ width: '100%', justifyContent: 'space-between' }}
                                        name={SHOW_TO_RETURNING_VIEWERS}
                                        label={<Typography variant="body2">Show To Returning Viewers</Typography>}
                                        labelPlacement="start"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                        disabled={
                                            Boolean(
                                                displayModes.includes(values[DISPLAY_MODE]) &&
                                                    ctaWithEnabledShowToReturningViewersGuid &&
                                                    values[GUID] !== ctaWithEnabledShowToReturningViewersGuid,
                                            ) || isLoading
                                        }
                                        helpInfo={
                                            (displayModes.includes(values[DISPLAY_MODE]) &&
                                            ctaWithEnabledShowToReturningViewersGuid &&
                                            values[GUID] !== ctaWithEnabledShowToReturningViewersGuid
                                                ? 'Only one CTA per video can be shown automatically to returning viewers. You already have this option enabled for a different CTA. Please disable it there if you’d like to enable it for this one.'
                                                : 'When this is enabled your CTA button will show immediately to all returning visitors, instead of waiting for the specified time in the video.') ||
                                            ''
                                        }
                                        onChange={(name, value) => {
                                            setValues({
                                                ...values,
                                                [name]: value,
                                                [SHOW_ONLY_WHEN_TRIGGERED_BEFORE]: false,
                                            })
                                        }}
                                        switchProps={{ size: 'small' }}
                                    />
                                </Box>
                                {(values[SHOW_TO_RETURNING_VIEWERS] || values[SHOW_ONLY_WHEN_TRIGGERED_BEFORE]) && (
                                    <SwitchForm
                                        sx={{ width: '100%', justifyContent: 'space-between' }}
                                        name={SHOW_ONLY_WHEN_TRIGGERED_BEFORE}
                                        label={<Typography variant="body2">Only When Triggered Before</Typography>}
                                        helpInfo="Enable this option to show this CTA only when your viewer reached the CTA time in their previous session."
                                        onChange={(name, value) => {
                                            setValues({
                                                ...values,
                                                [name]: value,
                                                [SHOW_TO_RETURNING_VIEWERS]: false,
                                            })
                                        }}
                                        disabled={isLoading}
                                        switchProps={{ size: 'small' }}
                                        labelPlacement="start"
                                        tooltipPlacement={SwitchTooltipPlacement.inside}
                                    />
                                )}
                            </Box>
                        </Box>

                        <Divider className="dividerSection" />

                        <Accordion
                            expanded={formExpanded && values[DISPLAY_MODE] !== VideoCallToActionDisplayMode.customHTML}
                            className="formSectionAccordion"
                        >
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Box className="subSection colorsAndEffects">
                                    <Typography className="subSectionTitle">Colors and Effects</Typography>

                                    <Box className="marginLeft gap">
                                        <Box className="fieldWrapper">
                                            <ColorPicker name={COLOR_BACKGROUND} label="Button" disabled={isLoading} />
                                        </Box>
                                        <ColorPicker
                                            name={COLOR_HOVER_BACKGROUND}
                                            label="Hover over background"
                                            disabled={isLoading}
                                        />
                                        <ColorPicker name={COLOR_FOREGROUND} label="Text" disabled={isLoading} />
                                        <ColorPicker
                                            name={COLOR_HOVER_FOREGROUND}
                                            label="Hover over text"
                                            disabled={isLoading}
                                        />
                                        <SwitchForm
                                            sx={{ width: '100%', justifyContent: 'space-between' }}
                                            name={SHADOW}
                                            label={<Typography variant="body2">Drop Shadow</Typography>}
                                            switchProps={{ size: 'small' }}
                                            labelPlacement="start"
                                            disabled={isLoading}
                                            tooltipPlacement={SwitchTooltipPlacement.inside}
                                        />
                                    </Box>
                                </Box>
                                <Divider className="dividerSection" />
                            </AccordionDetails>
                        </Accordion>

                        <Box className="ctas">
                            <Button variant="outlined" className="redesign" onClick={handleCancel}>
                                Cancel
                            </Button>
                            <Button variant="contained" className="redesign" type="submit" disabled={isLoading}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
