import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { videoAPI } from 'api/controllers'
import { VIDEO_PUBLISH_QUERY, VIDEOS_QUERY, VIDEO_FOLDERS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'

type Payload = Id[]

export const usePublishVideoMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, Payload>(
        VIDEO_PUBLISH_QUERY,
        async (payload: Payload) => {
            const videosText = `ideo${payload.length > 1 ? 's' : ''}`

            return await mutate(Promise.all(payload.map((id) => videoAPI.publishVideo(id))), {
                pending: `Publishing v${videosText}`,
                success: `V${videosText} published`,
                error: `V${videosText} publication failed`,
            })
        },
        {
            invalidate: [VIDEOS_QUERY, VIDEO_FOLDERS_QUERY],
        },
    )
}
