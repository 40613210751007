import { AxiosError } from 'axios'
import { useSearchParams } from 'react-router-dom'
import { useQuery, UseQueryOptions } from 'react-query'

import { PlanPayload } from 'types/Auth'
import { authAPI } from 'api/controllers'
import { PLAN_QUERY } from 'api/constants'

export const usePlanQuery = (key: string, options?: UseQueryOptions<PlanPayload, AxiosError, PlanPayload>) => {
    const [searchParams] = useSearchParams()
    const queryParams = new URLSearchParams()
    const pricepoint = searchParams.get('pricepoint')
    const coupons = searchParams.getAll('coupon')
    if (pricepoint) {
        queryParams.append('pricePoint', pricepoint)
    }
    coupons?.forEach((coupon) => queryParams.append('coupons[]', coupon))

    const queryString = `?${queryParams.toString()}`
    return useQuery<PlanPayload, AxiosError>([PLAN_QUERY, key], () => authAPI.getPlan(key, queryString), options)
}
