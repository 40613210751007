import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

import { videoAPI } from 'api/controllers'
import { VIDEO_FOLDERS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'

type Payload = Id[]

export const useDeleteFoldersMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, Payload>(VIDEO_FOLDERS_QUERY, async (payload: Payload) => {
        const videosText = `older${payload.length > 1 ? 's' : ''}`

        return mutate(Promise.all(payload.map((id) => videoAPI.deleteFolder(id))), {
            pending: `Deleting f${videosText}`,
            error: `Failed to delete f${videosText}`,
            success: `F${videosText} deleted`,
        })
    })
}
