import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { CUSTOMER_QUERY, CUSTOMER_SUBSCRIPTION_QUERY } from 'api/constants'
import { queryClient } from 'api/queries'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { ActivateCustomerPlanPayload, Customer, CustomerStatus, Plan } from 'types/Customer'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useActivatePlanMutation = (options?: UseMutationOptions<Plan, any, ActivateCustomerPlanPayload>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Plan, ActivateCustomerPlanPayload>(
        CUSTOMER_SUBSCRIPTION_QUERY,
        async (payload: ActivateCustomerPlanPayload) => mutate(userAPI.activatePlan(payload), apiLocale.user.plan),
        {
            ...options,
            onSuccess: (data, variables, context) => {
                options?.onSuccess?.(data, variables, context)

                const customer = queryClient.getQueryData(CUSTOMER_QUERY) as Customer

                if (customer) {
                    queryClient.setQueryData(CUSTOMER_QUERY, {
                        ...customer,
                        status: customer.status === CustomerStatus.inactive ? CustomerStatus.active : customer.status,
                    })
                }
            },
            invalidate: [CUSTOMER_QUERY],
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
