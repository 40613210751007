import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_INVITATION_RESEND } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

export const useResendInvitationMutation = () => ({
    resendInvitation: useInvalidateMutation<undefined, AgencyClientId>(
        AGENCY_INVITATION_RESEND,
        async (id: AgencyClientId) => agencyController.client.invitation.resend(id),
    ),
})
