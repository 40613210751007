import { omit } from 'lodash'
import { IconButton, Tooltip } from '@mui/material'
import { IconButtonProps } from '@mui/material/IconButton/IconButton'
import { forwardRef } from 'react'

export const BaseIconButton = forwardRef((props: IconButtonProps, ref) => {
    return (
        <Tooltip ref={ref} title={props.title || props['aria-label']} arrow>
            <IconButton {...omit(props, ['title'])} />
        </Tooltip>
    )
})
