import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import {
    DELETE_CLOSED_CAPTIONS_MUTATION,
    CLOSED_CAPTIONS_DRAFT,
    VIDEOS_QUERY,
    CLOSED_CAPTIONS_TEXT,
} from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'
import { Video } from 'types/Video'

export const useDeleteClosedCaptionsMutation = (options?: Options<null, Video, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, Video, string>(
        DELETE_CLOSED_CAPTIONS_MUTATION,
        async (video: Video) => {
            return mutate(settingsAPI.deleteClosedCaptions(video), apiLocale.settings.closedCaptions.delete)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, CLOSED_CAPTIONS_DRAFT, CLOSED_CAPTIONS_TEXT],
        },
    )
}
