import { useQuery, UseQueryOptions } from 'react-query'

import { Funnel } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import { SMART_VIDS_QUERY } from 'api/constants'

export const useSmartVidQuery = (id?: string, options?: UseQueryOptions<Funnel, unknown, Funnel>) =>
    useQuery<Funnel>([SMART_VIDS_QUERY, id], () => smartVidConroller.smartVid.getById(String(id)), {
        enabled: Boolean(id),
        ...options,
    })
