import { SxProps, Theme } from '@mui/material'

const root: SxProps<Theme> = {
    minHeight: 310,
    maxHeight: 800,
    borderRadius: 4,
    width: 1,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
}

const skeletonBox: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const skeleton: SxProps<Theme> = {
    width: '70%',
    height: 60,
    borderRadius: 4,
}

export default {
    root,
    skeletonBox,
    skeleton,
}
