import { SxProps, Theme } from '@mui/material'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'

const container: SxProps<Theme> = {
    borderRadius: 4,
    backgroundColor: (theme) => theme.palette.secondary.light,
    border: (theme) => `1px solid ${theme.palette.secondary.dark}`,
    overflow: 'hidden',
}

const description: SxProps<Theme> = {
    p: 3,
    backgroundColor: (theme) => theme.palette.info.light,
}

export default {
    cc: {
        container,
        description,
    },
    switch: style.container,
}
