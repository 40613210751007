import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { WHITELISTED_DOMAINS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { UpdateWhitelistedDomainPayload } from 'types/Customer'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useUpdateWhitelistedDomainMutation = (
    options?: UseMutationOptions<null, any, UpdateWhitelistedDomainPayload>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, UpdateWhitelistedDomainPayload>(
        WHITELISTED_DOMAINS_QUERY,
        async (payload: UpdateWhitelistedDomainPayload) =>
            mutate(userAPI.updateWhitelistedDomain(payload), apiLocale.user.whitelistDomain.update),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
