import React, { DetailedHTMLProps, ImgHTMLAttributes, useEffect, useRef, useState } from 'react'

import { Box, BoxProps } from '@mui/material'

export type LazyLoadImageProps = BoxProps<'img'> &
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

export const LazyLoadImage: React.FC<LazyLoadImageProps> = ({ src, onError, ...props }) => {
    const imgRef = useRef<HTMLImageElement>(null)
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true)
                    if (imgRef.current) {
                        observer.unobserve(imgRef.current)
                    }
                }
            })
        })

        if (imgRef.current) {
            observer.observe(imgRef.current)
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current)
            }
            observer.disconnect()
        }
    }, [])

    const handleError: LazyLoadImageProps['onError'] = (event) => isVisible && onError?.(event)

    return (
        <Box
            ref={imgRef}
            src={isVisible ? src : '/img/video/no-image.png'}
            onError={handleError}
            component="img"
            {...props}
        />
    )
}
