import { Link as ReactRouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'

import { getSx } from 'styles/theme/utils'
import style from './Link.style'

export type LinkProps = MuiLinkProps &
    RouterLinkProps & {
        external?: boolean
        disabled?: boolean
    }

export const Link = ({ external, disabled, ...props }: LinkProps) => {
    const commonProps = {
        ...props,
        sx: getSx([style.root, Boolean(disabled) && style.disabled], props.sx),
    }

    return external ? (
        <MuiLink {...commonProps} href={String(props.to)} />
    ) : (
        <MuiLink {...commonProps} component={ReactRouterLink} />
    )
}
