import { useQuery } from 'react-query'

import { FOLDER, SEARCH } from 'design/atoms/Table/constants'
import { TITLE, getFolderPath } from 'design/pages/MyVids'
import { FolderAPI, Folder } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEO_FOLDERS_QUERY } from 'api/constants'

export const useVideoFoldersQuery = () =>
    useQuery<Folder[]>(VIDEO_FOLDERS_QUERY, async () => {
        const folders = await videoAPI.getFolders()

        return folders
            ?.map((f: FolderAPI) => ({
                ...f,
                type: FOLDER,
                [SEARCH]: f[TITLE],
                folderPath: getFolderPath(folders, f),
            }))
            .reverse()
    })
