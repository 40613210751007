import { SxProps, Theme } from '@mui/material'

const stepLineSkeleton: SxProps<Theme> = {
    mt: 8,
    height: 4,
}

const stepLine: SxProps<Theme> = {
    height: 4,
    flex: 1,
    cursor: 'pointer',
    borderRadius: 1,
}

export default {
    stepLine,
    stepLineSkeleton,
}
