import { useEffect } from 'react'

const useBeforeUnload = (message: string, when: boolean) => {
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault()
            return (event.returnValue = message)
        }

        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload)
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }

        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [when, message])
}

export default useBeforeUnload
