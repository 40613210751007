import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_CLIENTS, AGENCY_UPDATE_CLIENT } from 'api/constants'
import { useApiErrorToast, useInvalidateMutation } from 'api/hooks'
import { UpdateAgencyClientRequest } from 'api/contracts/agency/endpoints/updateAgencyClient.contract'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

export const useUpdateAgencyClientMutation = (id: AgencyClientId) => {
    const { showApiErrorToast } = useApiErrorToast()
    const mutation = useInvalidateMutation<undefined, UpdateAgencyClientRequest>(
        AGENCY_UPDATE_CLIENT,
        async (payload) => agencyController.client.update(id, payload),
        { onError: (e) => showApiErrorToast(e), invalidate: [AGENCY_GET_CLIENTS] },
    )

    return {
        updateAgencyClient: mutation.mutateAsync,
        ...mutation,
    }
}
