import { apiLocale } from 'api/api.locale'
import {
    SMART_VIDS_QUERY,
    SMART_VID_STEP_QUERY,
    UPDATE_SMART_VID_STEP_MUTATION,
    VIDEOS_QUERY,
    SMART_VID_DETAILS_QUERY,
} from 'api/constants'
import {
    UpdateSmartVidStepRequest,
    UpdateSmartVidStepResponse,
} from 'api/contracts/smartVids/endpoints/updateSmartVidSteps.contract'
import { ApiError } from 'api/contracts/utilitary/error'
import { smartVidConroller } from 'api/controllers'
import { Options, useInvalidateMutation } from 'api/hooks'
import { queryClient } from 'api/queries'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

export const useUpdateSmartVidStepMutation = (
    options?: Options<UpdateSmartVidStepResponse, UpdateSmartVidStepRequest, ApiError>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<UpdateSmartVidStepResponse, UpdateSmartVidStepRequest>(
        UPDATE_SMART_VID_STEP_MUTATION,
        async (request) => mutate(smartVidConroller.steps.update(request), apiLocale.smartVids.step.update),
        {
            ...options,
            onSuccess: (...data) => {
                options?.onSuccess?.(...data)

                const [, request] = data

                queryClient.setQueryData([SMART_VID_STEP_QUERY, request.svId, request.vidId], request.step)
            },
            invalidate: [VIDEOS_QUERY, SMART_VIDS_QUERY, SMART_VID_STEP_QUERY, SMART_VID_DETAILS_QUERY],
        },
    )
}
