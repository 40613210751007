import { UseQueryOptions, useQuery } from 'react-query'

import { settingsAPI } from 'api/controllers'
import { CLOSED_CAPTIONS_TEXT } from 'api/constants'

import { ClosedCaptionsSourceResponse } from 'api/contracts/closedCaptions/closedCaptionsSource.contract'

export const useClosedCaptionsTextQuery = (
    url: ClosedCaptionsSourceResponse,
    options?: UseQueryOptions<string, unknown>,
) =>
    useQuery<ClosedCaptionsSourceResponse>(
        [CLOSED_CAPTIONS_TEXT, url],
        () => settingsAPI.readClosedCaptionsText(url),
        options,
    )
