import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const container: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    backgroundColor: 'rgba(242, 244, 247, 1)',
    flexGrow: 1,
    p: 6,
}

const sortBy: SxProps<Theme> = {
    // To prevent input dribbling on option change
    minWidth: 170,
}

const clientCardContainer: SxProps<Theme> = (theme) => ({
    display: 'grid',
    gap: 5,
    gridTemplateColumns: '1fr',

    [theme.breakpoints.up('laptop')]: {
        gridTemplateColumns: '1fr 1fr',
    },
})

export default {
    container,
    sortBy,
    clientCard: {
        container: clientCardContainer,
    },
}
