import { SxProps, Theme } from '@mui/material'

const modalButtons: SxProps<Theme> = {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'end',
    gap: 4,
}

export default {
    modal: {
        buttons: modalButtons,
    },
}
