import { SxProps, Theme } from '@mui/material'

const errorLink: SxProps<Theme> = {
    color: 'inherit',
    textDecoration: 'underline',

    '&:hover': {
        color: 'inherit',
        textDecoration: 'none',
    },
}

const haveAccountLogin: SxProps<Theme> = (theme: Theme) => ({
    mt: 3,
    mb: -4,
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.up('tablet')]: {
        display: 'none',
    },
})

export default {
    errorLink,
    haveAccountLogin,
}
