import { locale } from 'locales'

export default {
    message: {
        error: locale.messages.errors.generic,
        redirect: (delay: number) => `You will be redirected to Homepage in ${delay} seconds`,
    },
    action: {
        cancel: locale.operations.generic.cancel,
    },
}
