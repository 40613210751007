import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_ACCEPT_INVITATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { AcceptAgencyInvitationRequest } from 'api/contracts/agency/endpoints/acceptAgencyInvitation.contract'

export const useAcceptAgencyInvitationMutation = () => ({
    acceptInvitation: useInvalidateMutation<undefined, AcceptAgencyInvitationRequest>(
        AGENCY_ACCEPT_INVITATION,
        async (payload: AcceptAgencyInvitationRequest) => agencyController.client.invitation.accept(payload),
    ),
})
