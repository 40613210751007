export enum MetricKey {
    heatmap = 'heatmap',
    heatmapSkips = 'heatmapSkips',
    heatmapRewinds = 'heatmapRewinds',
    audience = 'audience',
    conversions = 'conversions',
    engagement = 'engagement',
    fullScreen = 'fullScreen',
    volume = 'volume',
    closedCaptions = 'closedCaptions',
    speedUps = 'speedUps',
    pauses = 'pauses',
}

export interface MetricsSwitchesState {
    [MetricKey.heatmap]: boolean
    [MetricKey.heatmapSkips]: boolean
    [MetricKey.heatmapRewinds]: boolean
    [MetricKey.audience]: boolean
    [MetricKey.conversions]: boolean
    [MetricKey.engagement]: boolean
    [MetricKey.fullScreen]: boolean
    [MetricKey.volume]: boolean
    [MetricKey.closedCaptions]: boolean
    [MetricKey.speedUps]: boolean
    [MetricKey.pauses]: boolean
}

export interface SetMetricPayload {
    key: MetricKey
    value: boolean
}
