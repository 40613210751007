import { Components, Theme } from '@mui/material'

export const MuiSlider: Components<Omit<Theme, 'components'>>['MuiSlider'] = {
    variants: [
        {
            props: { color: 'primary' },
            style: ({ theme }) => ({
                '.MuiSlider-rail': { backgroundColor: theme.palette.action.active },
            }),
        },
    ],
}
