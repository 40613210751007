import { Components, Theme } from '@mui/material'

import { font, pxToRem } from 'styles/theme/typography'
import { textStyle } from 'styles/theme/utils'

export const MuiButton: Components<Omit<Theme, 'components'>>['MuiButton'] = {
    styleOverrides: {
        root: ({ theme, ownerState }) => ({
            fontFamily: font.inter.semibold,
            textTransform: 'capitalize',
            borderRadius: 12,
            gap: 8,
            minWidth: 'auto',

            '.MuiButton-startIcon': {
                margin: 0,

                '&>*:nth-of-type(1)': {
                    fontSize: pxToRem(16),
                },
            },

            ...(ownerState.variant === 'contained' && {
                '&:active': {
                    boxShadow: theme.shadows[6],
                },
            }),
            ...((ownerState.variant === 'outlined' || ownerState.variant === 'text') && {
                boxShadow: 'unset',
            }),
        }),
    },

    variants: [
        {
            props: { variant: 'contained' },
            style: ({ theme }) => ({ boxShadow: theme.shadows[2] }),
        },

        {
            props: { size: 'x2Large' },
            style: { ...textStyle(18, 28), padding: '16px 28px' },
        },
        {
            props: { size: 'xLarge' },
            style: { ...textStyle(16, 24), padding: '11px 20px' },
        },
        {
            props: { size: 'large' },
            style: { ...textStyle(15, 26), padding: '9px 18px' },
        },
        {
            props: { size: 'medium' },
            style: { ...textStyle(14, 24), padding: '8px 16px' },
        },
        {
            props: { size: 'small' },
            style: { ...textStyle(13, 22), padding: '7px 14px' },
        },
        {
            props: { size: 'xSmall' },
            style: { ...textStyle(13, 22), padding: '5px 14px' },
        },
        {
            props: { size: 'iconX2Large' },
            style: { padding: 18 },
        },
        {
            props: { size: 'iconLarge' },
            style: { padding: 12 },
        },
        {
            props: { size: 'iconMedium' },
            style: { padding: 8 },
        },
        {
            props: { size: 'iconSmall' },
            style: { padding: 5 },
        },

        {
            props: { color: 'primary', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.primary.dark}` }),
        },
        {
            props: { color: 'secondary', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.secondary.dark}` }),
        },
        {
            props: { color: 'error', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.error.dark}` }),
        },
        {
            props: { color: 'warning', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.warning.dark}` }),
        },
        {
            props: { color: 'success', variant: 'contained' },
            style: ({ theme }) => ({ border: `1px solid ${theme.palette.success.dark}` }),
        },
        {
            props: { color: 'tertiary', variant: 'outlined' },
            style: ({ theme }) => ({
                border: `1px solid ${theme.palette.common.tertiaryOutlinedBorder}`,

                '&:hover': {
                    border: `1px solid ${theme.palette.common.tertiaryOutlinedBorder}`,
                },
            }),
        },
        {
            props: { disabled: true, variant: 'contained' },
            style: { border: `1px solid` },
        },
        {
            props: { disabled: true, variant: 'outlined' },
            style: ({ theme }) => ({
                border: `1px solid rgba(${theme.palette.common.black}, ${theme.palette.action.focusOpacity}) !important`,
            }),
        },
    ],
}
