import { UseMutationOptions } from 'react-query'
import { smartVidConroller } from 'api/controllers'
import {
    SMART_VIDS_QUERY,
    VIDEO_BRANCH_QUERY,
    SMART_VIDS_STEPS_QUERY,
    VIDEOS_QUERY,
    UPDATE_SMART_VIDS_MUTATION,
} from 'api/constants'
import { Id } from 'types'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import {
    UpdateSmartVidRequest,
    UpdateSmartVidResponse,
} from 'api/contracts/smartVids/endpoints/updateSmartVid.contract'

export const useUpdateSmartVidMutation = (
    id?: Id,
    options?: UseMutationOptions<UpdateSmartVidRequest, unknown, UpdateSmartVidResponse>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<UpdateSmartVidRequest, UpdateSmartVidResponse>(
        [UPDATE_SMART_VIDS_MUTATION, id],
        async (payload: UpdateSmartVidRequest) =>
            mutate(smartVidConroller.smartVid.update(String(id), payload), apiLocale.smartVids.update),
        {
            invalidate: [VIDEO_BRANCH_QUERY, SMART_VIDS_QUERY, SMART_VIDS_STEPS_QUERY, VIDEOS_QUERY],
            ...options,
        },
    )
}
