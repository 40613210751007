import { useMemo } from 'react'
import { get } from 'lodash'
import { Typography } from '@mui/material'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    UI_CONTROL_BAR_ALWAYS_SHOW,
    UI_CONTROL_BAR_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
    UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
    NONE,
} from 'design/pages/VidSettings/constants'
import { Radio } from 'design/atoms/Form'
import { ControlsSectionFormData } from 'types/VidSettings'
import { SwitchTooltipPlacement, SwitchForm } from 'design/atoms/Switch'

import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import locale from './SeekingBar.locale'

export const endVideoActions = [
    {
        value: UI_CONTROL_BAR_SEEK_BAR_SHOW,
        ...locale.controls.endVideo.standard,
    },
    {
        value: UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
        ...locale.controls.endVideo.revisit,
    },
    {
        value: UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
        ...locale.controls.endVideo.engageBar,
    },
    {
        value: NONE,
        ...locale.controls.endVideo.none,
    },
]

export const seekingBarStyleKeys = [
    UI_CONTROL_BAR_SEEK_BAR_SHOW,
    UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS,
    UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW,
] as const

interface Props {
    isSmartVidGlobalSettings?: boolean
}

export const SeekingBar = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )
    const {
        formik: { values, setValues },
        isLoading,
        onChange,
    } = useSectionContext<ControlsSectionFormData>()
    const commonProps = {
        afterChange: onChange,
        disabled: isLoading,
        redesign: true,
        switchProps: { size: 'small' },
    } as const

    const getRadioValue = () =>
        seekingBarStyleKeys.reduce((acc, l) => {
            if (values[l]) {
                return l
            }
            return acc
        }, '') || NONE

    const handleRadioChange = (key?: keyof ControlsSectionFormData) => {
        if (!key || values[key]) {
            return
        }

        setValues({
            ...values,
            ...seekingBarStyleKeys.reduce(
                (acc, k) => ({
                    ...acc,
                    [k]: false,
                }),
                {},
            ),
            ...(key ? { [key]: true } : {}),
        })

        onChange()
    }

    return (
        <Section title={locale.title} indent stackProps={{ gap: 2 }}>
            <SwitchForm
                sx={style.container}
                name={UI_CONTROL_BAR_ALWAYS_SHOW}
                label={<Typography variant="body2">{locale.controls.alwaysShow.label}</Typography>}
                labelPlacement="start"
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={locale.controls.alwaysShow.hint}
                {...commonProps}
            />

            <Radio
                labelVariant="body2"
                name="seekingRadioGroup"
                options={endVideoActions}
                value={getRadioValue()}
                controlled
                {...commonProps}
                onClick={(event) => {
                    if (get(event, 'target.checked')) {
                        handleRadioChange()
                    }
                }}
                onChange={(_, value) => {
                    const key = value as keyof ControlsSectionFormData

                    handleRadioChange(key)
                }}
            />
        </Section>
    )
}
