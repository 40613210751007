import { Components, Theme } from '@mui/material'

import { unifiedInputPadding } from 'styles/common'

export const MuiOutlinedInput: Components<Omit<Theme, 'components'>>['MuiOutlinedInput'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            borderRadius: theme.spacing(3),
            // outlined input has invisible
            // for box sizing system border
            padding: '1px !important',
        }),
        input: {
            padding: unifiedInputPadding,
        },
    },
}
