import { ReactNode } from 'react'
import { MsalProvider } from '@azure/msal-react'
import { MsalReactTester } from 'msal-react-tester'

import { getMsalInstance } from 'thirdPartyServices/microsoft'
import { Flow } from 'constants/environment.constants'

type UntestableWrappersProps = {
    flow?: Flow
    children: ReactNode
}

export const UntestableWrappers = ({ children, flow }: UntestableWrappersProps) => {
    if (flow !== Flow.system) {
        return <>{children}</>
    }

    return (
        <MsalProvider instance={flow === Flow.system ? getMsalInstance : new MsalReactTester().client}>
            {children}
        </MsalProvider>
    )
}
