import { SxProps, Theme } from '@mui/material'

const label: SxProps<Theme> = (theme: Theme) => ({
    maxWidth: 200,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
    pl: 2,

    [theme.breakpoints.up('laptop')]: {
        maxWidth: 240,
    },
})

const error: SxProps<Theme> = {
    color: (theme) => theme.palette.error.main,
}

export default {
    label,
    error,
}
