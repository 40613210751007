import { Components, Theme } from '@mui/material'

export const MuiInputAdornment: Components<Omit<Theme, 'components'>>['MuiInputAdornment'] = {
    styleOverrides: {
        root: {
            margin: 0,
        },
        positionStart: ({ theme }) => ({
            marginLeft: theme.spacing(2),
        }),

        positionEnd: ({ theme }) => ({
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(3),
        }),
    },
}
