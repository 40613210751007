import { RgbColor, RgbaColor } from 'react-colorful'
import { rgbToHex as muiRgbToHex, hexToRgb as muiHexToRgb } from '@mui/material'

export const rgbToHex = (rgb?: RgbColor): string => {
    if (!rgb) {
        return ''
    }

    const { r, g, b } = rgb

    return muiRgbToHex(`rgb(${r}, ${g}, ${b})`)
}

const defaultRgb = { r: 0, g: 0, b: 0 }

export const hexToRgb = (hex?: string): RgbColor => {
    if (!hex) return defaultRgb

    const rgbString = muiHexToRgb(hex)

    const regex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/
    const matches = rgbString.match(regex)

    if (matches?.length === 4) {
        const [, r, g, b] = matches.map(Number)

        return { r, g, b }
    }

    return defaultRgb
}

export const getRgbaStyle = (c: RgbaColor, includeOpacity = true) => {
    if (!c) {
        return ''
    }

    return `rgba(${c.r}, ${c.g}, ${c.b}, ${includeOpacity ? c.a : 1})`
}
