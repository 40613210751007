import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

import { TimezoneDate } from 'api/contracts/utilitary/entities/timezoneDate'
import { INCREASED, PLAYS_LAST_24_HOURS, TOTAL_PLAY_COUNT } from 'design/pages/Dashboard/constants'
import { VIDEO } from 'design/pages/MyVids'
import { ChartEntity, KeyValuePair } from 'types'
import { Conversion } from 'types/Conversion'
import { Video } from 'types/Video'
import { valueFormatter, currencyOrPercentageOrDefault, formatAsPercentage, valueOrDefault } from 'utils'
import { DATA } from 'constants/api.constants'

export type RecentlyPlayed = {
    [INCREASED]: boolean
    [PLAYS_LAST_24_HOURS]: number
    [TOTAL_PLAY_COUNT]: number
}

export type RecentlyPlayedStats = {
    [VIDEO]: Video
    [DATA]: RecentlyPlayed
}

export type TotalPlaysStats = {
    [VIDEO]: Video
    [DATA]: ChartEntity[]
}

export type ConversionsStats = {
    conversion: Conversion
    [DATA]: ChartEntity[]
}

export type TopConvertedVideo = {
    count: number
}

export type TopConvertedVideoStats = {
    [VIDEO]: Video
    [DATA]: TopConvertedVideo
}

export interface Metrics {
    impressions?: number
    conversions?: KeyValuePair<number>
    revenue?: number
    watches?: KeyValuePair<number>
    plays?: number
    playRate?: number
    bounceRate?: number
    rewinds?: KeyValuePair<number>
    skips?: KeyValuePair<number>
    playsUnique?: number | boolean
    engagement?: number
    conversionCount?: number | boolean
    conversionRate: number
    revenueAverage?: number
    unmuteRate: number
    pgOptInRate: number
}

export interface Segment {
    title: string
}

export enum SegmentType {
    item = 'item',
    trafficSource = 'traffic-source',
    converted = 'converted',
    geo = 'geo',
    device = 'device',
    browser = 'browser',
    newVsReturning = 'new-vs-returning',
    urlParams = 'url_params',
    tags = 'tags',
    dayOfWeek = 'day-of-week',
    domain = 'domain',
    timeOfDay = 'time-of-day',
}

export interface Segments {
    skips: KeyValuePair<number>
    rewinds: KeyValuePair<number>
    title: string
    metrics: Metrics
    color?: string
    checked?: boolean
    variants?: Segments[]
    segments: string[]
}

export interface StatisticsRequest {
    controller: string
    action: string
    segment: string
    metrics: string[]
    videoGuid: string
    dateFrom: string
    dateTo: string
}

export interface VideoStats {
    guid: string
    sources: {
        HLS: string
        DASH: string
    }
    length: number
    title: string
    width?: number
    height?: number
    aspectRatio?: string
    dateCreated: string
}

export interface StatisticsResponse {
    timestampCreated: number
    data: {
        video: VideoStats
        segment: Segment
        segments: Segments[]
        total: {
            playsUnique: number
            plays: number
            playRate: number
        }
    }
}

export interface StatisticsData {
    request: StatisticsRequest
    response: StatisticsResponse
}

export enum RequestMetrics {
    watches = 'metric.watches',
    conversions = 'metric.conversions',
    rewinds = 'metric.rewinds',
    skips = 'metric.skips',
}

export enum MetricKeys {
    plays = 'plays',
    playRate = 'playRate',
    bounceRate = 'bounceRate',
    playsUnique = 'playsUnique',
    unmuteRate = 'unmuteRate',
    engagement = 'engagement',
    conversionCount = 'conversionCount',
    conversionRate = 'conversionRate',
    revenueAverage = 'revenueAverage',
    revenue = 'revenue',
    impressions = 'impressions',
}

export enum SmartVidMetricKeys {
    uniqueViewers = 'uniqueViewers',
    completionRate = 'completionRate',
    totalPlays = 'totalPlays',
    playRate = 'playRate',
    unmuteRate = 'unmuteRate',
    conversions = 'conversions',
    conversionRate = 'conversionRate',
    revenue = 'revenue',
}

export type ColsVisibilityState = {
    [MetricKeys.plays]: boolean
    [MetricKeys.playRate]: boolean
    [MetricKeys.bounceRate]: boolean
    [MetricKeys.playsUnique]: boolean
    [MetricKeys.unmuteRate]: boolean
    [MetricKeys.engagement]: boolean
    [MetricKeys.conversionCount]: boolean
    [MetricKeys.conversionRate]: boolean
    [MetricKeys.revenueAverage]: boolean
    [MetricKeys.revenue]: boolean
}

export const StatsTableColumns = [
    {
        field: MetricKeys.plays,
        headerName: 'Plays',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.playRate,
        headerName: 'Play Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(formatAsPercentage),
    },
    {
        field: MetricKeys.playsUnique,
        headerName: 'Unique Plays',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.unmuteRate,
        headerName: 'Unmute Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(formatAsPercentage),
    },
    {
        field: MetricKeys.engagement,
        headerName: 'Engagement',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(formatAsPercentage),
    },
    {
        field: MetricKeys.conversionCount,
        headerName: 'Conversions',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(valueOrDefault),
    },
    {
        field: MetricKeys.conversionRate,
        headerName: 'Conv. Rate',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(formatAsPercentage),
    },
    {
        field: MetricKeys.revenueAverage,
        headerName: 'Avg. Order',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(currencyOrPercentageOrDefault),
    },
    {
        field: MetricKeys.revenue,
        headerName: 'Revenue',
        minWidth: 90,
        flex: 1,
        valueFormatter: valueFormatter<number>(currencyOrPercentageOrDefault),
    },
]

export interface SetColVisibilityPayload {
    key: MetricKeys
    value: boolean
}

export interface SmartVidStatisticsRequest {
    customerGuid: string
    svGuid: string
    dateFrom: string
    dateTo: string
    timezone: string
}

export interface SmartVidStatisticsResponse {
    summary: {
        [SmartVidMetricKeys.uniqueViewers]: number
        [SmartVidMetricKeys.completionRate]: number
        [SmartVidMetricKeys.totalPlays]: number
        [SmartVidMetricKeys.playRate]: number
        [SmartVidMetricKeys.unmuteRate]: number
        [SmartVidMetricKeys.conversions]: number
        [SmartVidMetricKeys.conversionRate]: number
        [SmartVidMetricKeys.revenue]: number
    }
}

export interface SmartVidStatisticsData {
    request: SmartVidStatisticsRequest
    response: SmartVidStatisticsResponse
}

export interface UrlParamsResponse {
    response: UrlParamItem[]
}

export interface UrlParamItem {
    id: number
    video_id: number
    param_name: string
    param_count: number
    first_seen: TimezoneDate
    last_seen: TimezoneDate
}

export interface ResetStatsPayload {
    dateFrom: string
}

export type AspectRatio = {
    [key: string]: number
}

export type SidebarGroupMenuItem = {
    link: string
    title: string
    icon: (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }) | null
    displayToggle: boolean
    subTitle: string
}
