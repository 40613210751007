import { UseMutationOptions } from 'react-query'

import { VidConditionTargets, VideoGuid } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEOS_QUERY, VID_CONDITIONS_MUTATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useVidConditionsMutation = (
    guid: VideoGuid,
    options?: UseMutationOptions<string, unknown, VidConditionTargets>,
) => {
    const { mutate } = useMutationToastDecorator({ showPendingToast: false, showSuccessToast: false })

    return useInvalidateMutation<string, VidConditionTargets>(
        [VID_CONDITIONS_MUTATION, guid],
        async (payload: VidConditionTargets) => mutate(videoAPI.vidConditions(guid, payload), apiLocale.vidConditions),
        {
            invalidate: [VIDEOS_QUERY],
            ...options,
        },
    )
}
