import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { CustomerStatus } from 'types/Customer'
import { useCustomerQuery, useCustomerSubscriptionQuery } from 'api/queries'
import { getMarketingParamsAll, URLParamKey } from 'utils'
import { REFERRER } from 'constants/cookies.constants'
import { usePageViewEventTrack } from './usePageViewEventTrack'

export function useAppCuesIdentification() {
    const { data: customer } = useCustomerQuery()
    const { data: subscription } = useCustomerSubscriptionQuery()
    const [cookies] = useCookies()

    useEffect(() => {
        if (!customer || !subscription) {
            return
        }

        const params = ['utm_source', 'utm_medium'] as URLParamKey[]
        const marketingParams = getMarketingParamsAll(cookies, params)
        const referrer = cookies[REFERRER]

        window.Appcues?.identify(customer.guid, {
            guid: customer.guid,
            createdAt: customer.dateCreated,
            firstName: customer.firstname,
            email: customer.email,
            tier: subscription.plan.tier,
            isTrial: subscription.status === CustomerStatus.trial,
            loginCount: customer.loginCount,
            status: subscription.status,
            current_plan_valid_till: subscription.dateNextBilling,
            current_period_bandwidth: subscription.bandwidthUsed || subscription.bandwidthUsedInMB,
            current_plan_bandwidth_limit: subscription.bandwidthLimit || subscription.bandwidthLimitInMB,
            tracking_conversion_count: customer.conversionsCount,
            isBetaTester: customer.isBetaTester,
            referrer,
            ...marketingParams,
        })
    }, [customer, subscription])

    usePageViewEventTrack()
}
