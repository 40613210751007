import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { SET_UPSELL_SHOWN_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { UpsellShownPayload } from 'types/Customer'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useSetUpsellShownMutation = (options?: UseMutationOptions<null, any, UpsellShownPayload>) => {
    const { mutate } = useMutationToastDecorator({
        showPendingToast: false,
        showSuccessToast: false,
        showErrorToast: false,
    })

    return useInvalidateMutation<null, UpsellShownPayload>(
        SET_UPSELL_SHOWN_QUERY,
        async (payload: UpsellShownPayload) => mutate(userAPI.setUpsellShown(payload), apiLocale.user.upsellShown),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
