import { UseMutationOptions } from 'react-query'

import { integrationsAPI } from 'api/controllers'
import { ZapierFields } from 'types/Integrations'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { ZAPPIER_FIELDS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useDeleteZapierFieldsMutation = (options?: UseMutationOptions<null, any, ZapierFields>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, ZapierFields>(
        ZAPPIER_FIELDS_QUERY,
        async (fieldNames: string[]) => {
            const fieldsText = `ield${fieldNames.length > 1 ? 's' : ''}`

            return mutate(Promise.all(fieldNames.map((name) => integrationsAPI.deleteZapierField(name))), {
                pending: `Deleting f${fieldsText}`,
                error: `Failed to delete f${fieldsText}`,
                success: `F${fieldsText} deleted`,
            })
        },
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
