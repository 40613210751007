import { useEffect } from 'react'
import { get } from 'lodash'
import { useCookies } from 'react-cookie'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license-pro'
import { IntercomProvider } from 'react-use-intercom'
import { Box } from '@mui/material'

import RoutesAuth from 'App/Routes/RoutesAuth'
import Layout from 'design/templates/Layout'
import useHideIntercom from 'hooks/system/useHideIntercom'
import { ScreenLoader } from 'design/atoms/ScreenLoader'
import init3rdPartyServices from 'thirdPartyServices'
import { useCustomerQuery, queryClient } from 'api/queries'
import { parseJwt } from 'utils'

import { PATHNAMES_WITHOUT_LAYOUT, style } from 'App'
import { usePlanFromParameter } from 'hooks/system/usePlanFromParameter'
import { route } from 'constants/routes'
import { ACCESS_TOKEN, REFRESH_TOKEN, COOKIES_OPTIONS, REFERRER } from 'constants/cookies.constants'
import { VIDALYTICS_DOMAIN } from 'constants/environment.constants'

LicenseInfo.setLicenseKey(String(process.env.REACT_APP_MUI_PRO_LICENSE_KEY))

const AUTH_ROUTES = [
    route.auth.signIn,
    route.auth.microsoftAuth,
    route.auth.signUp.index,
    route.auth.forgotPassword,
    route.auth.resetPassword.index,
]

export const App = () => {
    const [cookies, setCookie, removeCookie] = useCookies()
    const [searchParams] = useSearchParams()
    const hideIntercom = useHideIntercom()
    const challengeParam = searchParams.get('login_challenge')

    const location = useLocation()
    const navigate = useNavigate()
    const hasAuthCookies = Boolean(cookies[ACCESS_TOKEN] || cookies[REFRESH_TOKEN])
    const { data: customer, isLoading, isFetching } = useCustomerQuery()

    useEffect(() => {
        const appReferrer = document.referrer
        if (!appReferrer) return

        const appReferrerUrl = new URL(appReferrer)
        const appReferrerDomain = appReferrerUrl.hostname.replace('www.', '')

        if (appReferrerDomain !== VIDALYTICS_DOMAIN) {
            setCookie(REFERRER, decodeURI(appReferrer), {
                path: route.home,
                domain: 'vidalytics.com',
                maxAge: 60 * 60 * 24 * 60,
            })
        }
    }, [])

    useEffect(() => {
        if (challengeParam) {
            queryClient.clear()
            removeCookie(REFRESH_TOKEN, COOKIES_OPTIONS)
            removeCookie(ACCESS_TOKEN, COOKIES_OPTIONS)
        }
    }, [challengeParam])

    init3rdPartyServices()
    usePlanFromParameter()

    if ((isLoading || isFetching) && customer === undefined && hasAuthCookies) {
        return <ScreenLoader />
    }

    const isAdmin = get(parseJwt(cookies[ACCESS_TOKEN]), 'payload.isAdmin') === true
    const isAuth =
        !customer?.email ||
        customer === null ||
        PATHNAMES_WITHOUT_LAYOUT.some((pathname) => location.pathname.startsWith(pathname))

    if (!isAuth && AUTH_ROUTES.find((item) => location.pathname?.includes(item))) {
        navigate(route.dashboard)
        return null
    }

    return (
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID as string} shouldInitialize={!isAdmin}>
            {hideIntercom}
            {isAuth && <RoutesAuth />}
            {!isAuth && (
                <Box sx={style.app}>
                    <Layout />
                </Box>
            )}
        </IntercomProvider>
    )
}
