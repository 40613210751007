import { endpoints } from 'api/constants'
import { GetSwitchableMetricsQuery } from 'api/contracts/dashboard/endpoints/getSwitchableMetrics.contract'
import { GetPrimaryMetricsCardQuery } from 'api/contracts/dashboard/endpoints/getPrimaryMetricsCard.contract'
import { GetCtaRowDataQuery } from 'api/contracts/dashboard/endpoints/getCtaRowData.contract'
import { GetConversionsRowDataQuery } from 'api/contracts/dashboard/endpoints/getConversionsRowData.contract'
import { GetTagsRowDataQuery } from 'api/contracts/dashboard/endpoints/getTagsRowData.contract'
import { GetDistributionMetricsQuery } from 'api/contracts/dashboard/endpoints/getDistributionMetrics.contract'
import { GetDistributionByDateQuery } from 'api/contracts/dashboard/endpoints/getDistributionByDate.contract'
import { http } from 'api/utils/http.utils'

const getStatsUrl = (path: string) => `${process.env.REACT_APP_STATS_API_URL}/${path}`

export const dashboardController = {
    getSwitchableMetricsGraphs: (params: GetSwitchableMetricsQuery) =>
        http.get(getStatsUrl(endpoints.dashboard.switchableMetricGraphs), { params }),
    getPrimaryMetricsCard: (params: GetPrimaryMetricsCardQuery) =>
        http.get(getStatsUrl(endpoints.dashboard.primaryMetricsCard), { params }),
    getCtaRowData: (params: GetCtaRowDataQuery) => http.get(getStatsUrl(endpoints.dashboard.rowData.cta), { params }),
    getConversionsRowData: (params: GetConversionsRowDataQuery) =>
        http.get(getStatsUrl(endpoints.dashboard.rowData.conversions), { params }),
    getTagsRowData: (params: GetTagsRowDataQuery) =>
        http.get(getStatsUrl(endpoints.dashboard.rowData.tags), { params }),
    getDistributionMetrics: (params: GetDistributionMetricsQuery) =>
        http.get(getStatsUrl(endpoints.dashboard.distributionMetrics), { params }),
    getDistributionByDate: (params: GetDistributionByDateQuery) =>
        http.get(getStatsUrl(endpoints.dashboard.distributionByDate), { params }),
}
