import { ReactNode } from 'react'
import { Stack, SxProps, Theme } from '@mui/material'

import { getSx } from 'styles/theme/utils'
import style from './NoContent.style'

export type NoContentProps = {
    icon?: ReactNode
    message?: string | ReactNode
    action?: ReactNode
    containerSx?: SxProps<Theme>
}

export const NoContent = ({ icon, message, action, containerSx }: NoContentProps) => {
    return (
        <Stack sx={getSx(style.container, containerSx)}>
            {icon}
            {message}
            {action}
        </Stack>
    )
}
