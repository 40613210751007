export const commonVideoTitlePhrase = 'TEST RECORD - '

export const MOCKED_VIDEOS = [
    {
        guid: 'aXoJCODoKEv2YpK_',
        title: `${commonVideoTitlePhrase}Video about bikes`,
        folderGuid: 'root',
        dateCreated: '2022-07-25T15:21:36+00:00',
        dateUpdated: '2022-07-25T15:21:36+00:00',
        datePublished: '2022-08-24T15:36:09+00:00',
        isUnpublished: false,
        length: '00:23',
        player: { id: 5, title: 'New', name: 'new' },
        plays: 255,
        embedCodeGuid: 'bVaU2XyVpXkPbu5A',
        thumbnail: {
            src: 'https://fast.dev.vidalytics.com/video/2ImOpt8V/aXoJCODoKEv2YpK_/67343/57877/thumb/thumbnail-5_0.png',
            previewSrc:
                'https://fast.dev.vidalytics.com/video/2ImOpt8V/aXoJCODoKEv2YpK_/67343/57877/thumb/thumbnail-5_0.png',
        },
        isReady: true,
        funnel: {
            id: 16,
            headline: 'pexels-ivan-khmelyuk-7222009',
        },
        search: 'Video about bikes:bVaU2XyVpXkPbu5A',
        children: [],
    },
    {
        guid: '4gFjGP2rRrG2GJmt',
        title: `${commonVideoTitlePhrase}Random title`,
        folderGuid: 'root',
        dateCreated: '2022-08-10T16:11:25+00:00',
        dateUpdated: '2022-08-10T16:11:25+00:00',
        datePublished: '2022-08-24T15:18:16+00:00',
        isUnpublished: false,
        length: '00:23',
        player: { id: 2, title: 'New', name: 'new' },
        plays: 124,
        embedCodeGuid: 'guIjM1JSvBDpMMlT',
        thumbnail: {
            src: 'https://fast.dev.vidalytics.com/video/2ImOpt8V/4gFjGP2rRrG2GJmt/67410/57939/thumb/thumbnail-5_0.png',
            previewSrc:
                'https://fast.dev.vidalytics.com/video/2ImOpt8V/4gFjGP2rRrG2GJmt/67410/57939/thumb/thumbnail-5_0.png',
        },
        isReady: true,
        funnel: {
            id: 16,

            headline: 'pexels-ivan-khmelyuk-7222009',
        },
        search: 'Random title:guIjM1JSvBDpMMlT',
        children: [],
    },
    {
        guid: 'idEhQ3UpdBRdVxee',
        title: 'Inside folder',
        folderGuid: 'R9Ga1t5X737uMCng',
        dateCreated: '2022-08-24T17:20:12+00:00',
        dateUpdated: '2022-08-24T17:20:12+00:00',
        datePublished: '2022-08-24T17:20:12+00:00',
        isUnpublished: false,
        length: '00:23',
        player: { id: 1, title: 'Legacy', name: 'legacy' },
        plays: 11,
        embedCodeGuid: 'NcV9XCGNTaTovQwE',
        thumbnail: {
            src: 'https://fast.dev.vidalytics.com/video/2ImOpt8V/idEhQ3UpdBRdVxee/67494/57987/thumb/thumbnail-5_0.jpeg',
            previewSrc:
                'https://fast.dev.vidalytics.com/video/2ImOpt8V/idEhQ3UpdBRdVxee/67494/57987/thumb/thumbnail-5_0.jpeg',
        },
        isReady: true,
        funnel: null,
        search: 'Inside folder:NcV9XCGNTaTovQwE',
        children: [],
    },
]

export const MOCKED_FOLDERS = [
    {
        guid: 'R9Ga1t5X737uMCng',
        parentFolderGuid: 'root',
        dateCreated: '2022-08-25T12:26:28+00:00',
        dateUpdated: null,
        videoCount: 1,
        title: 'New Folder',
        type: 'folder',
        search: 'New Folder',
    },
    {
        guid: 'GWApgjwajawgi',
        parentFolderGuid: 'root',
        dateCreated: '2022-01-25T12:26:28+00:00',
        dateUpdated: null,
        videoCount: 0,
        title: 'New Folder 1',
        type: 'folder',
        search: 'New Folder 1',
    },
]
