import { ComponentType, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
    useTable,
    useRowSelect,
    useSortBy,
    useFilters,
    HeaderProps,
    CellProps,
    Column,
    Row as RowType,
    OrderByFn as SortFn,
    TableState,
    UseSortByOptions,
} from 'react-table'
import { List, AutoSizer, CellMeasurerCache, CellMeasurer, ListRowRenderer } from 'react-virtualized'
import MaUTable from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import Stack from '@mui/material/Stack'
import TableRow from '@mui/material/TableRow'
import Box, { BoxProps } from '@mui/material/Box'

import { useLayout } from 'hooks/utilities/useLayout'
import useSkeletonData from './hooks/useSkeletonData'
import { Selection } from './Toolbar/Selection'
import MobileTHead from './Toolbar/MobileTHead'
import Row, { RowClickHandler, RowProps } from './Row'
import Toolbar, { ToolbarProps } from './Toolbar'
import NoData from './NoData'
import { SEARCH, SELECTION_COL } from './constants'

import './index.scss'

export type OrderByFn<T extends object = {}> = (
    rows: Array<RowType<T>>,
    sortFns: Array<SortFn<T>>,
    directions: boolean[],
) => Array<RowType<T>>

export type TableProps<T extends object = {}> = Omit<ToolbarProps, 'toggleSortBy' | 'state'> &
    Omit<RowProps, 'row' | 'toggleRowSelected' | 'selectedFlatRows' | 'state' | 'length'> &
    UseSortByOptions<T> &
    Omit<BoxProps, 'onDrop'> & {
        columns: ReadonlyArray<Column<T>>
        data?: Array<T>
        initialState?: Partial<TableState<T>>
        sortByInside?: boolean
        selection?: boolean
        renderMobileMenu?: boolean
        skeletonLength?: number
        loading?: boolean
        useVirtualization?: boolean
        noDataText?: string
        noFilteredDataText?: string
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        TableHeadRow?: ComponentType<any>
        onRowClick?: RowClickHandler
    }

function Table<T extends object = {}>({
    columns,
    data = [],
    initialState,
    button,
    sortBy,
    noDataText,
    noFilteredDataText,
    sortByInside = false,
    loading = false,
    useVirtualization = false,
    selection = true,
    renderMobileMenu = true,
    searchByIds,
    searchByLabel,
    skeletonLength = 5,
    TableHeadRow,
    onRowClick,
    onDrop,
    orderByFn,
    ...rootBoxProps
}: TableProps<T>) {
    const cache = useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100,
        }),
    )
    const { isTablet } = useLayout()
    const { pathname } = useLocation()
    const pathnameRef = useRef(pathname)
    const scrollToRef = useRef(-1)
    const listRef = useRef<List>(null)
    const changeStructure = isTablet && renderMobileMenu
    const skeletonData = useSkeletonData(skeletonLength, columns)
    const {
        state,
        headerGroups,
        rows,
        selectedFlatRows,
        prepareRow,
        toggleSortBy,
        toggleRowSelected,
        getTableProps,
        setAllFilters,
    } = useTable(
        {
            columns,
            data: loading ? skeletonData : data,
            initialState,
            orderByFn,
        },
        useFilters,
        useSortBy,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((initialColumns) =>
                selection
                    ? [
                          {
                              id: SELECTION_COL,
                              Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<T>) => (
                                  <Selection {...getToggleAllRowsSelectedProps()} />
                              ),
                              Cell: ({ row, flatRows, selectedFlatRows }: CellProps<T>) => (
                                  <Selection
                                      id={row.id}
                                      flatRows={flatRows}
                                      selectedFlatRows={selectedFlatRows}
                                      {...row.getToggleRowSelectedProps()}
                                  />
                              ),
                              width: '20px',
                          },
                          ...initialColumns,
                      ]
                    : initialColumns,
            )
        },
    )

    useLayoutEffect(() => {
        if (pathnameRef.current !== pathname) {
            scrollToRef.current = 0
            pathnameRef.current = pathname
        }

        cache.current.clearAll()
    }, [rows, pathname])

    const toolbarConfig = {
        button,
        searchByIds,
        searchByLabel,
        setAllFilters,
        sortBy,
        state,
        toggleSortBy,
    }

    const renderNoData = () => {
        if (rows.length || loading) {
            return null
        }

        const filterValue = state.filters.find((f) => f.id === SEARCH)?.value

        return <NoData content={data.length && filterValue ? noFilteredDataText : noDataText} />
    }

    const renderRow: ListRowRenderer = ({ key, index, style, parent }) => {
        const row = rows[index]

        if (!row) {
            return
        }

        prepareRow(row)
        return (
            <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                {({ registerChild }) => (
                    <Box style={style} ref={registerChild}>
                        <Row
                            row={row}
                            state={state}
                            loading={loading}
                            selectedFlatRows={selectedFlatRows}
                            length={rows.length}
                            onClick={onRowClick}
                            toggleRowSelected={toggleRowSelected}
                            onDrop={onDrop}
                        />
                    </Box>
                )}
            </CellMeasurer>
        )
    }

    const scrollTopWheneverMeasurementsAreDone = () => {
        if (scrollToRef.current === 0) {
            listRef.current?.scrollToPosition?.(scrollToRef.current)
            scrollToRef.current = -1
        }
    }

    return (
        <Box className="Table" {...rootBoxProps}>
            {changeStructure && <MobileTHead toolbarConfig={toolbarConfig} headerGroups={headerGroups} />}
            {!changeStructure && !sortByInside && <Toolbar {...toolbarConfig} />}

            {/* eslint-disable react/jsx-key */}
            <TableContainer>
                <MaUTable {...getTableProps()}>
                    {!changeStructure && (
                        <TableHead>
                            {headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers
                                        .filter((column) => !column.hiddenHeader)
                                        .map((column) => (
                                            <TableCell
                                                {...column.getHeaderProps()}
                                                {...(column.colSpans ? { colSpan: column.colSpans.header } : {})}
                                                className={column.id}
                                                style={{
                                                    width: column.width,
                                                    ...column.headerStyles,
                                                    ...column.styles,
                                                }}
                                            >
                                                {column.render('Header', {
                                                    toolbarConfig: sortByInside ? toolbarConfig : null,
                                                })}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            ))}
                            {TableHeadRow && <TableHeadRow rows={rows} />}
                        </TableHead>
                    )}

                    {!useVirtualization && (
                        <TableBody>
                            {rows.map((row) => {
                                prepareRow(row)
                                return (
                                    <Row
                                        key={`${row.getRowProps().key}-wrap`}
                                        row={row}
                                        state={state}
                                        loading={loading}
                                        selectedFlatRows={selectedFlatRows}
                                        length={rows.length}
                                        onClick={onRowClick}
                                        toggleRowSelected={toggleRowSelected}
                                        onDrop={onDrop}
                                    />
                                )
                            })}
                        </TableBody>
                    )}
                </MaUTable>
            </TableContainer>

            {Boolean(rows.length && useVirtualization) && (
                <Stack flex={1}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List
                                ref={listRef}
                                width={width}
                                height={height}
                                deferredMeasurementCache={cache.current}
                                rowHeight={cache.current.rowHeight}
                                rowCount={rows.length}
                                rowRenderer={renderRow}
                                onRowsRendered={scrollTopWheneverMeasurementsAreDone}
                            />
                        )}
                    </AutoSizer>
                </Stack>
            )}

            {renderNoData()}
        </Box>
    )
}

export default Table
