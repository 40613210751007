import { locale } from 'locales'

export default {
    edit: locale.operations.entity.edit,
    jumpIn: 'Jump In',
    removeCancel: `${locale.operations.entity.remove} / ${locale.operations.generic.cancel}`,
    invitation: {
        withdraw: 'Withdraw invitation',
        resend: 'Resend invitation',
    },
    cancellation: {
        undo: 'Undo cancellation',
        resend: 'Resend',
    },
}
