import { useState } from 'react'
import { capitalize } from 'lodash'
import { Box, Typography, IconButton, Button } from '@mui/material'

import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import { DISPLAY_MODE, HTML_SELECTOR_TYPE } from 'design/pages/VidSettings/constants'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { Modal } from 'design/templates/Modal'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { CallToActionType, VideoCallToActionDisplayMode } from 'types/VideoCallToAction'
import { CallToActionConfig } from 'types/Video'
import { useDeleteCTAMutation } from 'api/mutations'

import './index.scss'

interface CallToActionCardProps {
    cta: CallToActionConfig
    disabled?: boolean
    onEditClick: (cta: CallToActionConfig) => void
}

const displayModeLabels = {
    [VideoCallToActionDisplayMode.customHTML]: 'Custom HTML',
    [VideoCallToActionDisplayMode.expandContainer]: 'Expand Container',
    [VideoCallToActionDisplayMode.onTop]: 'On Top',
    [VideoCallToActionDisplayMode.reserveSpace]: 'Reserve Space',
}

const CallToActionCard = ({ cta, disabled, onEditClick }: CallToActionCardProps) => {
    const [deleteCTAOpen, setDeleteCTAOpen] = useState(false)
    const deleteCTAMutation = useDeleteCTAMutation()
    const { timedExitCTA } = useFeatureFlags()

    return (
        <Box className="CallToActionCard Card">
            <Box className="header">
                <Typography className="title">{cta.title.replace(/<br>/g, '\n')}</Typography>
                <Box className="btns">
                    <IconButton className="edit" onClick={() => onEditClick(cta)}>
                        <EditRoundedIcon />
                    </IconButton>
                    {!disabled && (
                        <IconButton className="delete" onClick={() => setDeleteCTAOpen(true)}>
                            <DeleteOutlineRoundedIcon />
                        </IconButton>
                    )}
                </Box>
            </Box>

            {[
                { label: 'Place', value: displayModeLabels[cta.displayMode] },
                cta[DISPLAY_MODE] === VideoCallToActionDisplayMode.customHTML
                    ? { label: 'Element type', value: capitalize(cta[HTML_SELECTOR_TYPE]) }
                    : { label: 'Link', value: cta.link },
                {
                    label: cta.type === CallToActionType.time ? 'Time' : 'Exit CTA',
                    value: cta.type === CallToActionType.time || timedExitCTA ? `${cta.timeFrom} - ${cta.timeTo}` : '',
                },
            ].map((row) => (
                <Box className="row" key={row.label}>
                    <Typography>{row.label}</Typography>
                    <Typography className="rowValue">{row.value}</Typography>
                </Box>
            ))}

            <Modal open={deleteCTAOpen} onClose={() => setDeleteCTAOpen(false)}>
                <Modal.Header
                    title="Are you sure you want to delete this Call To Action?"
                    onClose={() => setDeleteCTAOpen(false)}
                />
                <Modal.Body>
                    <Typography>This action cannot be undone.</Typography>
                </Modal.Body>
                <Modal.Actions>
                    <Button variant="text" onClick={() => setDeleteCTAOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setDeleteCTAOpen(false)
                            deleteCTAMutation.mutate(cta)
                        }}
                    >
                        Confirm
                    </Button>
                </Modal.Actions>
            </Modal>
        </Box>
    )
}

export default withErrorBoundary(CallToActionCard)
