import { FC } from 'react'
import { Box, TextField, Skeleton } from '@mui/material'

import style from './EmbedCode.style'

type EmbedCodeProps =
    | {
          isLoading: true
          embedCode?: string
      }
    | {
          isLoading: false
          embedCode: string
      }

export const EmbedCode: FC<EmbedCodeProps> = ({ isLoading, embedCode }) => {
    if (isLoading) {
        return <Skeleton variant="rectangular" height={225} />
    }

    return (
        <Box sx={style.resizable}>
            <TextField
                sx={style.snippet}
                InputProps={{
                    sx: style.input,
                }}
                value={embedCode?.trim()}
                multiline
                fullWidth
            />
        </Box>
    )
}
