import { UseMutationOptions } from 'react-query'
import { StepSettings } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import {
    SMART_VIDS_QUERY,
    UPDATE_BRANCH_SETTINGS_QUERY,
    STEP_SETTINGS_QUERY,
    VIDEO_BRANCH_QUERY,
    SMART_VIDS_STEPS_QUERY,
    VIDEOS_QUERY,
} from 'api/constants'
import { queryClient } from 'api/queries'
import { Id } from 'types'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useUpdateStepSettingsMutation = (
    id: Id,
    options?: UseMutationOptions<StepSettings, unknown, StepSettings>,
) => {
    const key = UPDATE_BRANCH_SETTINGS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<StepSettings, StepSettings>(
        key,
        async (payload: StepSettings) =>
            mutate(smartVidConroller.updateStepSettings(id, payload), apiLocale.funnel.updateStepSettings),
        {
            ...options,
            invalidate: [VIDEO_BRANCH_QUERY, SMART_VIDS_QUERY, SMART_VIDS_STEPS_QUERY, VIDEOS_QUERY],
            onSuccess: (response, variables, context) => {
                if (options?.onSuccess) {
                    options.onSuccess(response, variables, context)
                }

                queryClient.setQueryData([STEP_SETTINGS_QUERY, id], response)
            },
        },
    )
}
