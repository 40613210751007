import { SxProps, Theme } from '@mui/material'
import { getPropByTheme } from '../../../../../styles/theme/utils'

const container: SxProps<Theme> = (theme) => ({
    position: 'absolute',
    backgroundColor: getPropByTheme('#263238', '#FFF', theme.palette.mode),
    zIndex: 1,
    bottom: 8,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    py: 3,
    px: 4,
    minWidth: 400,
    maxWidth: 'calc(100% - 32px)',
    flexWrap: 'nowrap',
    rowGap: 3,
    columnGap: 4,

    [theme.breakpoints.down('laptop')]: {
        py: 2,
        px: 2,
        zIndex: 2147483001, // over intercom button
    },
})

const clearButton: SxProps<Theme> = {
    color: (theme) => getPropByTheme('#FFF', '#263238', theme.palette.mode),
}

const selectedText: SxProps<Theme> = {
    mr: 2,
    color: (theme) => getPropByTheme('#FFF', '#263238', theme.palette.mode),
}

const buttonContainer: SxProps<Theme> = {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    justifyContent: 'center',
    flexWrap: 'wrap',
}

const textContainer: SxProps<Theme> = {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
}

export default {
    container,
    clearButton,
    selectedText,
    textContainer,
    buttonContainer,
}
