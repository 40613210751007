import { ReactNode, useCallback, useMemo } from 'react'
import { Divider, ListItemText, MenuItem } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import EditIcon from '@mui/icons-material/Edit'
import UndoIcon from '@mui/icons-material/Undo'
import { useAppDispatch } from 'App'
import {
    openEditClientModal,
    openResendInvitationModal,
    openRemoveClientModal,
    openResendCancellationEmailModal,
    openRevokeClientCancellationModal,
    openWithdrawClientInvitationModal,
} from 'design/templates/Modal'
import { AgencyClient, AgencyClientId } from 'api/contracts/agency/entities/agencyClient'
import { useClientAccount } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import locale from './ActionsMenu.locale'
import { testId } from './ActionsMenu.constants'

interface MenuItemParams {
    label: string
    action: () => void
    icon: ReactNode
    accent?: boolean
    divider?: boolean
}

interface ActionsMenuProps {
    /** The unique client identifier. */
    id: AgencyClientId

    clientInfo: Pick<AgencyClient, 'id' | 'name' | 'picture' | 'status' | 'onCancellation'>

    onClose: () => void
}

/**
 * A menu list of operations depending on the status of the client.
 */
export const ActionsMenu = ({ id, clientInfo, onClose }: ActionsMenuProps) => {
    const dispatch = useAppDispatch()
    const { jumpIn } = useClientAccount()

    const activeMenuItems = useMemo<MenuItemParams[]>(
        () => [
            {
                label: locale.edit,
                action: () => dispatch(openEditClientModal(clientInfo)),
                icon: <EditIcon fontSize="small" />,
            },
            {
                label: locale.jumpIn,
                action: () => jumpIn(id),
                icon: <ExitToAppIcon fontSize="small" />,
            },
            {
                label: locale.removeCancel,
                action: () => dispatch(openRemoveClientModal({ id })),
                icon: <MailOutlineIcon fontSize="small" color="error" />,
                accent: true,
            },
        ],
        [id],
    )

    const pendingActivationMenuItems = useMemo<MenuItemParams[]>(
        () => [
            {
                action: () => dispatch(openResendInvitationModal({ id })),
                icon: <MailOutlineIcon fontSize="small" />,
                label: locale.invitation.resend,
            },
            {
                action: () => dispatch(openWithdrawClientInvitationModal({ id })),
                icon: <DeleteOutlineIcon fontSize="small" color="error" />,
                label: locale.invitation.withdraw,
                accent: true,
            },
        ],
        [id, dispatch],
    )

    const pendingCancellationMenuItems = useMemo<MenuItemParams[]>(
        () => [
            {
                action: () => dispatch(openEditClientModal(clientInfo)),
                icon: <EditIcon fontSize="small" />,
                label: locale.edit,
            },
            {
                action: () => jumpIn(id),
                icon: <ExitToAppIcon fontSize="small" />,
                label: locale.jumpIn,
            },
            {
                action: () => dispatch(openRevokeClientCancellationModal({ id })),
                icon: <UndoIcon fontSize="small" />,
                label: locale.cancellation.undo,
                divider: true,
            },
            {
                action: () => dispatch(openResendCancellationEmailModal({ id })),
                icon: <MailOutlineIcon fontSize="small" />,
                label: locale.cancellation.resend,
            },
        ],
        [id, dispatch],
    )

    const renderMenuItems = useCallback(
        (items: MenuItemParams[]) => (
            <>
                {items.reduce<ReactNode[]>((acc, { icon, divider, action, label, accent }) => {
                    const item = (
                        <MenuItem
                            onClick={() => {
                                action()
                                onClose()
                            }}
                            data-testid={testId.action(label)}
                            key={testId.action(label)}
                        >
                            <ListItemIcon>{icon}</ListItemIcon>
                            <ListItemText primaryTypographyProps={{ color: accent ? 'error' : '' }}>
                                {label}
                            </ListItemText>
                        </MenuItem>
                    )

                    return divider ? [...acc, item, <Divider key={testId + 'divider'} />] : [...acc, item]
                }, [])}
            </>
        ),
        [],
    )

    if (clientInfo.status === 'active' && clientInfo.onCancellation)
        return renderMenuItems(pendingCancellationMenuItems)
    else if (clientInfo.status === 'active') return renderMenuItems(activeMenuItems)
    else if (clientInfo.status === 'pending') return renderMenuItems(pendingActivationMenuItems)

    return null
}
