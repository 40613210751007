import { useCookies } from 'react-cookie'
import mixpanel from 'mixpanel-browser'
import { queryClient, useCustomerSubscriptionQuery } from 'api/queries'

import { selectUploaderState, setFiles } from 'design/pages/VideoUpload/uploader.slice'
import { getUploadingLockStatus, UPLOADING_BEFORE_UNLOAD_MESSAGE } from 'hooks/system/useUploadingWarning'
import { PROM_REF, UPSELL_TOKEN_COOKIE } from 'design/pages/SignUp/constants'
import { useAppSelector, useAppDispatch } from 'App'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'
import { CUSTOMER_QUERY } from 'api/constants'
import { clearAgencyAccountStatus } from 'design/pages/AccountSettings/account.slice'
import { useClientAccount } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { CustomerStatus } from 'types/Customer'
import { ACCESS_TOKEN, REFRESH_TOKEN, COOKIES_OPTIONS } from 'constants/cookies.constants'

function useLogout() {
    const [, , removeCookie] = useCookies()
    const dispatch = useAppDispatch()
    const { jumpOut } = useClientAccount()
    const { files } = useAppSelector(selectUploaderState)
    const { data: subscription } = useCustomerSubscriptionQuery()

    return () => {
        if (getUploadingLockStatus(files)) {
            if (!window.confirm(UPLOADING_BEFORE_UNLOAD_MESSAGE)) {
                return
            }

            files.forEach((f) => f.state?.cancelTokenSource?.cancel())
            dispatch(setFiles([]))
        }

        removeCookie(ACCESS_TOKEN, COOKIES_OPTIONS)
        removeCookie(REFRESH_TOKEN, COOKIES_OPTIONS)
        removeCookie(UPSELL_TOKEN_COOKIE, COOKIES_OPTIONS)
        removeCookie(PROM_REF, COOKIES_OPTIONS)
        localStorage.removeItem('lastOpenedStat')
        localStorage.removeItem('thumbnailSource')

        if (subscription?.plan) {
            mixpanel.track(MIXPANEL_EVENTS.SIGN_OUT, {
                subscription_tier: subscription.plan.tier,
                subscription_id: subscription.plan.apiHandle,
                subscription_istrial: subscription.status === CustomerStatus.trial,
            })

            mixpanel.reset()
        }

        jumpOut()
        dispatch(clearAgencyAccountStatus())

        queryClient.setQueryData(CUSTOMER_QUERY, null)
    }
}

export default useLogout
