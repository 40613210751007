import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export const useTagManagerInit = () => {
    useEffect(() => {
        window.dataLayer = window.dataLayer || []

        TagManager.initialize({
            gtmId: process.env.REACT_APP_GTM_ID as string,
        })
    }, [])
}
