export default {
    controls: {
        section:
            'Change the colors of your player to match your brand. You can choose the color for the controls and the background shadow that appears behind them.',
        background: 'Background',
        foreground: 'Foreground',
        useColors: {
            label: 'Use these colors on all settings',
            hint: 'Turning this option on will use the colors above for ALL the settings of your video, like the Resume Play and Redirect Message.',
        },
        dropShadow: {
            label: 'Drop Shadow',
            hint: 'We add a slight drop shadow behind our player messages and controls. Disable this if you want to remove it.',
        },
    },
}
