import { UseMutationOptions } from 'react-query'

import { Conversion, ConversionPayload } from 'types/Conversion'
import { conversionAPI } from 'api/controllers'
import { CONVERSIONS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useCreateConversionMutation = (options?: UseMutationOptions<Conversion, unknown, ConversionPayload>) => {
    const key = CONVERSIONS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Conversion, ConversionPayload>(
        key,
        async (payload: ConversionPayload) =>
            mutate(conversionAPI.createConversion(payload), apiLocale.conversion.create),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
