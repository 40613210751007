import { useEffect } from 'react'
import * as Sentry from '@sentry/react'

import { useCustomerQuery } from 'api/queries'

export default function useSetSentryUser() {
    const customer = useCustomerQuery()

    useEffect(() => {
        if (!customer.isSuccess || !customer.data || process.env.REACT_APP_IS_LOCAL) {
            return
        }

        Sentry.setUser({ id: customer.data.guid })
    }, [customer])
}
