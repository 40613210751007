import { once } from 'lodash'
import { useParams } from 'react-router-dom'
import { useState, createContext, ReactNode, Dispatch, SetStateAction, useContext, useRef, useEffect } from 'react'

import { ThumbnailSource } from 'types/VidSettings'
import { useUpdateVideoMutation } from 'api/mutations'
import { ThumbnailType } from 'types/Video'
import { locale } from 'locales'
import { SettingsTab } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarTabs'

interface ContextState {
    thumbnailFromVideoType?: ThumbnailType
    activeTab: SettingsTab
    integrationsExist: boolean
    notificationMessage: string
    updateVideoMutation: ReturnType<typeof useUpdateVideoMutation>
    setNotificationMessage: Dispatch<SetStateAction<string>>
    setThumbnailFromVideoType: Dispatch<SetStateAction<ThumbnailType | undefined>>
    setActiveTab: Dispatch<SetStateAction<SettingsTab>>
    setIntegrationsExist: Dispatch<SetStateAction<boolean>>
    thumbnailSource: ThumbnailSource
    setThumbnailSource: Dispatch<SetStateAction<ThumbnailSource>>
    sameForMobileThumb: boolean
    setSameForMobileThumb: Dispatch<SetStateAction<boolean>>
}

const createVidSettingsContext = once(() => createContext({} as ContextState))
const useVidSettingsContext = () => useContext(createVidSettingsContext())

const HIDE_NOTIFICATION_TM = 3000

type VidSettingsProviderProps = {
    children?: ReactNode
}

function VidSettingsProvider({ children }: VidSettingsProviderProps) {
    const { videoGuid, stepVidId } = useParams()
    const [thumbnailFromVideoType, setThumbnailFromVideoType] = useState<ThumbnailType | undefined>(undefined)
    const [sameForMobileThumb, setSameForMobileThumb] = useState<boolean>(false)
    const [thumbnailSource, setThumbnailSource] = useState<ThumbnailSource>(ThumbnailSource.image)
    const [integrationsExist, setIntegrationsExist] = useState(true)
    const [activeTab, setActiveTab] = useState<SettingsTab>(SettingsTab.style)

    const [notificationMessage, setNotificationMessage] = useState('')
    const notificationTimerRef = useRef<ReturnType<typeof setTimeout>>()
    const updateVideoMutation = useUpdateVideoMutation(String(videoGuid || stepVidId), {
        onSuccess: () => {
            setNotificationMessage(locale.messages.info.saved)
            notificationTimerRef.current = setTimeout(() => setNotificationMessage(''), HIDE_NOTIFICATION_TM)
        },
    })

    useEffect(() => {
        return () => {
            if (notificationTimerRef.current) {
                clearTimeout(notificationTimerRef.current)
            }
        }
    }, [])

    const vidSettingsProps = {
        thumbnailFromVideoType,
        activeTab,
        integrationsExist,
        notificationMessage,
        updateVideoMutation,
        setNotificationMessage,
        setThumbnailFromVideoType,
        setActiveTab,
        setIntegrationsExist,
        thumbnailSource,
        setThumbnailSource,
        sameForMobileThumb,
        setSameForMobileThumb,
    }

    const VidSettingsContext = createVidSettingsContext()

    return <VidSettingsContext.Provider value={vidSettingsProps}>{children}</VidSettingsContext.Provider>
}

export { VidSettingsProvider, useVidSettingsContext }
