import { alpha, SxProps, Theme } from '@mui/material'
import { getPropByTheme } from 'styles/theme/utils'

const fallback: SxProps<Theme> = (theme: Theme) => ({
    borderRadius: 3,
    width: 1,
    px: 3,
    py: '7px',
    overflow: 'hidden',
    border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,

    [theme.breakpoints.up('laptop')]: {
        width: 320,
    },
})

const error: SxProps<Theme> = {
    border: (theme) => `1px solid ${theme.palette.error.main}`,
}

const rounded: SxProps<Theme> = {
    borderRadius: 3,
}

const divider: SxProps<Theme> = {
    width: 'calc(100% - 24px)',
    height: '1px',
    position: 'absolute',
    bottom: -2,
    left: 12,
}

const input: SxProps<Theme> = {
    borderRadius: 3,
    height: 42,
    background: (theme) => getPropByTheme('#fff', alpha('#fff', 0.09), theme.palette.mode),
    color: (theme) => theme.palette.text.primary,
    '& .MuiOutlinedInput-root': {
        borderRadius: 3,
        height: 42,
        py: 0,
    },
}

const disabled: SxProps<Theme> = (theme: Theme) => ({
    opacity: 0.6,
    backgroundColor: theme.palette.action.disabledBackground,
})

export default {
    rounded,
    fallback,
    divider,
    input,
    disabled,
    error,
}
