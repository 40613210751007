import { CustomerStatus } from 'types/Customer'
import { useCustomerQuery } from 'api/queries'

const useUserStatuses = () => {
    const { data: customer } = useCustomerQuery()

    return {
        isUserInactive: customer?.status === CustomerStatus.inactive,
        isUserActive: customer?.status === CustomerStatus.active,
        isUserOnTrial: customer?.status === CustomerStatus.trial,
    }
}

export default useUserStatuses
