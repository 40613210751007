import { UseMutationOptions, useQueryClient } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { GLOBAL_SETTINGS_MUTATION, GLOBAL_SETTINGS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { GlobalSettings } from 'types/Customer'

export const useUpdateGlobalSettingsMutation = (
    options?: UseMutationOptions<GlobalSettings, Error, GlobalSettings>,
) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<GlobalSettings, GlobalSettings>(
        GLOBAL_SETTINGS_MUTATION,
        async (payload: GlobalSettings) =>
            mutate(userAPI.updateGlobalSettings(payload), apiLocale.user.globalSettings.update),
        {
            ...options,
            onMutate: async (variables) => {
                await queryClient.cancelQueries(GLOBAL_SETTINGS_QUERY)

                queryClient.setQueryData<GlobalSettings>(GLOBAL_SETTINGS_QUERY, (previousGlobalSettings) => ({
                    ...previousGlobalSettings,
                    ...variables,
                }))

                return options?.onMutate?.(variables)
            },
            invalidate: [GLOBAL_SETTINGS_QUERY],
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
