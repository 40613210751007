import { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'
import { useFormikContext } from 'formik'
import { SignUpFormData } from 'types/Auth'

const useSuccessHandleForExtraModule = (onSuccess: () => void) => {
    const { values } = useFormikContext<SignUpFormData>()
    const formValuesRef = useRef<SignUpFormData | null>(null)

    // make sure form updated
    // then submit form
    useEffect(() => {
        if (isEqual(values, formValuesRef.current)) {
            formValuesRef.current = null
            onSuccess()
        }
    }, [values])

    return formValuesRef
}

export default useSuccessHandleForExtraModule
