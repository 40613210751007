import {
    Box,
    Button,
    CardMedia,
    Chip,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import UploadIcon from '@mui/icons-material/Upload'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { calculateThumbnailSource } from 'utils'
import Skeleton from '@mui/material/Skeleton'

import { ThumbnailSource } from 'types/VidSettings'
import { ThumbnailSourceType, ThumbnailType, VideoThumbGeneration } from 'types/Video'
import { useAppDispatch } from 'App'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { openAgencyLockedFeaturesModal } from 'design/templates/Modal'
import { ChangeEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { ProItem } from '../ProItem/ProItem'
import style from './ImageUploader.style'

interface ImageUploadProps {
    id: ThumbnailType
    title?: string
    image?: string
    tooltip?: string
    imageValidateSize: {
        min: number
        max: number
    }
    disableSelection?: boolean
    disabled?: boolean
    onUpload: (payload: { data: File; id: string }) => void
    onDelete: (id: string) => void
    setThumbnailSource?: (source: ThumbnailSource) => void
    setThumbnailFromVideoType?: (type: ThumbnailType | undefined) => void
    isDeletable?: boolean
    disableSource?: boolean
    thumbnailSourceType?: ThumbnailSourceType
    videoThumbnailGeneration?: VideoThumbGeneration
    handleSwitchThumbnailType?: (thumbnailType: ThumbnailType, value: ThumbnailSource) => void
}

export const ImageUpload = ({
    id,
    title,
    image,
    tooltip,
    imageValidateSize = { min: 1000, max: 5e6 },
    disableSelection,
    disabled,
    onUpload,
    onDelete,
    setThumbnailSource,
    isDeletable,
    disableSource,
    setThumbnailFromVideoType,
    thumbnailSourceType,
    videoThumbnailGeneration,
    handleSwitchThumbnailType,
}: ImageUploadProps) => {
    const [error, setError] = useState('')
    const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>()
    const [localThumbnailSource, setLocalThumbnailSource] = useState<ThumbnailSource>(ThumbnailSource.image)
    useEffect(() => {
        if (videoThumbnailGeneration === VideoThumbGeneration.pending) {
            setLocalThumbnailSource(ThumbnailSource.video)
        } else if (thumbnailSourceType) {
            setLocalThumbnailSource(calculateThumbnailSource(thumbnailSourceType, id))
        }
    }, [id, thumbnailSourceType])

    const hiddenFileInput = useRef<HTMLInputElement>(null)
    const { videoThumbnails } = useFeatureFlags()
    const { isFree } = useAccountSubscription()
    const dispatch = useAppDispatch()
    const { agencyAccessLocked } = useAgencyAccess()

    const handleUploadClick = () => {
        hiddenFileInput.current?.click()
    }
    const getErrorMessage = (fileType: string, fileSize: number) => {
        const isValidType = /image*/.test(fileType)
        let data = !isValidType ? 'Please select valid image file. ' : ''
        if (fileSize <= imageValidateSize.min) {
            data += `Your file is too small. Min size is ${imageValidateSize.min / 1000} MB`
        } else if (fileSize >= imageValidateSize.max) {
            data += `Your file is too big. Limit is ${imageValidateSize.max / 1000000} MB`
        }
        return data ? data : null
    }
    const handleThumbnailSource = (source: ThumbnailSource) => {
        setLocalThumbnailSource(source)
        setThumbnailFromVideoType?.(undefined)
        setThumbnailSource?.(source)
        handleSwitchThumbnailType?.(id, source)
    }
    const handleSelectFromVideo = (id: string) => {
        setThumbnailSource?.(localThumbnailSource)
        setThumbnailFromVideoType?.(id as ThumbnailType)
    }
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target.files?.[0]

        if (!file) {
            return
        }

        const errorMessage = getErrorMessage(file.type, file.size)

        if (errorMessage) {
            setError(errorMessage)
            return
        }

        const reader = new FileReader()
        reader.onload = () => {
            setImagePreview(reader.result)

            onUpload({
                data: file,
                id,
            })

            if (error) {
                setError('')
            }
        }

        if (file) {
            reader.readAsDataURL(file)
        }
    }

    const imageCanBeDisplayed = Boolean(imagePreview || image)

    const isUploadDisabled = useMemo(() => localThumbnailSource === ThumbnailSource.video, [localThumbnailSource])
    const isThumbnailSwitchAvailable = useMemo(
        () => videoThumbnails && !disableSource && id !== ThumbnailType.customPauseScreen,
        [videoThumbnails, disableSource, id],
    )
    const isVideoSource = useMemo(() => {
        return image?.split('.').pop() === 'mp4'
    }, [image])
    return (
        <Box>
            {title && (
                <Stack direction="row" gap={1} alignItems="center" mb={4}>
                    <Typography variant="body2" fontWeight="bold">
                        {title}
                    </Typography>

                    <HelpTooltip arrow id={`${title}Help`} placement="top" title={tooltip} />
                </Stack>
            )}

            <Stack gap={3} sx={style.thumbnail.uploader}>
                <Box sx={[style.thumbnail.container, { display: imageCanBeDisplayed ? 'block' : 'none' }]}>
                    {!disabled && imageCanBeDisplayed && !isVideoSource && (
                        <Box
                            component="img"
                            sx={style.thumbnail.image}
                            src={String(image || imagePreview)}
                            alt={title}
                        />
                    )}
                    {!disabled && imageCanBeDisplayed && isVideoSource && (
                        <CardMedia component="video" sx={style.thumbnail.image} image={`${image}?autoplay=1`} />
                    )}

                    {disabled && (
                        <Box>
                            <Typography sx={style.thumbnail.skeletonText}>Processing</Typography>
                            <Skeleton sx={style.thumbnail.skeleton} variant="rectangular"></Skeleton>
                        </Box>
                    )}
                    {(isDeletable === undefined || isDeletable) && (
                        <IconButton
                            color="error"
                            sx={style.thumbnail.delete}
                            disabled={disabled}
                            onClick={() => {
                                onDelete(id)
                                setImagePreview(null)
                                setError('')
                            }}
                        >
                            {!disabled && <DeleteRoundedIcon />}
                        </IconButton>
                    )}
                </Box>
                {isThumbnailSwitchAvailable && (
                    <Stack gap={3} direction="row" justifyContent="center" alignItems="center">
                        <RadioGroup
                            row
                            value={localThumbnailSource}
                            onChange={(event) => handleThumbnailSource(event.target.value as ThumbnailSource)}
                        >
                            <FormControlLabel
                                value={ThumbnailSource.image}
                                control={<Radio />}
                                disabled={disabled}
                                label="Image"
                            />
                            <Tooltip
                                placement="top"
                                title={isFree ? 'Pro feature. Upgrade and start using now.' : null}
                            >
                                <FormControlLabel
                                    disabled={isFree || agencyAccessLocked || disabled}
                                    value={ThumbnailSource.video}
                                    control={<Radio />}
                                    label="Video"
                                />
                            </Tooltip>
                            <ProItem isFreePlan={isFree} />
                        </RadioGroup>
                        {agencyAccessLocked && (
                            <Chip
                                onClick={() => dispatch(openAgencyLockedFeaturesModal({ fallback: false }))}
                                size="small"
                                color="accent"
                                icon={<LockRoundedIcon />}
                                label="Locked"
                                disabled={disabled}
                            />
                        )}
                    </Stack>
                )}
                <Stack gap={3} direction="row">
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleUploadClick}
                        startIcon={<UploadIcon />}
                        disabled={isUploadDisabled || disabled}
                    >
                        Upload
                    </Button>
                    <input type="file" onChange={handleChange} ref={hiddenFileInput} style={{ display: 'none' }} />
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={disableSelection || disabled}
                        onClick={() => handleSelectFromVideo(id)}
                        startIcon={<SelectAllIcon />}
                    >
                        Select from video
                    </Button>
                </Stack>
                {error}
            </Stack>
        </Box>
    )
}
