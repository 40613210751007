import { Field, FieldProps } from 'formik'
import { ReactNode } from 'react'
import { RadioGroupProps, RadioGroup, FormControlLabel, Radio as MuiRadio, RadioProps, Typography } from '@mui/material'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { CustomTypographyVariant } from 'styles/theme/typography'

type Option = {
    value: string
    label: ReactNode
    tooltip?: ReactNode
    disabled?: boolean
    propsRadio?: RadioProps
}

type RadioFormProps = RadioGroupProps & {
    name: string
    options: Option[]
    disabled?: boolean
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    value?: any
    controlled?: boolean
    afterChange?: (name: string, value: string) => void
    labelVariant?: CustomTypographyVariant
}

export const Radio = ({
    name,
    value,
    controlled,
    onChange,
    afterChange,
    disabled = false,
    labelVariant,
    options,
    ...rest
}: RadioFormProps) => {
    return (
        <Field name={name}>
            {({ field, form: { setFieldValue } }: FieldProps) => (
                <RadioGroup
                    {...rest}
                    {...field}
                    value={controlled ? value : field.value}
                    sx={[disabled && { pointerEvents: 'none' }]}
                    onChange={
                        onChange ||
                        function (_, value) {
                            setFieldValue(name, value)
                            afterChange?.(name, value)
                        }
                    }
                >
                    {options?.map((option) => (
                        <FormControlLabel
                            key={`${option.label}-${option.value}`}
                            value={option.value}
                            control={<MuiRadio sx={{ padding: '6px' }} {...(option.propsRadio || {})} />}
                            label={
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                                    variant={labelVariant}
                                    {...(option.disabled ? { color: 'text.disabled' } : {})}
                                >
                                    {option.label}
                                    {option.tooltip && <HelpTooltip title={option.tooltip} />}
                                </Typography>
                            }
                            disabled={option.disabled || disabled}
                            sx={{ px: '6px' }}
                        />
                    ))}
                </RadioGroup>
            )}
        </Field>
    )
}
