import { SxProps, Theme } from '@mui/material'

const title: SxProps = {
    pt: 5,
}

const container =
    (redesigned: boolean): SxProps<Theme> =>
    () => ({
        minHeight: '100vh',

        ...(redesigned && {
            backgroundColor: (theme) => theme.palette.background.surface,
        }),
    })

const offset =
    (redesigned: boolean): SxProps<Theme> =>
    () => ({
        px: 6,

        ...(redesigned && {
            px: 8,
        }),
    })

const main: SxProps = {
    flexGrow: 1,
}

export default {
    title,
    container,
    offset,
    main,
}
