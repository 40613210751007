import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { type ButtonProps } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'

import ClosedCaptionsRenameModal from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsRenameModal'
import ClosedCaptionsDeleteModal from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsDeleteModal'
import useClosedCaptionsContent from 'design/organisms/ClosedCaptionsEditor/useClosedCaptionsContent'
import { useVideoQuery } from 'api/queries'

import locale from './ClosedCaptionsMenu.locale'

const DEFAULT_FILE_NAME = 'closedCaptions'
const EXT = 'vtt'

type ClosedCaptionsMenuProps = {
    propsButton?: ButtonProps
    noRename?: boolean
}

export const ClosedCaptionsMenu = ({ propsButton, noRename }: ClosedCaptionsMenuProps) => {
    const { videoGuid } = useParams()
    const { data } = useVideoQuery(videoGuid)
    const { isLoading, name, content } = useClosedCaptionsContent(String(videoGuid))
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false)

    const source = data?.settings?.ui_closedCaptions_source

    const open = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget)
    }, [])

    const close = useCallback(() => {
        setMenuAnchorEl(null)
    }, [])

    const handleDownload = useCallback(() => {
        close()

        if (source && content) {
            const blob = new Blob([content], { type: 'text/plain' })
            const a = document.createElement('a')
            a.href = window.URL.createObjectURL(blob)
            a.download = name || source?.split('/')?.pop() || `${DEFAULT_FILE_NAME}.${EXT}`
            a.target = '_blank'
            a.click()
            window.URL.revokeObjectURL(a.href)
        }
    }, [isLoading, name, source])

    const handleRename = useCallback(() => {
        close()
        setIsRenameModalOpen(true)
    }, [])

    const handleOpenModal = useCallback(() => {
        close()
        setIsDeleteModalOpen(true)
    }, [])

    return (
        <>
            <Button
                variant="text"
                color="tertiary"
                size="iconLarge"
                startIcon={<MoreVertRoundedIcon />}
                disabled={!content}
                onClick={open}
                {...propsButton}
            />

            <Menu
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={close}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {!noRename && (
                    <MenuItem onClick={handleRename}>
                        <Stack gap={3} direction="row" component="span" alignItems="center">
                            <EditIcon />
                            <Typography variant="body1">{locale.rename}</Typography>
                        </Stack>
                    </MenuItem>
                )}

                <MenuItem onClick={handleDownload}>
                    <Stack gap={3} direction="row" component="span" alignItems="center">
                        <DownloadRoundedIcon />
                        <Typography variant="body1">{locale.download}</Typography>
                    </Stack>
                </MenuItem>

                <Divider />

                <MenuItem onClick={handleOpenModal}>
                    <Stack gap={3} direction="row" component="span" alignItems="center">
                        <DeleteOutlineRoundedIcon color="error" />
                        <Typography color="error" variant="body1">
                            {locale.delete}
                        </Typography>
                    </Stack>
                </MenuItem>
            </Menu>

            <ClosedCaptionsDeleteModal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} />
            <ClosedCaptionsRenameModal isOpen={isRenameModalOpen} setIsOpen={setIsRenameModalOpen} name={name || ''} />
        </>
    )
}

export default ClosedCaptionsMenu
