import { useRef } from 'react'
import { isNumber } from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from '@mui/material'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import {
    PLAYBACK_SMART_PAUSE_RESUME,
    PLAYBACK_ON_END,
    PLAYBACK_SMART_PAUSE,
    PLAYER_STARTUP_QUALITY,
    PLAYER_LOWER_BANDWIDTH_USAGE,
    UI_OVERLAY_REDIRECT_COLOR_BACKGROUND,
    UI_OVERLAY_REDIRECT_COLOR_FOREGROUND,
    UI_OVERLAY_REDIRECT_SHOW,
    UI_OVERLAY_REDIRECT_TEXT,
    UI_OVERLAY_REDIRECT_TIMEOUT,
    UI_OVERLAY_REDIRECT_URL,
} from 'design/pages/VidSettings/constants'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { Radio, Input, ColorPicker } from 'design/atoms/Form'
import { PlayOptionsFormData } from 'types/VidSettings'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { PlayerOnPlaybackEndType } from 'types/Player'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { EXAMPLE_URL_PLACEHOLDER } from 'design/organisms/VidSettingsSidebar/settings.contants'
import { videoStartupQualityOptions } from './PlayOptionsContent.utils'
import locale from './PlayOptionsContent.locale'
import { ResumePlayContent } from './ResumePlayContent'

import './index.scss'

export const PlayOptionsContent = () => {
    const { lowerBandwidthUsage, showAutoplayOnReturn } = useFeatureFlags()

    const {
        isLoading,
        formik: { values, setFieldValue, setValues, setFieldTouched },
        formik,
        video,
        onChange,
    } = useSettingsSectionContext<PlayOptionsFormData>()

    const commonProps = {
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const

    const endVideoActions = [
        {
            value: PlayerOnPlaybackEndType.PAUSE,
            ...locale.endOfVideoActions.actions.pause,
        },
        {
            value: PlayerOnPlaybackEndType.DISPLAY_THUMBNAIL,
            ...locale.endOfVideoActions.actions.displayThumbnail,
        },
        {
            value: PlayerOnPlaybackEndType.REPLAY,
            label: locale.endOfVideoActions.actions.replay.label,
            disabled: Boolean(isLoading || video?.funnel),
            tooltip: isLoading || video?.funnel ? locale.endOfVideoActions.actions.replay.tooltip : null,
        },
        {
            value: PlayerOnPlaybackEndType.REDIRECT,
            label: locale.endOfVideoActions.actions.redirect.label,
            disabled: Boolean(isLoading || video?.funnel),
            tooltip: isLoading || video?.funnel ? locale.endOfVideoActions.actions.redirect.tooltip : null,
        },
    ]

    const timeInputRef = useRef<HTMLInputElement>()

    const afterChangeWithLinkValidation = async () => {
        await setFieldTouched(UI_OVERLAY_REDIRECT_URL, true)
        const errors = await formik.validateForm()

        if (!Object.values(errors).length) {
            onChange()
        }
    }

    const afterChangeWithLinkValueCheck = () => {
        if (values[UI_OVERLAY_REDIRECT_URL]) {
            onChange()
        } else {
            setFieldValue(PLAYBACK_ON_END, PlayerOnPlaybackEndType.PAUSE)
            onChange()
        }
    }

    const changeValidation = async () => {
        const errors = await formik.validateForm()
        if (!Object.values(errors).length) {
            onChange()
        }
        await afterChangeWithLinkValidation()
    }

    return (
        <Box className="PlayOptionsContent">
            <Typography className="radioTitle">
                {locale.startupQuality.title}
                <HelpTooltip id="LabelHelp" title={locale.startupQuality.titleTooltip} />
            </Typography>
            <Box className="wrapper">
                <Radio
                    name={PLAYER_STARTUP_QUALITY}
                    options={videoStartupQualityOptions}
                    {...commonProps}
                    afterChange={afterChangeWithLinkValueCheck}
                    labelVariant="body2"
                />
            </Box>
            {lowerBandwidthUsage && (
                <>
                    <Divider className="dividerSection" />
                    <Box pr={5}>
                        <SwitchForm
                            sx={style.container}
                            name={PLAYER_LOWER_BANDWIDTH_USAGE}
                            label={
                                <Typography variant="body2">
                                    {locale.startupQuality.lowerBandwidthUsage.label}
                                </Typography>
                            }
                            labelPlacement="start"
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            helpInfo={locale.startupQuality.lowerBandwidthUsage.tooltip}
                            afterChange={afterChangeWithLinkValueCheck}
                            {...commonProps}
                        />
                    </Box>
                </>
            )}
            <Divider className="dividerSection" />
            <ResumePlayContent />
            <Divider className="dividerSection" />
            <Typography className="radioTitle">{locale.endOfVideoActions.title}</Typography>
            <Box className="wrapper">
                <Radio
                    name={PLAYBACK_ON_END}
                    labelVariant="body2"
                    options={endVideoActions}
                    {...commonProps}
                    afterChange={(_, value) => value !== PlayerOnPlaybackEndType.REDIRECT && onChange()}
                    onBlur={() => values[UI_OVERLAY_REDIRECT_URL] && onChange()}
                />

                <Accordion
                    expanded={values[PLAYBACK_ON_END] === PlayerOnPlaybackEndType.REDIRECT}
                    className="formSectionAccordion"
                >
                    <AccordionSummary></AccordionSummary>
                    <AccordionDetails>
                        <Input
                            name={UI_OVERLAY_REDIRECT_URL}
                            label={locale.endOfVideoActions.actions.redirect.link}
                            placeholder={EXAMPLE_URL_PLACEHOLDER}
                            variant="outlined"
                            formControlClassName="uiOverlayRedirecUrl"
                            multiline
                            {...commonProps}
                            onBlur={afterChangeWithLinkValidation}
                        />

                        <Input
                            InputProps={{
                                inputRef: timeInputRef,
                                value: `${
                                    isNumber(Number(values[UI_OVERLAY_REDIRECT_TIMEOUT]))
                                        ? values[UI_OVERLAY_REDIRECT_TIMEOUT]
                                        : '0'
                                }s`,
                            }}
                            name={UI_OVERLAY_REDIRECT_TIMEOUT}
                            label={locale.endOfVideoActions.actions.redirect.delay}
                            placeholder="10"
                            variant="outlined"
                            formControlClassName="uiOverlayRedirecUrl"
                            onChange={(ev) => {
                                const nextValue = String(Number(ev.target.value.replace(/[^0-9.]/g, ''))) || '0'
                                const pos = nextValue.length || 0

                                setFieldValue(UI_OVERLAY_REDIRECT_TIMEOUT, nextValue)

                                setTimeout(() => {
                                    timeInputRef.current?.setSelectionRange?.(pos, pos)
                                }, 0)

                                return nextValue
                            }}
                            onBlur={changeValidation}
                            {...commonProps}
                        />

                        <SwitchForm
                            sx={{ width: '100%', justifyContent: 'space-between' }}
                            name={UI_OVERLAY_REDIRECT_SHOW}
                            label={
                                <Typography variant="body2">
                                    {locale.endOfVideoActions.actions.redirect.messageLabel}
                                </Typography>
                            }
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            helpInfo={locale.endOfVideoActions.actions.redirect.messageTooltip}
                            afterChange={changeValidation}
                            {...commonProps}
                        />

                        <Accordion expanded={values[UI_OVERLAY_REDIRECT_SHOW]} className="formSectionAccordion">
                            <AccordionSummary></AccordionSummary>
                            <AccordionDetails>
                                <Input
                                    name={UI_OVERLAY_REDIRECT_TEXT}
                                    label={locale.endOfVideoActions.actions.redirect.headline}
                                    inputProps={{ maxLength: 100 }}
                                    variant="outlined"
                                    multiline
                                    displayMaxLength
                                    onBlur={changeValidation}
                                    {...commonProps}
                                />

                                <ColorPicker
                                    name={UI_OVERLAY_REDIRECT_COLOR_FOREGROUND}
                                    label={locale.endOfVideoActions.actions.redirect.textColor}
                                    prefixed
                                    afterChange={changeValidation}
                                    {...commonProps}
                                />
                                <ColorPicker
                                    name={UI_OVERLAY_REDIRECT_COLOR_BACKGROUND}
                                    label={locale.endOfVideoActions.actions.redirect.shadowColor}
                                    prefixed
                                    afterChange={changeValidation}
                                    {...commonProps}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Divider className="dividerSection" />
            <Box pr={5} pb={2}>
                <SwitchForm
                    sx={style.container}
                    name={PLAYBACK_SMART_PAUSE}
                    label={
                        <Typography className="radioTitle radioTitle--without-mb" variant="body2">
                            {locale.smartPause.title}
                        </Typography>
                    }
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.smartPause.tooltip}
                    onChange={(_, value) => {
                        setValues({
                            ...values,
                            [PLAYBACK_SMART_PAUSE]: value,
                            ...(showAutoplayOnReturn ? { [PLAYBACK_SMART_PAUSE_RESUME]: value } : {}),
                        })

                        afterChangeWithLinkValueCheck()
                    }}
                    {...commonProps}
                />
            </Box>
            <Box className="wrapper">
                {showAutoplayOnReturn && values[PLAYBACK_SMART_PAUSE] && (
                    <Box ml={4}>
                        <SwitchForm
                            sx={style.container}
                            name={PLAYBACK_SMART_PAUSE_RESUME}
                            label={<Typography variant="body2">{locale.smartPause.autoplayOnReturn.title}</Typography>}
                            tooltipPlacement={SwitchTooltipPlacement.inside}
                            helpInfo={locale.smartPause.autoplayOnReturn.tooltip}
                            afterChange={afterChangeWithLinkValueCheck}
                            {...commonProps}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
