import { Box } from '@mui/material'
import styles from './ScreenLoader.styles'

export const ScreenLoader = () => (
    <Box sx={styles.container}>
        <Box sx={styles.wrapper}>
            <Box
                component="svg"
                sx={[styles.logo.container, styles.animationSettings]}
                viewBox="0 0 38 36"
                preserveAspectRatio="xMidYMid meet"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Box component="path" d="M15 16.5L28 16.5L17 33L1.5 10" stroke="#edf4fa" strokeWidth="2.5" />
                <Box
                    component="path"
                    d="M34 12L9.00001 12L17 24.5L20.5901 19.1148"
                    stroke="#edf4fa"
                    strokeWidth="2.5"
                />

                <Box
                    component="path"
                    sx={[styles.logo.bottomLine, styles.animationSettings]}
                    d="M15 16.5L28 16.5L17 33L1.5 10"
                />
                <Box
                    component="path"
                    sx={[styles.logo.upperLine, styles.animationSettings]}
                    d="M34 12L9.00001 12L17 24.5L20.5901 19.1148"
                />

                <rect y="9" width="4" height="2" fill="white" />
                <Box component="path" d="M31 14H35L37.5 10.3H33.5L31 14Z" fill="white" />
                <Box component="path" d="M17.5 18.7L15.5 18.7L13 15H15L17.5 18.7Z" fill="white" />
                <Box component="path" d="M18 18H23V20H18V18Z" fill="white" />
            </Box>
        </Box>
    </Box>
)
