import { useLayoutEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { trackAppCuesEvent, APPCUES_EVENTS } from 'thirdPartyServices/appCues'
import { route } from 'constants/routes'

// can be modified with startsWith, etc
const CUSTOM_OR_HIDDEN_PATHNAMES = [{ includes: 'training-center' }]

export const usePageViewEventTrack = () => {
    const { entity } = useParams()
    const location = useLocation()

    useLayoutEffect(() => {
        if (CUSTOM_OR_HIDDEN_PATHNAMES.some((rule) => location.pathname.includes(rule.includes))) {
            return
        }

        if (location.pathname.includes(`${route.stats.root(entity)}/`)) {
            trackAppCuesEvent(APPCUES_EVENTS.VIDSTATS_OPEN)
        }

        trackAppCuesEvent(`${APPCUES_EVENTS.VISITED_PAGE} URL - ${location.pathname}`)
    }, [location, entity])
}
