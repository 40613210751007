export default {
    thumbnailTooltip:
        'Choose a frame from your video or upload an image to use as a thumbnail, which will be shown to users before the video plays.',
    uploadThumbnailTitle: 'Thumbnail',
    mobileSwitcher: 'Use The Same For Mobile Thumbnail',
    mobileThumbTitle: 'Mobile thumbnail',
    mobileThumbHelpText: 'This thumbnail will only be shown to viewers on mobile devices, including tablets.',
    exitThumbnailLabel: 'Exit Thumbnail',
    exitThumbnailHelpText:
        'This thumbnail is never seen until a viewer pauses your video, like an exit pop just for your video.',
    strethLabel: 'Stretch thumbnails',
    stretchThumbnailHelpText:
        'Stretch thumbnails to take the whole video container, no matter what their original size or aspect ratio is.',
}
