import {
    Box,
    FormControlLabel,
    FormControlLabelProps,
    Switch as MuiSwitch,
    SwitchProps as MuiSwitchProps,
} from '@mui/material'
import { ReactNode } from 'react'

import { HelpTooltip } from 'design/atoms/HelpTooltip'
import { getSx } from 'styles/theme/utils'
import style from './Switch.styles'

export enum SwitchTooltipPlacement {
    after = 'after',
    inside = 'inside',
}

export interface SwitchProps extends Omit<FormControlLabelProps, 'control'> {
    /** Switch value, on or off */
    value?: boolean
    /** Switch label position controls */
    labelPlacement?: FormControlLabelProps['labelPlacement']
    /** Switch help info text */
    helpInfo?: ReactNode
    betaLabel?: ReactNode
    /** Switch  disable toggle */
    disabled?: boolean
    /** Switch tooltip position controls */
    tooltipPlacement?: SwitchTooltipPlacement
    /** Switch additional className */
    labelClassName?: string
    /** Switch additional props */
    switchProps?: MuiSwitchProps
}
/**
 * Switch component
 */
export const Switch = ({
    value,
    label,
    betaLabel,
    labelPlacement,
    helpInfo,
    disabled,
    tooltipPlacement = SwitchTooltipPlacement.after,
    labelClassName,
    switchProps,
    sx,
    onChange,
    ...props
}: SwitchProps) => {
    const renderTooltip = () => helpInfo && <HelpTooltip title={helpInfo} />

    const renderLabel = () =>
        tooltipPlacement === SwitchTooltipPlacement.inside ? (
            <Box sx={style.labelWrapper}>
                {label}
                {renderTooltip()}
                {betaLabel}
            </Box>
        ) : (
            label
        )

    return (
        <>
            <FormControlLabel
                className={labelClassName}
                checked={value}
                sx={getSx(style.formControlLabelRoot, sx)}
                control={
                    <MuiSwitch
                        sx={style.switchRoot(switchProps?.color, switchProps?.size)}
                        {...switchProps}
                        disableRipple
                    />
                }
                label={renderLabel()}
                disabled={disabled}
                labelPlacement={labelPlacement || 'start'}
                onChange={onChange}
                {...props}
            />
            {tooltipPlacement === SwitchTooltipPlacement.after && renderTooltip()}
        </>
    )
}

// Workaround for Storybook bug. Do not delete.
Switch.displayName = 'Switch'
