import { Components, Theme } from '@mui/material'

export const MuiInputBase: Components<Omit<Theme, 'components'>>['MuiInputBase'] = {
    styleOverrides: {
        root: ({ theme, ownerState }) => ({
            backgroundColor: ownerState.disabled
                ? theme.palette.action.disabledBackground
                : theme.palette.background.paper,
        }),
    },
}
