import { UseMutationOptions } from 'react-query'
import mixpanel from 'mixpanel-browser'

import { integrationsAPI } from 'api/controllers'
import { ChangeAnalyticsIntegrationStatusPayload } from 'types/Integrations'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { ANALYTICS_SETTINGS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { MIXPANEL_EVENTS } from 'thirdPartyServices/mixpanel'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useChangeIntegrationStatusMutation = (
    options?: UseMutationOptions<null, any, ChangeAnalyticsIntegrationStatusPayload>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, ChangeAnalyticsIntegrationStatusPayload>(
        ANALYTICS_SETTINGS_QUERY,
        async (payload: ChangeAnalyticsIntegrationStatusPayload) =>
            mutate(integrationsAPI.changeIntegrationStatus(payload), apiLocale.integration.changeStatus),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
            onSuccess: (data, variables, context) => {
                if (variables.status) {
                    mixpanel.track(MIXPANEL_EVENTS.INTEGRATION_ENABLED, { type: variables.integration })
                }
                options?.onSuccess?.(data, variables, context)
            },
        },
    )
}
