import {
    AnalyticsSettings,
    AnalyticsSettingPayload,
    ChangeAnalyticsIntegrationStatusPayload,
    ZapierStatus,
    HubspotStatus,
    ZapierFields,
    ZapierStatusUpdatePayload,
    HubspotLink,
    HighLevelLink,
    HighLevelStatus,
    HighLevelValidation,
    SegmentKeyChangePayload,
    SegmentKeyStatus,
    AllIntegrationStatuses,
} from 'types/Integrations'
import { http } from 'api/utils/http.utils'

export const integrationsAPI = {
    getAllIntegrationStatuses: () => http.get<AllIntegrationStatuses>('integrations'),
    getAnalyticsSettings: () => http.get<AnalyticsSettings>('customer/integration/setting'),
    getZapierStatus: () => http.get<ZapierStatus>('integration/zapier'),
    getHubspotStatus: () => http.get<HubspotStatus>('hubspot/status'),
    getZapierFields: () => http.get<ZapierFields>('integration/zapier/fields'),
    getHubspotLink: (redirectUri: string) => http.get<HubspotLink>(`/hubspot/auth/link?redirect_uri=${redirectUri}`),
    verifyValidationCode: (validationCode: string) =>
        http.get<HubspotStatus>(`hubspot/auth/validate-code?code=${validationCode}`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8' },
        }),
    updateGoogleIntegration: (payload: AnalyticsSettingPayload) =>
        http.put<AnalyticsSettingPayload, AnalyticsSettingPayload>('customer/integration/setting/google', payload),
    updateFacebookIntegration: (payload: AnalyticsSettingPayload) =>
        http.put<AnalyticsSettingPayload, AnalyticsSettingPayload>(
            'customer/integration/setting/facebookPixel',
            payload,
        ),
    changeIntegrationStatus: (payload: ChangeAnalyticsIntegrationStatusPayload) =>
        http.post<ChangeAnalyticsIntegrationStatusPayload>('customer/integration/setting/change-status', payload),
    changeZapierStatus: (payload: ZapierStatusUpdatePayload) =>
        http.post<ZapierStatusUpdatePayload, ZapierStatus>('integration/zapier', payload),
    deleteZapierField: (fieldName: string) => http.delete<string>(`integration/zapier/field/${fieldName}`),
    deleteHubspot: () => http.delete<string>('hubspot/delete'),
    getHighLevelLink: (redirectUri: string) =>
        http.get<HighLevelLink>(`/high-level/auth/link?redirect_uri=${redirectUri}`),
    getHighLevelStatus: () => http.get<HighLevelStatus>('/high-level/integration/status'),
    deleteHighLevel: () => http.post<string>('/high-level/integration/cancel'),
    sendHighLevelValidationCode: (payload: HighLevelValidation) =>
        http.post<HighLevelValidation>('/high-level/integration', payload),
    reGenerateToken: () => http.post('customer/integration/active-campaign/token-generate'),
    getSegmentKey: () => http.get<SegmentKeyStatus>('twilio-segment/write-key/show'),
    deleteSegmentKey: () => http.delete('twilio-segment/write-key/delete'),
    changeSegmentKey: (payload: SegmentKeyChangePayload) =>
        http.post<SegmentKeyChangePayload, SegmentKeyChangePayload>('twilio-segment/write-key/upsert', payload),
}
