import { useParams } from 'react-router-dom'
import { StatsEntity } from 'constants/routes'

const useCurrentEntity = () => {
    const { entity } = useParams()

    return {
        entity,
        isSmartVidEntity: entity === StatsEntity.smartVid,
        isAnalyticsEntity: entity === StatsEntity.analytics,
    }
}

export default useCurrentEntity
