import { useQuery, UseQueryOptions } from 'react-query'

import { StepSettings } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import { STEP_SETTINGS_QUERY } from 'api/constants'

export const useStepSettingsQuery = (
    videoId?: string,
    options?: UseQueryOptions<StepSettings, unknown, StepSettings>,
) =>
    useQuery<StepSettings>(
        [STEP_SETTINGS_QUERY, videoId],
        () => smartVidConroller.getStepSettings(String(videoId)),
        options,
    )
