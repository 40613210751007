import { useEffect, useState, useRef } from 'react'
import Typography from '@mui/material/Typography'
import { Box, Stack } from '@mui/material'

import {
    openSubscriptionModal,
    setPlan,
    SubscriptionCtaSource,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import HelperButton from 'design/atoms/Table/Toolbar/HelperButton'
import { openModal, ModalType, closeModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import { Page } from 'design/templates/Page'
import usePageTitle from 'hooks/navigation/usePageTitle'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { Conversion } from 'types/Conversion'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { CustomerStatus } from 'types/Customer'
import { useAppDispatch } from 'App'
import { useCustomerQuery, useConversionsQuery } from 'api/queries'
import useTariffPlans from 'hooks/user/useTariffPlans'
import SearchField from 'design/atoms/Table/Toolbar/SearchField'
import { HEADLINE } from 'design/atoms/Table/constants'
import { EditConversionModal, openAgencyLockedFeaturesModal } from 'design/templates/Modal'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import { ConversionsTable } from './ConversionsTable/ConversionsTable'
import { ConversionsLockedContent } from './ConversionsLockedContent/ConversionsLockedContent'
import style from './Conversions.style'

export const Conversions = withErrorBoundary(() => {
    usePageTitle('Conversions | Vidalytics')

    const dispatch = useAppDispatch()
    const lockModalContainer = useRef<HTMLDivElement>()
    const { data: conversions, isLoading } = useConversionsQuery()
    const { data: customer } = useCustomerQuery()
    const { lockedFeatures, isLoading: isAccountSubscriptionLoading, planTransitionRequired } = useAccountSubscription()
    const { pro: proPlan } = useTariffPlans()
    const [conversion, setConversion] = useState<Conversion>()
    const [editConversionModalOpen, setEditConversionModalOpen] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const { agencyAccessLocked } = useAgencyAccess()

    const isUserOnTrial = customer?.status === CustomerStatus.trial

    useEffect(() => {
        if (isAccountSubscriptionLoading) return

        if (agencyAccessLocked) {
            dispatch(openAgencyLockedFeaturesModal({ fallback: true }))
            return
        }

        if (lockedFeatures && !planTransitionRequired) {
            dispatch(
                openModal({
                    type: ModalType.FEATURE_LOCKED,
                    title: 'Upgrade to Unlock Conversion Tracking!',
                    description:
                        'See the exact spot in your videos that makes your viewers convert. It is as easy as dropping a snippet of code on your thank you page, and, oh so powerful... 😎',
                    proPlan,
                    isUserOnTrial,
                    containerRef: lockModalContainer.current,
                    onUpgradeClick,
                    children: <ConversionsLockedContent />,
                }),
            )
            return
        }

        // if user updated subscription
        if (!planTransitionRequired) {
            dispatch(closeModal())
        }
    }, [agencyAccessLocked, lockedFeatures, proPlan, isAccountSubscriptionLoading, planTransitionRequired])

    const onEditConversionModalClose = () => {
        setEditConversionModalOpen(false)
        setConversion(undefined)
    }

    const buttonConfiguration = {
        label: '+ New Conversion',
        onClick: () => setEditConversionModalOpen(true),
        disabled: lockedFeatures || agencyAccessLocked,
    }

    const handleActionEdit = (conversion?: Conversion) => {
        if (conversion) {
            setConversion(conversion)
        }
        setEditConversionModalOpen(true)
    }

    const onUpgradeClick = () => {
        dispatch(closeModal())
        dispatch(setPlan({ plan: proPlan }))
        dispatch(
            openSubscriptionModal({
                containerRef: lockModalContainer.current,
                ctaSource: SubscriptionCtaSource.CONVERSIONS,
            }),
        )
    }

    const renderHeader = () => (
        <Stack flexDirection="row" sx={style.headerText} gap={4} flexWrap="wrap">
            <Typography variant="h6" component="h1" noWrap>
                Conversions
            </Typography>

            <SearchField
                sx={style.headerSearch}
                searchByIds={[HEADLINE]}
                searchByLabel="Search by Name"
                onChange={(search) => setSearchValue(search)}
            />

            <Box sx={style.helperButton}>
                <HelperButton fullWidth button={buttonConfiguration} />
            </Box>
        </Stack>
    )

    return (
        <Page
            redesigned
            title="Conversions"
            header={renderHeader()}
            containerSx={style.container}
            sx={style.content}
            stackRef={lockModalContainer}
        >
            <ConversionsTable
                conversions={conversions}
                isLoading={isLoading}
                searchValue={searchValue}
                lockedFeatures={lockedFeatures}
                handleActionEdit={handleActionEdit}
            />

            <EditConversionModal
                open={editConversionModalOpen}
                conversion={conversion}
                onClose={onEditConversionModalClose}
            />
        </Page>
    )
})

export default Conversions
