import { ExtendedFiles, selectUploaderState } from 'design/pages/VideoUpload/uploader.slice'
import useBeforeUnload from 'hooks/navigation/useBeforeUnload'
import { useAppSelector } from 'App'

export const getUploadingLockStatus = (files: ExtendedFiles) =>
    files.some((f) => (f.state?.errorType ? false : f.state?.startedProcessing && !f.state?.isSettled))

export const UPLOADING_BEFORE_UNLOAD_MESSAGE =
    'If you leave or refresh current application, you will lose your uncompleted uploads. Are you sure you want to continue?'

const useUploadingWarning = () => {
    const { files } = useAppSelector(selectUploaderState)

    useBeforeUnload(UPLOADING_BEFORE_UNLOAD_MESSAGE, getUploadingLockStatus(files))
}

export default useUploadingWarning
