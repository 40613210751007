import { useQueryClient, UseMutationOptions } from 'react-query'

import { Funnel, ReplaceVideoPayload } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import { SMART_VIDS_QUERY, SMART_VIDS_STEPS_QUERY, VIDEO_BRANCH_QUERY, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useReplaceFunnelVideoMutation = (
    options?: UseMutationOptions<Funnel, ReplaceVideoPayload, ReplaceVideoPayload>,
) => {
    const queryClient = useQueryClient()
    const key = SMART_VIDS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Funnel, ReplaceVideoPayload>(
        key,
        async (payload: ReplaceVideoPayload) =>
            mutate(smartVidConroller.replaceSmartVidVideo(payload), apiLocale.funnel.replace),
        {
            onSuccess: async (...args) => {
                options?.onSuccess?.(...args)

                await queryClient.cancelQueries(key)

                const [data] = args

                queryClient.setQueryData([key, data.id], data)

                const keys = [key, SMART_VIDS_STEPS_QUERY, VIDEO_BRANCH_QUERY]

                await Promise.all(keys.map((k) => queryClient.invalidateQueries(k)))
            },
            invalidate: [VIDEOS_QUERY],
        },
    )
}
