import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_RESEND_CANCELLATION_EMAIL } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

export const useResendCancellationEmailMutation = () => ({
    resendCancellationEmail: useInvalidateMutation<undefined, AgencyClientId>(
        AGENCY_RESEND_CANCELLATION_EMAIL,
        async (id: AgencyClientId) => agencyController.client.cancelAccount.resendEmail(id),
    ),
})
