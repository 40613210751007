import { useCallback } from 'react'
import { useToast } from 'design/organisms/ToastProvider'
import { locale } from 'locales'

export const useCopyToClipboard = () => {
    const { showToast } = useToast()

    const copy = useCallback((text: string) => {
        if (window.isSecureContext) {
            navigator.clipboard
                .writeText(text)
                .then(() => showToast({ type: 'info', message: locale.messages.info.copied }))
                .catch(() => showToast({ type: 'info', message: locale.messages.errors.generic }))
        } else {
            console.error('The context is NOT secure')
        }
    }, [])

    return { copy }
}
