import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'

import { SvgIconProps } from '@mui/material'
import { FC } from 'react'
import style from './HelpTooltip.style'

type HelpTooltipProps = {
    iconColor?: SvgIconProps['color']
}

export const HelpTooltip: FC<HelpTooltipProps & Omit<TooltipProps, 'children'>> = ({ iconColor, ...props }) => {
    return (
        <Tooltip placement="top" arrow {...props}>
            <HelpOutlineIcon
                onClick={(event) => event.stopPropagation()}
                color={iconColor || 'primary'}
                sx={style.icon}
            />
        </Tooltip>
    )
}
