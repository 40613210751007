import { KeyboardEvent, MouseEvent } from 'react'
import { useCookies } from 'react-cookie'
import { queryClient } from 'api/queries'
import { IS_REDIRECTING } from 'api/constants'
import { convertValueToDollars } from 'utils'
import { ThumbnailSourceType, ThumbnailType } from 'types/Video'
import { ThumbnailSource } from 'types/VidSettings'
import { ACCESS_TOKEN, REFRESH_TOKEN } from 'constants/cookies.constants'

export const toggleDrawer = (o: boolean, setOpen: (o: boolean) => void) => (event: KeyboardEvent | MouseEvent) => {
    if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
        return
    }

    setOpen(o)
}

export const isTouchDevice = () => {
    // @ts-ignore
    return 'ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0
}

export const parseJwt = (token?: string) => {
    if (!token) {
        return null
    }

    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(''),
    )

    return JSON.parse(jsonPayload)
}

export const enableQueryHelper = () => {
    const [cookies] = useCookies()
    return Boolean((cookies[ACCESS_TOKEN] || cookies[REFRESH_TOKEN]) && !queryClient.getQueryData(IS_REDIRECTING))
}

export const valueOrDefault = (value: number | string, deflt = '-') => value || deflt

export const currencyOrPercentageOrDefault = (value?: number | string, deflt = '-') => {
    if (value === undefined) {
        return deflt
    }

    if (typeof value === 'string' && value.endsWith('%')) {
        return value
    }

    return convertValueToDollars(Number(value))
}

export const calculateVideoRatio = (width = 0, height = 0) => {
    // Calculate the greatest common divisor (GCD) using Euclid's algorithm
    const gcd = (a: number, b: number): number => {
        return b === 0 ? a : gcd(b, a % b)
    }

    // Calculate the GCD of width and height
    const commonDivisor: number = gcd(width, height)

    // Calculate the ratio by dividing width and height by the GCD
    const widthRatio: number = width / commonDivisor
    const heightRatio: number = height / commonDivisor

    // Return the video ratio as a string
    return {
        widthRatio,
        heightRatio,
    }
}

export const calculateThumbnailSource = (
    thumbnailSourceType: ThumbnailSourceType,
    id: ThumbnailType,
): ThumbnailSource => {
    if (thumbnailSourceType === ThumbnailSourceType.image) {
        return ThumbnailSource.image
    }
    if (thumbnailSourceType === ThumbnailSourceType.videoBoth) {
        return ThumbnailSource.video
    }
    if (id === ThumbnailType.thumbnail && thumbnailSourceType === ThumbnailSourceType.videoDesktop) {
        return ThumbnailSource.video
    }
    if (id === ThumbnailType.mobileThumbnail && thumbnailSourceType === ThumbnailSourceType.videoMobile) {
        return ThumbnailSource.video
    }
    return ThumbnailSource.image
}
