import { useQueryClient } from 'react-query'

import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { SubUser } from 'types/SubUser'
import { Id } from 'types'
import { subUserAPI } from 'api/controllers'
import { SUB_USERS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'

type Payload = Id

export const useDeleteSubUserMutation = () => {
    const queryClient = useQueryClient()
    const key = SUB_USERS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, Payload>(
        key,
        async (payload: Payload) => {
            return mutate(subUserAPI.deleteSubUser(payload), apiLocale.subUser.delete)
        },
        {
            onMutate: async (...args) => {
                await queryClient.cancelQueries(key)

                const [id] = args
                const previousData = queryClient.getQueryData(key) as SubUser[]

                if (previousData) {
                    queryClient.setQueryData(
                        key,
                        previousData.filter((c) => c.id !== id),
                    )
                }

                return previousData
            },
            // @ts-ignore
            onError: async (err, variables, previousValue) => queryClient.setQueryData(key, previousValue),
            onSettled: () => {
                queryClient.invalidateQueries(key)
            },
            invalidateOn: InvalidateOn.NEVER,
        },
    )
}
