import { IconButton, Popover, Stack, Typography, Alert, Menu, Tooltip, Slide, Button } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import { useState, MouseEvent, useMemo, useCallback } from 'react'
import { AstronautWaitingIcon } from 'assets/new'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { AgencyClient } from 'api/contracts/agency/entities/agencyClient'
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded'
import { AccountMetric } from 'api/contracts/agency/entities/common'
import { keys as _keys } from 'lodash'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'
import { useClientAccount } from '../AgencyAccount.hooks'
import style from './ClientCard.style'
import { AccountInformation } from './AccountInformation/AccountInformation'
import { ActionsMenu } from './ActionsMenu/ActionsMenu'
import { MetricCard } from './MetricCard/MetricCard'
import { AgencyDashboardCard } from './AgencyDashboardCard/AgencyDashboardCard'

type AgencyClientProps = Omit<AgencyClient, 'tariffInfo'> & { disableActionsMenu?: boolean }

export const ClientCard = ({
    id,
    name,
    picture,
    statistics,
    videosCount,
    status,
    email,
    onCancellation,
    disableActionsMenu,
}: AgencyClientProps) => {
    const [accountInfoMenu, setAccountInfoMenu] = useState<null | HTMLElement>(null)
    const [clientActionsMenu, setClientActionsMenu] = useState<null | HTMLElement>(null)
    const [isJumpInToastShown, setJumpInToastShown] = useState(false)
    const { jumpIn } = useClientAccount()

    const { palette } = useTheme()

    const openAccountInfoMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setAccountInfoMenu(event.currentTarget)
    }
    const closeAccountInfoMenu = () => {
        setAccountInfoMenu(null)
    }

    const openClientActionsMenu = (event: MouseEvent<HTMLButtonElement>) => {
        setClientActionsMenu(event.currentTarget)
    }
    const closeClientActionsMenu = () => {
        setClientActionsMenu(null)
    }

    const openJumpInToast = useCallback(() => {
        if (status === 'pending' || onCancellation) return
        if (onCancellation) return

        setJumpInToastShown(true)
    }, [status, onCancellation])

    const pendingActivationStub = useMemo(() => {
        if (status !== 'pending') return null

        return (
            <Stack justifyContent="center" alignItems="center" flexGrow={1} gap={4}>
                <AstronautWaitingIcon style={{ fill: palette.action.disabled }} />
                <Typography variant="body2" color="text.disabled">
                    Pending Account Activation
                </Typography>
            </Stack>
        )
    }, [status])

    const renderActionButton = useCallback(() => {
        if (status === 'pending') {
            return (
                <Tooltip title="We will display the account information once a plan is selected">
                    <IconButton>
                        <InfoOutlinedIcon />
                    </IconButton>
                </Tooltip>
            )
        }

        return (
            <IconButton onClick={openAccountInfoMenu}>
                <InfoOutlinedIcon />
            </IconButton>
        )
    }, [status])

    const metricCards = useMemo(() => {
        if (status !== 'active' || !statistics) return null

        const metrics = _keys(statistics) as AccountMetric[]

        return (
            <Box sx={style.metrics.container}>
                {metrics.map((metric) => (
                    <MetricCard metric={metric} {...statistics[metric]} key={metric} />
                ))}
            </Box>
        )
    }, [])

    return (
        <AgencyDashboardCard
            CardProps={{
                sx: { gap: 3 },
                onMouseEnter: () => openJumpInToast(),
                onMouseLeave: () => setJumpInToastShown(false),
            }}
        >
            <Stack gap={4} justifyContent="space-between" alignItems="center" direction="row">
                <Avatar src={picture || undefined} />
                <Stack flexGrow={1} overflow="hidden">
                    <Typography fontWeight="bold" overflow="hidden" textOverflow="ellipsis">
                        {name}
                    </Typography>
                    <Stack direction="row" alignItems="center">
                        <Typography variant="caption2" color="text.secondary" mr={4}>
                            {email}
                        </Typography>
                        <VideocamRoundedIcon sx={{ mr: 1, fill: palette.action.active }} />
                        <Typography variant="caption2" color="text.secondary">
                            {videosCount || 0}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack gap={1} direction="row">
                    {renderActionButton()}
                    <IconButton onClick={openClientActionsMenu}>
                        <MoreVertRoundedIcon />
                    </IconButton>
                </Stack>
            </Stack>

            {metricCards}

            {pendingActivationStub}

            {onCancellation && (
                <Alert severity="error" sx={style.notification.cancellation}>
                    Cancellation pending
                </Alert>
            )}

            <Slide direction="up" in={isJumpInToastShown} mountOnEnter unmountOnExit>
                <Button
                    startIcon={<ExitToAppRoundedIcon />}
                    sx={style.jumpIn}
                    variant="contained"
                    color="secondary"
                    onClick={() => jumpIn(id)}
                >
                    Jump in
                </Button>
            </Slide>

            <Popover
                open={!!accountInfoMenu}
                anchorEl={accountInfoMenu}
                onClose={closeAccountInfoMenu}
                PaperProps={{
                    sx: style.accountInfo.menu,
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <AccountInformation id={id} />
            </Popover>

            {!disableActionsMenu && (
                <Menu
                    open={!!clientActionsMenu}
                    anchorEl={clientActionsMenu}
                    onClose={closeClientActionsMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <ActionsMenu
                        id={id}
                        clientInfo={{ id, name, picture, onCancellation, status }}
                        onClose={closeClientActionsMenu}
                    />
                </Menu>
            )}
        </AgencyDashboardCard>
    )
}
