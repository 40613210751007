import { get as _get } from 'lodash'
import PlayerOptions from 'types/PlayerConfig'
import { VideoGuid } from 'types/Video/index'

export const isVideoGuid = (videoGuid: VideoGuid | string | undefined) => !!videoGuid && videoGuid !== 'undefined'

export const setSettings = (embedId: string, settings: PlayerOptions) => {
    if (!window._vidalytics) window._vidalytics = {}
    if (!window._vidalytics.embeds) window._vidalytics.embeds = {}
    if (!window._vidalytics.embeds[embedId]) {
        window._vidalytics.embeds[embedId] = {}
    }

    window._vidalytics.embeds[embedId].options = settings
}

export function mountContainer(container: HTMLElement, embedId: string, settings: PlayerOptions) {
    const Embed = window.Vidalytics?.Embed
    if (!Embed) return
    const embedInstance = new Embed()
    embedInstance.mount(container, embedId, settings)
}

export function getVidalyticsPlayer(embedId: string) {
    window._vidalytics = window._vidalytics || {}
    window._vidalytics.embeds = window._vidalytics.embeds || {}
    window._vidalytics.embeds[embedId] = window._vidalytics.embeds[embedId] || {}

    return new Promise((resolve) => {
        if (window._vidalytics.embeds[embedId].player) {
            resolve(window._vidalytics.embeds[embedId].player)
            return
        }

        let embedValue: object
        Object.defineProperty(window._vidalytics.embeds[embedId], 'player', {
            get() {
                return embedValue
            },
            set(value) {
                embedValue = value
                resolve(value)
            },
            configurable: true,
        })
    })
}

const TM = 25
const MAX = 400 // 10 sec
export function vidalyticsPlayerInitialized(embedId: string) {
    return new Promise((resolve) => {
        getVidalyticsPlayer(embedId).then((data) => {
            if (_get(data, 'isInitialized')) {
                resolve(data)
            }

            let iterator = 0
            const interval = setInterval(() => {
                iterator++
                if (_get(data, 'isInitialized') || iterator > MAX) {
                    clearInterval(interval)
                    resolve(data)
                }
            }, TM)
        })
    })
}
