import { Divider, Stack } from '@mui/material'
import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'

import { useSmartVidQuery, useVideoQuery } from 'api/queries'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { GlobalSettingsFormProvider } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { CONTROLS_SECTION_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { useMemo } from 'react'
import { ControlsSectionFormData } from 'types/VidSettings'
import { VideoDetails } from 'types/Video'
import Controls from './Controls'
import { PlayerColors } from './PlayerColors'
import { SeekingBar } from './SeekingBar'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const ControlsSection = ({ resetForm, isSmartVidGlobalSettings }: FormikProps<ControlsSectionFormData> & Props) => {
    const { videoGuid, smartVidId } = useParams()

    const { data: smartVid } = useSmartVidQuery(String(smartVidId), {
        enabled: Boolean(smartVidId),
    })

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid || smartVid?.video.guid, {
        onSuccess: initForm,
    })

    const Provider = useMemo(
        () => (isSmartVidGlobalSettings ? GlobalSettingsFormProvider : SettingsSectionProvider),
        [isSmartVidGlobalSettings],
    )

    return (
        <Provider>
            <Form>
                <Stack mb={4} gap={4} divider={<Divider />}>
                    <PlayerColors isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                    <SeekingBar isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                    <Controls isSmartVidGlobalSettings={isSmartVidGlobalSettings} />
                </Stack>
            </Form>
        </Provider>
    )
}

const FormikWrapper = ({ isSmartVidGlobalSettings }: Props) => {
    return (
        <Formik<ControlsSectionFormData>
            initialValues={CONTROLS_SECTION_INITIAL_VALUES}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <ControlsSection {...props} isSmartVidGlobalSettings={isSmartVidGlobalSettings} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
