import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import { Button, Stack, useMediaQuery, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { Row, UseTableCellProps } from 'react-table'

import { useAppDispatch } from 'App'
import { Link } from 'design/atoms/Link'
import { FOLDER } from 'design/atoms/Table/constants'
import { ExpandedVideo, ExpandedVideosWithFolders, useVideosWithFolders } from 'design/pages/MyVids'
import useSettingsLinkProps from 'design/pages/VidSettings/hooks/useSettingsLinkProps'
import { useGetDetailedStatsUrl } from 'design/pages/VidStats/hooks/useGetDetailedStatsUrl'
import { ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import useUpgradeCallback from 'hooks/user/useUpgradeCallback'
import { SwitchNewPlayer } from './SwitchNewPlayer/SwitchNewPlayer'
import './index.scss'

type RowActionsProps = {
    cell: UseTableCellProps<object>
    row: Row<ExpandedVideo>
    head?: boolean
    folder?: boolean
}

export function RowActions({
    row: {
        original: { guid, isReady, player },
    },
    head,
    folder,
}: RowActionsProps) {
    const dispatch = useAppDispatch()
    const { getDetailedStatsUrl } = useGetDetailedStatsUrl()
    const { swithNewPlayerFlag } = useFeatureFlags()
    const settingsLinkProps = useSettingsLinkProps()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
    const upgradeCallback = useUpgradeCallback()
    const { fullList } = useVideosWithFolders()

    const vidsInsideFolder = useMemo(() => {
        if (!folder) {
            return []
        }

        const currentFolder = fullList.find((record) => record.guid === guid)

        if (!currentFolder) {
            return []
        }

        const accumulatedVids = [] as ExpandedVideo[]

        const accumulateChildrenVids = (records: ExpandedVideosWithFolders) => {
            records.forEach((record) => {
                if (record.type === FOLDER) {
                    return accumulateChildrenVids(record.children)
                }

                accumulatedVids.push(record as ExpandedVideo)
            })
        }

        accumulateChildrenVids(currentFolder.children)

        return accumulatedVids
    }, [folder, fullList])

    const openVideoEmbedCodeModal = upgradeCallback(() => {
        dispatch(openModal({ type: ModalType.VIDEO_EMBED_CODE, videoId: guid }))
    })

    if (folder && (!vidsInsideFolder.length || !swithNewPlayerFlag)) {
        return null
    }

    return (
        <div className="RowActions">
            <SwitchNewPlayer
                player={player || {}}
                id={guid}
                head={head}
                folder={folder}
                vidsInsideFolder={vidsInsideFolder}
            />
            <Stack justifyContent="space-between" gap={3} direction="row">
                <Link to={getDetailedStatsUrl({ videoGuid: guid })} disabled={!isReady}>
                    <Button variant="outlined" disabled={!isReady} className="button">
                        Stats
                    </Button>
                </Link>
                <Link to={settingsLinkProps.getLink(guid)} external={settingsLinkProps.external}>
                    <Button variant="outlined" className="button">
                        Edit
                    </Button>
                </Link>
                <Button
                    onClick={openVideoEmbedCodeModal}
                    sx={{ display: isMobile ? 'none' : 'inline-flex' }}
                    variant="outlined"
                    className="button"
                >
                    <ShareOutlinedIcon />
                </Button>
            </Stack>
        </div>
    )
}
