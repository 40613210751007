import { InputAdornment, SxProps, TextField, Theme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useRef, useState } from 'react'

import ClearIcon from '@mui/icons-material/Clear'
import { getSx } from 'styles/theme/utils'
import style from './SearchField.style'

type SearchFieldProps = {
    searchByIds?: string[]
    searchByLabel: string
    setAllFilters?: any
    onChange?: (search: string) => void
    sx?: SxProps<Theme>
}

export default function SearchField({ sx, searchByIds, searchByLabel, setAllFilters, onChange }: SearchFieldProps) {
    const [isSearching, setIsSearching] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const _onChange = (value: string) => {
        setAllFilters?.((filters: any) => [
            ...filters
                .filter((f: any) => searchByIds?.some((id: string) => id !== f.id))
                .map((f: any) => ({ ...f, value: { ...f.value, searchValue: value } })),
            ...(searchByIds?.map((id: string) => ({ id, value })) || []),
        ])

        onChange?.(value)
    }

    const onFocus = () => {
        setIsSearching(true)
    }

    return (
        <>
            <TextField
                inputRef={inputRef}
                sx={getSx(style.textField(isSearching), sx)}
                onFocus={onFocus}
                placeholder={searchByLabel}
                onChange={(event) => _onChange(event.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment sx={{ mr: 2 }} onClick={() => inputRef.current?.focus()} position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment
                            onClick={() => {
                                _onChange('')
                                setIsSearching(false)
                                inputRef.current?.value && (inputRef.current.value = '')
                            }}
                            position="end"
                        >
                            <ClearIcon />
                        </InputAdornment>
                    ),
                    notched: false,
                }}
            />
        </>
    )
}
