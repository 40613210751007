import { SxProps, Theme } from '@mui/material'
import { getPropByTheme } from 'styles/theme/utils'

const thumbnailContainer: SxProps<Theme> = { position: 'relative' }

const image: SxProps<Theme> = {
    width: '100%',
    borderRadius: 3,
    maxHeight: 240,
    objectFit: 'contain',
}

const thumbnailDelete: SxProps<Theme> = {
    position: 'absolute',
    top: 0,
    right: 0,
    transition: '250ms ease-in-out',
}
const skeleton: SxProps<Theme> = {
    borderRadius: 3,
    height: 240,
    width: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}
const skeletonText: SxProps<Theme> = {
    position: 'absolute',
    top: '50%',
    left: '45%',
}
const uploader: SxProps<Theme> = {
    p: 4,
    border: (theme) => `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: (theme) => getPropByTheme('#F8F9FC', '#303238', theme.palette.mode),
    borderRadius: 4,
}

export default {
    thumbnail: {
        uploader,
        image,
        skeleton,
        skeletonText,
        container: thumbnailContainer,
        delete: thumbnailDelete,
    },
}
