import { SxProps } from '@mui/material'

const button: SxProps = {
    p: '10px 6px',
    textTransform: 'none',
}

export default {
    button,
}
