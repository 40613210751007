import { SyntheticEvent, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import styles from '../Sidebar.styles'

import useVidSettingsMenu from './useVidSettingsMenu'

const StyleSettings = () => {
    const menu = useVidSettingsMenu()
    const [expanded, setExpanded] = useState<string | boolean>(menu[0].label)
    const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <Box className="StyleSettings">
            {menu.map((rootMenuItem) => (
                <Accordion
                    key={rootMenuItem.label}
                    expanded={expanded === rootMenuItem.label}
                    onChange={handleChange(rootMenuItem.label)}
                >
                    <AccordionSummary
                        expandIcon={<KeyboardArrowRightRoundedIcon />}
                        aria-controls={`${rootMenuItem.label}Panel`}
                        id={`${rootMenuItem.label}PanelSummary`}
                        className="rootPanelSummary"
                        sx={expanded === rootMenuItem.label ? styles.tabHeader.highlighted : styles.tabHeader.idleIcon}
                    >
                        <Box className="mainSummary">
                            <rootMenuItem.Icon />
                            <Typography
                                fontFamily="Inter"
                                fontWeight="600"
                                variant="body1"
                                component="span"
                                sx={
                                    expanded === rootMenuItem.label
                                        ? styles.tabHeader.highlighted
                                        : styles.tabHeader.idle
                                }
                            >
                                {rootMenuItem.label}
                            </Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box className="mainDetails">{<rootMenuItem.Children />}</Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}

export default withErrorBoundary(StyleSettings)
