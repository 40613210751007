import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { CREATE_TAG_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'
import { TagConfig } from 'types/VideoTag'

type Payload = {
    videoGuid: string
    tag: TagConfig
}

export const useCreateTagMutation = (options?: Options<TagConfig, Payload, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<TagConfig, Payload, string>(
        CREATE_TAG_MUTATION,
        async (payload: Payload) => {
            return mutate(settingsAPI.createTag(payload.videoGuid, payload.tag), apiLocale.settings.tag.create)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
