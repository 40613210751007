import format from 'date-fns/format'

import { statsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { RESET_VID_STATS_MUTATION } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'

export const useResetVidStatsMutation = (options?: Options<null, string, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, string, string>(
        RESET_VID_STATS_MUTATION,
        async (videoGuid: string) => {
            const payload = {
                dateFrom: format(new Date(), 'yyyy-MM-dd'),
            }

            return mutate(statsAPI.resetVidStats(videoGuid, payload), apiLocale.stats.reset)
        },
        options,
    )
}
