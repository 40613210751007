import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import ClosedCaptionRoundedIcon from '@mui/icons-material/ClosedCaptionRounded'
import ControlsSection from './ControlsSection'
import ThumbnailsSection from './ThumbnailsSection'
import ClosedCaptionsSection from './ClosedCaptionsSection'

const useVidSettingsMenu = () => [
    { label: 'Controls', Icon: ColorLensRoundedIcon, Children: ControlsSection },
    { label: 'Thumbnails', Icon: ImageRoundedIcon, Children: ThumbnailsSection },
    { label: 'Closed Captions', Icon: ClosedCaptionRoundedIcon, Children: ClosedCaptionsSection },
]

export default useVidSettingsMenu
