import { UseMutationOptions, useQueryClient } from 'react-query'
import { userAPI } from 'api/controllers'
import { Customer, UpdateCustomerPayload } from 'types/Customer'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { CUSTOMER_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'

export const useUpdateCustomerMutation = (
    options?: UseMutationOptions<Customer, Error, Partial<Customer & UpdateCustomerPayload>>,
) => {
    const queryClient = useQueryClient()
    const key = CUSTOMER_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Customer, Partial<Customer & UpdateCustomerPayload>>(
        key,
        async (payload) => mutate(userAPI.updateCustomer(payload), apiLocale.user.updateCustomer),
        {
            ...options,
            onMutate: async (data) => {
                await queryClient.cancelQueries(key)

                const previousData = queryClient.getQueryData(key) as Customer

                queryClient.setQueryData(key, {
                    ...previousData,
                    ...data,
                })
            },
            invalidate: [key],
            invalidateOn: InvalidateOn.SETTLED,
        },
    )
}
