import { ConversionCountType, ConversionWindow } from 'types/Conversion'

export const TITLE = 'title'
export const VALUE = 'value'
export const COUNT_TYPE = 'countType'
export const CONVERSION_WINDOW = 'conversionWindow'
export const CONVERSION_COUNT = 'count'

export const INITIAL_VALUES = {
    [TITLE]: '',
    [VALUE]: '',
    [COUNT_TYPE]: ConversionCountType.ONE_PER_VIEWER,
    [CONVERSION_WINDOW]: `${ConversionWindow['30 days']}`,
}

export const PRO_PLAN_FEATURES = [
    {
        title: 'Video Marketing Analytics',
        subfeatures: [
            'Audience Engagement',
            'The World’s Only Video Conversion Tracking',
            'Video Heatmaps',
            'Video Stats Segments',
            'Compare View',
        ],
    },
    {
        title: 'Unbranded player',
    },
    {
        title: 'Tags',
    },
    {
        title: 'Play Gates',
    },
    {
        title: 'Free storage for up to 10 videos',
    },
    {
        title: '4x more video plays',
    },
]
