import * as yup from 'yup'

export const FIRSTNAME = 'firstname'
export const LASTNAME = 'lastname'
/**
 * @deprecated use FIRSTNAME instead
 */
export const FIRSTNAME_REQ = 'firstName' // firstname and firstName existance is BE bug
/**
 * @deprecated use FIRSTNAME instead
 */
export const LASTNAME_REQ = 'lastName' // lastname and lastName existance is BE bug
export const BUSINESSNAME = 'businessName'
export const EMAIL = 'email'
export const PASSWORD = 'password'
export const NEW_PASSWORD = 'newPassword'
export const CONFIRM_PASSWORD = 'passwordConfirmation'
export const REMEMBER_ME = 'rememberMe'
export const REDIRECT = 'redirect'
export const ACCESS = 'access'
export const REFRESH = 'refresh'

// VALIDATION FIELDS

export const FIRSTNAME_VALIDATION = yup.string().max(300).required('First Name is required')
export const LASTNAME_VALIDATION = yup.string().max(300).required('Last Name is required')
export const BUSINESSNAME_VALIDATION = yup.string().max(300).required('Business Name is required')
export const EMAIL_VALIDATION = yup.string().email('Please enter a valid email').max(75).required('Email is required')
export const PASSWORD_VALIDATION = yup
    .string()
    .min(8, 'Your password must contain 8 characters or more')
    .max(50, 'Password is too long')
    .required('Password is required')
export const REQUIRED_PASSWORD_VALIDATION = yup.string().required('Password is required')

// VALIDATION FORMS

export const SIGN_IN_VALIDATION_FORM = yup.object({
    [EMAIL]: EMAIL_VALIDATION,
    [PASSWORD]: REQUIRED_PASSWORD_VALIDATION,
})

export const CHANGE_EMAIL_VALIDATION_FORM = SIGN_IN_VALIDATION_FORM

export const EMAIL_VALIDATION_FORM = yup.object({
    [EMAIL]: EMAIL_VALIDATION,
})

export const CHANGE_PASSWORD_VALIDATION_FORM = yup.object({
    [PASSWORD]: REQUIRED_PASSWORD_VALIDATION,
    [NEW_PASSWORD]: PASSWORD_VALIDATION,
})

export const RESET_PASSWORD_VALIDATION_FORM = yup.object({
    [PASSWORD]: PASSWORD_VALIDATION,
    [CONFIRM_PASSWORD]: yup
        .string()
        .min(8, 'Your password must contain 8 characters or more')
        .max(50, 'Password confirmation is too long')
        .required('Password confirmation is required')
        .oneOf([yup.ref(PASSWORD), null], 'New password and confirmation do not match'),
})

export const PERSONAL_INFO_VALIDATION_FORM = yup.object({
    [FIRSTNAME]: FIRSTNAME_VALIDATION,
    [LASTNAME]: LASTNAME_VALIDATION,
    [EMAIL]: EMAIL_VALIDATION,
})

export const AGENCY_INFO_VALIDATION_FORM = yup.object({
    [FIRSTNAME]: FIRSTNAME_VALIDATION,
    [LASTNAME]: LASTNAME_VALIDATION,
    [BUSINESSNAME]: BUSINESSNAME_VALIDATION,
    [EMAIL]: EMAIL_VALIDATION,
})
