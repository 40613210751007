import { SxProps } from '@mui/material'

const container: SxProps = {
    position: 'absolute',
    top: 46,
    right: 0,
    zIndex: 1,
}

export default {
    container,
}
