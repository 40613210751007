import { useMemo } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { useFormikContext } from 'formik'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import {
    UI_OVERLAY_EXPIRE_ENABLED,
    UI_OVERLAY_EXPIRE_DATE_EXPIRE,
    UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT,
    UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT,
    UI_OVERLAY_EXPIRE_LINK_HREF,
    UI_OVERLAY_EXPIRE_COLOR_FOREGROUND,
    UI_OVERLAY_EXPIRE_COLOR_BACKGROUND,
    UI_OVERLAY_EXPIRE_LINK_BLANK,
} from 'design/pages/VidSettings/constants'
import { Link } from 'design/atoms/Link'
import { SwitchForm } from 'design/atoms/Switch'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { ExpireVideoSectionFormData } from 'types/VidSettings'
import { Input, ColorPicker } from 'design/atoms/Form'
import CheckboxForm from 'design/atoms/Checkbox/CheckboxForm/CheckboxForm'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import sidebarStyle from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { EXAMPLE_URL_PLACEHOLDER } from 'design/organisms/VidSettingsSidebar/settings.contants'
import { route } from 'constants/routes'
import style from './ExpireVideoContent.style'

import './index.scss'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const ExpireVideoContent = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        formik: { values, errors },
        onChange,
        isLoading,
    } = useSectionContext<ExpireVideoSectionFormData>()
    const { submitForm } = useFormikContext()

    const replaceBrToLineBreak = (value: string) => value.replace(/<br\s*\/?>/gi, '\n')

    const handleSubmit = async () => {
        await submitForm()

        if (!Object.keys(errors).length) {
            onChange()
        }
    }
    const commonProps = { afterChange: handleSubmit, disabled: isLoading }
    const commonInputProps = {
        onBlur: handleSubmit,
        disabled: isLoading,
    }

    return (
        <Box className="ExpireVideoContent">
            <Accordion expanded={values[UI_OVERLAY_EXPIRE_ENABLED]} className="sectionSubAccordion">
                <AccordionSummary>
                    <Box className="infoContainer">
                        <Typography className="info">
                            Create more urgency for your videos by having them ‘expire’ at a specific date & time{' '}
                            <HelpTooltip
                                id="expireVideoInfo"
                                title={
                                    <>
                                        Your video container will still show up, but the content will be locked.{' '}
                                        <Link
                                            to={route.static.help.article({
                                                slug: '5821890-how-to-make-your-video-expire-after-some-time',
                                            })}
                                            target="_blank"
                                            rel="noreferrer"
                                            external
                                            sx={style.link}
                                        >
                                            Learn more here.
                                        </Link>
                                    </>
                                }
                            />
                        </Typography>
                        <SwitchForm
                            sx={sidebarStyle.container}
                            name={UI_OVERLAY_EXPIRE_ENABLED}
                            label={<Typography variant="body2">Expire on set date</Typography>}
                            labelPlacement="start"
                            switchProps={{ size: 'small' }}
                            {...commonProps}
                        />
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Input
                        name={UI_OVERLAY_EXPIRE_DATE_EXPIRE}
                        label="Expire at"
                        variant="outlined"
                        placeholder="YYYY/MM/DD hh:mm:ss"
                        inputProps={{
                            value: replaceBrToLineBreak(String(String(values[UI_OVERLAY_EXPIRE_DATE_EXPIRE]))),
                        }}
                        {...commonInputProps}
                    />
                    <Input
                        name={UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT}
                        label="Top Text"
                        variant="outlined"
                        placeholder="This Video Has Expired"
                        multiline
                        displayMaxLength
                        inputProps={{
                            maxLength: 50,
                            value: replaceBrToLineBreak(String(String(values[UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT]))),
                        }}
                        {...commonInputProps}
                    />
                    <Input
                        name={UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT}
                        label="Bottom Text"
                        variant="outlined"
                        placeholder="Click Here to Go Back"
                        multiline
                        displayMaxLength
                        inputProps={{
                            maxLength: 50,
                            value: replaceBrToLineBreak(String(String(values[UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT]))),
                        }}
                        {...commonInputProps}
                    />
                    <Input
                        name={UI_OVERLAY_EXPIRE_LINK_HREF}
                        label="Link"
                        variant="outlined"
                        placeholder={EXAMPLE_URL_PLACEHOLDER}
                        inputProps={{
                            value: replaceBrToLineBreak(String(String(values[UI_OVERLAY_EXPIRE_LINK_HREF]))),
                        }}
                        {...commonInputProps}
                    />
                    <CheckboxForm name={UI_OVERLAY_EXPIRE_LINK_BLANK} label="Open in a new tab" {...commonProps} />
                    <ColorPicker
                        name={UI_OVERLAY_EXPIRE_COLOR_FOREGROUND}
                        prefixed
                        label="Text Color"
                        {...commonProps}
                    />
                    <ColorPicker
                        name={UI_OVERLAY_EXPIRE_COLOR_BACKGROUND}
                        prefixed
                        label="Shadow Color"
                        {...commonProps}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default withErrorBoundary(ExpireVideoContent)
