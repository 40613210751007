import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { HubspotStatus } from 'types/Integrations'
import { integrationsAPI } from 'api/controllers'
import { HUBSPOT_VALIDATION_CODE_QUERY, HUBSPOT_STATUS_QUERY } from 'api/constants'
import { route } from 'constants/routes'

export const useVerifyValidationCodeQuery = (
    code?: string | null,
    options?: UseQueryOptions<HubspotStatus, unknown, HubspotStatus>,
) => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    return useQuery<HubspotStatus>(
        HUBSPOT_VALIDATION_CODE_QUERY,
        () => integrationsAPI.verifyValidationCode(String(code)),
        {
            enabled: Boolean(code),
            ...options,
            onSettled: () => {
                queryClient.resetQueries(HUBSPOT_STATUS_QUERY)
                navigate(route.integrations.hubspot, { replace: true })
            },
        },
    )
}
