import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { FolderAPI, ChangePlayerTypePayload } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEO_FOLDERS_QUERY, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'

export const useChangePlayerType = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<FolderAPI, ChangePlayerTypePayload[]>(
        VIDEOS_QUERY,
        async (payload: ChangePlayerTypePayload[]) =>
            mutate(
                Promise.all(payload.map((item) => videoAPI.changePlayerType(item))),
                apiLocale.video.changePlayerVersion,
            ),
        {
            invalidate: [VIDEO_FOLDERS_QUERY],
        },
    )
}
