import { Form, Formik, FormikHelpers } from 'formik'
import { Box } from '@mui/material'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    PLAY_GATES_SECTION_INITIAL_VALUES,
    PLAY_GATE_SWITCH_VALIDATE,
    TIME,
    PLAY_GATE_ONLY_ONE,
    BUTTON_COLOR_BACKGROUND,
    BUTTON_COLOR_FOREGROUND,
    PLAY_GATE_PHONE_SHOW,
    PLAY_GATE_EMAIL_SHOW,
    PLAY_GATE_NAME_SHOW,
    validateVideoLengthSchema,
    TEXT_TOP,
    TEXT_BOTTOM,
    PLAY_GATE_TIME_UNIQ,
} from 'design/pages/VidSettings/constants'
import { PlayGatesSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { Dispatch, SetStateAction, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useCreatePlayGateMutation, useUpdatePlayGateMutation } from 'api/mutations'
import { useVideoQuery } from 'api/queries'
import { VideoPlayGateConfig } from 'types/VideoPlayGate'
import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import { GUID } from 'constants/api.constants'
import { PlayGatesContent } from './PlayGatesContent'

interface PlayGatesSectionProps {
    formExpanded: boolean
    setFormExpanded: Dispatch<SetStateAction<boolean>>
}

const PlayGatesSection = (props: PlayGatesSectionProps) => {
    return (
        <SettingsSectionProvider>
            <Form className="PlayGatesForm">
                <Box className="PlayGatesSection">
                    <PlayGatesContent {...props} />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    const [formExpanded, setFormExpanded] = useState(false)
    const { videoGuid, stepVidId } = useParams()
    const vidId = videoGuid || stepVidId
    const { data: video } = useVideoQuery(vidId)

    const PlayGatesValidationSchema = yup.object({
        [TIME]: validateVideoLengthSchema(video?.video?.length || '0'),
        [PLAY_GATE_SWITCH_VALIDATE]: yup
            .boolean()
            .test('is-true', 'Please choose at least one option (Name, Phone, Email).', function () {
                return !!(
                    this.parent[PLAY_GATE_PHONE_SHOW] ||
                    this.parent[PLAY_GATE_NAME_SHOW] ||
                    this.parent[PLAY_GATE_EMAIL_SHOW]
                )
            }),
        [PLAY_GATE_ONLY_ONE]: yup
            .boolean()
            .test(
                'is-one',
                'You can only have one Exit Play Gate. Delete your previous one to create a new one',
                (value) => value === true,
            ),
        [PLAY_GATE_TIME_UNIQ]: yup
            .boolean()
            .test(
                'is-uniq',
                "There's already a Play Gate set at this time. Please choose a different time to proceed.",
                (value) => value === true,
            ),
    })

    const createPlayTagMutation = useCreatePlayGateMutation()
    const updatePlayTagMutation = useUpdatePlayGateMutation()
    const { setIntegrationsExist } = useVidSettingsContext()

    const replaceNewlinesWithBr = (inputString: string) => {
        return inputString?.replace(/\n/g, '<br>')
    }

    const onSubmit = async (values: VideoPlayGateConfig, formikHelpers: FormikHelpers<PlayGatesSectionFormData>) => {
        if (createPlayTagMutation.isLoading || updatePlayTagMutation.isLoading) return

        delete values[BUTTON_COLOR_FOREGROUND]
        delete values[BUTTON_COLOR_BACKGROUND]
        delete values[PLAY_GATE_SWITCH_VALIDATE]
        delete values[PLAY_GATE_ONLY_ONE]

        if (values[GUID]) {
            await updatePlayTagMutation.mutateAsync({
                ...values,
                textTop: replaceNewlinesWithBr(values[TEXT_TOP]),
                textBottom: replaceNewlinesWithBr(values[TEXT_BOTTOM]),
            })
        } else {
            const result = await createPlayTagMutation.mutateAsync({
                videoGuid: String(vidId),
                playGate: {
                    ...values,
                    textTop: replaceNewlinesWithBr(values[TEXT_TOP]),
                    textBottom: replaceNewlinesWithBr(values[TEXT_BOTTOM]),
                },
            })
            if (result) {
                setIntegrationsExist(false)
            }
        }

        setFormExpanded(false)
        formikHelpers.resetForm()
    }

    return (
        <Formik<PlayGatesSectionFormData>
            initialValues={PLAY_GATES_SECTION_INITIAL_VALUES}
            validationSchema={PlayGatesValidationSchema}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={onSubmit}
        >
            {() => <PlayGatesSection formExpanded={formExpanded} setFormExpanded={setFormExpanded} />}
        </Formik>
    )
}
export default withErrorBoundary(FormikWrapper)
