import { useQuery, UseQueryOptions } from 'react-query'

import { SMART_VID_EMBED_QUERY } from 'api/constants'
import { smartVidConroller } from 'api/controllers'
import { Id } from 'types'
import { VideoEmbed } from 'types/Video'

export const useSmartVidEmbedQuery = (id?: Id, options?: UseQueryOptions<VideoEmbed, unknown>) =>
    useQuery<VideoEmbed>([SMART_VID_EMBED_QUERY, id], () => smartVidConroller.smartVid.getEmdeb(String(id)), {
        enabled: Boolean(id),
        ...options,
    })
