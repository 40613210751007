export const getTotalSeconds = (length: string): number => {
    const fractionParts = length.split(':')

    let hours = 0
    let minutes: number
    let seconds: number

    if (fractionParts.length > 2) {
        hours = +fractionParts[0]
        minutes = +fractionParts[1]
        seconds = +fractionParts[2]
    } else {
        minutes = +fractionParts[0]
        seconds = +fractionParts[1]
    }

    return hours * 3600 + minutes * 60 + seconds
}
