import { ReactNode, useMemo } from 'react'
import { Field, FieldAttributes, FieldProps } from 'formik'
import {
    Box,
    MenuItem,
    Typography,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    SelectChangeEvent,
    Stack,
    SxProps,
    Theme,
    MenuItemProps,
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import { getSx } from 'styles/theme/utils'
import style from './Select.style'

export interface SelectOption {
    label: string | number
    value: MenuItemProps['value']
    img?: string
    disabled?: boolean
}

export interface SelectProps {
    afterChange?: (name: string, value: string) => void
    className?: string
    errorMessage?: string
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    fieldAttributes?: FieldAttributes<any>
    label?: string | ReactNode
    name: string
    options: SelectOption[]
    renderFooter?: ReactNode
    withImage?: boolean
    placeholderProps?: SxProps<Theme>
    wrapperSx?: SxProps<Theme>
    selectProps?: MuiSelectProps
}

export const Select = ({
    name,
    options,
    errorMessage,
    fieldAttributes,
    afterChange,
    label,
    className,
    renderFooter,
    withImage = false,
    selectProps,
    wrapperSx,
    placeholderProps,
}: SelectProps) => {
    const renderOptions = useMemo(
        () =>
            options.map(({ label, value, img, disabled }) => (
                <MenuItem key={label} value={value} disabled={disabled}>
                    {withImage && (
                        <Stack flexDirection="row" gap={2}>
                            {img && <ImgWithErrorHandler src={img} alt={label as string} sx={style.img.main} />}
                            <Typography noWrap ml={!img ? '50px' : 0}>
                                {label}
                            </Typography>
                        </Stack>
                    )}
                    {!withImage && <Typography noWrap>{label}</Typography>}
                </MenuItem>
            )),
        [options],
    )

    const labelId = useMemo(() => `select-label__${label}`, [label])
    return (
        <Box className={className} sx={getSx(selectProps?.fullWidth ? { width: 1 } : {}, wrapperSx)}>
            <Field name={name} {...fieldAttributes}>
                {({ field, form: { setFieldValue } }: FieldProps) => (
                    <Stack sx={{ position: 'relative' }}>
                        {label && <InputLabel id={labelId}>{label}</InputLabel>}
                        {withImage && <Box sx={[style.img.placeholder, ...getSx(placeholderProps ?? {})]} />}
                        <FormControl disabled={selectProps?.disabled} error={selectProps?.error || !!errorMessage}>
                            <MuiSelect
                                {...field}
                                {...selectProps}
                                onChange={(e: SelectChangeEvent<HTMLInputElement>) => {
                                    setFieldValue(name, e.target.value)

                                    if (afterChange) {
                                        afterChange(name, e.target.value as string)
                                        return
                                    }
                                }}
                            >
                                {renderOptions}
                                {renderFooter}
                            </MuiSelect>
                            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
                        </FormControl>
                    </Stack>
                )}
            </Field>
        </Box>
    )
}
