import { createTestId } from 'utils/create-test-id.utils'

export const viewbox = {
    logoAndSign: '0 0 160 32',
    logoOnly: '0 0 32 32',
    signOnly: '36 0 128 32',
}

export const testId = {
    container: createTestId('logo__container'),
    logo: createTestId('logo'),
    sign: createTestId('sign'),
} as const
