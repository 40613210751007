// <--- New SV constants

// interaction
export const ID = 'id'
export const HEADLINE = 'headline'
export const ENABLE_BACKGROUND = 'enableBackground'
export const DATA = 'data'
export const VIDEO_GUID = 'videoGuid'

export const INTERACTION_NAME = 'interactionName'
export const COUNT_DOWN_TIMER = 'countDownTimer'
export const TIME_DURATION = 'timeDuration'

// display time
export const DISPLAY_TIME = 'displayTime'
export const MODE = 'mode'
export const TIME_FROM = 'timeFrom'
export const TIME_TO = 'timeTo'
export const TIME_OFFSET = 'timeOffset'
export const RESUME_IN_SECONDS = 'resumeInSeconds'
export const PAUSE_PLAYBACK = 'pausePlayback'
export const SHOW_TO_RETURNING_VIEWERS = 'showToReturningViewers'

// question style
export const QUESTION_STYLE = 'questionStyle'
export const POSITION = 'position'
export const BACKGROUND_COLOR = 'backgroundColor'
export const BACKGROUND_OPACITY = 'backgroundOpacity'
export const FORM_BACKGROUND_RGBA = 'formBackgroundRgba'
export const TEXT_SHADOW = 'textShadow'

// button colors and effects
export const BUTTON_COLORS_AND_EFFECTS = 'buttonColorsAndEffects'
export const BORDER_RADIUS = 'borderRadius'
export const BUTTON_COLOR = 'buttonColor'
export const HOVER_OVER = 'hoverOver'
export const TEXT_COLOR = 'textColor'
export const TEXT_HOVER = 'textHover'
export const SHADOW = 'shadow'

// default action
export const IS_ACTIVE = 'isActive'
export const TYPE = 'type'
export const DELAY = 'delay'
export const OPEN_IN_NEW_TAB = 'openInNewTab'
export const REDIRECT_MESSAGE = 'redirectMessage'
export const REDIRECT_LINK = 'redirectLink'

export enum DefaultAction {
    openLink = 'openLink',
    openVideo = 'openVideo',
    replay = 'replay',
    displayThumbnail = 'displayThumbnail',
    none = 'none',
}

// choice
export const CHOICES = 'choices'
export const NAME = 'name'
export const LABEL = 'label'
export const ACTION = 'action'

export const SPOTLIGHT_MODE = 'SpotlightMode'
export const VIDEO = 'video'
export const SCHEMA = 'schema'
export const GUID = 'guid'
export const TITLE = 'title'
export const PREVIEW_SRC = 'previewSrc'
export const DASHBOARD_SRC = 'dashboardSrc'
export const LINK = 'link'

// jump back options
export const GO_BACK = 'goBack'
export const START_OVER = 'startOver'
export const SHOW = 'show'
export const TEXT = 'text'
export const COLOR = 'color'

// clickable areas
export const WIDTH = 'width'
export const HEIGHT = 'height'

export const POSITION_X = 'positionX'
export const POSITION_Y = 'positionY'

// display mode
export const DISPLAY_MODE = 'displayMode'
export const TIME_BEFORE = 'timeBefore'

// ---> New SV constants

export const MAX_OPTIONS_LENGTH = 4

// top level

export const BRANCH_TYPE = 'branchType'
export const INHERIT_STYLES = 'inheritStyles'
export const OPTIONS = 'options'

// choice
export const CHOICE_TEXT = 'choiceText'
export const AREA_BUTTON_COLOR = 'areaButtonColor'
export const AREA_HOVER_OVER = 'areaHoverOver'

// next video
export const DEFAULT_NEXT_VIDEO = 'defaultNextBranch'
export const TIME_DELAY = 'timeDelay'
export const TIME_DELAY_TYPE = 'timeDelayType'
export const TIMER_TYPE = 'timerType'
export const DEFAULT_ACTION_ENABLED = 'defaultActionEnabled'
export const DEFAULT_ACTION = 'defaultAction'

export enum TimeDelayType {
    IMMEDIATELY = 'immediately',
    SECONDS = 'seconds',
}

export const HTML_TAGS_REGEX = /<[^>]*>/g
export const HTML_TAGS_VALIDATION_MESSAGE = 'HTML tags are not allowed'
