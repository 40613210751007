import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { CLOSED_CAPTIONS_SECTION_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { ClosedCaptionsSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { VideoDetails } from 'types/Video'
import { useVideoQuery } from 'api/queries'
import ClosedCaptionsAdvancedContent from 'design/organisms/VidSettingsSidebar/StyleSettings/ClosedCaptionsSection/ClosedCaptionsAdvancedContent'
import { ClosedCaptionsContent } from './ClosedCaptionsContent/ClosedCaptionsContent'

const ClosedCaptionsSection = ({ resetForm }: FormikProps<ClosedCaptionsSectionFormData>) => {
    const { videoGuid } = useParams()
    const { captionsImprovements } = useFeatureFlags()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid, {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <Form>{captionsImprovements ? <ClosedCaptionsAdvancedContent /> : <ClosedCaptionsContent />}</Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    return (
        <Formik<ClosedCaptionsSectionFormData>
            initialValues={CLOSED_CAPTIONS_SECTION_INITIAL_VALUES}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <ClosedCaptionsSection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
