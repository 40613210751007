import _get from 'lodash/get'
import { useEffect } from 'react'
import { HexColorInput, RgbaColor } from 'react-colorful'

import { hexToRgb, rgbToHex } from 'utils/colors'
import { Color, ColorPickerProps } from '../ColorPicker'
import { useColorPickerContext } from '../ColorPicker.context'
import { Opacity } from './Opacity'

export interface InputsProps extends Pick<ColorPickerProps, 'name' | 'opacityMode' | 'allowEmpty' | 'prefixed'> {
    disabled?: boolean
    color?: Color
    handleChange: (value: Color) => void
}

export const Inputs = ({ name, color, allowEmpty, prefixed, opacityMode, disabled, handleChange }: InputsProps) => {
    const hexColor = opacityMode ? rgbToHex(color as RgbaColor) : (color as string)
    const { localColor, setLocalColor } = useColorPickerContext()

    useEffect(() => {
        setLocalColor(hexColor)
    }, [hexColor])

    const setColor = (c: string) => {
        handleChange(opacityMode ? { ...hexToRgb(c), a: _get(color, 'a') || 0 } : c)
    }

    return (
        <>
            <HexColorInput
                id={name}
                prefixed={prefixed}
                color={localColor}
                disabled={disabled}
                onChange={setLocalColor}
                onBlur={() => setColor(localColor)}
                onChangeCapture={(e) => {
                    if (!allowEmpty) {
                        return
                    }

                    const value = (e.target as HTMLInputElement).value

                    if (value === '#' || !value) {
                        setColor('')
                    }
                }}
            />
            {opacityMode && <Opacity name={name} color={color} disabled={disabled} handleChange={handleChange} />}
        </>
    )
}
