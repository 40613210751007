import { useQueryClient, UseMutationOptions } from 'react-query'

import { Conversion, UpdateConversionPayload } from 'types/Conversion'
import { conversionAPI } from 'api/controllers'
import { CONVERSIONS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const useUpdateConversionMutation = (options?: UseMutationOptions<Conversion, any, UpdateConversionPayload>) => {
    const queryClient = useQueryClient()
    const key = CONVERSIONS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Conversion, UpdateConversionPayload>(
        key,
        async (payload: UpdateConversionPayload) =>
            mutate(conversionAPI.updateConversion(payload), apiLocale.conversion.update),
        {
            ...options,
            onMutate: async (...args) => {
                await queryClient.cancelQueries(key)

                const [{ conversion: data }] = args
                const previousData = queryClient.getQueryData(key) as Conversion[]

                if (previousData) {
                    queryClient.setQueryData(
                        key,
                        previousData.map((c) => (c.guid === data.guid ? data : c)),
                    )
                }

                options?.onMutate?.(...args)

                return previousData
            },
            // @ts-ignore
            onError: async (err, variables, previousValue) => queryClient.setQueryData(key, previousValue),
            invalidateOn: InvalidateOn.SETTLED,
        },
    )
}
