import { VideoSettings } from 'types/Video'

export enum DisplayTimeMode {
    atTime = 'atTime',
    beforeTheEnd = 'beforeTheEnd',
    atTheEnd = 'atTheEnd',
    onExit = 'onExit',
}

export enum OnClickAction {
    openVideo = 'openVideo',
}

export enum InteractionType {
    multiChoiceButton = 'multiChoiceButton',
    multiChoiceQuestion = 'multiChoiceQuestion',
    multiChoiceClickableArea = 'multiChoiceClickableArea',
}

export enum AligningPosition {
    top = 'top',
    center = 'center',
    bottom = 'bottom',
}

export enum SmartVidVersion {
    v1 = 'v1',
    v2_0 = 'v2.0',
}

export interface SmartVidGlobalSettings {
    videoGuid: string
    headline: string
    globalSettings: VideoSettings
}
