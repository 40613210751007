import { settingsAPI } from 'api/controllers'
import { VideoDetails } from 'types/Video'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { PUBLISH_SETTINGS_MUTATION, VIDEOS_QUERY, CLOSED_CAPTIONS_DRAFT, CLOSED_CAPTIONS_TEXT } from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'

export const usePublishSettingsMutation = (options?: Options<VideoDetails, string, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoDetails, string, string>(
        PUBLISH_SETTINGS_MUTATION,
        async (videoGuid: string) => {
            return mutate(settingsAPI.publishSettings(videoGuid), apiLocale.settings.settings.publish)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, CLOSED_CAPTIONS_DRAFT, CLOSED_CAPTIONS_TEXT],
        },
    )
}
