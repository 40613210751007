import { settingsAPI } from 'api/controllers'
import { VideoDetails } from 'types/Video'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DELETE_PLAY_GATE_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { VideoPlayGateConfig } from 'types/VideoPlayGate'

export const useDeletePlayGateMutation = (options?: Options<VideoDetails, VideoPlayGateConfig, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoDetails, VideoPlayGateConfig, string>(
        DELETE_PLAY_GATE_MUTATION,
        async (videoPlayGate: VideoPlayGateConfig) => {
            return mutate(settingsAPI.deletePlayGate(videoPlayGate), apiLocale.settings.playGate.delete)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
