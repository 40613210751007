import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_SWITCHABLE_METRICS_GRAPHS } from 'api/constants'
import {
    GetSwitchableMetricsQuery,
    GetSwitchableMetricsResponse,
} from 'api/contracts/dashboard/endpoints/getSwitchableMetrics.contract'

export const useGetSwitchableMetricsQuery = (query: GetSwitchableMetricsQuery) => {
    const getSwitchableMetricsCardQuery = useQuery<GetSwitchableMetricsResponse>(
        [DASHBOARD_GET_SWITCHABLE_METRICS_GRAPHS, query.dateFrom, query.dateTo, query.type],
        () => dashboardController.getSwitchableMetricsGraphs(query),
    )

    return {
        switchableMetrics: getSwitchableMetricsCardQuery.data,
        getSwitchableMetricsQuery: getSwitchableMetricsCardQuery,
    }
}
