import { useQuery } from 'react-query'

import { ConversionEmbedCode } from 'types/Conversion'
import { conversionAPI } from 'api/controllers'
import { Id } from 'types'
import { CONVERSION_EMBED_QUERY } from 'api/constants'

export const useConversionEmbedQuery = (guid: Id) =>
    useQuery<ConversionEmbedCode>([CONVERSION_EMBED_QUERY, guid], () => conversionAPI.getConversionEmbedCode(guid), {
        enabled: !!guid,
    })
