import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { ColorPicker, Input } from 'design/atoms/Form'
import { PlayOptionsFormData } from 'types/VidSettings'
import { PlayerOnPlaybackEndType } from 'types/Player'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import {
    PLAYBACK_ON_END,
    PLAYBACK_RESUME,
    UI_OVERLAY_REDIRECT_URL,
    UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT,
    UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT,
    UI_OVERLAY_RESUME_COLOR_BACKGROUND,
    UI_OVERLAY_RESUME_COLOR_FOREGROUND,
    UI_OVERLAY_RESUME_SHOW,
    UI_OVERLAY_RESUME_TEXT,
} from 'design/pages/VidSettings/constants'
import { replaceBrToLineBreak } from './PlayOptionsContent.utils'
import locale from './PlayOptionsContent.locale'
import './index.scss'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

export const ResumePlayContent = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const {
        isLoading,
        formik: { values, setValues, setFieldValue },
        video,
        onChange,
    } = useSectionContext<PlayOptionsFormData>()

    const afterChangeWithLinkValueCheck = () => {
        if (values[UI_OVERLAY_REDIRECT_URL]) {
            onChange()
        } else {
            setFieldValue(PLAYBACK_ON_END, PlayerOnPlaybackEndType.PAUSE)
            onChange()
        }
    }

    const commonProps = {
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const

    return (
        <Box className="ResumePlayContent">
            <Box pr={2}>
                <SwitchForm
                    sx={style.container}
                    name={PLAYBACK_RESUME}
                    label={
                        <Typography
                            className="radioTitle radioTitle--without-mb"
                            variant="body2"
                            {...(isLoading || video?.funnel ? { color: 'text.disabled' } : {})}
                        >
                            {locale.resumePlay.title}
                        </Typography>
                    }
                    labelPlacement="start"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={
                        isLoading || video?.funnel
                            ? locale.resumePlay.titleTooltipFunnel
                            : locale.resumePlay.titleTooltipDefault
                    }
                    onChange={(name, value) => {
                        setValues({
                            ...values,
                            [name]: value,
                            [UI_OVERLAY_RESUME_SHOW]: value,
                        })
                        afterChangeWithLinkValueCheck()
                    }}
                    {...commonProps}
                />
            </Box>
            <Box className="wrapper">
                <SwitchForm
                    sx={style.container}
                    name={UI_OVERLAY_RESUME_SHOW}
                    label={<Typography variant="body2">{locale.resumePlay.resumePlayMessage.label}</Typography>}
                    labelPlacement="start"
                    labelClassName="resumePlayMessage"
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    afterChange={afterChangeWithLinkValueCheck}
                    helpInfo={locale.resumePlay.resumePlayMessage.tooltip}
                    {...commonProps}
                />

                <Box sx={{ mb: 3 }}>
                    <Input
                        name={UI_OVERLAY_RESUME_TEXT}
                        label={locale.resumePlay.headline}
                        inputProps={{
                            maxLength: 60,
                            value: replaceBrToLineBreak(values[UI_OVERLAY_RESUME_TEXT]),
                        }}
                        variant="outlined"
                        displayMaxLength
                        onBlur={afterChangeWithLinkValueCheck}
                        {...commonProps}
                        rows={3}
                        multiline
                    />
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Input
                        name={UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT}
                        label={locale.resumePlay.resumeText}
                        inputProps={{
                            maxLength: 50,
                            value: replaceBrToLineBreak(values[UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT]),
                        }}
                        onBlur={afterChangeWithLinkValueCheck}
                        variant="outlined"
                        rows={2}
                        multiline
                        displayMaxLength
                        {...commonProps}
                    />
                </Box>
                <Box sx={{ mb: 3 }}>
                    <Input
                        name={UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT}
                        label={locale.resumePlay.replayText}
                        inputProps={{
                            maxLength: 50,
                            value: replaceBrToLineBreak(values[UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT]),
                        }}
                        variant="outlined"
                        rows={2}
                        multiline
                        onBlur={afterChangeWithLinkValueCheck}
                        displayMaxLength
                        {...commonProps}
                    />
                </Box>
                <Box className="resumeColors">
                    <ColorPicker
                        name={UI_OVERLAY_RESUME_COLOR_FOREGROUND}
                        afterChange={afterChangeWithLinkValueCheck}
                        label={locale.resumePlay.textColor}
                        prefixed
                        {...commonProps}
                    />
                    <ColorPicker
                        name={UI_OVERLAY_RESUME_COLOR_BACKGROUND}
                        label={locale.resumePlay.shadowColor}
                        afterChange={afterChangeWithLinkValueCheck}
                        prefixed
                        {...commonProps}
                    />
                </Box>
            </Box>
        </Box>
    )
}
