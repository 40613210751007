import { InputAdornment } from '@mui/material'
import _get from 'lodash/get'
import { useEffect, useMemo } from 'react'
import { RgbaColor } from 'react-colorful'

import { Input } from 'design/atoms/Form'
import { useColorPickerContext } from 'design/atoms/Form/ColorPicker/ColorPicker.context'
import { InputChangeHandler } from 'types'
import { InputsProps } from '../Inputs'
import style from './Opacity.style'

type OpacityProps = Pick<InputsProps, 'name' | 'color' | 'handleChange'> & {
    disabled?: boolean
}

// react colorful supports only 0-1 range for alpha
// but we need 0 - 100 for percentage input
export const PERCENTAGE_ADAPTER = 100

export const Opacity = ({ name, color, disabled, handleChange }: OpacityProps) => {
    const opacity = useMemo(() => {
        const alpha = _get(color, 'a') || 0 // react colorful 0-1 range
        const adaptedOpacity = Math.round(alpha * PERCENTAGE_ADAPTER)

        return String(adaptedOpacity)
    }, [color])

    const { localOpacity, setLocalOpacity } = useColorPickerContext()

    useEffect(() => {
        setLocalOpacity(opacity)
    }, [opacity])

    const handleOpacityChange: InputChangeHandler = (e) => {
        let opacity = Number(e.target.value)

        if (isNaN(opacity)) {
            return
        }

        if (opacity > 100) {
            opacity = 100
        } else if (opacity < 0) {
            opacity = 0
        }

        setLocalOpacity(String(opacity))
    }

    const handleBlur = () => {
        handleChange({ ...(color as RgbaColor), a: Number(localOpacity) / PERCENTAGE_ADAPTER })
    }

    return (
        <Input
            name={name}
            sx={style.opacity}
            value={localOpacity}
            InputProps={{
                inputProps: {
                    type: 'number',
                    step: '0.01',
                },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            disabled={disabled}
            onChange={handleOpacityChange}
            onBlur={handleBlur}
        />
    )
}
