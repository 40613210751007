import Card from '@mui/material/Card'
import { Box, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded'
import { PaletteOptions } from '@mui/material/styles'
import { AccountMetric, AccountMetricInfo } from 'api/contracts/agency/entities/common'
import style from './MetricCard.style'
import { testIdFactory } from './MetricCard.utils'
import { meta } from './MetricCard.constants'

export type MetricCardProps = { metric: AccountMetric } & Partial<AccountMetricInfo>

export type TrendColor = Extract<keyof PaletteOptions, 'error' | 'info' | 'success'>

/**
 * Component displays metric information in a user-friendly manner.
 * The key features of this component include a label, a numeric value, and a progress indicator.
 * These features are designed with interactive color schemes to enhance the visual experience of the users.
 */
export const MetricCard = ({ metric, value, trend }: MetricCardProps) => {
    const { label, postfix, prefix } = meta[metric]

    const trendColor: TrendColor = useMemo(() => {
        if (trend === undefined) return 'info'

        if (trend < 0) return 'error'
        if (trend === 0) return 'info'
        if (trend > 0) return 'success'

        return 'info'
    }, [trend])

    const testId = useMemo(() => testIdFactory(label), [metric])

    const progress = useMemo(() => {
        if (trend !== 0 && !trend) return null

        return (
            <Stack direction="row" gap={1} alignItems="center" data-testid={testId.trend.container}>
                <Box sx={style.trend.iconContainer} data-testid={testId.trend.icon.container}>
                    <ArrowUpwardRoundedIcon
                        fontSize="inherit"
                        sx={style.trend.icon(trendColor)}
                        data-testid={testId.trend.icon.svg}
                    />
                </Box>
                <Typography color={`${trendColor}.main`} fontWeight="bold" data-testid={testId.trend.value}>
                    {trend > 0 && '+'}
                    {Math.round(trend)}%
                </Typography>
            </Stack>
        )
    }, [trend, trendColor])

    return (
        <Card variant="outlined" sx={style.container} data-testid={testId.card}>
            <>
                <Typography variant="body2" color="text.secondary" data-testid={testId.label}>
                    {label}
                </Typography>
                {trend === undefined || !value ? (
                    <Typography variant="h6" fontWeight="bold" flexGrow={1}>
                        -
                    </Typography>
                ) : (
                    <Typography variant="h6" fontWeight="bold" data-testid={testId.value}>
                        {`${prefix}${value
                            .toLocaleString('en-US', {
                                useGrouping: true,
                                maximumFractionDigits: 2,
                            })
                            .replace(/,/g, ' ')}${postfix}`}
                    </Typography>
                )}
                {progress}
            </>
        </Card>
    )
}
