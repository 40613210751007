import { useMemo } from 'react'

import { CustomerSubscriptionPlanTier } from 'types/Customer'
import { usePlansQuery } from 'api/queries'

const useTariffPlans = () => {
    const { data: plans } = usePlansQuery()

    const pro = useMemo(
        () =>
            plans?.find(
                (p) => p.tier === CustomerSubscriptionPlanTier.pro || p.tier === CustomerSubscriptionPlanTier.proIv,
            ),
        [plans],
    )

    const enterprise = useMemo(() => plans?.find((p) => p.tier === CustomerSubscriptionPlanTier.enterprise), [plans])

    return { pro, enterprise }
}

export default useTariffPlans
