import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Typography, IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'
import CloseIcon from '@mui/icons-material/Close'
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare'

import { useLayout } from 'hooks/utilities/useLayout'
import { route } from 'constants/routes'
import locale from './TemporaryMobileBanner.locale'
import style from './TemporaryMobileBanner.style'
import { AFTER_LOGIN, BANNER_WAS_SHOWN } from './TemporaryMobileBanner.constants'

export const TemporaryMobileBanner = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const { isMobile } = useLayout()

    const isAfterLogin = search.includes(AFTER_LOGIN)
    const wasBannerShown = localStorage.getItem(BANNER_WAS_SHOWN) === 'true'
    const [isBannerOpen, setIsBannerOpen] = useState(!wasBannerShown && isAfterLogin && isMobile)

    const closeBanner = useCallback(() => {
        setIsBannerOpen(false)
        localStorage.setItem(BANNER_WAS_SHOWN, 'true')
    }, [])

    const handleShare = useCallback(async () => {
        try {
            const url = `${window.location.origin}${route.auth.signIn}`

            await navigator.share({ url })
        } catch (error) {
            console.error('There is some error during sharing link: ', error)
        }

        closeBanner()
    }, [])

    useEffect(() => {
        if (isAfterLogin) {
            navigate({ search: search.replace(AFTER_LOGIN, '') }, { replace: true })
        }
    }, [])

    if (!isBannerOpen) {
        return null
    }

    return (
        <Box sx={style.container}>
            <Box sx={style.backdrop} onClick={closeBanner} />
            <Box sx={style.wrapper}>
                <IconButton sx={style.close} onClick={closeBanner}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" sx={style.title}>
                    {locale.title}
                </Typography>
                <Typography variant="body2" sx={style.subtitle}>
                    {locale.subtitle}
                </Typography>
                <Button variant="contained" startIcon={<ShareIcon />} fullWidth sx={style.button} onClick={handleShare}>
                    {locale.shareLink}
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<MobileScreenShareIcon />}
                    fullWidth
                    color="tertiary"
                    onClick={closeBanner}
                >
                    {locale.continueMobile}
                </Button>
            </Box>
        </Box>
    )
}
