import { QueryClient } from 'react-query'

const normalPollingInterval = 600000

// Create a client
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: normalPollingInterval,
            staleTime: normalPollingInterval,
            retry: false,
            refetchOnWindowFocus: false,
        },
    },
})
