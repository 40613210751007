import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_DISTRIBUTION_METRICS } from 'api/constants'
import {
    DISTRIBUTION_TYPE,
    GetDistributionMetricsQuery,
    GetDistributionMetricsResponse,
} from 'api/contracts/dashboard/endpoints/getDistributionMetrics.contract'

const trafficSourceDistributionMock: GetDistributionMetricsResponse = {
    request: {
        customerId: 24495,
        dateFrom: '2024-08-01T00:00:00Z',
        dateTo: '2024-08-03T00:00:00Z',
        type: DISTRIBUTION_TYPE.TRAFFIC_SOURCE,
    },
    data: [
        {
            header: 'United kingdom',
            value: 3240,
        },
        {
            header: 'Item 2',
            value: 3239,
        },
        {
            header: 'Item 3',
            value: 1754,
        },
        {
            header: 'Item 4',
            value: 1264,
        },
        {
            header: 'Item 5',
            value: 500,
        },
    ],
    total: 9997,
}

export const useGetDistributionMetricsQuery = (query: GetDistributionMetricsQuery) => {
    const getDistributionMetricsQuery = useQuery<GetDistributionMetricsResponse>(
        [DASHBOARD_GET_DISTRIBUTION_METRICS, query.dateFrom, query.dateTo, query.type],
        () => dashboardController.getDistributionMetrics(query),
    )

    return {
        distributionMetrics: getDistributionMetricsQuery.data,
        getDistributionMetricsQuery,
        trafficSourceDistributionMock,
    }
}
