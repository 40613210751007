import { MouseEvent, ReactElement, ReactNode } from 'react'
import { Box, ButtonProps, IconButton, Button, Typography, Stack } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro'

import { useLayout } from 'hooks/utilities/useLayout'

import style from './SelectedActions.style'
import locale from './SelectedActions.locale'

export type SelectedAction = {
    icon: ReactElement
    label: ReactNode
    sx?: ButtonProps['sx']
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void
    actionClick?: (rowSelection: GridRowId[], event: MouseEvent<HTMLButtonElement>) => void
}

type SelectedActionsProps = {
    actions?: SelectedAction[]
    rowSelectionModel: GridRowSelectionModel
    onClearRowSelectionModel: () => void
}

export const SelectedActions = ({ actions, rowSelectionModel, onClearRowSelectionModel }: SelectedActionsProps) => {
    const { isMobile } = useLayout()
    if (!rowSelectionModel.length || !actions?.length) return null

    return (
        <Box sx={style.container}>
            <Stack sx={style.textContainer}>
                <IconButton sx={style.clearButton} onClick={onClearRowSelectionModel}>
                    <ClearIcon />
                </IconButton>

                <Typography noWrap variant="body1" sx={style.selectedText}>
                    {rowSelectionModel.length} {locale.title}
                </Typography>
            </Stack>

            <Stack sx={style.buttonContainer}>
                {actions?.map((action, index) => {
                    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
                        action.actionClick?.(rowSelectionModel, event)

                        action.onClick?.(event)
                    }

                    if (isMobile) {
                        return (
                            <IconButton key={index} sx={action.sx} onClick={onClick}>
                                {action.icon}
                            </IconButton>
                        )
                    }

                    return (
                        <Button key={index} variant="text" startIcon={action.icon} sx={action.sx} onClick={onClick}>
                            {action.label}
                        </Button>
                    )
                })}
            </Stack>
        </Box>
    )
}
