import { GetConversionsRowDataResponse } from 'api/contracts/dashboard/endpoints/getConversionsRowData.contract'
import { GetTagsRowDataResponse } from 'api/contracts/dashboard/endpoints/getTagsRowData.contract'
import { GetCtaRowDataResponse } from 'api/contracts/dashboard/endpoints/getCtaRowData.contract'

/**
 * This util is used in DataTable to add mandatory id's to rows
 */
export const addIdToRows = (
    data?: GetConversionsRowDataResponse['data'] | GetTagsRowDataResponse['data'] | GetCtaRowDataResponse['data'],
) => {
    if (!data) return []

    return data.map((item, index) => ({ ...item, guid: index }))
}
