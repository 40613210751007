import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import './index.scss'

type CloseButtonProps = {
    onClose: () => void
}

const CloseButton = ({ onClose }: CloseButtonProps) => {
    return (
        <IconButton onClick={onClose} className="CloseButton">
            <CloseIcon />
        </IconButton>
    )
}

export default CloseButton
