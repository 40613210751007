import { useParams } from 'react-router-dom'

import { useSmartVidQuery, useVideoQuery } from 'api/queries'
import useCurrentEntity from 'design/pages/VidStats/hooks/useCurrentEntity'

const useSvIds = (includeRootSvVideo = false) => {
    const { smartVidId, stepVidId, videoGuid } = useParams()
    const { data: video } = useVideoQuery(videoGuid)
    const { data: smartVid } = useSmartVidQuery(smartVidId)
    const { isSmartVidEntity } = useCurrentEntity()

    const useVidGuid = isSmartVidEntity || videoGuid

    return {
        videoId: useVidGuid ? videoGuid : includeRootSvVideo ? stepVidId || smartVid?.video.guid : stepVidId,
        smartVidId: useVidGuid ? video?.funnel?.id : smartVidId,
    }
}

export default useSvIds
