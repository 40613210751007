import React from 'react'
import { Box, Typography, IconButton, Button } from '@mui/material'
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import { useDeleteTagMutation } from 'api/mutations'
import { Modal } from 'design/templates/Modal'
import { TagConfig, TagType } from 'types/VideoTag'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { capitalize } from 'lodash'

import './index.scss'

interface TagsCardProps {
    tag: TagConfig
    onEditClick: (tag: TagConfig) => void
    onDeleteClick: () => void
}

const TagsCard = ({ tag, onEditClick, onDeleteClick }: TagsCardProps) => {
    const deleteTagMutation = useDeleteTagMutation()

    const [deleteTagModal, setDeleteTagModal] = React.useState<boolean>(false)

    const chooseTagTypeLabel = () => {
        if (tag.type === TagType.callToAction) {
            return 'Call to Action'
        }
        return capitalize(tag.type)
    }

    return (
        <Box className="TagsCard Card">
            <Box className="header">
                <Typography className="title">{tag?.name.replace(/<br>/g, '\n')}</Typography>
                <Box className="btns">
                    <IconButton className="edit" onClick={() => onEditClick(tag)}>
                        <EditRoundedIcon />
                    </IconButton>
                    <IconButton
                        className="delete"
                        onClick={() => {
                            setDeleteTagModal(true)
                        }}
                    >
                        <DeleteOutlineRoundedIcon />
                    </IconButton>
                </Box>

                <Modal open={deleteTagModal} onClose={() => setDeleteTagModal(false)}>
                    <Modal.Header
                        title="Are you sure you want to delete this tag?"
                        onClose={() => setDeleteTagModal(false)}
                    />
                    <Modal.Body>
                        <Typography>This action cannot be undone.</Typography>
                    </Modal.Body>
                    <Modal.Actions>
                        <Button
                            variant="text"
                            onClick={() => setDeleteTagModal(false)}
                            data-testid="cancelDeleteTagHandler"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            data-testid="confirmDeleteTagHandler"
                            onClick={() => {
                                setDeleteTagModal(false)
                                onDeleteClick()
                                deleteTagMutation.mutate(tag)
                            }}
                        >
                            Confirm
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Box>

            {[
                { label: 'ID', value: tag?.guid },
                { label: 'Fields', value: tag?.customVariables },
                {
                    label: chooseTagTypeLabel(),
                    value: tag.type === TagType.time ? tag.time : '',
                },
            ].map((row) =>
                Array.isArray(row.value) ? (
                    <Box className="row" key={row.label}>
                        <Typography>{row.label}</Typography>
                        <Box className="rowTagsWrapper">
                            {row?.value?.map((value) => (
                                <Box key={value} className="rowTag">
                                    {value}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ) : (
                    <Box className="row" key={row.label}>
                        <Typography>{row.label}</Typography>
                        <Typography>{row.value}</Typography>
                    </Box>
                ),
            )}
        </Box>
    )
}

export default withErrorBoundary(TagsCard)
