// buttonClick
export const APPCUES_EVENTS = {
    PLANS_POPUP_PLAN_BTN_CLK: 'Clicked plan button on the plans popup',
    MAIN_NAVBAR_UPLOAD_BTN_CLK: 'Clicked on upload button on main nav bar',
    SMART_VID_PUBLISH_BTN_CLK: 'Clicked Publish button on Smart Vid page',
    SMART_VID_GET_EMBED_CODE_BTN_CLK: 'Clicked Get Embed Code button on Smart Vid page',

    // linkClick
    REQUEST_FEATURE_LNK_CLK: 'Clicked on Request a Feature link on the footer',
    BRANCH_PAGE_HELP_LNK_CLK: 'Clicked help link on branch page',

    // elementClick
    TRAINING_CENTER_VIDEO_CLICKED: 'Training center video clicked',

    // pageView
    VISITED_PAGE: 'Visited page',

    // settingsUpdate
    SUB_USER_ADDED: 'Sub user added',
    SUB_USER_REMOVED: 'Sub user removed',

    // fileUpload
    UPLOAD_INITIATED: 'upload_initiated',
    UPLOAD_FINISHED: 'upload_finished',

    VIDSETTINGS_CHANGED: 'vidsettings_changed',
    EMBED_CODE_COPIED: 'embed_code_copied',
    EMBED_LINK_COPIED: 'embed_link_copied',
    FIRST_PLAYS_RECEIVED: 'video_played',
    CONVERSION_CODE_COPIED: 'conversion_code_copied',
    VIDSTATS_OPEN: 'vidstats_open',
}

export const PLAYS_LOG_THRESHOLD = 10

export default APPCUES_EVENTS
