import { ReactNode } from 'react'
import { Skeleton } from '@mui/material'

import style from './CreditCardFormItemSkeleton.style'

type ChargifyFormItemSkeletonProps = {
    isLoading?: boolean
    children?: ReactNode
}

export const ChargifyFormItemSkeleton = ({ isLoading = false, children = null }: ChargifyFormItemSkeletonProps) => {
    if (!isLoading) {
        return <>{children}</>
    }

    return <Skeleton sx={style.skeleton} animation="wave" />
}

export default ChargifyFormItemSkeleton
