import { Stack, Typography } from '@mui/material'
import { useField } from 'formik'
import { RgbaColor } from 'react-colorful'

import useComponentVisible from 'hooks/utilities/useComponentVisible'
import { withColorPickerProvider } from './ColorPicker.context'
import style from './ColorPicker.style'
import { Inputs } from './Inputs'
import { Picker } from './Picker'
import { Preview } from './Preview'

export type Color = string | RgbaColor

export interface ColorPickerProps {
    name: string
    label?: string
    disabled?: boolean
    opacityMode?: boolean
    allowEmpty?: boolean
    prefixed?: boolean
    afterChange?: (name: string, value: Color) => void
}

export const ColorPicker = withColorPickerProvider(
    ({ name, label, disabled = false, allowEmpty, prefixed, opacityMode, afterChange }: ColorPickerProps) => {
        const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
        const [{ value }, , { setValue }] = useField({ name })

        const handleChange = (value: Color) => {
            setValue(value)
            afterChange?.(name, value)
        }

        return (
            <Stack sx={style.container}>
                {label && (
                    <Typography variant="body2" component="label" htmlFor={name}>
                        {label}
                    </Typography>
                )}

                <Stack
                    sx={[style.infoContainer, style.input.idle, disabled && style.input.disabled]}
                    onClick={() => !disabled && setIsComponentVisible(true)}
                >
                    <Preview opacityMode={opacityMode} disabled={disabled} />

                    <Inputs
                        name={name}
                        color={value}
                        allowEmpty={allowEmpty}
                        prefixed={prefixed}
                        opacityMode={opacityMode}
                        disabled={disabled}
                        handleChange={handleChange}
                    />

                    <Picker
                        ref={ref}
                        visible={isComponentVisible}
                        opacityMode={opacityMode}
                        handleChange={handleChange}
                    />
                </Stack>
            </Stack>
        )
    },
)
