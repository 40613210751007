import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_CLIENTS, AGENCY_REMOVE_CLIENT } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

export const useRemoveClientAccountMutation = () => ({
    removeClientAccount: useInvalidateMutation<undefined, AgencyClientId>(
        AGENCY_REMOVE_CLIENT,
        async (id: AgencyClientId) => agencyController.client.remove(id),
        { invalidate: [AGENCY_GET_CLIENTS] },
    ),
})
