import { SxProps } from '@mui/material'

const root: SxProps = {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 3,
}

const label: SxProps = {
    width: 100,
    flexShrink: 0,
}

const inptusWrapper: SxProps = {
    width: 1,
    gap: 3,
    flexDirection: 'row',
    alignItems: 'center',

    '& > .MuiBox-root': {
        width: 'auto',
    },
}

const input: SxProps = {
    width: 78,

    input: {
        textAlign: 'center',
    },
}

export default { root, label, input, inptusWrapper }
