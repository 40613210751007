import { SxProps, Theme } from '@mui/material'

const title: SxProps<Theme> = (theme) => ({
    mb: 8,
    fontWeight: 600,
    textAlign: 'center',
    [theme.breakpoints.up('laptop')]: {
        textAlign: 'left',
    },
})

export default {
    title,
}
