import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { MetricKeys, ColsVisibilityState, SetColVisibilityPayload } from 'design/organisms/StatsTable/types'

const initialState: ColsVisibilityState = {
    [MetricKeys.plays]: true,
    [MetricKeys.playRate]: true,
    [MetricKeys.impressions]: true,
    [MetricKeys.playsUnique]: true,
    [MetricKeys.unmuteRate]: true,
    [MetricKeys.engagement]: true,
    [MetricKeys.conversionCount]: true,
    [MetricKeys.conversionRate]: true,
    [MetricKeys.revenueAverage]: true,
    [MetricKeys.pgOptInRate]: false,
    [MetricKeys.revenue]: true,
    [MetricKeys.revenuePerViewer]: false,
    [MetricKeys.bounceRate]: false,
}

export const statsTableColumnsMenuSlice = createSlice({
    name: 'statsTableColumnsMenu',
    initialState,
    reducers: {
        setColVisibility: (state, action: PayloadAction<SetColVisibilityPayload>) => {
            const { key, value } = action.payload

            state[key] = value
        },
    },
})

export const { setColVisibility } = statsTableColumnsMenuSlice.actions

export const selectStatsTableColumnsMenuState = (state: RootState) => state.statsTableColumnsMenu

export const statsTableColumnsMenu = statsTableColumnsMenuSlice.reducer
