import { SyntheticEvent, ChangeEvent, FocusEvent } from 'react'

export type Id = string | number

export enum Actions {
    CREATE = 'create',
    EDIT = 'edit',
}

export type ClickHandler = (event: Event | SyntheticEvent) => void
export type InputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => void
export type InputFocusHandler = (event: FocusEvent<HTMLInputElement>) => void

export type ChartEntity = {
    x: string
    y: number
}

export type KeyValuePair<valueType> = {
    [key: string]: valueType
}

export enum ExportType {
    CSV = 'CSV',
    EXCEL = 'Excel',
}
