import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_PRIMARY_METRICS_CARD } from 'api/constants'
import {
    GetPrimaryMetricsCardQuery,
    GetPrimaryMetricsCardResponse,
} from 'api/contracts/dashboard/endpoints/getPrimaryMetricsCard.contract'
import { METRICS } from 'constants/metrics.constants'

const primaryMetricsMock: GetPrimaryMetricsCardResponse = {
    request: {
        customerId: 24495,
        dateFrom: '2024-08-01T00:00:00Z',
        dateTo: '2024-08-03T00:00:00Z',
    },
    data: {
        [METRICS.PLAYS]: {
            metric: 59037.0,
            delta: 22754.0,
            percent: 63,
        },
        [METRICS.PLAY_RATE]: {
            metric: 0.47,
            delta: 0.01,
            percent: 2,
        },
        [METRICS.CONVERSIONS]: {
            metric: 109.0,
            delta: 44.0,
            percent: 68,
        },
        [METRICS.REVENUE]: {
            metric: 16241.0,
            delta: 6556.0,
            percent: 68,
        },
    },
}

export const useGetPrimaryMetricsCardQuery = (query: GetPrimaryMetricsCardQuery) => {
    const getPrimaryMetricsCardQuery = useQuery<GetPrimaryMetricsCardResponse>(
        [DASHBOARD_GET_PRIMARY_METRICS_CARD, query.dateFrom, query.dateTo],
        () => dashboardController.getPrimaryMetricsCard(query),
    )

    return { primaryMetricsCard: getPrimaryMetricsCardQuery.data, getPrimaryMetricsCardQuery, primaryMetricsMock }
}
