import { useEffect, useRef } from 'react'
import { Cookies } from 'react-cookie'
import useLogout from 'hooks/user/useLogout'
import throttle from 'lodash/throttle'
import { useAppSelector } from 'App'
import { authAPI } from 'api/controllers'
import { selectUploaderState } from 'design/pages/VideoUpload/uploader.slice'
import { headers } from 'api/utils/http.utils'
import { ExtendedFile } from 'types/Video'
import { REMEMBER_ME } from 'constants/validations/user.constants'
import { ACCESS_TOKEN, REFRESH_TOKEN, COOKIES_OPTIONS } from 'constants/cookies.constants'

const REFRESH_INTERVAL = 3000000 // 50 minutes

export const useAutoSignOut = () => {
    const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)
    const cookies = new Cookies()
    const { files } = useAppSelector(selectUploaderState)

    const hasActiveFiles = files.some(
        (el: ExtendedFile) => !el.state?.errorType && !el.state?.mutationError && !el.state?.uploaded,
    )
    const logout = useLogout()
    const removeCookies = () => {
        if (hasActiveFiles) {
            resetTimeout()
        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }

            logout()
        }
    }
    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = setTimeout(removeCookies, 3600000)
    }
    const handleRefreshToken = async () => {
        try {
            cookies.remove(ACCESS_TOKEN, COOKIES_OPTIONS)
            const refreshToken = cookies.get(REFRESH_TOKEN)
            const response = await authAPI.refreshToken({
                headers: {
                    ...headers,
                    Authorization: `Bearer ${refreshToken}`,
                },
            })

            cookies.set(ACCESS_TOKEN, response.access, COOKIES_OPTIONS)
            cookies.set(REFRESH_TOKEN, response.refresh, COOKIES_OPTIONS)
        } catch (_error) {
            console.log(_error)
        }
    }
    useEffect(() => {
        const rememberMe = localStorage.getItem(REMEMBER_ME)
        if (rememberMe === 'false') {
            const events: (keyof WindowEventMap)[] = ['mousemove', 'keydown', 'click', 'scroll']

            const throttledEvent = throttle(() => {
                resetTimeout()
            }, 3000)

            events.forEach((event) => {
                window.addEventListener(event, throttledEvent)
            })
            resetTimeout()

            return () => {
                events.forEach((event) => {
                    window.removeEventListener(event, throttledEvent)
                })
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                }
            }
        }
    }, [files])

    useEffect(() => {
        if (hasActiveFiles && !intervalRef.current) {
            intervalRef.current = setInterval(handleRefreshToken, REFRESH_INTERVAL)
        } else if (!hasActiveFiles && intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [files])
}
