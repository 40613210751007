import { apiLocale } from 'api/api.locale'
import {
    DELETE_SMART_VID_STEP_MUTATION,
    SMART_VID_DETAILS_QUERY,
    SMART_VID_STEP_QUERY,
    VIDEOS_QUERY,
} from 'api/constants'
import {
    DeleteSmartVidStepRequest,
    DeleteSmartVidStepResponse,
} from 'api/contracts/smartVids/endpoints/deleteSmartVidStep.contract'
import { ApiError } from 'api/contracts/utilitary/error'
import { smartVidConroller } from 'api/controllers'
import { Options, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

export const useDeleteSmartVidStepMutation = (
    options?: Options<DeleteSmartVidStepResponse, DeleteSmartVidStepRequest, ApiError>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<DeleteSmartVidStepResponse, DeleteSmartVidStepRequest>(
        DELETE_SMART_VID_STEP_MUTATION,
        async ({ svId, vidId }) => mutate(smartVidConroller.steps.delete(svId, vidId), apiLocale.smartVids.step.delete),
        {
            invalidate: [SMART_VID_DETAILS_QUERY, SMART_VID_STEP_QUERY, VIDEOS_QUERY],
            ...options,
        },
    )
}
