import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_CONVERSIONS_ROW_DATA } from 'api/constants'
import {
    GetConversionsRowDataQuery,
    GetConversionsRowDataResponse,
} from 'api/contracts/dashboard/endpoints/getConversionsRowData.contract'
import { addIdToRows } from 'api/utils/dashboard.utils'

const conversionsRowDataMock: GetConversionsRowDataResponse = {
    request: {
        customerId: 24495,
        dateFrom: '2024-08-01T00:00:00Z',
        dateTo: '2024-08-03T00:00:00Z',
    },
    data: [
        {
            name: 'User 1',
            videoTitle: 'How to Learn TypeScript',
            conversions: 120,
            conversionRate: 25.4,
        },
        {
            name: 'User 2',
            videoTitle: 'Introduction to React',
            conversions: 150,
            conversionRate: 30.2,
        },
        {
            name: 'User 3',
            videoTitle: 'Advanced JavaScript Techniques',
            conversions: 95,
            conversionRate: 22.1,
        },
        {
            name: 'User 4',
            videoTitle: 'Understanding CSS Grid',
            conversions: 110,
            conversionRate: 28.7,
        },
        {
            name: 'User 5',
            videoTitle: 'Mastering Python',
            conversions: 130,
            conversionRate: 29.9,
        },
    ],
}

export const useGetConversionsRowDataQuery = (query: GetConversionsRowDataQuery) => {
    const getConversionsRowDataQuery = useQuery<GetConversionsRowDataResponse>(DASHBOARD_GET_CONVERSIONS_ROW_DATA, () =>
        dashboardController.getConversionsRowData(query),
    )

    return {
        conversionsRowData: addIdToRows(getConversionsRowDataQuery.data?.data),
        getConversionsRowDataQuery,
        conversionsRowDataMock,
    }
}
