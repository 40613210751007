export default {
    action: {
        addClient: 'Add new client',
        jumpInMyAccount: 'Jump Into My Account',
    },
    help: 'help',
    menu: {
        label: 'Agency',
        option: {
            accountSettings: 'Account Settings',
            userSettings: 'User Settings',
            logout: 'Logout',
        },
    },
}
