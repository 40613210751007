import SvgIcon from '@mui/material/SvgIcon'
import { Components } from '@mui/material'
import { Theme } from '@mui/material/styles'

export const MuiRadio: Components<Omit<Theme, 'components'>>['MuiRadio'] = {
    defaultProps: {
        checkedIcon: (
            <SvgIcon viewBox="0 0 18 18">
                <path d="M8.49996 5.66666C6.41663 5.66666 5.16663 7.33332 5.16663 8.99999C5.16663 10.6667 6.52516 12.3333 8.49996 12.3333C10.4748 12.3333 11.8333 10.6667 11.8333 8.99999C11.8333 7.33332 10.4987 5.66666 8.49996 5.66666ZM8.49996 0.666656C3.89996 0.666656 0.166626 4.39999 0.166626 8.99999C0.166626 13.6 3.89996 17.3333 8.49996 17.3333C13.1 17.3333 16.8333 13.6 16.8333 8.99999C16.8333 4.39999 13.1 0.666656 8.49996 0.666656ZM8.49996 16.5C4.81663 16.5 0.999959 13.5833 0.999959 8.99999C0.999959 4.41666 4.81663 1.49999 8.49996 1.49999C12.1833 1.49999 16 4.41666 16 8.99999C16 13.5833 12.1833 16.5 8.49996 16.5Z" />
            </SvgIcon>
        ),
        icon: (
            <SvgIcon viewBox="0 0 18 18">
                <path d="M8.49996 0.666672C3.89996 0.666672 0.166626 4.40001 0.166626 9.00001C0.166626 13.6 3.89996 17.3333 8.49996 17.3333C13.1 17.3333 16.8333 13.6 16.8333 9.00001C16.8333 4.40001 13.1 0.666672 8.49996 0.666672ZM8.49996 16.5C4.81663 16.5 0.999959 13.5833 0.999959 9.00001C0.999959 4.41667 4.81663 1.50001 8.49996 1.50001C12.1833 1.50001 16 4.41667 16 9.00001C16 13.5833 12.1833 16.5 8.49996 16.5Z" />
            </SvgIcon>
        ),
    },

    styleOverrides: {
        root: ({ ownerState }) => ({
            padding: '12px',

            ...(ownerState.size === 'small' && {
                svg: {
                    fontSize: 16,
                },
            }),

            ...(ownerState.size === 'medium' && {
                svg: {
                    fontSize: 20,
                },
            }),

            ...(ownerState.size === 'large' && {
                svg: {
                    fontSize: 24,
                },
            }),
        }),
    },
}
