import { useQuery, UseQueryOptions } from 'react-query'

import { RecentlyPlayedStats } from 'design/pages/VidStats/types'
import { statsAPI } from 'api/controllers'
import { MOST_RECENT_PLAYED_QUERY } from 'api/constants'

export const useMostRecentPlayedQuery = <Prepared>(
    options?: UseQueryOptions<RecentlyPlayedStats[], unknown, Prepared>,
) => {
    return useQuery<RecentlyPlayedStats[], unknown, Prepared>(
        MOST_RECENT_PLAYED_QUERY,
        () => statsAPI.getMostRecentlyPlayed(),
        options,
    )
}
