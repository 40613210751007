import { settingsAPI } from 'api/controllers'
import { VideoDetails } from 'types/Video'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { LOAD_SETTINGS_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'

interface Payload {
    videoGuid: string
    fromGuid: string
}

export const useLoadSettingsMutation = (options?: Options<VideoDetails, Payload, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoDetails, Payload, string>(
        LOAD_SETTINGS_MUTATION,
        async ({ videoGuid, fromGuid }: Payload) => {
            return mutate(settingsAPI.loadSettings(videoGuid, fromGuid), apiLocale.settings.settings.loading)
        },
        { ...options, invalidate: [VIDEOS_QUERY] },
    )
}
