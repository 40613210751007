import useUploadingWarning from 'hooks/system/useUploadingWarning'
import { SubscriptionModal, UpsellBannerModal } from 'design/templates/Modal'
import { useAutoSignOut } from 'hooks/navigation/useAutoSignOut'
import { TemporaryMobileBanner } from 'design/templates/Layout/TemporaryMobileBanner'
import RoutesProtectedLazy from 'App/Routes/RoutesProtectedLazy'

import './index.scss'

const Layout = () => {
    useUploadingWarning()
    useAutoSignOut()

    return (
        <>
            <TemporaryMobileBanner />
            <RoutesProtectedLazy />
            <SubscriptionModal />
            <UpsellBannerModal />
        </>
    )
}
export default Layout
