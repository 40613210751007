import { SxProps } from '@mui/material'

const skeleton: SxProps = {
    mt: 2,
    mb: 8,
    minHeight: 80,
    width: 1,
}

export default {
    skeleton,
}
