import { settingsAPI } from 'api/controllers'
import { CallToActionConfig, VideoDetails } from 'types/Video'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DELETE_CTA_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'

export const useDeleteCTAMutation = (options?: Options<VideoDetails, CallToActionConfig, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoDetails, CallToActionConfig, string>(
        DELETE_CTA_MUTATION,
        async (callToAction: CallToActionConfig) => {
            return mutate(settingsAPI.deleteCTA(callToAction), apiLocale.settings.cta.delete)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
