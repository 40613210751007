import { SxProps, Theme } from '@mui/material'

const root: SxProps<Theme> = {
    display: 'flex',
    minHeight: 205,
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 4,
    py: 9,
}

const cancelBtn: SxProps<Theme> = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'text.disabled',
    mt: 4,
}

export default {
    root,
    cancelBtn,
}
