import { useState, useRef, useEffect, Dispatch, SetStateAction, ReactNode, SyntheticEvent, KeyboardEvent } from 'react'
import { Property } from 'csstype'

import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper, { PopperPlacementType } from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined'

import { ClickHandler } from 'types'

import './index.scss'

export type MenuOptionHandler = (setOpen: Dispatch<SetStateAction<boolean>>) => ClickHandler

type MenuOption = {
    label: ReactNode
    onClick: MenuOptionHandler
    disabled?: boolean
}

type MenuProps = {
    id?: string
    label: ReactNode
    transformOrigin?: Property.TransformOrigin<string | number>
    placement?: PopperPlacementType
    options: MenuOption[]
}

export default function Menu({
    id,
    label,
    options,
    transformOrigin = 'left top',
    placement = 'bottom-start',
}: MenuProps) {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const handleToggle = () => {
        setOpen((prev) => !prev)
    }

    const handleClose = (event: Event | SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        } else if (event.key === 'Escape') {
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <div className="Menu">
            <Button
                ref={anchorRef}
                id={id}
                className="ariaButton"
                aria-controls={open ? `aria-${id}` : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                data-testid={`${id}-menu`}
                variant="outlined"
                endIcon={<ArrowForwardIosOutlinedIcon />}
            >
                {label}
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement={placement}
                transition
                disablePortal
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin,
                        }}
                    >
                        <Paper className="paper">
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id={`aria-${id}`}
                                    aria-labelledby={id}
                                    onKeyDown={handleListKeyDown}
                                >
                                    {options.map((option, i) => (
                                        <MenuItem
                                            key={`${option.label}-${i}`}
                                            onClick={option.onClick(setOpen)}
                                            disabled={option.disabled}
                                            data-testid={`${id}-${option.label}`}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
