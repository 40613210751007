import { SxProps, Theme } from '@mui/material'

const dotAfterDetails: SxProps<Theme> = {
    'span:not(:last-child)::after': {
        content: "''",
        display: 'inline-block',
        position: 'relative',
        width: 3,
        height: 3,
        backgroundColor: (theme) => theme.palette.text.primary,
        borderRadius: '50%',
        top: '-3px',
        mx: 2,
    },
}

export default {
    dotAfterDetails,
}
