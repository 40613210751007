import { TableState } from 'react-table'
import SearchField from 'design/atoms/Table/Toolbar/SearchField'
import { Stack } from '@mui/material'
import HelperButton, { HelperButtonType } from './HelperButton'
import SortBy, { SortByType, SortFn } from './SortBy'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type ToolbarProps = {
    button?: HelperButtonType
    searchByIds?: string[]
    searchByLabel?: string
    setAllFilters?: any
    sortBy?: SortByType
    state: TableState<object>
    toggleSortBy: SortFn
}

export default function Toolbar({
    button,
    searchByIds,
    searchByLabel,
    setAllFilters,
    sortBy,
    state,
    toggleSortBy,
}: ToolbarProps) {
    return (
        <Stack columnGap={2} flexDirection="row">
            {button && <HelperButton button={button} />}
            {searchByIds && searchByLabel && (
                <SearchField searchByIds={searchByIds} searchByLabel={searchByLabel} setAllFilters={setAllFilters} />
            )}
            <SortBy sortBy={sortBy} state={state} toggleSortBy={toggleSortBy} />
        </Stack>
    )
}
