import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { PLAYBACK_SECTION_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { PlaybackSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { VideoDetails } from 'types/Video'
import { useVideoQuery } from 'api/queries'
import SmartAutoplayContent from './SmartAutoplayContent'

const PlaybackSection = ({ resetForm }: FormikProps<PlaybackSectionFormData>) => {
    const { videoGuid } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid, {
        onSuccess: initForm,
    })

    return (
        <SettingsSectionProvider>
            <Form>
                <Box className="PlaybackSection">
                    <SmartAutoplayContent />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    return (
        <Formik<PlaybackSectionFormData>
            initialValues={PLAYBACK_SECTION_INITIAL_VALUES}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <PlaybackSection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
