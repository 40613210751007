import { BUSINESSNAME, FIRSTNAME, LASTNAME } from 'design/pages/SignUp/constants'
import { EMAIL } from 'constants/validations/user.constants'

export const CURRENT_EMAIL = 'currentEmail'
export const CURRENT_PASSWORD = 'password'
export const NEW_PASSWORD = 'newPassword'

export { EMAIL, FIRSTNAME, LASTNAME }

export const INITIAL_VALUES = {
    [BUSINESSNAME]: '',
    [FIRSTNAME]: '',
    [LASTNAME]: '',
    [CURRENT_EMAIL]: '',
    [EMAIL]: '',
    [CURRENT_PASSWORD]: '',
    [NEW_PASSWORD]: '',
}
