import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import {
    CLOSED_CAPTIONS_DRAFT,
    CLOSED_CAPTIONS_TEXT,
    UPDATE_CLOSED_CAPTIONS_MUTATION,
    VIDEOS_QUERY,
} from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { Video } from 'types/Video'

interface Payload {
    video: Video
    file: string | Blob
}

export const useUpdateClosedCaptionsMutation = (options?: Options<Video, Payload, string>) => {
    const { mutate } = useMutationToastDecorator({ showPendingToast: false, showSuccessToast: false })

    return useInvalidateMutation<Video, Payload, string>(
        UPDATE_CLOSED_CAPTIONS_MUTATION,
        async (payload: Payload) => {
            return mutate(
                settingsAPI.updateClosedCaptions(payload.video, payload.file),
                apiLocale.settings.closedCaptions.update,
            )
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, CLOSED_CAPTIONS_DRAFT, CLOSED_CAPTIONS_TEXT],
        },
    )
}
