export interface HlsPlayer {
    isInitialized: boolean
    play(): Promise<void>
    playing(): boolean
    pause(): void
    paused(): boolean
    muted(value?: boolean): boolean
    seek(): void
    currentTime(value?: number): number
    duration(): number
    isTouched(value?: boolean): boolean
    destroy(): void
    on(event: string, listener: HlsEventListener): void
    off(event: string, listener: HlsEventListener): void
    once(event: string, listener: HlsEventListener): void
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type HlsEventListener = (...args: any[]) => void

export enum PlayerOnPlaybackEndType {
    PAUSE = 'pause',
    REDIRECT = 'redirect',
    REPLAY = 'replay',
    DISPLAY_THUMBNAIL = 'display_thumbnail',
}

export enum PlayerMode {
    GRAPH = 'graph',
    DEFAULT = 'default',
    PREVIEW = 'preview',
}

export enum VideoStartupQuality {
    AUTO = 'auto',
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
}
