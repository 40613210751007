export const FIRST_NAME = 'firstname'
export const LAST_NAME = 'lastname'
export const EMAIL = 'email'
export const PERMISSIONS = 'permissions'
export const CREATED = 'created'
export const UPDATED = 'updated'

export const INITIAL_VALUES = {
    [FIRST_NAME]: '',
    [LAST_NAME]: '',
    [EMAIL]: '',
    [PERMISSIONS]: [
        {
            name: 'create_conversion',
            title: 'Create Conversions',
            is_applied: false,
        },
        {
            name: 'add_edit_payment_settings',
            title: 'Add/Edit Payment Settings',
            is_applied: false,
        },
        {
            name: 'child_account_managing',
            title: 'Child account managing',
            is_applied: false,
        },
        {
            name: 'user_integration',
            title: 'Use Integrations',
            is_applied: false,
        },
        {
            name: 'add_ip_filters',
            title: 'Add IP Filters',
            is_applied: false,
        },
        {
            name: 'view_video_settings',
            title: 'View Video Settings',
            is_applied: false,
        },
        {
            name: 'add_domain_whitelisting',
            title: 'Add Domain Whitelisting',
            is_applied: false,
        },
        {
            name: 'edit_video_settings',
            title: 'Edit Video Settings',
            is_applied: false,
        },
        {
            name: 'upload',
            title: 'Upload',
            is_applied: false,
        },
        {
            name: 'view_video_stats',
            title: 'View Video Stats',
            is_applied: false,
        },
        {
            name: 'delete_videos',
            title: 'Delete Videos',
            is_applied: false,
        },
        {
            name: 'create_smart_vids',
            title: 'Create/Edit Smart Vids',
            is_applied: false,
        },
        {
            name: 'publish_smart_vids',
            title: 'Publish Smart Vids',
            is_applied: false,
        },
    ],
}
