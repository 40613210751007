import distanceInWords from 'date-fns/formatDistance'

export const getDateAgoInWords = (date: string) => {
    if (!date) return null
    const d = new Date(date)
    const localTime = d.setHours(d.getHours() + d.getTimezoneOffset() / -60)
    return distanceInWords(localTime, new Date(), { addSuffix: true })
}

export const getDateAgoWithRightTimezone = (date: string) =>
    date ? distanceInWords(new Date(date), new Date(), { addSuffix: true }) : null

export const getPricePoint = (pricepoint: string) => {
    switch (pricepoint) {
        case 'onemonthtrial':
            return '1 month'
        default:
            return null
    }
}

export const getBillingPeriod = (billingPeriod?: string, short?: boolean) => {
    switch (billingPeriod) {
        case '1 month':
            return short ? 'mo' : 'month'
        case '12 months':
            return short ? 'y' : 'year'
        case '12 month':
            return short ? 'y' : 'year'
        default:
            return billingPeriod
    }
}

export const convertTimeToSeconds = (time: string) => {
    const timeParts = time.split(':')
    return timeParts.reduce((acc, time) => 60 * acc + +time, 0) + 's'
}
