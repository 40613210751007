import { Chip } from '@mui/material'
import { memo } from 'react'
import BoltIcon from '@mui/icons-material/Bolt'

import { SubscriptionCtaSource, openSubscriptionModal, setPlan } from 'design/templates/Modal'
import useTariffPlans from 'hooks/user/useTariffPlans'
import { closeModal, ModalType, openModal } from 'design/templates/Modal/ModalTypes/modal.slice'
import { ConversionsLockedContent } from 'design/pages/Conversions/'
import { useAppDispatch } from 'App'

export const ProItem = memo(({ isFreePlan }: { isFreePlan: boolean }) => {
    const { pro: proPlan } = useTariffPlans()
    const dispatch = useAppDispatch()
    const onUpgradeClick = () => {
        dispatch(closeModal())
        dispatch(setPlan({ plan: proPlan }))
        dispatch(
            openSubscriptionModal({
                ctaSource: SubscriptionCtaSource.VID_SETTINGS,
            }),
        )
    }
    const handleOpenProModal = () => {
        dispatch(
            openModal({
                type: ModalType.FEATURE_LOCKED,
                title: 'Upgrade to Unlock Video Thumbnails!',
                description: 'Boost engagement and conversion with Vidalytics` most advanced features.',
                proPlan,
                onUpgradeClick,
                children: <ConversionsLockedContent />,
            }),
        )
    }
    return (
        <>
            {isFreePlan && (
                <Chip
                    onClick={() => handleOpenProModal()}
                    size="small"
                    sx={{ color: '#EC407A', backgroundColor: 'rgba(251, 81, 152, 0.12)', alignSelf: 'center' }}
                    icon={<BoltIcon style={{ color: '#EC407A' }} />}
                    label="PRO"
                />
            )}
        </>
    )
})
