import { locale } from 'locales'

export default {
    firstName: locale.inputs.user.firstName,
    lastName: locale.inputs.user.lastName,
    email: locale.inputs.user.email,
    password: locale.inputs.user.password,
    form: {
        signUpManually: 'or Sign up manually below',
        businessName: 'Business Name',
        typeAgencyName: 'Type agency name',
    },
    planError: {
        planDoesNotExist: 'The plan you are trying to sign up for does not exist.',
        tryDifferentOne: 'Please try a different one',
        reachOutTo: 'or reach out to',
        teamCanAssistYou: 'so the team can assist you.',
    },
    inUse: {
        looksLikeYouAlreadyHaveAnAccount: 'Looks like you already have an account',
        please: 'Please',
        logIn: 'log in',
        hereOr: 'here or',
        contactOurTeamForHelp: 'contact our team for help',
    },
    buttons: {
        letMeIn: 'Let me in!',
        nextStep: 'Next Step',
    },
}
