export const VIDEO = 'video'
export const EMBED_CODE_GUID = 'embedCodeGuid'
export const IS_UNPUBLISHED = 'isUnpublished'
export const THUMBNAIL = 'thumbnail'
export const TITLE = 'title'
export const LENGTH = 'length'
export const PLAYS = 'plays'
export const IS_READY = 'isReady'
export const FUNNEL = 'funnel'
export const SRC = 'src'
export const PREVIEW_SRC = 'previewSrc'
export const DASHBOARD_SRC = 'dashboardSrc'
export const BREADCRUMB = 'breadcrumb'

export const NEW_FOLDER_LABEL = 'New Folder'
