import { SxProps, Theme } from '@mui/material'

const root: SxProps<Theme> = {
    textDecoration: 'none',

    '&:hover': {
        textDecoration: 'none',
    },
}

const disabled: SxProps<Theme> = {
    pointerEvents: 'none',
}

export default {
    root,
    disabled,
}
