export default {
    auth: {
        backToLogin: 'Back to Log In',
        forgotPassword: 'Forgot password',
        resetPassword: 'Reset password',
        signIn: 'Sign in',
        signOut: 'Sign out',
        signUp: 'Sign up',
    },
    entity: {
        add: 'Add',
        copy: 'Copy',
        create: 'Create',
        delete: 'Delete',
        download: 'Download',
        edit: 'Edit',
        move: 'Move',
        moveToFolder: 'Move to Folder',
        publish: 'Publish',
        remove: 'Remove',
        rename: 'Rename',
        select: 'Select',
    },
    generic: {
        addPaymentMethod: 'Add payment method',
        back: 'back',
        cancel: 'Cancel',
        close: 'Close',
        confirm: 'Confirm',
        copyToClipboard: 'Copy to clipboard',
        embed: 'Embed',
        embedOrShare: `Embed/Share`,
        ok: 'OK',
        reFetch: 'Re-Fetch',
        refresh: 'Refresh',
        resend: 'Resend',
        revert: 'Revert',
        revoke: 'Revoke',
        save: 'Save',
        send: 'Send',
        share: 'Share',
        withdraw: 'Withdraw',
    },
    refreshThePage: 'Refresh the page',
    startTrial: {
        free: 'Start Your Free Trial',
        fourteenDays: 'Start 14 Day Trial',
    },
    status: {
        copied: 'Copied',
    },
    trial: {
        fourteenDay: 'Start 14 Day Trial',
        free: 'Start Your Free Trial',
    },
    upload: 'Upload Video',
}
