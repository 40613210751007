import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { REPLACE_VIDEO_MUTATION, VIDEO_FOLDERS_QUERY, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { videoAPI } from 'api/controllers'
import { UseMutationOptions } from 'react-query'

interface Response {
    status: boolean
}

interface Payload {
    oldId: string
    newId: string
}

export const useReplaceVideoMutation = (options?: UseMutationOptions<Response, string, Payload>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation(
        REPLACE_VIDEO_MUTATION,
        async (payload: Payload) => {
            return mutate(videoAPI.replaceVideo(payload.oldId, payload.newId), apiLocale.video.replace)
        },
        {
            invalidate: [VIDEOS_QUERY, VIDEO_FOLDERS_QUERY],
            ...options,
        },
    )
}
