import z from 'zod'
import { agencyClient } from 'api/contracts/agency/entities/agencyClient'
import { ApiHandle } from 'types/Customer'

const request = {
    body: agencyClient
        .pick({ name: true, email: true })
        .describe('Stub')
        .and(z.object({ apiHandle: z.nativeEnum(ApiHandle).optional() })),
} as const

const response = {
    body: z.object({
        data: agencyClient.pick({ id: true, email: true, name: true, picture: true, createdAt: true }),
        invitationUrl: z.string().url(),
    }),
} as const

export const createAgencyClientContract = { request, response } as const

export type CreateAgencyClientRequest = z.infer<typeof request.body>
export type CreateAgencyClientResponse = z.infer<typeof response.body>
