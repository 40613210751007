import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_IS_CLIENT } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { RevokeAgencyAccessResponse } from 'api/contracts/agency/endpoints/revokeAgencyAccess.contract'

export const useRevokeAgencyAccessMutation = () => ({
    revokeAgencyAccess: useInvalidateMutation<RevokeAgencyAccessResponse, undefined>(AGENCY_IS_CLIENT, async () =>
        agencyController.revokeAccess(),
    ),
})
