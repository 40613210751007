import { useState, useEffect, RefObject } from 'react'
import { useToast } from 'design/organisms/ToastProvider'

export type ChargifyToken = string | null
export interface ChargifyError {
    message: string
    invalidFields: string[]
}

export const useChagifyTokenStatus = () => {
    const { showToast } = useToast()

    const chargifyTokenSuccess = (onSucess: (token: string) => void) => (token: ChargifyToken) => {
        if (!token) {
            showToast({
                message: 'Chargify token is not available',
                type: 'error',
            })
            return
        }

        onSucess(token)
    }

    const chargifyTokenError = (error: ChargifyError) => {
        showToast({
            message: error.message || JSON.stringify(error),
            type: 'error',
        })
    }

    return { chargifyTokenError, chargifyTokenSuccess }
}

const INTERVAL_TM = 50 // up to 100 ticks until resolving
const TIMEOUT_TM = 5000

export const useChagifyFormLoadingState = (ref: RefObject<HTMLFormElement>) => {
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            if (ref.current?.querySelector('iframe') !== null) {
                setIsLoading(false)
                if (interval) {
                    clearInterval(interval)
                }
            }
        }, INTERVAL_TM)

        setTimeout(() => {
            if (interval) {
                clearInterval(interval)
            }

            if (isLoading) {
                setIsLoading(false)
            }
        }, TIMEOUT_TM)
    }, [])

    return { isLoading }
}
