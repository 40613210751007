import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { Video } from 'types/Video'

import locale from './VideoSelectControlItem.locale'
import style from './VideoSelectControlItem.style'

type VideoSelectControlItemProps = {
    item?: Video
    error?: boolean
}

const VideoSelectControlItem = ({ item, error }: VideoSelectControlItemProps) => {
    const src = item?.thumbnail?.previewSrc

    return (
        <Stack direction="row">
            <ImgWithErrorHandler
                src={src}
                alt={item?.title || locale.chooseVideo}
                sx={{
                    width: 45,
                    height: 26,
                    borderRadius: '6px',
                    objectFit: 'cover',
                    ...(!src ? { filter: 'brightness(0.95)' } : {}),
                }}
            />
            <Typography sx={[style.label, !!error && style.error]}>{item?.title || locale.chooseVideo}</Typography>
        </Stack>
    )
}

export default withErrorBoundary(VideoSelectControlItem)
