import { useQuery, UseQueryOptions } from 'react-query'
import { enableQueryHelper } from 'utils'

import { CustomerSubscription } from 'types/Customer'
import { userAPI } from 'api/controllers'
import { CUSTOMER_SUBSCRIPTION_QUERY } from 'api/constants'

export const useCustomerSubscriptionQuery = (options?: UseQueryOptions<CustomerSubscription, unknown>) => {
    return useQuery<CustomerSubscription>(CUSTOMER_SUBSCRIPTION_QUERY, () => userAPI.getCustomerSubscription(), {
        ...options,
        enabled: enableQueryHelper(),
    })
}
