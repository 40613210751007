import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { UPDATE_PLAY_GATE_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { VideoPlayGateConfig } from 'types/VideoPlayGate'

export const useUpdatePlayGateMutation = (options?: Options<VideoPlayGateConfig, VideoPlayGateConfig, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoPlayGateConfig, VideoPlayGateConfig, string>(
        UPDATE_PLAY_GATE_MUTATION,
        async (playGate: VideoPlayGateConfig) => {
            return mutate(settingsAPI.updatePlayGate(playGate), apiLocale.settings.playGate.update)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
