import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { CREATE_CTA_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { Options, useInvalidateMutation } from 'api/hooks'
import { CallToActionConfig } from 'types/Video'

type Payload = {
    videoGuid: string
    callToAction: CallToActionConfig
}

export const useCreateCTAMutation = (options?: Options<CallToActionConfig, Payload, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<CallToActionConfig, Payload, string>(
        CREATE_CTA_MUTATION,
        async (payload: Payload) => {
            return mutate(settingsAPI.createCTA(payload.videoGuid, payload.callToAction), apiLocale.settings.cta.create)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
