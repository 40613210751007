import { Components, Theme } from '@mui/material'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'

export const MuiAutocomplete: Components<Omit<Theme, 'components'>>['MuiAutocomplete'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            minWidth: theme.spacing(40),
        }),
        inputRoot: {
            padding: 0,
        },
        input: {
            marginLeft: 6,
        },
    },
    defaultProps: {
        popupIcon: <ArrowDropDownRoundedIcon />,
    },
}
