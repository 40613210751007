import { authAPI } from 'api/controllers'
import { ResetPasswordPayload } from 'types/Auth'
import { RESET_PASSWORD_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useResetPasswordMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, ResetPasswordPayload>(
        RESET_PASSWORD_QUERY,
        async (payload: ResetPasswordPayload) => mutate(authAPI.resetPassword(payload), apiLocale.auth.resetPassword),
    )
}
