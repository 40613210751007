import { Box, SwitchProps as MuiSwitchProps } from '@mui/material'
import { Field, FieldProps } from 'formik'

import { Switch as CommonSwitch, SwitchProps as CommonSwitchProps } from 'design/atoms/Switch'

import style from './SwitchForm.styles'

export interface SwitchFormProps extends Omit<CommonSwitchProps, 'onChange'> {
    name: string
    onChange?: (name: string, value: boolean) => void
    afterChange?: (name: string, value: boolean) => void
    switchProps?: MuiSwitchProps
}

export const SwitchForm = ({ name, onChange, afterChange, ...props }: SwitchFormProps) => {
    const renderSwitch = ({ field, form: { setFieldValue } }: FieldProps) => (
        <CommonSwitch
            value={field.value}
            {...props}
            onChange={(_, checked) => {
                if (onChange) {
                    onChange(name, checked)
                    return
                }

                setFieldValue(name, checked)
                afterChange?.(name, checked)
            }}
        />
    )

    return (
        <Box sx={style.switchFormStyles}>
            <Field name={name}>{renderSwitch}</Field>
        </Box>
    )
}
