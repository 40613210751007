import { UseMutationOptions } from 'react-query'

import { integrationsAPI } from 'api/controllers'
import { AnalyticsSettingPayload, IntegrationName } from 'types/Integrations'
import { ANALYTICS_SETTINGS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useUpdateAnalyticsIntegrationMutation = (
    name: IntegrationName,
    options?: UseMutationOptions<AnalyticsSettingPayload, any, AnalyticsSettingPayload>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<AnalyticsSettingPayload, AnalyticsSettingPayload>(
        ANALYTICS_SETTINGS_QUERY,
        async (payload: AnalyticsSettingPayload) =>
            mutate(
                name === IntegrationName.Google
                    ? integrationsAPI.updateGoogleIntegration(payload)
                    : integrationsAPI.updateFacebookIntegration(payload),
                apiLocale.integration.updateAnalytics,
            ),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
