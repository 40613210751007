import { Box, Divider, Typography } from '@mui/material'

import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    UI_OVERLAY_PAUSE_SOURCE,
    UI_THUMBNAIL_FULL_SIZE,
    UI_THUMBNAIL_MOBILE_SOURCE,
} from 'design/pages/VidSettings/constants'
import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import { ThumbnailSource, ThumbnailsSectionFormData } from 'types/VidSettings'
import { ThumbnailSourceType, ThumbnailType, VideoThumbGeneration } from 'types/Video'
import { SwitchTooltipPlacement, SwitchForm } from 'design/atoms/Switch'
import { useDeleteThumbnailMutation, useUpdateThumbnailMutation } from 'api/mutations'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import { useEffect, useMemo, useState } from 'react'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import useVideoPolling from 'hooks/system/useVideoPolling'
import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { useSameMobileThumbnailMutation } from 'api/mutations/settings/useSameMobileThumbnailMutation'
import { ImageUpload } from './ImageUploader/ImageUploader'
import locale from './../../StyleSettings.locale'

interface ThumbnailsContentProps {
    isSmartVidGlobalSettings?: boolean
}

export const ThumbnailsContent = ({ isSmartVidGlobalSettings }: ThumbnailsContentProps) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )

    const { video, isLoading, onChange } = useSectionContext<ThumbnailsSectionFormData>()

    const { setThumbnailFromVideoType, sameForMobileThumb, setSameForMobileThumb, setThumbnailSource } =
        useVidSettingsContext()

    const [isExitThumbnail, setIsExitThumbnail] = useState<boolean>(false)
    const updateThumbnailMutation = useUpdateThumbnailMutation()
    const deleteThumbnailMutation = useDeleteThumbnailMutation()
    const sameMobileThumbnailMutation = useSameMobileThumbnailMutation()

    const isTheSameMobileThumb = useMemo(() => {
        if (
            (video?.video.thumbnailType === ThumbnailSourceType.image &&
                video?.settings.ui_thumbnail_mobile_source !== video?.video.thumbnail.previewSrc) ||
            (video?.video.thumbnailType !== ThumbnailSourceType.videoBoth &&
                video?.video.thumbnailType !== ThumbnailSourceType.image) ||
            (video?.video.thumbnailType === ThumbnailSourceType.videoBoth &&
                video?.settings.ui_thumbnail_default_mp4 !== video?.settings.ui_thumbnail_mobile_mp4)
        ) {
            return false
        }
        return true
    }, [video])
    const videoThumbnailGeneration = useMemo(() => video?.video.videoThumbnailGeneration, [video])
    const isExitThumbnailFromVideo = useMemo(() => !!video?.settings[UI_OVERLAY_PAUSE_SOURCE], [video])

    const thumbnailImage = useMemo(
        () => video?.video.thumbnail.src,
        [video?.video.thumbnail.src, video?.video.thumbnailType],
    )
    const thumbnailVideo = useMemo(() => video?.settings.ui_thumbnail_default_mp4, [video])
    const mobileImage = useMemo(() => video?.settings.ui_thumbnail_mobile_source, [video])
    const mobileVideo = useMemo(() => video?.settings.ui_thumbnail_mobile_mp4, [video])

    const handleSwitchThumbnailType = (thumbnailType: ThumbnailType, value: ThumbnailSource) => {
        if (!video) return
        let newThumbnailType
        if (thumbnailType === ThumbnailType.thumbnail) {
            if (value === ThumbnailSource.image && video?.video.thumbnailType === ThumbnailSourceType.videoBoth) {
                newThumbnailType = ThumbnailSourceType.videoMobile
            } else if (
                value === ThumbnailSource.image &&
                video?.video.thumbnailType === ThumbnailSourceType.videoDesktop
            ) {
                newThumbnailType = ThumbnailSourceType.image
            } else if (value === ThumbnailSource.video && video?.video.thumbnailType === ThumbnailSourceType.image) {
                newThumbnailType = ThumbnailSourceType.videoDesktop
            } else if (
                value === ThumbnailSource.video &&
                video?.video.thumbnailType === ThumbnailSourceType.videoMobile
            ) {
                newThumbnailType = ThumbnailSourceType.videoBoth
            }
        } else if (thumbnailType === ThumbnailType.mobileThumbnail) {
            if (value === ThumbnailSource.image && video?.video.thumbnailType === ThumbnailSourceType.videoBoth) {
                newThumbnailType = ThumbnailSourceType.videoDesktop
            } else if (
                value === ThumbnailSource.image &&
                video?.video.thumbnailType === ThumbnailSourceType.videoMobile
            ) {
                newThumbnailType = ThumbnailSourceType.image
            } else if (value === ThumbnailSource.video && video?.video.thumbnailType === ThumbnailSourceType.image) {
                newThumbnailType = ThumbnailSourceType.videoMobile
            } else if (
                value === ThumbnailSource.video &&
                video?.video.thumbnailType === ThumbnailSourceType.videoDesktop
            ) {
                newThumbnailType = ThumbnailSourceType.videoBoth
            }
        }
        if (newThumbnailType) {
            sameMobileThumbnailMutation.mutate({
                videoGuid: video?.video.guid as string,
                thumbnailType: newThumbnailType,
            })
        }
    }

    useVideoPolling(video?.video.guid as string)

    useEffect(() => {
        setIsExitThumbnail(isExitThumbnailFromVideo)
    }, [isExitThumbnailFromVideo])

    useEffect(() => {
        setSameForMobileThumb(isTheSameMobileThumb)
    }, [isTheSameMobileThumb])

    const handleFileUpload = ({ data, id }: { data: File; id: string }) => {
        if (!video) {
            return
        }
        updateThumbnailMutation.mutate({ video: video.video, file: data, thumbnailType: id as ThumbnailType })
    }

    const handleDelete = (id: string) => {
        if (!video) {
            return
        }
        if (id === ThumbnailType.thumbnail) {
            deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.thumbnail })
            if (video?.settings.ui_thumbnail_mobile_source === video?.video.thumbnail.previewSrc) {
                deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.mobileThumbnail })
                setSameForMobileThumb(true)
            }
        } else {
            deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: id as ThumbnailType })
        }
    }

    const handleMobileThumbnail = (name: string) => {
        if (!video || name !== UI_THUMBNAIL_MOBILE_SOURCE) return

        if (sameForMobileThumb) {
            setSameForMobileThumb(false)

            if (video?.video.thumbnailType === ThumbnailSourceType.videoDesktop) {
                sameMobileThumbnailMutation.mutate({
                    videoGuid: video.video.guid as string,
                    thumbnailType: ThumbnailSourceType.videoBoth,
                })
            }
            if (video?.video.thumbnailType === ThumbnailSourceType.videoMobile) {
                sameMobileThumbnailMutation.mutate({
                    videoGuid: video.video.guid as string,
                    thumbnailType: ThumbnailSourceType.image,
                })
            }
        } else {
            if (video?.video.thumbnailType === ThumbnailSourceType.image) {
                deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.mobileThumbnail })
            }
            if (video?.video.thumbnailType === ThumbnailSourceType.videoBoth) {
                deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.mobileThumbnail })
                sameMobileThumbnailMutation.mutate({
                    videoGuid: video.video.guid as string,
                    thumbnailType: ThumbnailSourceType.videoDesktop,
                })
            }
            if (video?.video.thumbnailType === ThumbnailSourceType.videoMobile) {
                deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.mobileThumbnail })
                sameMobileThumbnailMutation.mutate({
                    videoGuid: video.video.guid as string,
                    thumbnailType: ThumbnailSourceType.image,
                })
            }
            setSameForMobileThumb(true)
        }
    }

    const handleRemoveExitThumbnail = (name: string, value: boolean) => {
        if (name === UI_OVERLAY_PAUSE_SOURCE && !value) {
            if (!video) return
            if (!video?.settings[UI_OVERLAY_PAUSE_SOURCE]) {
                setIsExitThumbnail(false)
                return
            }
            deleteThumbnailMutation.mutate({ video: video.video, thumbnailType: ThumbnailType.customPauseScreen })
            setIsExitThumbnail(false)
        } else {
            setIsExitThumbnail(true)
        }
    }

    const videoIsReady = video?.video.isReady
    const isDeletable = useMemo(
        () =>
            video?.settings.ui_thumbnail_mobile_source !== video?.video.thumbnail.previewSrc ||
            video?.video.thumbnailType === ThumbnailSourceType.videoBoth ||
            video?.video.thumbnailType === ThumbnailSourceType.videoMobile,
        [video],
    )
    return (
        <Section mb={4}>
            <ImageUpload
                id={ThumbnailType.thumbnail}
                title={locale.uploadThumbnailTitle}
                image={thumbnailImage ?? thumbnailVideo}
                tooltip={locale.thumbnailTooltip}
                disableSelection={!videoIsReady}
                disabled={videoThumbnailGeneration === VideoThumbGeneration.pending || isLoading}
                imageValidateSize={{ min: 1000, max: 2e6 }}
                onUpload={handleFileUpload}
                onDelete={handleDelete}
                setThumbnailSource={setThumbnailSource}
                setThumbnailFromVideoType={setThumbnailFromVideoType}
                thumbnailSourceType={video?.video.thumbnailType}
                videoThumbnailGeneration={videoThumbnailGeneration}
                handleSwitchThumbnailType={handleSwitchThumbnailType}
            />
            <Box className="switchContainer">
                <SwitchForm
                    sx={style.switchContainer}
                    name={UI_THUMBNAIL_MOBILE_SOURCE}
                    label={<Typography variant="body2">{locale.mobileSwitcher}</Typography>}
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    onChange={handleMobileThumbnail}
                    labelPlacement="start"
                    disabled={videoThumbnailGeneration === VideoThumbGeneration.pending || isLoading}
                    switchProps={{ size: 'small' }}
                    value={sameForMobileThumb}
                />
            </Box>
            {!sameForMobileThumb && (
                <ImageUpload
                    id={ThumbnailType.mobileThumbnail}
                    title={locale.mobileThumbTitle}
                    image={mobileImage ?? mobileVideo}
                    isDeletable={isDeletable}
                    tooltip={locale.mobileThumbHelpText}
                    imageValidateSize={{ min: 1000, max: 2e6 }}
                    disableSelection={!videoIsReady}
                    disabled={videoThumbnailGeneration === VideoThumbGeneration.pending || isLoading}
                    onUpload={handleFileUpload}
                    onDelete={handleDelete}
                    setThumbnailSource={setThumbnailSource}
                    setThumbnailFromVideoType={setThumbnailFromVideoType}
                    thumbnailSourceType={video?.video.thumbnailType}
                    videoThumbnailGeneration={videoThumbnailGeneration}
                    handleSwitchThumbnailType={handleSwitchThumbnailType}
                />
            )}
            <Divider />
            <Box className="switchContainer">
                <SwitchForm
                    sx={style.switchContainer}
                    name={UI_OVERLAY_PAUSE_SOURCE}
                    disabled={isLoading}
                    label={
                        <>
                            <Typography variant="body1" fontWeight={600}>
                                {locale.exitThumbnailLabel}
                            </Typography>
                            <HelpTooltip title={locale.exitThumbnailHelpText} />
                        </>
                    }
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    onChange={handleRemoveExitThumbnail}
                    labelPlacement="start"
                    switchProps={{ size: 'small' }}
                    value={isExitThumbnail}
                />
            </Box>
            {isExitThumbnail && (
                <ImageUpload
                    title=""
                    id={ThumbnailType.customPauseScreen}
                    image={video?.settings[UI_OVERLAY_PAUSE_SOURCE]}
                    imageValidateSize={{ min: 1000, max: 2e6 }}
                    disableSelection={!videoIsReady}
                    disabled={isLoading}
                    onUpload={handleFileUpload}
                    onDelete={handleDelete}
                    setThumbnailSource={setThumbnailSource}
                    setThumbnailFromVideoType={setThumbnailFromVideoType}
                    disableSource
                />
            )}
            <Divider />

            <Box className="switchContainer">
                <SwitchForm
                    sx={style.switchContainer}
                    name={UI_THUMBNAIL_FULL_SIZE}
                    label={<Typography variant="body2">{locale.strethLabel}</Typography>}
                    tooltipPlacement={SwitchTooltipPlacement.inside}
                    helpInfo={locale.stretchThumbnailHelpText}
                    disabled={isLoading}
                    afterChange={onChange}
                    labelPlacement="start"
                    switchProps={{ size: 'small' }}
                />
            </Box>
        </Section>
    )
}
