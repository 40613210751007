import z from 'zod'

import { locale } from 'locales'

export const accountMetric = z.union([
    z.literal('totalPlays'),
    z.literal('impressions'),
    z.literal('revenue'),
    z.literal('conversion'),
    z.literal('conversionRate'),
    z.literal('avgRevenuePerPlay'),
])

export type AccountMetric = z.infer<typeof accountMetric>

export const plan = z.union([z.literal('Pro'), z.literal('Premium'), z.literal('Agency'), z.literal('Free')])

export type Plan = z.infer<typeof plan>

export const accountMetricInfo = z.object({
    value: z.number().describe('Value associated to the account metrics'),
    trend: z.number().describe('Trend for the given account metric'),
})

export type AccountMetricInfo = z.infer<typeof accountMetricInfo>

export const agencyClientStatus = z.union([z.literal('active'), z.literal('pending')])

export type AgencyClientStatus = z.infer<typeof agencyClientStatus>

export const bandwidthUsage = z.object({
    used: z.number().min(0).describe('Amount of bandwidth used'),
    limit: z.number().min(0).describe('Bandwidth limit'),
})

export type BandwidthUsage = z.infer<typeof bandwidthUsage>

/**
 * Information about the account's tariff.
 */
export const tariff = z.object({
    currentPlan: plan,
    currentBillingAmountInCents: z.number().positive(),
    bandwidthUsage: bandwidthUsage,
    nextBill: z.string().datetime().describe('The timestamp of the next bill'),
})

export type Tariff = z.infer<typeof tariff>

export const statistics = z.record(accountMetric, accountMetricInfo)

export type Statistics = z.infer<typeof statistics>

export const accountInfo = z
    .object({
        id: z.number().describe('Unique identifier of the account'),
        email: z
            .string()
            .email('The email you entered is incorrect. Please double check the email address and try again.')
            .describe('Email associated with the account'),
        videosCount: z
            .number()
            .positive()
            .nullable()
            .optional()
            .describe('The total number of videos uploaded by the account'),
        picture: z.string().url().optional().nullable().describe('URL of the picture representing the account avatar'),
        status: agencyClientStatus,
        onCancellation: z
            .boolean()
            .optional()
            .describe('A value indicating whether the account is on the cancellation process'),
        createdAt: z.string().datetime().describe('The date and time when the account was created'),
    })
    .describe('Agency-related account Object Schema')

export type AccountInfo = z.infer<typeof accountInfo>

const avatarConstraints = {
    maxSize: 1024 * 1024 * 2, // 2MB
    type: ['image/png', 'image/jpg', 'image/jpeg'],
}

export const avatar = z
    .instanceof(File, { message: locale.messages.errors.file.chooseImage })
    .nullable()
    .refine((file) => {
        return !file || file.size <= avatarConstraints.maxSize
    }, locale.messages.errors.file.sizeMax('2 MB'))
    .refine((file) => {
        return !file || avatarConstraints.type.includes(file.type)
    }, locale.messages.errors.file.typeAllowed(avatarConstraints.type))
    .or(z.string().url())

export type Avatar = z.infer<typeof accountInfo>
