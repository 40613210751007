import { videoAPI } from 'api/controllers'
import { DELETE_ENCODING_THUMBNAIL_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { useToast } from 'design/organisms/ToastProvider'

export const useStopThumbnailEncodingMutation = () => {
    const { showToast } = useToast()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<undefined, { guid: string }>(
        DELETE_ENCODING_THUMBNAIL_MUTATION,
        async ({ guid }) =>
            mutate(videoAPI.deleteVideoThumbnailEncodingProcess(guid), {
                success: apiLocale.video.deleteThumbnailEncoding.pending,
            }),
        {
            invalidate: [VIDEOS_QUERY],
            onSuccess: () => {
                showToast({
                    type: 'error',
                    title: apiLocale.video.deleteThumbnailEncoding.success.title,
                    message: apiLocale.video.deleteThumbnailEncoding.success.message,
                })
            },
        },
    )
}
