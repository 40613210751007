import { useQuery } from 'react-query'

import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_INVITE_INFO } from 'api/constants'
import {
    GetAgencyInviteInfoResponse,
    GetAgencyInviteInfoQuery,
} from 'api/contracts/agency/endpoints/getAgencyInviteInfo.contract'
import { ApiError } from 'api/contracts/utilitary/error'
import { UseQueryOptions } from 'react-query/types/react/types'
import { useApiErrorToast } from 'api/hooks'

export const useGetAgencyInviteInfo = (
    token: string,
    options?: Omit<
        UseQueryOptions<GetAgencyInviteInfoQuery, ApiError, GetAgencyInviteInfoResponse>,
        'queryKey' | 'queryFn'
    >,
) => {
    const { showApiErrorToast } = useApiErrorToast()
    const getAgencyInviteInfoQuery = useQuery<GetAgencyInviteInfoQuery, ApiError, GetAgencyInviteInfoResponse>(
        AGENCY_GET_INVITE_INFO,
        () => agencyController.client.invitation.getInfo(token),
        { onError: (e) => showApiErrorToast(e), ...options },
    )

    return { getAgencyInviteInfoQuery, agencyInviteInfo: getAgencyInviteInfoQuery.data }
}
