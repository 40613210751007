import { UseMutationOptions } from 'react-query'

import { SignInWithMicrosoftPayload, SignInResponse } from 'types/Auth'
import { authAPI } from 'api/controllers'
import { useInvalidateMutation } from 'api/hooks'
import { CUSTOMER_QUERY } from 'api/constants'
import { onError } from './useSignInWithGoogleMutation'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useSignInWithMicrosoftMutation = (
    challengeParam: string,
    options?: UseMutationOptions<SignInResponse, any, SignInWithMicrosoftPayload>,
) => {
    return useInvalidateMutation<SignInResponse, SignInWithMicrosoftPayload>(
        CUSTOMER_QUERY,
        async (payload: SignInWithMicrosoftPayload) => authAPI.signInWithMicrosoft(challengeParam, payload),
        {
            ...options,
            onError: onError<SignInWithMicrosoftPayload>(options),
        },
    )
}
