import { SubUser, SubUserPayload, UpdateSubUserPayload } from 'types/SubUser'
import { Id } from 'types'
import { http } from 'api/utils/http.utils'

export const subUserAPI = {
    getSubUsers: () => http.get<SubUser>('customer/sub-user'),
    createSubUser: (payload: SubUserPayload): Promise<SubUser> =>
        http.post<SubUserPayload, SubUser>('customer/sub-user', payload),
    updateSubUser: (payload: UpdateSubUserPayload): Promise<SubUser> =>
        http.put<UpdateSubUserPayload, SubUser>(`customer/sub-user/${payload.id}`, payload),
    deleteSubUser: (id: Id) => http.delete(`customer/sub-user/${id}`),
}
