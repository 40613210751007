export enum TagType {
    time = 'time',
    interactive = 'interactive',
    callToAction = 'callToAction',
}

export interface TagConfig {
    customVariables: []
    guid?: string
    hubspotContactListId: null
    name: string
    time: string
    type: TagType
}
