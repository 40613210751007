import { Box, Grid, Stack, Tooltip } from '@mui/material'

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'

const ChargifyCreditCardForm = () => {
    return (
        <Box className="ChargifyCreditCardForm">
            <Grid container spacing={{ tablet: 4 }}>
                <Grid item tablet={6} mobile={12} id="chargify-firstname" />
                <Grid item tablet={6} mobile={12} id="chargify-lastname" />
            </Grid>

            <Grid container spacing={{ tablet: 4, desktop: 2 }}>
                <Grid item tablet={8} mobile={12} id="chargify-card" />
                <Grid item tablet={4} mobile={6}>
                    <Grid container spacing={2}>
                        <Grid item mobile={11} id="chargify-cvv" />

                        <Grid item mobile={1}>
                            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
                                <Tooltip
                                    arrow
                                    title="The card security code is the three digit code on the back of your Visa or Mastercard. For American Express card holders, it is a four digit number on the front of the credit card."
                                    placement="top"
                                >
                                    <HelpOutlineOutlinedIcon color="primary" fontSize="small" />
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={{ mobile: 4, desktop: 2 }}>
                <Grid item tablet={3} mobile={3} id="chargify-month" />
                <Grid item tablet={3} mobile={3} id="chargify-year" />
                <Grid item tablet={6} mobile={6} id="chargify-zip" />
            </Grid>
        </Box>
    )
}

export default withErrorBoundary(ChargifyCreditCardForm)
