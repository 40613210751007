import { UseMutationOptions } from 'react-query'

import { authAPI } from 'api/controllers'
import { CUSTOMER_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { SignUpPayload, SignUpResponse } from 'types/Auth'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useSignUpMutation = (options?: UseMutationOptions<SignUpResponse, any, SignUpPayload>) => {
    const { mutate } = useMutationToastDecorator({ showPendingToast: false, showSuccessToast: false })

    return useInvalidateMutation<SignUpResponse, SignUpPayload>(
        CUSTOMER_QUERY,
        async (payload: SignUpPayload) => mutate(authAPI.signUp(payload), apiLocale.auth.signUp),
        options,
    )
}
