import { SxProps, Theme, alpha } from '@mui/material'
import { getPropByTheme } from 'styles/theme/utils'

const WIDTH = 20
const HEIGHT = 20

const colorIdle: SxProps<Theme> = {
    width: WIDTH,
    height: HEIGHT,
    border: (theme) => `1px solid ${alpha(getPropByTheme('#2E476F', '#FFF', theme.palette.mode), 0.23)}`,
    borderRadius: 1,
}

const colorDisabled: SxProps<Theme> = {
    opacity: (theme) => theme.palette.action.disabledOpacity,
}

const opacityModeContainer: SxProps = {
    flexDirection: 'row',
    flexShrink: 0,
}

const opacityModeCommon: SxProps = {
    width: WIDTH / 2,
}

const opacityModeColor: SxProps = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
}

const opacityModeColorWithOpacity: SxProps = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
}

export default {
    color: {
        idle: colorIdle,
        disabled: colorDisabled,
    },
    opacityMode: {
        container: opacityModeContainer,
        common: opacityModeCommon,
        color: opacityModeColor,
        colorWithOpacity: opacityModeColorWithOpacity,
    },
}
