import { Typography, Stack } from '@mui/material'

import { Link } from 'design/atoms/Link'
import { route } from 'constants/routes'

import locale from './HaveAccountLogin.locale'

export const HaveAccountLogin = () => (
    <Stack direction="row" gap={1} py={5} data-testid="haveAccountLogin">
        <Typography variant="body2" lineHeight="1">
            {locale.haveAnAccount}
        </Typography>
        <Link fontSize="body2.fontSize" lineHeight="1" fontWeight="600" to={route.auth.signIn}>
            {locale.logIn}
        </Link>
    </Stack>
)
