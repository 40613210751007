import { Divider, Stack, Typography, LinearProgress } from '@mui/material'

import { AgencyClient } from 'api/contracts/agency/entities/agencyClient'
import { useGetTariffInfoQuery } from 'api/queries'

import style from './AccountInformation.styles'
import locale from './AccountInformation.locale'
import { testId } from './AccountInformation.constants'
import { AccountInformationItem } from './AccountInformationItem/AccountInformationItem'

export type AccountInformationProps = Pick<AgencyClient, 'id'>

/**
 * The `AccountInformation` is a React component designed to display user's account details including spending's, plan, and bandwidth usage.
 * It uses progress bars and typography components for a clear and user-friendly representation of data.
 * Ideally incorporated within account management sections of an application.
 */
export const AccountInformation = ({ id }: AccountInformationProps) => {
    const { getTariffInfoQuery, tariffInfo } = useGetTariffInfoQuery(id)

    return (
        <Stack gap={2} data-testid={testId.container} minWidth={250}>
            <Typography fontWeight="bold" gutterBottom data-testid={testId.header}>
                {locale.header}
            </Typography>
            <AccountInformationItem
                isLoading={getTariffInfoQuery.isLoading}
                skeletonWidth={50}
                label={locale.currentPlan}
                data={tariffInfo?.currentPlan}
            />
            <LinearProgress
                variant="determinate"
                value={
                    tariffInfo?.bandwidthUsage
                        ? (tariffInfo?.bandwidthUsage?.used / tariffInfo?.bandwidthUsage?.limit) * 100
                        : 0
                }
                sx={style.progressBar}
                data-testid={testId.progressBar}
            />
            <AccountInformationItem
                isLoading={getTariffInfoQuery.isLoading}
                skeletonWidth={100}
                label={locale.bandwidth.usage}
                data={
                    tariffInfo?.bandwidthUsage &&
                    locale.bandwidth.stats(tariffInfo?.bandwidthUsage.used, tariffInfo?.bandwidthUsage.limit)
                }
            />
            <Divider />
            <AccountInformationItem
                isLoading={getTariffInfoQuery.isLoading}
                label={locale.currentSpend}
                data={
                    tariffInfo?.currentBillingAmountInCents || tariffInfo?.currentBillingAmountInCents === 0
                        ? '$' + tariffInfo.currentBillingAmountInCents / 100
                        : undefined
                }
            />
        </Stack>
    )
}
