import { SxProps, Theme } from '@mui/material'

const link: SxProps = {
    display: 'flex',
    width: '100%',
}

const icon: SxProps<Theme> = {
    backgroundColor: (theme) => theme.palette.secondary.light,
}

export default {
    link,
    icon,
}
