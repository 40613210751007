import { useQuery, UseQueryOptions } from 'react-query'

import { SEARCH } from 'design/atoms/Table/constants'
import { TITLE, EMBED_CODE_GUID } from 'design/pages/MyVids'
import { Video } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEOS_QUERY } from 'api/constants'

export type VideosQueryType = {
    filteredByFunnels?: boolean
    processedVideosOnly?: boolean
    accessibleForFunnelGuid?: string
}

export const getVidWithFilter = (v: Video) => ({ ...v, [SEARCH]: `${v[TITLE]}:${v[EMBED_CODE_GUID]}` })

export const useVideosQuery = (
    { filteredByFunnels = false, processedVideosOnly = false, accessibleForFunnelGuid }: VideosQueryType = {},
    options?: UseQueryOptions<Video[], unknown, Video[]>,
) =>
    useQuery<Video[]>(
        [VIDEOS_QUERY, filteredByFunnels, accessibleForFunnelGuid],
        async () => {
            const videos = await videoAPI.getVideos({
                filter_without_funnel: filteredByFunnels ? 'true' : '',
                filter_accessible_for_funnel: accessibleForFunnelGuid ?? '',
            })
            const videosResult = processedVideosOnly ? videos.filter((video: Video) => !!video.isReady) : videos

            return videosResult?.map(getVidWithFilter)
        },
        options,
    )
