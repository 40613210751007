import { CustomerSubscriptionPlanTier } from 'types/Customer'
/**
 * FYI: key - plan ID, value - plan name
 */
export const Plan = {
    agency: 'Agency',
    free: 'Free',
    premium: 'Premium',
    pro: 'Pro',
    proIv: 'Pro Smart Vids',
    vip: 'Vip',
} as const

export const PlanIndexByTier = {
    [CustomerSubscriptionPlanTier.free]: 0,
    [CustomerSubscriptionPlanTier.pro]: 1,
    [CustomerSubscriptionPlanTier.proIv]: 1,
    [CustomerSubscriptionPlanTier.enterprise]: 2,
    [CustomerSubscriptionPlanTier.agency]: 100,
    [CustomerSubscriptionPlanTier.unreachable]: 100, // some unreachable value
} as const
