import { InputAdornment, Slider as MuiSlider, SliderProps as MuiSliderProps, Stack, Typography } from '@mui/material'
import { useField } from 'formik'

import { Input } from 'design/atoms/Form'
import { InputChangeHandler } from 'types'

import style from './Slider.style'

type Value = number | number[]

interface SliderProps extends MuiSliderProps {
    name: string
    label?: string
    adornmentText?: string
    afterChange?: (name: string, value: Value) => void
}

const TIMEOUT = 100

export const Slider = ({
    name,
    label,
    min = 0,
    max = 100,
    adornmentText,
    afterChange,
    ...sliderProps
}: SliderProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, , { setValue }] = useField({ name })

    const handleChange = (value: Value) => {
        setValue(value)

        setTimeout(() => afterChange?.(name, value), TIMEOUT)
    }

    const handleInputChange: InputChangeHandler = (e) => {
        let value = Number(e.target.value)

        if (isNaN(value)) {
            return
        }

        if (value > max) {
            value = max
        } else if (value < min) {
            value = min
        }

        handleChange(value)
    }

    return (
        <Stack sx={style.root}>
            {label && (
                <Typography sx={style.label} variant="body2" component="label" htmlFor={name}>
                    {label}
                </Typography>
            )}
            <Stack sx={style.inptusWrapper}>
                <MuiSlider
                    {...field}
                    value={Number(field.value)}
                    onChange={(_, value) => handleChange(value)}
                    {...sliderProps}
                />
                <Input
                    id={name}
                    name={name}
                    sx={style.input}
                    value={String(field.value)}
                    disabled={sliderProps.disabled}
                    InputProps={{
                        inputProps: {
                            type: 'number',
                        },
                        ...(adornmentText && {
                            endAdornment: <InputAdornment position="end">{adornmentText}</InputAdornment>,
                        }),
                    }}
                    onChange={handleInputChange}
                />
            </Stack>
        </Stack>
    )
}
