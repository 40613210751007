import { UseQueryOptions, useQuery } from 'react-query'

import { SMART_VID_DETAILS_QUERY } from 'api/constants'
import { GetSmartVidDetailsResponse } from 'api/contracts/smartVids/endpoints/getSmartVidDetails.contract'
import { ApiError } from 'api/contracts/utilitary/error'
import { smartVidConroller } from 'api/controllers'
import { Id } from 'types'

export const useSmartVidDetailsQuery = (svId?: Id, options?: UseQueryOptions<GetSmartVidDetailsResponse, ApiError>) =>
    useQuery<GetSmartVidDetailsResponse, ApiError>(
        [SMART_VID_DETAILS_QUERY, svId],
        () => smartVidConroller.smartVid.getDetails(String(svId)),
        { ...options, enabled: Boolean(svId) },
    )
