import { settingsAPI } from 'api/controllers'
import { VideoDetails } from 'types/Video'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DELETE_TAG_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { TagConfig } from 'types/VideoTag'

export const useDeleteTagMutation = (options?: Options<VideoDetails, TagConfig, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<VideoDetails, TagConfig, string>(
        DELETE_TAG_MUTATION,
        async (tag: TagConfig) => {
            return mutate(settingsAPI.deleteTag(tag), apiLocale.settings.tag.delete)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
