import { Components, Theme } from '@mui/material'

import { unifiedInputPadding } from 'styles/common'

export const MuiFilledInput: Components<Omit<Theme, 'components'>>['MuiFilledInput'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            borderRadius: theme.spacing(3),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        }),
        input: {
            padding: unifiedInputPadding,
        },
    },
}
