import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import {
    CLOSED_CAPTIONS_DRAFT,
    CLOSED_CAPTIONS_TEXT,
    GENERATE_CLOSED_CAPTIONS_MUTATION,
    VIDEOS_QUERY,
} from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { Video } from 'types/Video'

interface Payload {
    guid: string
}

export const useGenerateClosedCaptionsMutation = (options?: Options<Video, Payload, string>) => {
    const { mutate } = useMutationToastDecorator({ showPendingToast: false, showSuccessToast: false })

    return useInvalidateMutation<Video, Payload, string>(
        GENERATE_CLOSED_CAPTIONS_MUTATION,
        async ({ guid }: Payload) => {
            return mutate(settingsAPI.generateClosedCaptions(guid), apiLocale.settings.closedCaptions.update)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, CLOSED_CAPTIONS_DRAFT, CLOSED_CAPTIONS_TEXT],
        },
    )
}
