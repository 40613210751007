import { settingsAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { GENERATE_CLOSED_CAPTIONS_CANCEL_MUTATION, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation, Options } from 'api/hooks'
import { Video } from 'types/Video'

interface Payload {
    guid: string
}

export const useGenerateClosedCaptionsCancelMutation = (options?: Options<Video, Payload, string>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Video, Payload, string>(
        GENERATE_CLOSED_CAPTIONS_CANCEL_MUTATION,
        async ({ guid }: Payload) => {
            return mutate(settingsAPI.generateClosedCaptionsCancel(guid), apiLocale.settings.closedCaptions.cancel)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY],
        },
    )
}
