import { UseQueryOptions, useQuery } from 'react-query'

import PlayerOptions from 'types/PlayerConfig'
import { videoAPI } from 'api/controllers'
import { VIDEO_OPTIONS_QUERY } from 'api/constants'

export const usePlayerOptionsQuery = (
    id: string,
    mode = 'graph',
    options?: UseQueryOptions<PlayerOptions, unknown, PlayerOptions>,
) => useQuery<PlayerOptions>([VIDEO_OPTIONS_QUERY, id], () => videoAPI.getPlayerOptions(id, mode), options)
