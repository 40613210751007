import { PlayerOnPlaybackEndType, VideoStartupQuality } from 'types/Player'
import * as yup from 'yup'
import { TagType } from 'types/VideoTag'
import { UnmuteType } from 'types/Video'
import {
    CallToActionType,
    VideoCallToActionCustomHTMLSelectorType,
    VideoCallToActionDisplayMode,
} from 'types/VideoCallToAction'
import { VideoPlayGateType } from 'types/VideoPlayGate'
import { getTotalSeconds } from 'design/organisms/VidSettingsSidebar/MarketingSettings/helpers/getTotalSeconds'
import { GUID } from 'constants/api.constants'

export enum ActionNotifications {
    empty = '',
    saving = 'saving',
    saved = 'saved',
}

// Settings
export const SETTINGS = 'settings'
export const TARGETED = 'targeted'

// Player colors
export const UI_COLOR_FOREGROUND = 'ui_color_foreground'
export const UI_COLOR_BACKGROUND = 'ui_color_background'
export const UI_COLOR_FORCE = 'ui_color_force'

// Seeking bar
export const UI_CONTROL_BAR_SEEK_BAR_SHOW = 'ui_controlBar_seekBar_show'
export const UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS = 'ui_controlBar_seekBar_showToReturningViewers'
export const UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW = 'ui_controlBar_smartSeekBar_show'
export const UI_CONTROL_BAR_ALWAYS_SHOW = 'ui_controlBar_alwaysShow'
export const NONE = 'none'

// Controls
export const UI_OVERLAY_PLAY_BUTTOW_SHOW = 'ui_overlay_play_button_show'
export const UI_CONTROL_BAR_PLAY_SHOW = 'ui_controlBar_play_show'
export const UI_CONTROL_BAR_SETTINGS = 'ui_controlBar_settings'
export const UI_CONTROL_BAR_QUALITY_CONTROL_SHOW = 'ui_controlBar_qualityControl_show'
export const UI_CONTROL_BAR_SPEED_CONTROL_SHOW = 'ui_controlBar_speedControl_show'
export const UI_CONTROL_BAR_VOLUME_SHOW = 'ui_controlBar_volume_show'
export const UI_CONTROL_BAR_FULLSCREEN_SHOW = 'ui_controlBar_fullscreen_show'
export const UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT = 'ui_controlBar_fullscreen_customFullscreen_default_enabled'
export const UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE = 'ui_controlBar_fullscreen_customFullscreen_mobile_enabled'
export const UI_CONTROL_BAR_REWIND_SHOW = 'ui_controlBar_rewind_show'
export const UI_OVERLAY_PLAY_SEEK_BUTTONS_SHOW = 'ui_overlay_play_seekButtons_show'
export const PLAYBACK_SKIP_AND_REWIND_WITH_KEYBOARD = 'playback_skipAndRewindWithKeyboard'

// Closed Captions
export const UI_CLOSED_CAPTIONS_NAME = 'ui_closedCaptions_name'
export const UI_CLOSED_CAPTIONS_DEFAULT_DISPLAY = 'ui_closedCaptions_default_displayWhenMuted'
export const UI_CLOSED_CAPTIONS_MOBILE_DISPLAY = 'ui_closedCaptions_mobile_displayWhenMuted'
export const UI_CLOSED_CAPTIONS_DEFAULT_ENABLED = 'ui_closedCaptions_default_enabled'
export const UI_CLOSED_CAPTIONS_MOBILE_ENABLED = 'ui_closedCaptions_mobile_enabled'

// Playback
export const PLAYBACK_AUTOPLAY_ENABLED = 'playback_autoplay_enabled'
export const UI_OVERLAY_UNMUTE_TYPE = 'ui_overlay_unmute_type'

export const UI_OVERLAY_UNMUTE_TEXT_TOP_TEXT = 'ui_overlay_unmute_textTop_text'
export const UI_OVERLAY_UNMUTE_TEXT_BOTTOM_TEXT = 'ui_overlay_unmute_textBottom_text'
export const UI_OVERLAY_UNMUTE_MOBILE_TEXT_TOP_TEXT = 'ui_overlay_unmute_mobile_textTop_text'
export const UI_OVERLAY_UNMUTE_MOBILE_TEXT_BOTTOM_TEXT = 'ui_overlay_unmute_mobile_textBottom_text'

export const UI_OVERLAY_UNMUTE_COLOR_FOREGROUND = 'ui_overlay_unmute_color_foreground'
export const UI_OVERLAY_UNMUTE_COLOR_BACKGROUND = 'ui_overlay_unmute_color_background'

export const UI_OVERLAY_UNMUTE_DO_REPLAY = 'ui_overlay_unmute_doReplay'
export const PLAYBACK_AUTOPLAY_ONLY_MUTED = 'playback_autoplay_onlyMuted'
export const PLAYBACK_AUTOPLAY_MOBILE = 'playback_autoplay_mobile'
export const UI_OVERLAY_UNMUTE_GO_FULLSCREEN_DEFAULT_ENABLED = 'ui_overlay_unmute_goFullscreen_default_enabled'
export const UI_OVERLAY_UNMUTE_GO_FULLSCREEN_MOBILE_ENABLED = 'ui_overlay_unmute_goFullscreen_mobile_enabled'
export const UI_OVERLAY_UNMUTE_MOBILE_ENABLED = 'ui_overlay_unmute_mobile_enabled'

// Play Options
export const PLAYER_STARTUP_QUALITY = 'player_startupQuality'
export const PLAYER_LOWER_BANDWIDTH_USAGE = 'player_lowerBandwidthUsage'
export const PLAYBACK_RESUME = 'playback_resume'
export const UI_OVERLAY_RESUME_SHOW = 'ui_overlay_resume_show'
export const PLAYBACK_ON_END = 'playback_onEnd'
export const UI_OVERLAY_REDIRECT_URL = 'ui_overlay_redirect_url'
export const UI_OVERLAY_REDIRECT_TIMEOUT = 'ui_overlay_redirect_timeout'
export const UI_OVERLAY_RESUME_TEXT = 'ui_overlay_resume_text'
export const UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT = 'ui_overlay_resume_button_resume_text'
export const UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT = 'ui_overlay_resume_button_replay_text'
export const UI_OVERLAY_RESUME_COLOR_FOREGROUND = 'ui_overlay_resume_color_foreground'
export const UI_OVERLAY_RESUME_COLOR_BACKGROUND = 'ui_overlay_resume_color_background'
export const UI_OVERLAY_REDIRECT_SHOW = 'ui_overlay_redirect_show'
export const UI_OVERLAY_REDIRECT_TEXT = 'ui_overlay_redirect_text'
export const UI_OVERLAY_REDIRECT_COLOR_FOREGROUND = 'ui_overlay_redirect_color_foreground'
export const UI_OVERLAY_REDIRECT_COLOR_BACKGROUND = 'ui_overlay_redirect_color_background'
export const PLAYBACK_SMART_PAUSE = 'playback_smartPause'
export const PLAYBACK_SMART_PAUSE_RESUME = 'playback_smartPauseResume'
export const PLAYBACK_NO_PAUSE = 'playback_noPause'

export const UI_SHADOW = 'ui_shadow'

// Calls To Action
export const CALL_TO_ACTIONS = 'callToActions'
export const TITLE = 'title'
export const LINK = 'link'
export const LINK_IN_NEW_WINDOW = 'linkInNewWindow'
export const DISPLAY_MODE = 'displayMode'
export const HTML_SELECTOR_TYPE = 'htmlSelectorType'
export const HTML_SELECTOR = 'htmlSelector'
export const TYPE = 'type'
export const TIME_FROM = 'timeFrom'
export const TIME_TO = 'timeTo'
export const SHOW_TO_RETURNING_VIEWERS = 'showToReturningViewers'
export const SHOW_ONLY_WHEN_TRIGGERED_BEFORE = 'showOnlyWhenTriggeredBefore'
export const COLOR_BACKGROUND = 'color_background'
export const COLOR_HOVER_BACKGROUND = 'colorHover_background'
export const COLOR_FOREGROUND = 'color_foreground'
export const COLOR_HOVER_FOREGROUND = 'colorHover_foreground'
export const SHADOW = 'shadow'

// Tags
export const TAGS = 'tags'
export const NAME = 'name'
export const TIME = 'time'
export const CUSTOM_VARIABLES = 'customVariables'
export const HUBSPOT_CONTACT_LIST_ID = 'hubspotContactListId'
export const FIELDS = 'fields'
export const CREATE_TAG = 'createTag'

// Play Gate
export const PLAY_GATES = 'playGates'
export const TEXT_TOP = 'textTop'
export const TEXT_BOTTOM = 'textBottom'
export const BUTTON_TEXT = 'buttonText'
export const TEXT_SKIP = 'textSkip'
export const PLAY_GATE_NAME_SHOW = 'nameShow'
export const PLAY_GATE_PHONE_SHOW = 'phoneShow'
export const PLAY_GATE_EMAIL_SHOW = 'emailShow'
export const PLAY_GATE_SWITCH_VALIDATE = 'switchValidate'
export const PLAY_GATE_ONLY_ONE = 'playGateOnlyOne'
export const PLAY_GATE_TIME_UNIQ = 'playGateTimeUniq'
export const PLAY_GATE_SKIPPABLE = 'skippable'
export const PLAY_GATE_BUTTON_COLOR_BACKGROUND = 'buttonColorBackground'
export const PLAY_GATE_BUTTON_COLOR_FOREGROUND = 'colorForeground'
export const PLAY_GATE_CHECKBOX = 'checkbox'
export const PLAY_GATE_CHECKBOX_REQUIRED = 'checkboxRequired'
export const BUTTON_COLOR_BACKGROUND = 'colorBackground'
export const BUTTON_COLOR_FOREGROUND = 'buttonColorForeground'

// Thumbnails
export const UI_THUMBNAIL_FULL_SIZE = 'ui_thumbnail_fullSize'
export const UI_OVERLAY_PAUSE_SOURCE = 'ui_overlay_pause_source'
export const UI_THUMBNAIL_MOBILE_SOURCE = 'ui_thumbnail_mobile_source'

// Expire Video
export const UI_OVERLAY_EXPIRE_ENABLED = 'ui_overlay_expire_enabled'
export const UI_OVERLAY_EXPIRE_DATE_EXPIRE = 'ui_overlay_expire_dateExpire'
export const UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT = 'ui_overlay_expire_textTop_text'
export const UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT = 'ui_overlay_expire_textBottom_text'
export const UI_OVERLAY_EXPIRE_LINK_HREF = 'ui_overlay_expire_link_href'
export const UI_OVERLAY_EXPIRE_LINK_BLANK = 'ui_overlay_expire_link_blank'
export const UI_OVERLAY_EXPIRE_COLOR_FOREGROUND = 'ui_overlay_expire_color_foreground'
export const UI_OVERLAY_EXPIRE_COLOR_BACKGROUND = 'ui_overlay_expire_color_background'

export const CONTROLS_SECTION_INITIAL_VALUES = {
    [UI_COLOR_FOREGROUND]: '#ffffff',
    [UI_COLOR_BACKGROUND]: '#3974ff',
    [UI_COLOR_FORCE]: true,
    [UI_SHADOW]: true,

    [UI_CONTROL_BAR_SEEK_BAR_SHOW]: true,
    [UI_CONTROL_BAR_SHOW_TO_RETURNING_VIEWERS]: false,
    [UI_CONTROL_BAR_SMART_SEEK_BAR_SHOW]: false,
    [UI_CONTROL_BAR_ALWAYS_SHOW]: false,

    [UI_OVERLAY_PLAY_BUTTOW_SHOW]: true,
    [UI_CONTROL_BAR_PLAY_SHOW]: true,
    [UI_CONTROL_BAR_SETTINGS]: true,
    [UI_CONTROL_BAR_QUALITY_CONTROL_SHOW]: true,
    [UI_CONTROL_BAR_SPEED_CONTROL_SHOW]: true,
    [UI_CONTROL_BAR_VOLUME_SHOW]: true,
    [UI_CONTROL_BAR_FULLSCREEN_SHOW]: false,
    [UI_CONTROL_BAR_FULLSCREEN_CUSTOM_DEFAULT]: false,
    [UI_CONTROL_BAR_FULLSCREEN_CUSTOM_MOBILE]: false,
    [UI_CONTROL_BAR_REWIND_SHOW]: true,
    [UI_OVERLAY_PLAY_SEEK_BUTTONS_SHOW]: false,
    [PLAYBACK_SKIP_AND_REWIND_WITH_KEYBOARD]: false,
}

export const THUMBNAILS_SECTION_INITIAL_VALUES = {
    [UI_THUMBNAIL_FULL_SIZE]: false,
}

export const CLOSED_CAPTIONS_SECTION_INITIAL_VALUES = {
    [UI_CLOSED_CAPTIONS_DEFAULT_DISPLAY]: false,
    [UI_CLOSED_CAPTIONS_MOBILE_DISPLAY]: false,
    [UI_CLOSED_CAPTIONS_MOBILE_ENABLED]: true,
    [UI_CLOSED_CAPTIONS_DEFAULT_ENABLED]: false,
}

export const ADVANCED_PLAYBACK_TURNED_OFF = {
    [UI_OVERLAY_UNMUTE_DO_REPLAY]: false,
    [PLAYBACK_AUTOPLAY_ONLY_MUTED]: false,
    [PLAYBACK_AUTOPLAY_MOBILE]: false,
    [UI_OVERLAY_UNMUTE_GO_FULLSCREEN_DEFAULT_ENABLED]: false,
    [UI_OVERLAY_UNMUTE_GO_FULLSCREEN_MOBILE_ENABLED]: false,
    [UI_OVERLAY_UNMUTE_MOBILE_ENABLED]: false,
}

export const PLAYBACK_SECTION_INITIAL_VALUES = {
    [PLAYBACK_AUTOPLAY_ENABLED]: true,
    [UI_OVERLAY_UNMUTE_TYPE]: UnmuteType.box,
    [UI_OVERLAY_UNMUTE_TEXT_TOP_TEXT]: 'Your Video Is Playing',
    [UI_OVERLAY_UNMUTE_TEXT_BOTTOM_TEXT]: 'Click To Unmute',
    [UI_OVERLAY_UNMUTE_MOBILE_TEXT_TOP_TEXT]: 'Click To Unmute',
    [UI_OVERLAY_UNMUTE_MOBILE_TEXT_BOTTOM_TEXT]: 'Tap for Sound',
    [UI_OVERLAY_UNMUTE_COLOR_FOREGROUND]: '#ffffff',
    [UI_OVERLAY_UNMUTE_COLOR_BACKGROUND]: '#3974ff',

    ...ADVANCED_PLAYBACK_TURNED_OFF,
    [UI_OVERLAY_UNMUTE_DO_REPLAY]: true,
    [PLAYBACK_AUTOPLAY_MOBILE]: true,
}

export const PLAY_OPTIONS_INITIAL_VALUES = {
    [PLAYER_STARTUP_QUALITY]: VideoStartupQuality.AUTO,
    [PLAYER_LOWER_BANDWIDTH_USAGE]: false,
    [PLAYBACK_RESUME]: false,
    [UI_OVERLAY_RESUME_SHOW]: false,
    [PLAYBACK_ON_END]: PlayerOnPlaybackEndType.PAUSE,
    [UI_OVERLAY_REDIRECT_URL]: '',
    [UI_OVERLAY_REDIRECT_TIMEOUT]: 0,
    [UI_OVERLAY_REDIRECT_SHOW]: false,
    [UI_OVERLAY_REDIRECT_TEXT]: 'You will be redirected in %d seconds',
    [UI_OVERLAY_REDIRECT_COLOR_FOREGROUND]: '#ffffff',
    [UI_OVERLAY_REDIRECT_COLOR_BACKGROUND]: '#3974ff',
    [PLAYBACK_SMART_PAUSE]: false,
    [PLAYBACK_SMART_PAUSE_RESUME]: false,
    [PLAYBACK_NO_PAUSE]: false,
    [UI_OVERLAY_RESUME_TEXT]: `Welcome Back!
You\u0027ve already started
watching this video`,
    [UI_OVERLAY_RESUME_BUTTON_RESUME_TEXT]: `Continue
watching?`,
    [UI_OVERLAY_RESUME_BUTTON_REPLAY_TEXT]: `Start from the
Beginning?`,
}

export const EXPIRE_VIDEO_SECTION_INITIAL_VALUES = {
    [UI_OVERLAY_EXPIRE_ENABLED]: false,
    [UI_OVERLAY_EXPIRE_DATE_EXPIRE]: '',
    [UI_OVERLAY_EXPIRE_TEXT_TOP_TEXT]: 'This Video Has Expired',
    [UI_OVERLAY_EXPIRE_TEXT_BOTTOM_TEXT]: 'Click Here to Go Back',
    [UI_OVERLAY_EXPIRE_LINK_HREF]: 'Bottom Text Link',
    [UI_OVERLAY_EXPIRE_LINK_BLANK]: false,
    [UI_OVERLAY_EXPIRE_COLOR_FOREGROUND]: '#ffffff',
    [UI_OVERLAY_EXPIRE_COLOR_BACKGROUND]: '#3974ff',
}

export const CALLS_TO_ACTION_SECTION_INITIAL_VALUES = {
    [GUID]: '',
    [TITLE]: 'Let’s Do This',
    [LINK]: '',
    [LINK_IN_NEW_WINDOW]: false,
    [DISPLAY_MODE]: VideoCallToActionDisplayMode.reserveSpace,
    [HTML_SELECTOR_TYPE]: VideoCallToActionCustomHTMLSelectorType.class,
    [HTML_SELECTOR]: '',
    [TYPE]: CallToActionType.time,
    [TIME_TO]: '',
    [TIME_FROM]: '',
    [SHOW_TO_RETURNING_VIEWERS]: false,
    [SHOW_ONLY_WHEN_TRIGGERED_BEFORE]: false,
    [COLOR_BACKGROUND]: '#3974ff',
    [COLOR_HOVER_BACKGROUND]: '#2d57ba',
    [COLOR_FOREGROUND]: '#ffffff',
    [COLOR_HOVER_FOREGROUND]: '#ffffff',
    [SHADOW]: false,
}
export const PLAY_GATES_SECTION_INITIAL_VALUES = {
    [GUID]: '',
    [TEXT_TOP]: `Enter your info to get this video in your inbox.`,
    [TEXT_BOTTOM]: 'We hate spam and will never ever spam you. You can opt out at any time.',
    [BUTTON_TEXT]: 'Keep Watching',
    [PLAY_GATE_NAME_SHOW]: false,
    [TIME]: '00:00',
    [PLAY_GATE_PHONE_SHOW]: false,
    [PLAY_GATE_EMAIL_SHOW]: true,
    [PLAY_GATE_BUTTON_COLOR_BACKGROUND]: '#d0021b',
    [PLAY_GATE_BUTTON_COLOR_FOREGROUND]: '#ffffff',
    [TYPE]: VideoPlayGateType.time,
    [PLAY_GATE_SKIPPABLE]: false,
    [PLAY_GATE_CHECKBOX]: false,
    [PLAY_GATE_CHECKBOX_REQUIRED]: false,
    [TEXT_SKIP]: 'Skip this',
    [PLAY_GATE_SWITCH_VALIDATE]: true,
    [PLAY_GATE_ONLY_ONE]: true,
    [PLAY_GATE_TIME_UNIQ]: true,
}
export const TAGS_SECTION_INITIAL_VALUES = {
    [GUID]: '',
    [NAME]: '',
    [TIME]: '00:00',
    [TYPE]: TagType.time,
    [CUSTOM_VARIABLES]: [],
    [HUBSPOT_CONTACT_LIST_ID]: null,
}

// VALIDATION CONSTANTS

export const timePatternValidator = new RegExp('^(([0-9]?[0-9]):)?([0-9]?[0-9]):([0-9][0-9])$')

export const validateTimeLength = (time?: string, videoLength?: string) => {
    return !(!time || getTotalSeconds(time) > getTotalSeconds(videoLength || '0'))
}

export const validateVideoLengthSchema = (videoLength: string) => {
    return yup.string().when(TYPE, {
        is: (type: CallToActionType) => type === CallToActionType.exit,
        then: yup.string(),
        otherwise: yup
            .string()
            .matches(timePatternValidator, "Please enter the time as '00:00' or '00:00:00'")
            .test('time-from-before-video-length', 'Time should be less than the total video duration.', (time) =>
                time ? validateTimeLength(time, videoLength) : true,
            ),
    })
}
export const PLAYER_DOCS_FOCUSED_FULLSCREEN_LINK =
    'https://player-docs.vidalytics.com/extra-features/focused-fullscreen/'
