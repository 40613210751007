import _get from 'lodash/get'
import _has from 'lodash/has'
import _keys from 'lodash/keys'

export const getPropsDiff = <T>(obj1: T, obj2: T, prefix = ''): string[] => {
    const diffKeys: string[] = []

    const allKeys = new Set([..._keys(obj1), ..._keys(obj2)])

    allKeys.forEach((key: string) => {
        const fullKey = prefix ? `${prefix}.${key}` : key

        const val1 = _get(obj1, key)
        const val2 = _get(obj2, key)

        if (typeof val1 === 'object' && typeof val2 === 'object' && val1 !== null && val2 !== null) {
            diffKeys.push(...getPropsDiff(val1, val2, fullKey))
        } else if (val1 !== val2) {
            diffKeys.push(fullKey)
        } else if (!_has(obj1, key) || !_has(obj2, key)) {
            diffKeys.push(fullKey)
        }
    })

    return diffKeys
}
