import { apiLocale } from 'api/api.locale'
import { SMART_VID_DETAILS_QUERY, UPDATE_VIDEO_THUMBNAIL, VIDEOS_QUERY } from 'api/constants'
import { settingsAPI } from 'api/controllers'
import { Options, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { useActualVideoGuid } from 'hooks/video/useActualVideoGuid'
import { ThumbnailVideoType, VideoGuid } from 'types/Video'

type Payload = {
    videoGuid?: VideoGuid
    from: number
    to: number
    type?: ThumbnailVideoType
}

export const useUpdateVideoThumbnailMutation = (options?: Options<Payload, Payload, string>) => {
    const vidId = useActualVideoGuid()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Payload, Payload, string>(
        UPDATE_VIDEO_THUMBNAIL,
        async ({ from, to, type }: Payload) => {
            if (!vidId) return

            return mutate(settingsAPI.updateVideoThumbnail(vidId, from, to, type), apiLocale.settings.thumbnail.update)
        },
        {
            ...options,
            invalidate: [VIDEOS_QUERY, SMART_VID_DETAILS_QUERY],
        },
    )
}
