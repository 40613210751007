import { PublicClientApplication } from '@azure/msal-browser'

export const msalConfig = {
    auth: {
        clientId: String(process.env.REACT_APP_MICROSOFT_CLIENT_ID),
        redirectUri: `${window.location.origin}/microsoft-auth`,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
}

export const getMsalInstance = new PublicClientApplication(msalConfig)
