import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const link: SxProps<Theme> = {
    color: '#fff',
    textDecoration: 'underline',

    '&:hover': {
        color: '#fff',
    },
}

export default {
    link,
}
