import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const toolbar: SxProps<Theme> = {
    width: 1,
    boxShadow: 1,
    py: 3,
    px: 5,
}

const logo: SxProps<Theme> = {
    height: 32,
    width: 'auto',
}

const menuItem: SxProps<Theme> = {
    display: 'flex',
    gap: 4,
}

export default {
    toolbar,
    logo,
    menu: {
        item: menuItem,
    },
}
