import { useQuery, UseQueryOptions } from 'react-query'

import { VideoEmbed } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { Id } from 'types'
import { VIDEO_EMBED_QUERY } from 'api/constants'

export const useVideoEmbedQuery = (id?: Id, options?: UseQueryOptions<VideoEmbed, unknown>) =>
    useQuery<VideoEmbed>([VIDEO_EMBED_QUERY, id], () => videoAPI.getVideoEmbed(String(id)), {
        enabled: Boolean(id),
        ...options,
    })
