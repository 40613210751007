import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_TAGS_ROW_DATA } from 'api/constants'
import { GetTagsRowDataQuery, GetTagsRowDataResponse } from 'api/contracts/dashboard/endpoints/getTagsRowData.contract'
import { addIdToRows } from 'api/utils/dashboard.utils'

const tagsRowDataMock: GetTagsRowDataResponse = {
    request: {
        customerId: 24495,
        dateFrom: '2024-08-01T00:00:00Z',
        dateTo: '2024-08-03T00:00:00Z',
    },
    data: [
        {
            name: 'User 1',
            videoTitle: 'How to Learn TypeScript',
            triggeredTimes: 50,
        },
        {
            name: 'User 2',
            videoTitle: 'Introduction to React',
            triggeredTimes: 65,
        },
        {
            name: 'User 3',
            videoTitle: 'Advanced JavaScript Techniques',
            triggeredTimes: 40,
        },
        {
            name: 'User 4',
            videoTitle: 'Understanding CSS Grid',
            triggeredTimes: 55,
        },
        {
            name: 'User 5',
            videoTitle: 'Mastering Python',
            triggeredTimes: 60,
        },
    ],
}

export const useGetTagsRowDataQuery = (query: GetTagsRowDataQuery) => {
    const getTagsRowDataQuery = useQuery<GetTagsRowDataResponse>(DASHBOARD_GET_TAGS_ROW_DATA, () =>
        dashboardController.getTagsRowData(query),
    )

    return {
        tagsRowData: addIdToRows(getTagsRowDataQuery.data?.data),
        getTagsRowDataQuery,
        tagsRowDataMock,
    }
}
