import { useFormikContext } from 'formik'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

import { Modal } from 'design/templates/Modal'
import { locale } from 'locales'
import style from '../EditConversionModal.style'

interface ActionsProps {
    onClose(): void
    isMutationLoading: boolean
}

export const Actions = ({ onClose, isMutationLoading }: ActionsProps) => {
    const { isValidating, submitForm } = useFormikContext()

    return (
        <Modal.Actions sx={style.actions}>
            <Button color="primary" variant="outlined" onClick={onClose}>
                {locale.operations.generic.cancel}
            </Button>
            <LoadingButton
                color="primary"
                variant="contained"
                onClick={submitForm}
                disabled={!isValidating && isMutationLoading}
                loading={!isValidating && isMutationLoading}
            >
                {locale.operations.generic.save}
            </LoadingButton>
        </Modal.Actions>
    )
}
