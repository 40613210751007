import { locale } from 'locales'

export default {
    cta: 'Sign up with Google',
    notifications: {
        errors: {
            generic: locale.messages.errors.generic,
            genericWithCta: `${locale.messages.errors.generic}. Try again.`,
            parseFailure: 'Account information can not be parsed correctly.',
            dataUnavailable: 'Account information is not available. Please, enter data manually.',
        },
    },
}
