export const ID = 'id'
export const GUID = 'guid'
export const DATA = 'data'

// VIDEO folders
export const ROOT = 'root'
export const MAIN = 'Main'

export const CREATE = 'create'

export const SAVE_TIMEOUT = 500
