import { SxProps, Theme, alpha } from '@mui/material'

const wrapperFullWidth: SxProps = {
    flexGrow: 1,
    width: '100%',
}

const input: SxProps<Theme> = (theme) => ({
    ...theme.typography.body1,
    boxShadow: theme.shadows[2],

    input: {
        height: 'unset',
    },

    '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
        borderColor: () => alpha('#2E476F', 0.23),

        legend: {
            display: 'none',
        },
    },
    '&.MuiInputBase-multiline': {
        pr: 1, // to remove offset of scrollbar
    },
})

const multiline: SxProps<Theme> = {
    height: 'unset',
}

const error: SxProps = {
    ml: 0,
    textWrap: 'wrap',
}

export default {
    wrapperFullWidth,
    input,
    multiline,
    error,
}
