import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { MetricsSwitchesState, MetricKey, SetMetricPayload } from './types'

const initialState: MetricsSwitchesState = {
    [MetricKey.heatmap]: true,
    [MetricKey.heatmapSkips]: true,
    [MetricKey.heatmapRewinds]: true,
    [MetricKey.audience]: false,
    [MetricKey.conversions]: false,
    [MetricKey.engagement]: false,
    [MetricKey.fullScreen]: false,
    [MetricKey.volume]: false,
    [MetricKey.closedCaptions]: false,
    [MetricKey.speedUps]: false,
    [MetricKey.pauses]: false,
}

export const metricsSwitchesSlice = createSlice({
    name: 'metricsSwitches',
    initialState,
    reducers: {
        setMetricValue: (state, action: PayloadAction<SetMetricPayload>) => {
            const { key, value } = action.payload

            state[key] = value
        },
    },
})

export const { setMetricValue } = metricsSwitchesSlice.actions

export const selectMetricsSwitchesState = (state: RootState) => state.metricsSwitches
export const selectAnyMetricsEnabled = (state: RootState) =>
    Object.entries(state.metricsSwitches).some((pair) => pair[1])

export * from './types'

export const metricsSwitches = metricsSwitchesSlice.reducer
