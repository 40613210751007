import { useMemo } from 'react'
import { Typography } from '@mui/material'

import { useGlobalSettingsFormContext } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    UI_COLOR_BACKGROUND,
    UI_COLOR_FORCE,
    UI_COLOR_FOREGROUND,
    UI_OVERLAY_UNMUTE_TYPE,
    UI_SHADOW,
} from 'design/pages/VidSettings/constants'
import { ColorPicker } from 'design/atoms/Form'
import { ControlsSectionFormData } from 'types/VidSettings'
import { UnmuteType } from 'types/Video'
import { SwitchForm, SwitchTooltipPlacement } from 'design/atoms/Switch'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import style from 'design/organisms/VidSettingsSidebar/Sidebar.styles'
import locale from './PlayerColors.locale'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

export const PlayerColors = ({ isSmartVidGlobalSettings }: Props) => {
    const useSectionContext = useMemo(
        () => (isSmartVidGlobalSettings ? useGlobalSettingsFormContext : useSettingsSectionContext),
        [isSmartVidGlobalSettings],
    )
    const { video, isLoading, onChange } = useSectionContext<ControlsSectionFormData>()
    const commonProps = {
        afterChange: onChange,
        disabled: isLoading,
        switchProps: { size: 'small' },
    } as const
    const isAutoplayOverlaySelectedNone = video?.settings[UI_OVERLAY_UNMUTE_TYPE] === UnmuteType.none

    return (
        <Section title="Player colors" helpInfo={locale.controls.section} indent>
            <ColorPicker name={UI_COLOR_FOREGROUND} label={locale.controls.foreground} {...commonProps} prefixed />
            <ColorPicker name={UI_COLOR_BACKGROUND} label={locale.controls.background} {...commonProps} prefixed />
            <SwitchForm
                sx={style.container}
                name={UI_SHADOW}
                label={<Typography variant="body2">{locale.controls.dropShadow.label}</Typography>}
                tooltipPlacement={SwitchTooltipPlacement.inside}
                helpInfo={locale.controls.dropShadow.hint}
                labelPlacement="start"
                {...commonProps}
                disabled={isAutoplayOverlaySelectedNone || commonProps.disabled}
            />
            <SwitchForm
                sx={style.container}
                name={UI_COLOR_FORCE}
                label={<Typography variant="body2">{locale.controls.useColors.label}</Typography>}
                helpInfo={locale.controls.useColors.hint}
                labelPlacement="start"
                tooltipPlacement={SwitchTooltipPlacement.inside}
                {...commonProps}
            />
        </Section>
    )
}
