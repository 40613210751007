import { createTheme, PaletteMode } from '@mui/material'

import { paletteLight } from './theme/paletteLight'
import { paletteDark } from './theme/paletteDark'
import { breakpoints } from './theme/breakpoints'
import * as components from './theme/components'
import { shape } from './theme/shape'
import { typography } from './theme/typography'
import { shadows } from './theme/shadows'
import { gridPtSpacing } from './constants'

export const theme = (mode: PaletteMode) =>
    createTheme({
        breakpoints,
        components,
        palette: mode === 'light' ? paletteLight : paletteDark,
        shape,
        shadows,
        typography,
        spacing: gridPtSpacing,
    })
