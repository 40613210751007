import { AxiosRequestConfig } from 'axios'
import PlayerOptions from 'types/PlayerConfig'
import {
    VideoGuid,
    Video,
    VideoDetails,
    VideoEmbedPreview,
    CreateFolderPayload,
    FolderAPI,
    FolderNameChangePayload,
    MoveToFolderPayload,
    ChangePlayerTypePayload,
    VideoEmbed,
    PublicPreviewPermission,
    GoogleDriveFileUploadPayload,
    DropboxFileUploadPayload,
    VidConditionTargets,
} from 'types/Video'
import { Id } from 'types'
import { http } from 'api/utils/http.utils'

export const videoAPI = {
    // Videos
    uploadVideo: (payload: File, config: AxiosRequestConfig) => http.post<File, Video>('do-upload', payload, config),
    uploadVideoByLink: (payload: GoogleDriveFileUploadPayload | DropboxFileUploadPayload, config: AxiosRequestConfig) =>
        http.post<GoogleDriveFileUploadPayload | DropboxFileUploadPayload, Video>(
            'video/by-link-upload',
            payload,
            config,
        ),
    getVideo: (id: Id) => http.get<VideoDetails>(`video/${id}`),
    updateVideo: (id: Id, details: VideoDetails) =>
        http.post<{ video: VideoDetails }>(`video/${id}`, { video: details }),
    replaceVideo: (oldId: Id, newId: Id) => http.post(`video/${oldId}/replace/${newId}`),
    getEmbedVideoPreview: (id: Id, newPlayer?: boolean) =>
        http.get<VideoEmbedPreview>(`video/${id}/embed-preview${newPlayer ? '?player=new' : ''}`),
    getVideos: (filters: { filter_without_funnel?: string; filter_accessible_for_funnel?: string }) =>
        http.get<Video[]>(`videos?${new URLSearchParams(filters).toString()}`),
    getPlayerOptions: (id: Id, mode: string) => http.get<PlayerOptions>(`video/${id}/settings?mode=${mode}`),
    publishVideo: (id: Id) => http.post(`video/${id}/publish`),
    deleteVideo: (id: Id) => http.delete(`video/${id}`),
    duplicateVideo: (id: Id): Promise<Video> => http.post(`video/${id}/duplicate`),
    massRepublish: (): Promise<null> => http.post(`video/mass-republish`),
    getVideoEmbed: (id: Id) => http.get<VideoEmbed>(`video/${id}/embed`),
    setVideoEmbedPublicShare: (id: Id, publicPreviewAllowed: PublicPreviewPermission) =>
        http.post<PublicPreviewPermission, VideoEmbed>(`video/${id}/embed`, publicPreviewAllowed),
    vidConditions: (guid: VideoGuid, payload: VidConditionTargets, config?: AxiosRequestConfig): Promise<string> =>
        http.put(`video/${guid}/vid-conditions`, payload, config),

    // Folders
    getFolders: () => http.get<FolderAPI>('video/folders'),
    createFolder: (payload: CreateFolderPayload): Promise<FolderAPI> =>
        http.post<CreateFolderPayload, FolderAPI>('video/folder', payload),
    changeFolderName: (payload: FolderNameChangePayload): Promise<FolderAPI> =>
        http.post<FolderNameChangePayload, FolderAPI>(`video/folder/${payload.folder.guid}`, payload),
    deleteFolder: (id: Id) => http.delete(`video/folder/${id}`),
    moveToFolder: (payload: MoveToFolderPayload) => http.put(`video/${payload.itemId}/folder/${payload.targetId}`),
    moveFolderToFolder: (payload: MoveToFolderPayload) =>
        http.put(`video/folder/${payload.targetId}/folder/${payload.itemId}`),
    changePlayerType: (payload: ChangePlayerTypePayload): Promise<FolderAPI> =>
        http.post(`/video/${payload.id}/switch-player`, { player: payload.player }),
    deleteVideoThumbnailEncodingProcess: (id: Id) => http.delete(`video/${id}/cancel`),
}
