import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { useCustomerQuery } from 'api/queries'

export default function useHideIntercom(hide = false) {
    const { data: customer } = useCustomerQuery()
    const { isFree, data: subscription } = useAccountSubscription()

    if (isFree || !subscription || !customer || hide) {
        return (
            <style>
                {`
                    .intercom-lightweight-app {
                        display: none
                    }
                `}
            </style>
        )
    }

    return null
}
