import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { DOWNLOAD_INVOICE_MUTATION } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useDownloadInvoiceMutation = (options?: UseMutationOptions<BlobPart, any, Id>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<BlobPart, Id>(
        DOWNLOAD_INVOICE_MUTATION,
        async (id: Id) => mutate(userAPI.getDownloadInvoice(id), apiLocale.user.invoiceDownload),
        {
            ...options,
        },
    )
}
