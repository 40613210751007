import { UseMutationOptions, useQueryClient } from 'react-query'

import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { SEGMENT_KEY_QUERY, SEGMENT_STATUS_QUERY } from 'api/constants'
import { integrationsAPI } from 'api/controllers'
import { useInvalidateMutation } from 'api/hooks'

export const useDeleteSegmentIntegrationMutation = (options?: UseMutationOptions<null, unknown, null>) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, null>(
        SEGMENT_KEY_QUERY,
        async () =>
            mutate(integrationsAPI.deleteSegmentKey(), {
                pending: 'Deleting key',
                error: 'Integration deleted',
                success: 'Failed to delete integration',
            }),
        {
            ...options,
            onSuccess: () => {
                queryClient.resetQueries(SEGMENT_STATUS_QUERY)
            },
        },
    )
}
