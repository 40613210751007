import { Dispatch, SetStateAction, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus, AccountInfo } from '@azure/msal-browser'
import { useSearchParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useToast } from 'design/organisms/ToastProvider'
import { ALLOW_MICROSOFT_ACCOUNT_USAGE, MICROSOFT_REMEMBER_ME } from 'design/molecules/MicrosoftAuth/constants'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { SignInResponse, SignInWithMicrosoftPayload } from 'types/Auth'
import { useSignInWithMicrosoftMutation } from 'api/mutations'

import style from './MicrosoftButton.style'
import locale from './microsoft-button.locale'

type MicrosoftButtonProps = {
    rememberMe: boolean
    disabled: boolean
    setIsLoading: Dispatch<SetStateAction<boolean>>
    onSuccess: (response: SignInResponse, payload: SignInWithMicrosoftPayload) => void
}

const scopes = {
    scopes: ['User.Read'],
}

export const getLatestSignedInAccount = (accounts: AccountInfo[]): AccountInfo | null =>
    accounts.reduce((latestAccount, account) => {
        if (
            account.idTokenClaims?.iat &&
            latestAccount.idTokenClaims?.iat &&
            account.idTokenClaims.iat - latestAccount.idTokenClaims.iat > 0
        ) {
            return account
        }

        return latestAccount
    })

const MicrosoftButton = ({ rememberMe, disabled, setIsLoading, onSuccess }: MicrosoftButtonProps) => {
    const { instance, accounts, inProgress } = useMsal()
    const [searchParams] = useSearchParams()
    const challengeParam = searchParams.get('login_challenge')
    const signInWithMicrosoftMutation = useSignInWithMicrosoftMutation(challengeParam || '', {
        onSuccess,
        onSettled: () => setIsLoading(false),
    })
    const { showToast } = useToast()

    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    const informAboutError = (error?: any) => {
        console.error(error)
        showToast({
            message: locale.notification.contactUs,
            type: 'error',
        })
    }

    useEffect(() => {
        if (
            accounts?.length &&
            localStorage.getItem(ALLOW_MICROSOFT_ACCOUNT_USAGE) === 'true' &&
            inProgress === InteractionStatus.None
        ) {
            setIsLoading(true)
            const account = getLatestSignedInAccount(accounts)
            const microsoftRememberMe = localStorage.getItem(MICROSOFT_REMEMBER_ME)

            if (account) {
                instance
                    .acquireTokenSilent({ ...scopes, account })
                    .then((accessTokenResponse) => {
                        signInWithMicrosoftMutation.mutate({
                            token: accessTokenResponse.accessToken,
                            rememberMe: microsoftRememberMe === 'true' ? true : false,
                        })
                    })
                    .catch((error) => {
                        informAboutError(error)
                    })
            } else {
                informAboutError()
            }

            localStorage.removeItem(ALLOW_MICROSOFT_ACCOUNT_USAGE)
            localStorage.removeItem(MICROSOFT_REMEMBER_ME)
        }
    }, [accounts, inProgress])

    const handleLogin = () => {
        try {
            if (rememberMe) {
                localStorage.setItem(MICROSOFT_REMEMBER_ME, 'true')
            } else {
                localStorage.removeItem(MICROSOFT_REMEMBER_ME)
            }
            instance.acquireTokenRedirect(scopes)
        } catch (error: unknown) {
            informAboutError(error)
        }
    }

    return (
        <Button
            variant="outlined"
            fullWidth
            sx={style.button}
            onClick={handleLogin}
            disabled={disabled}
            startIcon={<img src="/img/icons/microsoft.svg" alt="Microsoft" />}
            data-testid="microsoftSSO"
        >
            <Typography variant="body2" fontWeight={600} color={disabled ? 'action.disabled' : undefined}>
                {locale.cta}
            </Typography>
        </Button>
    )
}

export default withErrorBoundary(MicrosoftButton)
