import { UseMutationOptions } from 'react-query'

import { smartVidConroller } from 'api/controllers'
import { queryClient } from 'api/queries'
import {
    CHOICE_QUERY,
    VIDEO_BRANCH_QUERY,
    SMART_VIDS_QUERY,
    SMART_VIDS_STEPS_QUERY,
    VIDEOS_QUERY,
    STEP_SETTINGS_QUERY,
} from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

type Payload = { id: Id; choiceIds: Id[] }

export const useDeleteFunnelChoiceMutation = (branchId: Id, options?: UseMutationOptions<null, unknown, Payload>) => {
    const key = CHOICE_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, Payload>(
        key,
        async (payload: Payload) => {
            const choicesText = `hoice${payload.choiceIds.length > 1 ? 's' : ''}`

            return mutate(
                Promise.all(payload.choiceIds.map((id) => smartVidConroller.deleteSmartVidStep(payload.id, id))),
                {
                    pending: `Deleting c${choicesText}`,
                    error: `Failed to delete c${choicesText}`,
                    success: `C${choicesText} deleted`,
                },
            )
        },
        {
            ...options,
            invalidate: [VIDEO_BRANCH_QUERY, SMART_VIDS_QUERY, SMART_VIDS_STEPS_QUERY, [STEP_SETTINGS_QUERY, branchId]],
            onSuccess: (...info) => {
                options?.onSuccess?.(...info)

                queryClient.removeQueries(VIDEOS_QUERY)
            },
        },
    )
}
