import z from 'zod'
import { accountInfo, statistics, tariff } from './common'

export const agencyClient = accountInfo.merge(
    z.object({
        name: z.string().max(255).describe('Name of the agency client'),
        tariffInfo: tariff.optional().describe('Information about the tariff plan of the agency'),
        statistics: statistics.optional().describe('Statistics data of the agency'),
    }),
)

export type AgencyClient = z.infer<typeof agencyClient>

export type AgencyClientId = AgencyClient['id']
