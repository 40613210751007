import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { IP_FILTERS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { UpdateIpFilterPayload } from 'types/Customer'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useUpdateIpFilterMutation = (options?: UseMutationOptions<null, any, UpdateIpFilterPayload>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, UpdateIpFilterPayload>(
        IP_FILTERS_QUERY,
        async (payload: UpdateIpFilterPayload) =>
            mutate(userAPI.updateIpFilter(payload), apiLocale.user.ipFilter.update),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
