import { UseMutationOptions, useQueryClient } from 'react-query'

import { integrationsAPI } from 'api/controllers'
import { HUBSPOT_LINK_QUERY, HUBSPOT_STATUS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useDeleteHubspotIntegrationMutation = (options?: UseMutationOptions<null, any, null>) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, null>(
        HUBSPOT_LINK_QUERY,
        async () => mutate(integrationsAPI.deleteHubspot(), apiLocale.integration.delete),
        {
            ...options,
            onSuccess: () => {
                queryClient.resetQueries(HUBSPOT_STATUS_QUERY)
            },
        },
    )
}
