import { createTestId } from 'utils/create-test-id.utils'
import { kebabCase } from 'lodash'

export const testIdFactory = (metric: string) => ({
    card: createTestId(`${kebabCase(metric)}__metric-card`),
    label: createTestId(`${kebabCase(metric)}__metric-card__label`),
    value: createTestId(`${kebabCase(metric)}__metric-card__value`),
    trend: {
        container: createTestId(`${kebabCase(metric)}__metric-card__trend__container`),
        icon: {
            container: createTestId(`${kebabCase(metric)}__metric-card__trend__icon-container`),
            svg: createTestId(`${kebabCase(metric)}__metric-card__trend__icon`),
        },
        value: createTestId(`${kebabCase(metric)}__metric-card__trend__value`),
    },
})
