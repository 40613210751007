import { useState } from 'react'

import { LazyLoadImage, LazyLoadImageProps } from 'design/atoms/LazyLoadImage'
import { Box } from '@mui/material'

interface ImgWithErrorHandlerProps extends LazyLoadImageProps {
    lazy?: boolean
}

export const ImgWithErrorHandler = ({ src, lazy = true, ...props }: ImgWithErrorHandlerProps) => {
    const [broken, setBroken] = useState(false)
    const commonProps = {
        ...props,
        src: broken || !src ? '/img/video/no-image.png' : src,
        onError: () => setBroken(true),
    }

    return lazy ? <LazyLoadImage {...commonProps} /> : <Box component="img" {...commonProps} />
}
