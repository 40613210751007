import React, { ReactNode } from 'react'
import { Provider } from 'react-redux'
import { CssBaseline } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider } from 'react-query'
import { CookiesProvider } from 'react-cookie'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { PersistGate } from 'redux-persist/integration/react'
import { GoogleOAuthProvider } from '@react-oauth/google'

import Modals from 'design/templates/Modal/ModalTypes'
import { appStore, persistor, UntestableWrappers, ThemeProvider } from 'App/index'
import { queryClient } from 'api/queries'
import { ToastProvider } from 'design/organisms/ToastProvider'
import { Flow } from 'constants/environment.constants'

import './Root.scss'

type RootProps = {
    flow?: Flow
    children: ReactNode
}

export const Root = ({ children, flow }: RootProps) => (
    <React.StrictMode>
        <ThemeProvider>
            <Provider store={appStore}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <CookiesProvider>
                            <DndProvider backend={HTML5Backend}>
                                <UntestableWrappers flow={flow}>
                                    <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_CLIENT_ID)}>
                                        <BrowserRouter>
                                            <ToastProvider>
                                                <CssBaseline />
                                                {children}

                                                <Modals />
                                            </ToastProvider>
                                        </BrowserRouter>
                                    </GoogleOAuthProvider>
                                </UntestableWrappers>
                            </DndProvider>
                        </CookiesProvider>
                        <ReactQueryDevtools />
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
)
