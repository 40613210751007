import { Id } from 'types'

export enum StatsEntity {
    video = 'video',
    analytics = 'analytics',
    smartVid = 'smart-vid',
    default = ':entity',
}

export interface StatsRoute {
    entity?: string
    videoGuid: string
    segmentType?: string
    segmentParamName?: string
}

export const defaultStatsRouteParams = {
    entity: StatsEntity.default,
    videoGuid: ':videoGuid',
    segmentType: ':segmentType?',
    segmentParamName: ':segmentParamName?',
}

const buildStatsRoute: (params?: StatsRoute) => string = ({
    entity,
    videoGuid,
    segmentType,
    segmentParamName,
} = defaultStatsRouteParams) => {
    if (!entity || !Object.values(StatsEntity).includes(entity as StatsEntity)) throw new Error('Incorrect entity')
    if (!segmentType && segmentParamName) throw new Error('Incorrect video stats params')

    let path = `/${entity}/stats/${videoGuid}`

    if (segmentType) path = path + `/${segmentType}`
    if (segmentParamName) path = path + `/${segmentParamName}`

    return path
}

interface SmartVidStepRoute {
    smartVidId?: string
    stepVidId?: Id
    interactionId?: Id
    router?: boolean
}

const buildSmartVidStepRoute: (params?: SmartVidStepRoute) => string = (
    { smartVidId, stepVidId, interactionId, router } = {
        smartVidId: ':smartVidId',
        stepVidId: ':stepVidId',
        interactionId: ':interactionId?',
        router: true,
    },
) => {
    let path = `/smart-vid/${smartVidId}/step/${stepVidId}`

    if (interactionId) path = path + `/interaction${router ? '?' : ''}/${interactionId}`

    return path
}

const locale = 'en'

export const route = {
    agency: {
        dashboard: '/agency/dashboard',
        join: '/agency-invitation',
    },
    all: '*',
    notFound: '/not-found',
    auth: {
        forgotPassword: '/forgot-password',
        microsoftAuth: '/microsoft-auth',
        resetPassword: {
            index: '/reset-password',
            withToken: ({ token } = { token: ':token' }) => `/reset-password/${token}`,
        },
        signIn: '/sign-in',
        signUp: {
            index: '/sign-up',
            withPlan: ({ planId } = { planId: ':planId' }) => `/sign-up/${planId}`,
        },
    },
    conversion: '/conversion',
    dashboard: '/dashboard',
    home: '/',
    integrations: {
        index: '/integrations',
        facebook: '/integrations/facebook',
        gaGtm: '/integrations/ga-gtm',
        highLevel: '/integrations/high-level',
        hubspot: '/integrations/hubspot',
        zapier: '/integrations/zapier',
        activeCampaign: '/integrations/active-campaign',
        segment: '/integrations/segment',
    },
    mail: {
        hi: 'mailto:hi@vidalytics.com',
    },
    placeholder: '#',
    plan: {
        upgrade: {
            index: '/plan/upgrade',
            byToken: ({ planId } = { planId: ':planId' }) => `/plan/upgrade/${planId}`,
        },
    },
    account: {
        index: '/account',
        usageStats: '/account/usage-stats',
        profile: '/account/profile',
        password: '/account/password',
        paymentSettings: '/account/payment-settings',
        users: '/account/users',
        globalSettings: '/account/global-settings',
    },
    settings: {
        account: {
            index: '/account-settings',
            invoices: '/account-settings/invoices',
        },
        user: {
            index: '/users-settings',
            upgradePlan: ({ email, plan } = { email: ':email', plan: ':plan' }) =>
                `/direct-upgrade/${email}?plan=${plan}`,
        },
        directUpgrade: '/direct-upgrade',
    },
    smartVideo: {
        branch: (
            { funnelId, branchAction, videoId } = {
                funnelId: ':funnelId',
                branchAction: ':branchAction',
                videoId: ':videoId',
            },
        ) => `/smart-vid/${funnelId}/branch/${branchAction}/${videoId}`,
        old: {
            byId: ({ funnelId } = { funnelId: ':funnelId' }) => `/smart-vid/${funnelId}`,
        },
        byId: ({ smartVidId } = { smartVidId: ':smartVidId' }) => `/smart-vid/${smartVidId}`,
        step: buildSmartVidStepRoute,
        catalog: '/smart-vid',
        create: '/smart-vid/create',
        underConstruction: '/smart-vid/under-construction',
    },
    static: {
        home: 'https://www.vidalytics.com',
        apiDocs: 'https://api-docs.vidalytics.com',
        contacts: 'https://www.vidalytics.com/contact',
        help: {
            index: `https://help.vidalytics.com/en`,
            article: ({ slug } = { slug: ':slug' }) => `https://help.vidalytics.com/${locale}/articles/${slug}`,
            collection: ({ slug } = { slug: ':slug' }) => `https://help.vidalytics.com/${locale}/collections/${slug}`,
        },
        pricing: 'https://www.vidalytics.com/pricing',
        privacyPolicy: 'https://www.vidalytics.com/privacy',
        terms: 'https://www.vidalytics.com/terms',
    },
    trainingCenter: {
        dashboard: '/dashboard/training-center',
        index: '/training-center',
        tutorial: ({ id } = { id: ':id' }) => `/training-center/tutorial/${id}`,
    },
    upload: '/upload',
    video: {
        byId: ({ guid } = { guid: ':guid' }) => `/video/${guid}`,
        catalog: '/video',
        settings: {
            root: '/video/settings',
            byId: ({ videoGuid } = { videoGuid: ':videoGuid' }) => `/video/settings/${videoGuid}`,
        },
    },
    stats: {
        root: (entity = ':entity') => `/${entity}/stats`,
        detailed: buildStatsRoute,
    },
    analytics: {
        root: (entity = ':entity') => `/${entity}`,
        noData: (entity = ':entity') => `/${entity}/no-data`,
    },
    welcome: {
        index: '/welcome',
        withPlan: ({ planId } = { planId: ':planId' }) => `/welcome/${planId}`,
    },
} as const

export default route
