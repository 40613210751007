import { useMemo, useState, useEffect } from 'react'
import { useDrag, useDrop, useDragDropManager } from 'react-dnd'
import classNames from 'classnames'
import { Row as RowType, TableState } from 'react-table'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'
import { getRandomArbitrary } from 'utils'

import { SELECTION_COL, FOLDER, RECORD } from 'design/atoms/Table/constants'
import Folder from './Folder'

import './index.scss'

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type DropHandler = (target: RowType<any>, items: RowType<any>[]) => void
export type RowClickHandler = (row: RowType<any>) => void

export type RowProps = {
    row: RowType<any>
    loading: boolean
    selectedFlatRows: RowType<any>[]
    state: TableState<any>
    length: number
    onClick?: RowClickHandler
    onDrop?: DropHandler
    toggleRowSelected: (rowId: string, set?: boolean | undefined) => void
}

function Row({ row, state, selectedFlatRows, loading, length, onClick, toggleRowSelected, onDrop }: RowProps) {
    const isFolder = row.original.type === FOLDER
    const [isFolderEditing, setFolderEditing] = useState(false)

    const dragDropManager = useDragDropManager()
    const isDragging = dragDropManager.getMonitor().isDragging()

    const [{ isCurrentItemDragging }, drag] = useDrag(
        () => ({
            type: row.original.type || RECORD,
            item: row.original,
            collect: (monitor) => ({
                isCurrentItemDragging: monitor.isDragging(),
            }),
        }),
        [],
    )

    useEffect(() => {
        if (!row.isSelected && isCurrentItemDragging) {
            toggleRowSelected(row.id, true)
        }
    }, [isCurrentItemDragging, row.isSelected])

    const handleDrop = (target: RowType<any>) => () => {
        const excludedTarget = selectedFlatRows.filter((r) => r.original.guid !== target.original.guid)
        if (excludedTarget.length) {
            onDrop?.(target, excludedTarget)
        }
    }

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [FOLDER, RECORD],
        drop: isFolder ? handleDrop(row) : undefined,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop:
                monitor.canDrop() && monitor.getItem<any>()?.guid !== row.original.guid && isFolder && row.original,
            cd: monitor.canDrop(),
        }),
    })

    const hasSelection = row.cells.some((cell) => cell.column.id === SELECTION_COL)
    const opacity = row.isSelected && isDragging ? 0.4 : 1

    const skeletonRandomWidthList = useMemo(
        () => Array.from({ length }, () => `${getRandomArbitrary(40, 70)}%`),
        [length],
    )

    const getContent = (cell: any, index: number) => {
        if (loading) {
            return <Skeleton width={skeletonRandomWidthList[index]} />
        }

        if (isFolder) {
            if (hasSelection && index === 0) {
                return cell.render('Cell')
            }
            if (index === (hasSelection ? 1 : 0)) {
                return <Folder key={`${row.id}-${cell.value}`} row={row} cell={cell} handleEdit={handleEdit} />
            }
            return null
        }

        return cell.column.CustomCell ? (
            <cell.column.CustomCell row={row} state={state} cell={cell} />
        ) : (
            cell.render('Cell')
        )
    }

    const handleEdit = (isFolderEditing: boolean) => {
        setFolderEditing(isFolderEditing)
    }

    const isActive = isOver && canDrop

    return (
        <TableRow
            {...row.getRowProps()}
            style={{ opacity }}
            ref={(ref) => {
                if (onDrop) {
                    drag(ref)
                    drop(ref)
                }
            }}
            onClick={() => onClick?.(row)}
            className={classNames({ selected: row.isSelected, isDragging, isFolderEditing })}
        >
            {/* eslint-disable react/jsx-key */}
            {row.cells.map((cell, index) => {
                return (
                    <TableCell
                        {...cell.getCellProps()}
                        className={`${cell.column.id} ${isActive ? 'active' : 'idle'}`}
                        style={{
                            width: cell.column.width,
                            ...cell.column.styles,
                        }}
                    >
                        {getContent(cell, index)}
                    </TableCell>
                )
            })}
        </TableRow>
    )
}

export default Row
