import { SxProps } from '@mui/material'

const opacity: SxProps = {
    width: 78,

    input: {
        textAlign: 'center',
    },
}

export default {
    opacity,
}
