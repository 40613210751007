import { SxProps, Theme } from '@mui/material'
import { MOBILE_BURGER_HEADER_HEIGHT } from '../../../styles/constants'

const headerText: SxProps<Theme> = (theme) => ({
    pt: 5,
    px: 8,

    [theme.breakpoints.down('tablet')]: {
        px: 4,
    },
})

const headerSearch: SxProps<Theme> = {
    ml: 'auto',
}

const container: SxProps<Theme> = (theme) => ({
    height: '100vh',

    [theme.breakpoints.down('tablet')]: {
        height: `calc(100vh - ${MOBILE_BURGER_HEADER_HEIGHT}px)`,
        minHeight: 'unset',
    },
})

const content: SxProps<Theme> = (theme) => ({
    px: 8,
    flexGrow: 1,
    maxHeight: 1,
    overflowY: 'hidden',
    paddingBottom: 4,

    [theme.breakpoints.down('tablet')]: {
        px: 0,
    },
})

const helperButton: SxProps<Theme> = (theme) => ({
    flexBasis: '100%',

    [theme.breakpoints.up('laptop')]: {
        flexBasis: 'unset',
    },
})

export default {
    headerText,
    headerSearch,
    helperButton,
    container,
    content,
}
