import { SxProps, Theme } from '@mui/material'

const container: SxProps<Theme> = {
    p: 4,
    height: '100%',
    borderRadius: 2,
    backgroundColor: (theme) => theme.palette.info.light,
    gap: 6,
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
}
export default {
    container,
}
