import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import BoltIcon from '@mui/icons-material/Bolt'
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded'
import { AlertColor } from '@mui/material'

import { useAppDispatch } from 'App'
import { openAgencyLockedFeaturesModal } from 'design/templates/Modal'
import { useToast } from 'design/organisms/ToastProvider'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import ClosedCaptionsZoneLoader from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsZoneLoader'
import { openSubscriptionModal } from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import { useAgencyAccess } from 'design/pages/AgencyAccount/AgencyAccount.hooks'
import {
    usePostClosedCaptionsMutation,
    useGenerateClosedCaptionsMutation,
    useGenerateClosedCaptionsCancelMutation,
} from 'api/mutations'
import { useVideoQuery, queryClient } from 'api/queries'
import { CLOSED_CAPTIONS_DRAFT } from 'api/constants'

import locale from './ClosedCaptionsZone.locale'
import style from './ClosedCaptionsZone.style'

enum CLOSED_CAPTIONS_GENERATING_STATUS {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
}

const REFRESH_TM = 30 * 1000
const STATUS_NOTIFYED = 'STATUS_NOTIFYED'

let intervalId: number | null = null

const ClosedCaptionsZone = () => {
    const { videoGuid } = useParams()
    const dispatch = useAppDispatch()
    const { agencyAccessLocked } = useAgencyAccess()
    const { showToast } = useToast()
    const { data: video, refetch } = useVideoQuery(videoGuid, {
        onSuccess(data) {
            const isNotifyed = localStorage.getItem(STATUS_NOTIFYED)

            let toast = null
            if (data?.video?.closedCaptionsGeneration === CLOSED_CAPTIONS_GENERATING_STATUS.SUCCESS) {
                toast = {
                    message: locale.ready,
                    type: 'success' as AlertColor,
                }

                queryClient.invalidateQueries(CLOSED_CAPTIONS_DRAFT)
            }

            if (data?.video?.closedCaptionsGeneration === CLOSED_CAPTIONS_GENERATING_STATUS.FAILED) {
                toast = {
                    message: locale.failed,
                    type: 'error' as AlertColor,
                }
            }

            if (!isNotifyed && toast) {
                localStorage.setItem(STATUS_NOTIFYED, 'true')
                showToast(toast)
            }
        },
    })

    const { mutate: postClosedCaptionsMutate, isLoading: isLoadingPostClosedCaptions } = usePostClosedCaptionsMutation({
        onSuccess() {
            showToast({
                message: locale.ready,
                type: 'success' as AlertColor,
                duration: 5000,
            })
        },
    })
    const { mutate: generateClosedCaptionsMutate, isLoading: isLoadingGenerateClosedCaptions } =
        useGenerateClosedCaptionsMutation()
    const { mutate: generateClosedCaptionsCancelMutate, isLoading: isLoadingGenerateClosedCaptionsCancel } =
        useGenerateClosedCaptionsCancelMutation()
    const { isEnterprise } = useAccountSubscription()
    const dropzone = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: async (acceptedFiles) => {
            const file = acceptedFiles?.filter((file) => !(file.type && file?.name?.lastIndexOf('.') === -1))

            if (!video || !file?.[0]) {
                showToast({
                    message: locale.invalidFile,
                    type: 'error',
                })
                return
            }

            postClosedCaptionsMutate({ video: video?.video, file: file[0] })
        },
        accept: {
            'text/plain': ['.srt', '.vtt'],
        },
    })

    const handleGenerateClick = useCallback(() => {
        if (!videoGuid) return

        if (agencyAccessLocked) {
            dispatch(openAgencyLockedFeaturesModal({ fallback: false }))
            return
        }

        if (!isEnterprise) {
            return dispatch(
                openSubscriptionModal({
                    hideCurrentPlan: true,
                }),
            )
        }

        localStorage.removeItem(STATUS_NOTIFYED)
        generateClosedCaptionsMutate({ guid: videoGuid })
    }, [videoGuid])

    const handleCancel = useCallback(() => {
        if (!videoGuid) return

        generateClosedCaptionsCancelMutate({ guid: videoGuid })
        localStorage.removeItem(STATUS_NOTIFYED)
    }, [videoGuid])

    const isGenerating = video?.video?.closedCaptionsGeneration === CLOSED_CAPTIONS_GENERATING_STATUS.PENDING

    useEffect(() => {
        if (!intervalId && isGenerating) {
            intervalId = window.setInterval(() => {
                refetch()
            }, REFRESH_TM)
        }

        if (intervalId && !isGenerating) {
            clearInterval(intervalId)
            intervalId = null
        }
    }, [isGenerating, intervalId])

    useEffect(() => {
        return () => {
            if (intervalId) {
                clearInterval(intervalId)
                intervalId = null
            }
        }
    }, [])

    if (
        isLoadingPostClosedCaptions ||
        isLoadingGenerateClosedCaptions ||
        isLoadingGenerateClosedCaptionsCancel ||
        isGenerating
    ) {
        return <ClosedCaptionsZoneLoader isGenerating={isGenerating} handleCancel={handleCancel} />
    }

    return (
        <Stack {...dropzone.getRootProps()} sx={style.root}>
            <Box component="input" {...dropzone.getInputProps()} />
            <Stack mx="auto" textAlign="center" px={4}>
                <Stack flexDirection="row" justifyContent="space-around">
                    <Box sx={style.ico}>
                        <FileUploadRoundedIcon sx={{ fontSize: 24 }} />
                    </Box>
                </Stack>
                <Box mt={6} mb={4}>
                    <Typography variant="h7" fontWeight={700}>
                        {locale.dragAndDrop}
                        <Typography onClick={dropzone.open} variant="h7" color="primary" sx={style.chooseFiles}>
                            {locale.chooseFiles}
                        </Typography>
                        {locale.toUpload}
                    </Typography>
                </Box>
                <Stack justifyContent="center" gap={3} sx={style.controls}>
                    <Button
                        onClick={dropzone.open}
                        variant="contained"
                        color="secondary"
                        startIcon={<FileUploadRoundedIcon />}
                    >
                        {locale.uploadCc}
                    </Button>
                    <Button
                        onClick={handleGenerateClick}
                        color={isEnterprise ? 'primary' : 'accent'}
                        variant="contained"
                        startIcon={<BoltIcon />}
                    >
                        {locale.generateCc}
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default ClosedCaptionsZone
