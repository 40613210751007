import { ComponentType } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Fallback } from 'design/atoms/Fallback'

const withErrorBoundary = <BaseProps extends {}>(WrappedComponent: ComponentType<BaseProps>) =>
    function container(props: BaseProps) {
        return (
            <ErrorBoundary fallbackRender={() => <Fallback />}>
                <WrappedComponent {...props} />
            </ErrorBoundary>
        )
    }
export default withErrorBoundary
