import { isString } from 'lodash'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { COOKIES_OPTIONS, SS_WATTS_KEY } from 'constants/cookies.constants'

export default function syncSiteSpect() {
    const [, setCookie] = useCookies()

    useEffect(() => {
        const ssWatts = window.ss_watts

        if (isString(ssWatts)) {
            setCookie(SS_WATTS_KEY, ssWatts, COOKIES_OPTIONS)
        }
    }, [])
}
