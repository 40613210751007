export default {
    title: 'Grow your design business',
    description:
        'Get more eyes on your work and stand out with attention grabbing profile features to attract more opportunities.',
    content:
        'Showcase your work to a community of over 10 million creative professionals around the world. Engage with new clients, collaborators, and future employers actively searching for designers like you.',
    cta: {
        cancel: 'No, thanks',
        confirm: 'Buy Now',
    },
    disclaimer: 'Small disclaimer font at the bottom',
}
