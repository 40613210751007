import { Box, Button, SxProps, Theme } from '@mui/material'
import BoltIcon from '@mui/icons-material/Bolt'

import {
    openSubscriptionModal,
    SubscriptionSteps,
    SubscriptionCtaSource,
} from 'design/templates/Modal/modalTemplates/SubscriptionModal/subscription.slice'
import useAccountSubscription from 'hooks/user/useAccountSubscription'
import { CustomerStatus } from 'types/Customer'
import { useCustomerQuery } from 'api/queries'
import { useAppDispatch } from 'App'
import style from './UpgradeCTA.style'
import locale from './UpgradeCTA.locale'

type UpgradeCTAProps = {
    onClick?: ({ isFreeAndNotTrial }: { isFreeAndNotTrial: boolean }) => void
    ctaSource?: SubscriptionCtaSource
    renderIcon?: boolean
    dataTestid?: string
    className?: string
    fullWidth?: boolean
    sx?: SxProps<Theme>
}

export const UpgradeCTA = ({
    ctaSource = SubscriptionCtaSource.HEADER,
    renderIcon,
    dataTestid,
    onClick,
    fullWidth,
    sx,
}: UpgradeCTAProps) => {
    const { data: customer } = useCustomerQuery()
    const { isFree } = useAccountSubscription()
    const dispatch = useAppDispatch()

    const isFreeAndNotTrial = Boolean(isFree && customer && customer.status !== CustomerStatus.trial)

    const handleClick = () => {
        if (onClick) {
            onClick({ isFreeAndNotTrial })

            return
        }

        dispatch(
            openSubscriptionModal({
                step: isFreeAndNotTrial ? SubscriptionSteps.SELECT_PLAN_STEP : SubscriptionSteps.ACTIVATION_STEP,
                ctaSource,
            }),
        )
    }

    return (
        <Button
            variant="contained"
            color="accent"
            onClick={handleClick}
            data-testid={dataTestid}
            startIcon={renderIcon && <BoltIcon />}
            fullWidth={fullWidth}
            sx={sx}
        >
            <Box component="span" sx={style.buttonText}>
                {isFreeAndNotTrial ? locale.startTrial : locale.upgrade}
            </Box>
        </Button>
    )
}
