import { SxProps, Theme } from '@mui/material'

const icon: SxProps<Theme> = {
    width: 16,
    height: 16,
}

export default {
    icon,
}
