import { SyntheticEvent, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'

import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'

import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import ManagementSection from './ManagementSection'
import useManagementSettingsMenu from './useManagementSettingsMenu'

const ManagementSettings = () => {
    const menu = useManagementSettingsMenu()
    const [expanded, setExpanded] = useState<string | boolean>(true)

    const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    return (
        <Box className="ManagementSettings">
            <ManagementSection />
            {menu.map((rootMenuItem) => (
                <Accordion
                    key={rootMenuItem.label}
                    expanded={expanded === rootMenuItem.label}
                    onChange={handleChange(rootMenuItem.label)}
                >
                    <AccordionSummary
                        expandIcon={<KeyboardArrowRightRoundedIcon />}
                        aria-controls={`${rootMenuItem.label}Panel`}
                        id={`${rootMenuItem.label}PanelSummary`}
                        className="rootPanelSummary"
                    >
                        <Box className="mainSummary">
                            <rootMenuItem.Icon />
                            <Typography className="summaryTitle" component="span">
                                {rootMenuItem.label}
                            </Typography>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box className="mainDetails">{<rootMenuItem.Children />}</Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    )
}

export default withErrorBoundary(ManagementSettings)
