import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'

export type SelectedVideoGuid = string | null | undefined

export type VideoCompareState = {
    selectorOpen: boolean
    selectedVidGuid: SelectedVideoGuid
    isPlaying: boolean
}

const initialState: VideoCompareState = {
    selectorOpen: false,
    selectedVidGuid: null,
    isPlaying: false,
}

export const videoCompareSlice = createSlice({
    name: 'videoCompare',
    initialState,
    reducers: {
        setSelectorState: (state, action: PayloadAction<boolean>) => {
            state.selectorOpen = action.payload

            if (action.payload) {
                state.isPlaying = false
            }
        },
        setSelectedVidGuid: (state, action: PayloadAction<SelectedVideoGuid>) => {
            state.selectedVidGuid = action.payload
        },
        setIsPlaying: (state, action: PayloadAction<boolean>) => {
            state.isPlaying = action.payload
        },
        resetState: () => initialState,
    },
})

export const { setSelectorState, setSelectedVidGuid, setIsPlaying, resetState } = videoCompareSlice.actions

export const selectVideoCompareState = (state: RootState) => state.videoCompare

export const videoCompare = videoCompareSlice.reducer
