import { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import BoltIcon from '@mui/icons-material/Bolt'

import style from './ProLabel.style'

type ProLabelProps = {
    propsTooltip?: Omit<TooltipProps, 'children'>
}

const ProLabel = ({ propsTooltip }: ProLabelProps) => {
    const label = useMemo(
        () => (
            <Typography sx={style.root}>
                <BoltIcon sx={{ fontSize: 'body1.fontSize' }} />
                PRO
            </Typography>
        ),
        [],
    )

    if (propsTooltip?.title) {
        return <Tooltip {...propsTooltip}>{label}</Tooltip>
    }

    return label
}

export default ProLabel
