import { useQuery, UseQueryOptions } from 'react-query'

import { FeatureFlags } from 'types/Customer'
import { userAPI } from 'api/controllers'
import { UNATHORIZED_FEATURE_FLAGS_QUERY } from 'api/constants'

export const useUnathorizedFeatureFlagsQuery = (options?: UseQueryOptions<FeatureFlags, unknown>) => {
    return useQuery<FeatureFlags>(UNATHORIZED_FEATURE_FLAGS_QUERY, () => userAPI.getUnathorizedFeatureFlags(), {
        ...options,
    })
}
