import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { videoAPI } from 'api/controllers'
import { MoveToFolderPayload } from 'types/Video'
import { FOLDER_MOVEMENT_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'

export const useMoveFolderToFolderMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, MoveToFolderPayload[]>(
        FOLDER_MOVEMENT_QUERY,
        async (payload: MoveToFolderPayload[]) => {
            const itemsText = `older${payload.length > 1 ? 's' : ''}`

            return mutate(Promise.all(payload.map((p) => videoAPI.moveFolderToFolder(p))), {
                pending: `Moving f${itemsText}`,
                error: `F${itemsText} movement failed`,
                success: `F${itemsText} moved`,
            })
        },
    )
}
