import { FunnelOptionType, FunnelDisplayMode, TimerType } from 'types/Video'

export const MAX_OPTIONS_LENGTH = 4

// top level
export const HEADLINE = 'headline'
export const BRANCH_TYPE = 'branchType'
export const ENABLE_BACKGROUND = 'enableBackground'
export const INHERIT_STYLES = 'inheritStyles'
export const OPTIONS = 'options'

// choice
export const ID = 'id'
export const CHOICE_TEXT = 'choiceText'
export const VIDEO_GUID = 'videoGuid'
export const BUTTON_COLOR = 'buttonColor'
export const HOVER_OVER = 'hoverOver'
export const AREA_BUTTON_COLOR = 'areaButtonColor'
export const AREA_HOVER_OVER = 'areaHoverOver'
export const TEXT_COLOR = 'textColor'
export const TEXT_HOVER = 'textHover'
// - clickable areas
export const WIDTH = 'width'
export const HEIGHT = 'height'
export const BORDER_RADIUS = 'borderRadius'
export const POSITION_X = 'positionX'
export const POSITION_Y = 'positionY'

// display mode
export const DISPLAY_MODE = 'displayMode'
export const TIME_FROM = 'timeFrom'
export const TIME_TO = 'timeTo'
export const TIME_BEFORE = 'timeBefore'

// next video
export const DEFAULT_NEXT_VIDEO = 'defaultNextBranch'
export const TIME_DELAY = 'timeDelay'
export const TIME_DELAY_TYPE = 'timeDelayType'
export const TIMER_TYPE = 'timerType'

// jump back options
export const GO_BACK = 'goBack'
export const START_OVER = 'startOver'
export const SHOW = 'show'
export const TEXT = 'text'
export const COLOR = 'color'

export enum TimeDelayType {
    IMMEDIATELY = 'immediately',
    SECONDS = 'seconds',
}

export const EMPTY_BUTTONS_OPTION = {
    [WIDTH]: 50,
    [HEIGHT]: 100,
    [BORDER_RADIUS]: 0,
    [POSITION_X]: 10,
    [POSITION_Y]: 20,
}

export const EMPTY_CHOICES_OPTION = {
    [CHOICE_TEXT]: '',
    [BUTTON_COLOR]: '#000000',
    [HOVER_OVER]: '#CECECE',
    [AREA_BUTTON_COLOR]: '',
    [AREA_HOVER_OVER]: '',
    [TEXT_COLOR]: '#CECECE',
    [TEXT_HOVER]: '#000000',
}

export const EMPTY_OPTION = {
    [VIDEO_GUID]: null,
    ...EMPTY_CHOICES_OPTION,
    ...EMPTY_BUTTONS_OPTION,
}

export const INITIAL_VALUES = {
    [HEADLINE]: '',
    [ENABLE_BACKGROUND]: true,
    [INHERIT_STYLES]: false,

    [BRANCH_TYPE]: FunnelOptionType.BUTTONS,
    [OPTIONS]: Array.from({ length: MAX_OPTIONS_LENGTH }, () => EMPTY_OPTION),

    [DISPLAY_MODE]: FunnelDisplayMode.END,
    [TIME_FROM]: '00:00',
    [TIME_TO]: '',
    [TIME_BEFORE]: '',

    [DEFAULT_NEXT_VIDEO]: null,
    [TIME_DELAY]: 0,
    [TIME_DELAY_TYPE]: TimeDelayType.IMMEDIATELY,
    [TIMER_TYPE]: TimerType.NONE,

    [GO_BACK]: {
        [SHOW]: false,
        [TEXT]: 'Go Back',
        [COLOR]: '#ffffff',
    },
    [START_OVER]: {
        [SHOW]: false,
        [TEXT]: 'Start Over',
        [COLOR]: '#ffffff',
    },
}

export const HTML_TAGS_REGEX = /<[^>]*>/g
export const HTML_TAGS_VALIDATION_MESSAGE = 'HTML tags are not allowed'
