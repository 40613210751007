import { locale } from 'locales'

const { entity, info } = locale.messages

export const apiLocale = {
    agency: {
        inviteClient: {
            error: 'Invite client failed',
            success: 'User invited successfully',
        },
    },
    vidConditions: {
        pending: 'Saving conditions',
        error: 'Saving conditions error',
        success: 'Saving conditions success',
    },
    auth: {
        forgotPassword: {
            pending: 'Sending email',
            error: locale.messages.errors.generic,
            success: 'Email sent',
        },
        resetPassword: {
            pending: 'Resetting password',
            error: 'Password reset failed',
            success: 'Password has been reset',
        },
        verifyEmail: {
            pending: 'Verifying email',
            error: 'Email already in use',
            success: 'Email can be used',
        },
        resetPasswordEmail: {
            error: "Password can't be reset",
        },
        signIn: {
            error: 'Sign in failed',
        },
        signUp: {
            error: 'Sign up failed',
        },
    },
    conversion: {
        create: {
            pending: entity.create.inProgress('conversion'),
            error: entity.create.failed('conversion'),
            success: entity.create.completed('Conversion'),
        },
        update: {
            pending: entity.update.inProgress('conversion'),
            error: entity.update.failed('conversion'),
            success: entity.update.completed('Conversion'),
        },
    },
    funnel: {
        create: {
            pending: entity.create.inProgress('Smart Vid'),
            error: entity.create.failed('Smart Vid'),
            success: entity.create.completed('Smart Vid'),
        },
        publish: {
            pending: entity.publish.inProgress('Smart Vid'),
            error: entity.publish.failed('Smart Vid'),
            success: entity.publish.completed('Smart Vid'),
        },
        replace: {
            pending: entity.replace.inProgress('Smart Vid'),
            error: entity.replace.failed('Smart Vid'),
            success: entity.replace.completed('Smart Vid'),
        },
        updateHeadline: {
            pending: entity.update.inProgress('headline'),
            error: entity.update.failed('headline'),
            success: entity.update.completed('Headline'),
        },
        updateStepSettings: {
            pending: entity.save.inProgress('branch'),
            error: entity.save.failed('branch'),
            success: entity.save.completed('Branch'),
        },
    },
    smartVids: {
        update: {
            pending: entity.update.inProgress('Smart Vid'),
            error: entity.update.failed('Smart Vid'),
            success: entity.update.completed('Smart Vid'),
        },
        create: {
            pending: entity.create.inProgress('Smart Vid'),
            error: entity.create.failed('Smart Vid'),
            success: entity.create.completed('Smart Vid'),
        },
        discard: {
            pending: entity.discard.inProgress('Smart Vid'),
            error: entity.discard.failed('Smart Vid'),
            success: entity.discard.completed('Smart Vid'),
        },
        step: {
            create: {
                pending: entity.create.inProgress('Smart Vid Step'),
                error: entity.create.failed('Smart Vid Step'),
                success: entity.create.completed('Smart Vid Step'),
            },
            update: {
                pending: entity.update.inProgress('Smart Vid Step'),
                error: entity.update.failed('Smart Vid Step'),
                success: entity.update.completed('Smart Vid Step'),
            },
            delete: {
                pending: entity.delete.inProgress('Smart Vid Step'),
                error: entity.delete.failed('Smart Vid Step'),
                success: entity.delete.completed('Smart Vid Step'),
            },
        },
        globalSetting: {
            update: {
                pending: entity.save.inProgress('Global Settings'),
                error: entity.save.failed('Global Settings'),
                success: entity.save.completed('Global Settings'),
            },
        },
    },
    integration: {
        updateAnalytics: {
            pending: entity.save.inProgress('changes'),
            error: entity.save.failed('save changes'),
            success: entity.save.completed('Changes'),
        },
        changeStatus: {
            pending: entity.update.inProgress('status'),
            error: entity.update.failed('status'),
            success: entity.update.completed('Status'),
        },
        delete: {
            pending: entity.delete.inProgress('integration'),
            error: entity.delete.failed('integration'),
            success: entity.delete.completed('Integration'),
        },
    },
    settings: {
        closedCaptions: {
            delete: {
                pending: entity.delete.inProgress('closed captions'),
                error: entity.delete.failed('closed captions'),
                success: entity.delete.completed('Closed captions'),
            },
            update: {
                pending: entity.update.inProgress('closed captions'),
                error: entity.update.failed('closed captions'),
                success: entity.update.completed('Closed captions'),
            },
            cancel: {
                pending: entity.cancel.inProgress('closed captions'),
                error: entity.cancel.failed('closed captions'),
                success: entity.cancel.completed('Closed captions'),
            },
        },
        cta: {
            create: {
                pending: entity.create.inProgress('CTA'),
                error: entity.create.failed('CTA'),
                success: entity.create.completed('CTA'),
            },
            delete: {
                pending: entity.delete.inProgress('CTA'),
                error: entity.delete.failed('CTA'),
                success: entity.delete.completed('CTA'),
            },
            update: {
                pending: entity.update.inProgress('CTA'),
                error: entity.update.failed('CTA'),
                success: entity.update.completed('CTA'),
            },
        },
        playGate: {
            create: {
                pending: entity.create.inProgress('Play Gate'),
                error: entity.create.failed('Play Gate'),
                success: entity.create.completed('Play Gate'),
            },
            delete: {
                pending: entity.delete.inProgress('Play Gate'),
                error: entity.delete.failed('Play Gate'),
                success: entity.delete.completed('Play Gate'),
            },
            update: {
                pending: entity.update.inProgress('Play Gate'),
                error: entity.update.failed('Play Gate'),
                success: entity.update.completed('Play Gate'),
            },
        },
        settings: {
            discard: {
                error: entity.discard.failed('settings'),
                pending: entity.discard.inProgress('settings'),
                success: entity.discard.completed('Settings were'),
            },
            loading: {
                error: entity.load.failed('settings'),
                pending: entity.load.inProgress('settings'),
                success: entity.load.completed('Settings'),
            },
            publish: {
                error: entity.publish.failed('settings'),
                pending: entity.publish.inProgress('settings'),
                success: entity.publish.completed('Settings were'),
            },
        },
        tag: {
            create: {
                pending: entity.create.inProgress('tag'),
                error: entity.create.failed('tag'),
                success: entity.create.completed('Tag'),
            },
            delete: {
                pending: entity.delete.inProgress('tag'),
                error: entity.delete.failed('tag'),
                success: entity.delete.completed('Tag'),
            },
            update: {
                pending: entity.update.inProgress('tag'),
                error: entity.update.failed('tag'),
                success: entity.update.completed('Tag'),
            },
        },
        thumbnail: {
            delete: {
                pending: entity.delete.inProgress('thumbnail'),
                error: entity.delete.failed('thumbnail'),
                success: entity.delete.completed('Thumbnail'),
            },
            update: {
                pending: entity.update.inProgress('thumbnail'),
                error: entity.update.failed('thumbnail'),
                success: entity.update.completed('Thumbnail'),
            },
            useSameMobile: {
                pending: entity.update.inProgress('thumbnail'),
                error: entity.update.failed('thumbnail'),
                success: entity.update.completed('thumbnail'),
            },
        },
    },
    stats: {
        reset: {
            pending: entity.reset.inProgress('stats'),
            error: entity.reset.failed('stats'),
            success: entity.reset.completed('Stats'),
        },
        download: {
            pending: entity.download.inProgress('stats'),
            error: entity.download.failed('stats'),
            success: entity.download.completed('Stats'),
        },
    },
    subUser: {
        create: {
            pending: entity.create.inProgress('user'),
            error: entity.create.failed('user'),
            success: entity.create.completed('User'),
        },
        delete: {
            pending: entity.delete.inProgress('user'),
            error: entity.delete.failed('user'),
            success: entity.delete.completed('User'),
        },
        update: {
            pending: entity.update.inProgress('user'),
            error: entity.update.failed('user'),
            success: entity.update.completed('User'),
        },
    },
    user: {
        invoiceDownload: {
            pending: entity.download.inProgress('invoice'),
            error: entity.download.failed('invoice'),
            success: entity.download.completed('Invoice'),
        },
        ipFilter: {
            create: {
                pending: entity.create.inProgress('IP filter'),
                error: entity.create.failed('IP filter'),
                success: entity.create.completed('IP filter'),
            },
            delete: {
                pending: entity.delete.inProgress('IP filter'),
                error: entity.delete.failed('IP filter'),
                success: entity.delete.completed('IP filter'),
            },
            update: {
                pending: entity.update.inProgress('IP filter'),
                error: entity.update.failed('IP filter'),
                success: entity.update.completed('IP filter'),
            },
        },
        plan: {
            pending: entity.activation.inProgress('plan'),
            error: entity.activation.failed('plan'),
            success: entity.activation.completed('Plan'),
        },
        upsellShown: {
            pending: entity.activation.inProgress('upsell'),
            error: entity.activation.failed('upsell'),
            success: entity.activation.completed('Upsell'),
        },
        updateCreditCard: {
            pending: entity.update.inProgress('card information'),
            error: entity.update.failed('card information'),
            success: entity.save.completed('Card information was'),
        },
        updateCustomer: {
            pending: entity.update.inProgress('information'),
            error: entity.update.failed('information'),
            success: entity.update.completed('Information'),
        },
        whitelistDomain: {
            create: {
                pending: entity.create.inProgress('domain'),
                error: entity.create.failed('domain'),
                success: entity.create.completed('Domain'),
            },
            delete: {
                pending: entity.delete.inProgress('domain'),
                error: entity.delete.failed('domain'),
                success: entity.delete.completed('Domain'),
            },
            update: {
                pending: entity.update.inProgress('domain'),
                error: entity.update.failed('domain'),
                success: entity.update.completed('Domain'),
            },
        },
        globalSettings: {
            update: {
                pending: entity.update.inProgress('Global settings'),
                error: entity.update.failed('Global settings'),
                success: entity.update.completed('Global settings'),
            },
        },
    },
    video: {
        republish: {
            pending: entity.republish.inProgress('video'),
            error: entity.republish.failed('video'),
            success: entity.republish.completed('Video'),
        },
        replace: {
            pending: entity.replace.inProgress('video'),
            error: entity.replace.failed('video'),
            success: entity.replace.completed('Video'),
        },
        renameFolder: {
            pending: entity.rename.inProgress('folder'),
            error: entity.rename.failed('folder'),
            success: entity.rename.completed('Folder'),
        },
        createFolder: {
            pending: entity.create.inProgress('folder'),
            error: entity.create.failed('folder'),
            success: entity.create.completed('Folder'),
        },
        changePlayerVersion: {
            pending: entity.change.inProgress('player version'),
            error: entity.change.failed('player version'),
            success: entity.change.completed('Player version'),
        },
        embedPublicShare: {
            pending: entity.update.inProgress('public share state'),
            error: entity.update.failed('public share state'),
            success: entity.update.completed('Public share state'),
        },
        deleteThumbnailEncoding: {
            pending: info.loading,
            success: {
                title: 'Sorry, something went wrong',
                message: "The video thumbnail couldn't be created. Please try again.",
            },
        },
    },
} as const
