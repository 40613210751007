import UploadVideo from 'assets/img/training-center/upload-video.svg'
import EmbedVideo from 'assets/img/training-center/embed-video.svg'
import ConversionStrategy from 'assets/img/training-center/conversion-strategy.svg'
import ConversionTracking from 'assets/img/training-center/conversion-tracking.svg'
import VideoAnalytics from 'assets/img/training-center/video-analytics.svg'
import { TrainingVideoType } from 'types/TrainingCenter'

export const TRAINING_CENTER_VIDEOS: TrainingVideoType[] = [
    {
        id: 'smart-vids-setup-demo',
        title: 'Smart Vids Setup Demo',
        description:
            'Learn how to create your first Smart Vid and take advantage of all the features that come with it.',
        thumbnail: '/img/training-center/smart-vids-setup.png',
        embedId: 'ckyUrKXuCvdzw5TD',
    },
    {
        id: 'how-to-upload-a-video',
        title: 'How to Upload a Video',
        description: 'Learn how to get started with your first video in Vidalytics.',
        thumbnail: UploadVideo,
        embedId: 'tAwrWjRLW0fUNYi5',
    },
    {
        id: 'how-to-embed-a-video',
        title: 'How To Embed a Video',
        description: 'Get your video on your website in seconds, and start getting traffic to it!',
        thumbnail: EmbedVideo,
        embedId: 'CbgvlXVi4TTVwiYM',
    },
    {
        id: 'ultimate-video-conversion-strategy',
        title: 'Ultimate Video Conversion Strategy',
        description: 'Learn which features to enable on your videos to achieve the best results with your marketing.',
        thumbnail: ConversionStrategy,
        embedId: 'N3b0EbB2PRTL4Jcw',
    },
    {
        id: 'how-to-set-up-conversion-tracking',
        title: 'How To Set Up Conversion Tracking',
        description:
            'Easily track your conversions after your video is watched to see which videos are bringing you sales.',
        thumbnail: ConversionTracking,
        embedId: 'zKckyzQZ2BHiR1O1',
    },
    {
        id: 'how-to-get-profitable-conversions-with-video-analytics',
        title: 'How To Get Profitable Conversions with Video Analytics',
        description: "Learn how to analyze Vidalytics' Stats to optimize your videos for better results.",
        thumbnail: VideoAnalytics,
        embedId: 'zWjNwAA7hYC6zc0K',
    },
]
