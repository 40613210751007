import { useQueryClient } from 'react-query'

import { Funnel, VideoToFunnelPayload } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import { SMART_VIDS_QUERY, VIDEOS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useCreateFunnelMutation = () => {
    const queryClient = useQueryClient()
    const key = SMART_VIDS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Funnel, VideoToFunnelPayload>(
        key,
        async (payload: VideoToFunnelPayload) =>
            mutate(smartVidConroller.createSmartVid(payload), apiLocale.funnel.create),
        {
            onSuccess: async (...args) => {
                await queryClient.cancelQueries(key)

                const [data] = args

                queryClient.setQueryData([key, data.id], data)

                queryClient.invalidateQueries(key)
            },
            invalidate: [VIDEOS_QUERY],
        },
    )
}
