import { SxProps, Theme } from '@mui/material'

const root: SxProps<Theme> = {
    display: 'flex',
    minHeight: 205,
    flexDirection: 'column',
    justifyContent: 'space-around',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderRadius: 4,
    py: 9,
}

const ico: SxProps<Theme> = {
    borderRadius: '50%',
    bgcolor: 'primary.light',
    color: 'white',
    px: 2,
    pt: 2,
    pb: '2px',
}

const cancelBtn: SxProps<Theme> = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'text.disabled',
    mt: 4,
}

const chooseFiles: SxProps<Theme> = {
    px: 1,
    cursor: 'pointer',
    fontWeight: 700,
}

const controls: SxProps<Theme> = (theme) => ({
    [theme.breakpoints.up('desktop')]: {
        flexDirection: 'row',
    },
})

export default {
    root,
    ico,
    chooseFiles,
    controls,
    cancelBtn,
}
