import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const container: SxProps<Theme> = {
    height: '100%',
    width: '100%',
    p: 3,
    backgroundColor: (theme) => theme.palette.background.paper,
}

const icon: SxProps<Theme> = {
    fontSize: '50px',
    m: '0 auto',
    fill: (theme) => theme.palette.text.disabled,
}

export default {
    container,
    icon,
}
