import { route } from 'constants/routes'

const { article, collection } = route.static.help

const helpArticles = {
    integration: {
        api: article({ slug: '2928645-api-functions' }),
        cfProTools: article({ slug: '4793066-vidalytics-cf-pro-tools' }),
        heySummit: article({ slug: '5082020-heysummit-vidalytics' }),
        make: article({ slug: '6082389-how-to-use-vidalytics-with-make-formerly-integromat' }),
        plusThis: article({ slug: '4793104-vidalytics-plusthis' }),
        salesMsg: article({ slug: '5371299-vidalytics-salesmsg' }),
        activeCampaign: article({ slug: '6891513-activecampaign-vidalytics-integration' }),
        knowledgeBase: collection({ slug: '441063-video-upload-customization' }),
        embedVideos: article({ slug: '600004-how-does-the-embed-code-work' }),
        zapierWhatIs: article({ slug: '1370744-what-is-zapier-why-should-i-use-it' }),
        zapierHowTo: article({ slug: '1370747-how-to-set-up-the-zapier-integration' }),
    },
    multipleVideos: article({ slug: '1539368-adding-multiple-videos-to-one-page' }),
    whatIsBandwidth: article({ slug: '7274945-what-is-bandwidth' }),
} as const

export default helpArticles
