import WbTwilightRoundedIcon from '@mui/icons-material/WbTwilightRounded'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import BeenhereRoundedIcon from '@mui/icons-material/BeenhereRounded'
import CallsToActionsSection from './CallsToActionsSection'
import PlayGatesSection from './PlayGatesSection'
import TagsSection from './TagsSection'

const useMarketingSettingsMenu = () => [
    { label: 'Calls to Action', Icon: WbTwilightRoundedIcon, Children: CallsToActionsSection },
    { label: 'Play Gates', Icon: EmailRoundedIcon, Children: PlayGatesSection, forPro: true },
    { label: 'Tags', Icon: BeenhereRoundedIcon, Children: TagsSection, forPro: true },
]

export default useMarketingSettingsMenu
