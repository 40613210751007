import { useMemo } from 'react'
import Box, { BoxProps } from '@mui/material/Box'

import logoTheme, { LogoTheme, LogoThemes } from './LogoThemes.constants'
import { viewbox, testId } from './Logo.constants'

interface LogoProps extends BoxProps {
    /** Specifies the theme of the logo. Optional parameter. */
    theme?: LogoThemes

    /** Determines if the Sign part of the logo should be displayed. Optional parameter. */
    showSign?: boolean

    /** Determines if the Logo part should be shown. Optional parameter. */
    showLogo?: boolean
}

/**
 * Logo component that displays a logo based on the given theme and options.
 * If the showLogo and showSign options are set to false, the component returns null.
 */
export const Logo = ({ theme = 'basic', showLogo = true, showSign = true, sx }: LogoProps) => {
    const style = useMemo<LogoTheme>(() => {
        switch (theme) {
            case 'light':
                return logoTheme.light
            case 'dark':
                return logoTheme.dark
            case 'Ukraine':
                return logoTheme.Ukraine
            case 'smooth':
                return logoTheme.smooth
            default:
                return logoTheme.basic
        }
    }, [theme])

    const viewBoxValue = useMemo(() => {
        if (showLogo && showSign) return viewbox.logoAndSign
        if (showLogo && !showSign) return viewbox.logoOnly
        if (!showLogo && showSign) return viewbox.signOnly
        return ''
    }, [showLogo, showSign])

    if (!showLogo && !showSign) return null

    return (
        <Box
            component="svg"
            viewBox={viewBoxValue}
            data-testid={testId.container}
            sx={[...(Array.isArray(style.root) ? style.root : [style.root]), sx]}
            xmlns="http://www.w3.org/2000/svg"
        >
            {showLogo && (
                <Box
                    component="path"
                    data-testid={testId.logo}
                    sx={style.logo}
                    d="M16.1434 28L0.258057 4H3.31272L16.1298 23.3645L24.2429 10.9523H15.903L14.1983 8.42767H28.9366L16.1434 28ZM16.138 19.7875L5.77116 4.00002H31.7421L30.0373 6.52467H10.4404L16.1331 15.139L17.6253 12.8559H20.6687L16.138 19.7875Z"
                />
            )}
            {showSign && (
                <Box component="g" sx={style.sign} data-testid={testId.sign}>
                    <Box
                        component="path"
                        sx={style.letter?.v}
                        d="M38.2926 8.80597C39.3723 8.80499 40.4532 8.80499 41.5329 8.80597C42.6073 12.023 43.6807 15.2408 44.7551 18.4589C45.8189 15.2408 46.8805 12.023 47.9466 8.80499C49.0102 8.80499 50.073 8.80499 51.1368 8.80597C49.5295 13.617 47.9189 18.426 46.3113 23.237C45.254 23.2338 44.1965 23.2484 43.1402 23.2297C41.5362 18.4177 39.9052 13.615 38.2926 8.80597Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.i}
                        d="M53.4359 8.80595C54.4965 8.80498 55.557 8.80498 56.6166 8.80595C56.6178 13.616 56.6166 18.427 56.6178 23.237C55.557 23.2392 54.4965 23.238 53.4359 23.238C53.4359 18.427 53.4349 13.616 53.4359 8.80595Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.d}
                        d="M63.4135 11.8077V20.2352C64.3487 20.2249 65.2879 20.2776 66.2166 20.155C67.1835 19.9861 68.0431 19.4393 68.6826 18.7287C69.8994 17.1882 69.8866 14.8783 68.6793 13.3349C68.0431 12.6233 67.191 12.0599 66.2198 11.8984C65.2942 11.7397 64.3487 11.8323 63.4135 11.8077ZM60.2329 8.80606C61.9774 8.80801 63.7231 8.79973 65.4677 8.8102C67.1367 8.82336 68.8079 9.36214 70.1026 10.3929C72.6939 12.4133 73.4866 16.1801 72.1207 19.0831C71.3346 20.8921 69.6792 22.3174 67.7411 22.8838C66.7729 23.2071 65.7368 23.239 64.723 23.2381C63.2262 23.2371 61.7294 23.239 60.2329 23.2381C60.2329 18.4271 60.2316 13.6161 60.2329 8.80606Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.a}
                        d="M78.5686 18.3714C79.6813 18.3733 80.7952 18.3733 81.9089 18.3714C81.3525 16.699 80.7982 15.0256 80.2429 13.3533C79.6835 15.0256 79.1259 16.699 78.5686 18.3714ZM78.6484 8.80594C79.7376 8.80496 80.8281 8.80496 81.9184 8.80496C83.5247 13.616 85.13 18.4269 86.7353 23.237C85.6652 23.2392 84.5948 23.2379 83.5247 23.2379C83.3143 22.6046 83.1066 21.9693 82.8961 21.336H77.5803C77.3708 21.9703 77.1611 22.6036 76.9517 23.2379H73.8103C75.4229 18.4269 77.0345 13.616 78.6484 8.80594Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.l}
                        d="M89.044 8.80595H92.2259V20.2353L99.4635 20.2363V23.238C95.5712 23.238 89.044 23.237 89.044 23.237V8.80595Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.y}
                        d="M98.5444 8.80493H101.974C101.974 8.80493 104.141 13.0075 105.226 15.108C106.311 13.0075 108.478 8.80493 108.478 8.80493H111.866C111.866 8.80493 108.475 15.273 106.813 18.5207C106.786 20.0922 106.809 21.6657 106.802 23.2369C105.738 23.2382 104.674 23.2382 103.61 23.2382C103.611 21.7953 103.607 20.3537 103.612 18.9109C103.607 18.7637 103.627 18.6063 103.547 18.4732C101.879 15.2513 98.5444 8.80493 98.5444 8.80493Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.t}
                        d="M113.269 8.80595H124.956L124.958 11.8066L120.709 11.8076V23.237C119.645 23.2392 118.581 23.238 117.517 23.238V11.8076H113.269V8.80595Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.i}
                        d="M127.525 8.80595C128.585 8.80498 129.646 8.80498 130.707 8.80595V23.238C129.646 23.238 128.585 23.238 127.525 23.237C127.526 18.427 127.524 13.616 127.525 8.80595Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.c}
                        d="M136.06 10.2775C138.25 8.47232 141.495 8.11801 144.124 9.17046C145.391 9.70607 146.501 10.5926 147.257 11.7181C146.398 12.3422 145.539 12.9641 144.679 13.5872C144.138 12.6819 143.181 12.0271 142.142 11.7625C140.808 11.473 139.294 11.6789 138.24 12.5698C137.394 13.1639 136.905 14.1113 136.689 15.0814C136.426 16.3449 136.723 17.7051 137.496 18.7544C138.107 19.4631 138.913 20.053 139.854 20.2692C140.92 20.5184 142.092 20.4319 143.068 19.9295C143.699 19.5598 144.306 19.0995 144.669 18.4652C145.535 19.0819 146.402 19.6977 147.267 20.3167C146.498 21.4464 145.38 22.3402 144.1 22.8787C142.411 23.5542 140.482 23.6366 138.729 23.1463C136.979 22.6665 135.445 21.5122 134.514 20.0016C133.8 18.7978 133.377 17.4178 133.408 16.0245C133.376 13.8621 134.342 11.676 136.06 10.2775Z"
                    />
                    <Box
                        component="path"
                        sx={style.letter?.s}
                        d="M155.041 14.4429C154.186 14.3378 153.208 14.41 152.509 13.8363C151.92 13.3307 152.111 12.3545 152.734 11.9571C153.426 11.4668 154.339 11.4709 155.149 11.6069C155.871 11.7563 156.587 12.3143 156.62 13.0722H156.622L159.523 11.6242C159.195 10.7501 158.57 9.97863 157.753 9.48347C156.458 8.67104 154.842 8.45904 153.335 8.65666C151.947 8.83796 150.594 9.52173 149.792 10.6544C149.313 11.3608 149.008 12.193 149 13.0434C148.916 14.3739 149.574 15.7539 150.735 16.49C151.746 17.147 152.996 17.2788 154.181 17.3807C154.903 17.4713 155.699 17.4384 156.321 17.8649C156.836 18.1973 156.99 18.8986 156.734 19.431C156.506 20.0024 155.882 20.3095 155.296 20.4299C154.451 20.569 153.518 20.5225 152.779 20.0612C152.316 19.8046 152.046 19.2997 152.018 18.7926L149.122 20.2383C149.285 20.6862 149.52 21.11 149.813 21.4884C150.703 22.5955 152.103 23.2307 153.521 23.3996C155.024 23.579 156.638 23.3638 157.915 22.5338C159.233 21.7182 160.02 20.2064 159.997 18.7019C160.041 17.6485 159.59 16.5982 158.848 15.8382C157.857 14.861 156.409 14.5221 155.041 14.4429Z"
                    />
                </Box>
            )}
            {style.gradient}
        </Box>
    )
}
