export const trackAppCuesEvent = (eventName: string) => {
    window.Appcues?.track(eventName)
}

export const PLAYS_LOG_SENT = 'PLAYS_LOG_SENT'

export const trackAppCuesEventOnce = (eventName: string, id: string) => {
    const sentKey = `${PLAYS_LOG_SENT}-${id}`
    const isSent = localStorage.getItem(sentKey)

    if (!isSent) {
        window.Appcues?.track(eventName)
        localStorage.setItem(sentKey, 'true')
    }
}
