export default {
    title: 'Seeking bar',
    controls: {
        alwaysShow: {
            label: 'Always show',
            hint: 'This setting indicates whether the control bar should disappear or not, and it will apply to any seeking bar that is selected for the video.',
        },
        endVideo: {
            standard: {
                label: 'Standard',
                tooltip:
                    'A regular seeking bar that progresses at regular speed and includes the duration of the video.',
            },
            revisit: {
                label: 'ReVisit Seeking Bar',
                tooltip:
                    'This seeking bar is shown only to viewers that come to your video for a second (or third) time.',
            },
            engageBar: {
                label: 'Rapid Engage Bar',
                tooltip:
                    'This progress bar starts off really fast, which will hook your viewers attention as your video will seem shorter. It then gradually slows down closer to the actual speed of your video. It will not allow your viewers to jump ahead in your video either. This feature cannot be used with the standard seeking bar.',
            },
            none: {
                label: 'None',
            },
        },
    },
}
