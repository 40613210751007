import { useQuery } from 'react-query'
import { UserApiToken } from 'types/Customer'
import { GENERATE_USER_API_TOKEN_QUERY } from 'api/constants'
import { userAPI } from 'api/controllers'

type UserApiTokenData = UserApiToken | null

/**
 * @deprecated use useGenerateApiTokenMutation instead
 */

export const useGenerateApiTokenQuery = () => {
    return useQuery<UserApiTokenData, Error>(GENERATE_USER_API_TOKEN_QUERY, () => userAPI.generateApiToken(), {
        enabled: false,
        refetchOnWindowFocus: false,
    })
}
