import { SxProps } from '@mui/material'
import { Theme, alpha } from '@mui/material/styles'

const container: SxProps<Theme> = {
    borderRadius: 3,
    px: 3,
    py: 2,
    overflow: 'hidden',
    border: (theme) => `1px solid ${theme.palette.action.disabled}`,
    backgroundColor: (theme) => theme.palette.action.hover,
}

const link: SxProps<Theme> = {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}

const fallback: SxProps<Theme> = (theme: Theme) => ({
    borderRadius: 3,
    width: 1,
    px: 3,
    py: '7px',
    overflow: 'hidden',
    border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,

    [theme.breakpoints.up('laptop')]: {
        width: 320,
    },
})

const disabled: SxProps<Theme> = (theme: Theme) => ({
    backgroundColor: theme.palette.action.disabledBackground,
})

const rowVideo: SxProps<Theme> = (theme: Theme) => ({
    width: 1,
    ml: 2,

    [theme.breakpoints.up('laptop')]: {
        width: 320,
    },
})

const rowCondition: SxProps<Theme> = (theme: Theme) => ({
    width: 1,
    pl: 6,

    [theme.breakpoints.up('laptop')]: {
        width: 200,
        pl: 0,
    },
})

const trash: SxProps<Theme> = (theme: Theme) => ({
    position: 'absolute',
    top: 8,
    left: -42,

    [theme.breakpoints.up('laptop')]: {
        position: 'relative',
        top: 'auto',
        left: 'auto',
    },
})

const select: SxProps<Theme> = () => ({
    '& .MuiSelect-select': {
        minWidth: 150,
    },

    '&.Mui-disabled': {
        opacity: 0.44,
    },

    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: (theme) => `1px solid ${theme.palette.error.main}`,
    },
})

const label: SxProps<Theme> = {
    position: 'absolute',
    top: 'calc(50% - 1px)',
    transform: 'translateY(-50%)',
    left: 12,
    fontSize: 'body1.fontSize',
    pointerEvents: 'none',
    transition: 'transform 200ms',
    zIndex: 1,
}

export default {
    container,
    link,
    fallback,
    rowVideo,
    rowCondition,
    trash,
    select,
    label,
    disabled,
}
