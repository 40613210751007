import { TableState, SortingRule } from 'react-table'

import SortOutlinedIcon from '@mui/icons-material/SortOutlined'
import Button from '@mui/material/Button'

import './index.scss'

export type SortByOption = {
    label: string
    id: string
}

export type SortByType = {
    operations: Array<SortByOption>
}

export type SortFn = (columnId: string, descending?: boolean, isMulti?: boolean) => void

type SortByProps = {
    sortBy?: SortByType
    state: TableState<object>
    toggleSortBy: SortFn
}

export const getCurrentSortingMode = (operation: SortByOption, sortedList: SortingRule<object>[]) => {
    if (!sortedList.length) return ''

    const sortedOption = sortedList.find((sorter) => sorter.id === operation.id)

    if (!sortedOption) return ''

    return sortedOption.desc ? 'descendant' : 'ascendant'
}

export default function SortBy({ sortBy, state: { sortBy: sortedList }, toggleSortBy }: SortByProps) {
    if (!sortBy) {
        return null
    }

    const handleClick = (id: SortByOption['id']) => () => {
        toggleSortBy(id)
    }

    return (
        <div className="SortBy">
            <span>Sort by</span>
            <div className="sortOperationsContainer">
                {sortBy.operations.map((operation) => {
                    const sortingMode = getCurrentSortingMode(operation, sortedList)

                    return (
                        <Button
                            key={operation.label}
                            className={`sortOperation ${sortingMode}`}
                            color="tertiary"
                            onClick={handleClick(operation.id)}
                            data-testid={`sortOperationButton-${operation.label}`}
                        >
                            <span>{operation.label}</span>
                            {sortingMode && <SortOutlinedIcon className={sortingMode} />}
                        </Button>
                    )
                })}
            </div>
        </div>
    )
}
