import { SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const container: SxProps<Theme> = {
    cursor: 'pointer',
    userSelect: 'none',
    p: 0,
    color: 'action.active',

    '&:hover *': {
        color: 'primary.light',
    },
}

export default {
    container,
}
