import { Funnel } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import { FUNNEL_PUBLISH_QUERY, SMART_VID_DETAILS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { Id } from 'types'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { UseMutationOptions } from 'react-query'

export const usePublishFunnelMutation = (options?: UseMutationOptions<Funnel, unknown, Id>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Funnel, Id>(
        FUNNEL_PUBLISH_QUERY,
        async (id: Id) => mutate(smartVidConroller.publishSmartVid(id), apiLocale.funnel.publish),
        { ...options, invalidate: [SMART_VID_DETAILS_QUERY] },
    )
}
