/* eslint-disable @typescript-eslint/no-var-requires */
import { Suspense, lazy } from 'react'
import { ScreenLoader } from 'design/atoms/ScreenLoader'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'

const RoutesProtected = lazy(() => import('App/Routes/RoutesProtected'))

function RoutesProtectedLazy() {
    return (
        <Suspense fallback={<ScreenLoader />}>
            <RoutesProtected />
        </Suspense>
    )
}

export default withErrorBoundary(RoutesProtectedLazy)
