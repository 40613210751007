import { Components, Theme } from '@mui/material'

export const MuiToggleButton: Components<Omit<Theme, 'components'>>['MuiToggleButton'] = {
    styleOverrides: {
        root: ({ theme }) => ({
            borderRadius: 12,

            color: theme.palette.text.secondary,

            '&:hover': {
                color: theme.palette.text.secondary,
            },

            '&.Mui-selected': {
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
            },
        }),
    },
    variants: [
        {
            props: { size: 'small' },
            style: { padding: '7px 11px' },
        },
        {
            props: { size: 'medium' },
            style: { padding: 11 },
        },
        {
            props: { size: 'large' },
            style: { padding: 15 },
        },
    ],
}
