import { useMemo, useCallback } from 'react'

import { useCustomerQuery } from 'api/queries'

export const VID_4566 = 'VID-4566-plan-reactivation'
export const VID_4622 = 'VID-4622-funnel-embed-code-api-change'
export const VID_5285 = 'VID-5285-switch-new-player'
export const VID_4846 = 'VID-4846-hide-inherit-styles-and-jump-back'
export const VID_4917 = 'VID-4917-agency-accounts'
export const VID_4797 = 'VID-4797-video-length-limit'
export const VID_6050 = 'VID-6050-high-level-integration'
export const VID_6290 = 'VID-6290-segment-integration'
export const VID_6400 = 'VID-6400-block-player-switch-for-new-uploads'
export const VID_4927 = 'VID-4927-timed-exit-cta'
export const VID_5559 = 'VID-5559_Vid_Conditions'
export const VID_5597 = 'VID-5597-Captions-Improvements'
export const VID_7329 = 'VID-7329-Caption-Search'
export const VID_7670 = 'VID-7670-disable-new-closed-caption-settings'
export const VID_7425 = 'VID-7425-add-button-to-change-the-light-dark-mode'
export const VID_5175 = 'upsell_banner'
export const VID_7609 = 'VID-7609-lower-bandwidth-usage-setting'
export const VID_6649 = 'VID-6649-account-settings-revamp'
export const VID_8547 = 'VID-8547-add-playgate-checkbox'
// Smart Vids
export const VID_5988 = 'VID-5988-SV-Create-New-SV'
export const VID_7046 = 'VID-7046-autoplay-on-return-vid-settings'
export const VID_5550 = 'VID-5550-add-global-script-to-account-settings'
export const VID_6410 = 'VID-6410-add-sv-analytics-mode'
export const VID_5416 = 'VID-5416-fe-add-ability-to-customize-columns-on-stats-page'
export const VID_8102 = 'VID-8102-discard-sv-changes'
export const VID_7344 = 'VID-7344-hide-resume-play'
export const VID_8474 = 'VID-8474-sv-analytics'
// Stats Revamp
export const VID_7155 = 'VID-7155-stats-revamp-components'
// Coupons functionality
export const VID_6665 = 'VID-6665-coupons'
// Video thumbnails
export const VID_7449 = 'VID-7449-video-thumbnails'
// Under construction Smart Vids 2
export const VID_8106 = 'VID-8106-under-construction'

export const VID_7932 = 'VID-7932-Analytics'

export const VID_7870 = 'VID-7870-Dashboard'

export const VID_7613 = 'VID-7613-imported-from'

const useFeatureFlags = () => {
    const { data: customerFlags } = useCustomerQuery()

    const featureFlags = useMemo(() => customerFlags?.featureFlags || {}, [customerFlags])

    const isFlagEnabled = useCallback(
        (
            flag:
                | typeof VID_4566
                | typeof VID_4622
                | typeof VID_5285
                | typeof VID_4846
                | typeof VID_4917
                | typeof VID_4797
                | typeof VID_6050
                | typeof VID_6290
                | typeof VID_4927
                | typeof VID_5988
                | typeof VID_6400
                | typeof VID_5597
                | typeof VID_5559
                | typeof VID_7046
                | typeof VID_7425
                | typeof VID_7046
                | typeof VID_7329
                | typeof VID_7670
                | typeof VID_5175
                | typeof VID_7609
                | typeof VID_5550
                | typeof VID_6665
                | typeof VID_6410
                | typeof VID_7155
                | typeof VID_7449
                | typeof VID_5416
                | typeof VID_6649
                | typeof VID_8547
                | typeof VID_7449
                | typeof VID_8102
                | typeof VID_7344
                | typeof VID_7870
                | typeof VID_7932
                | typeof VID_8474
                | typeof VID_8106
                | typeof VID_7613,
        ) => Boolean(featureFlags[flag]),
        [featureFlags],
    )

    return {
        featureFlags,
        isFlagEnabled,
        planReactivation: isFlagEnabled(VID_4566),
        funnelEmbedCodeApiChange: isFlagEnabled(VID_4622),
        swithNewPlayerFlag: isFlagEnabled(VID_5285),
        hideISandJB: isFlagEnabled(VID_4846),
        highLevelCardIntegration: isFlagEnabled(VID_6050),
        agencyAccounts: isFlagEnabled(VID_4917),
        videoLengthLimit: isFlagEnabled(VID_4797),
        segmentCardIntegration: isFlagEnabled(VID_6290),
        blockPlayerSwitchForNewUploads: isFlagEnabled(VID_6400),
        timedExitCTA: isFlagEnabled(VID_4927),
        createNewSV: isFlagEnabled(VID_5988),
        captionsImprovements: isFlagEnabled(VID_5597),
        vidConditions: isFlagEnabled(VID_5559),
        captionSearch: isFlagEnabled(VID_7329),
        disableNewCaptionSettings: isFlagEnabled(VID_7670),
        showAutoplayOnReturn: isFlagEnabled(VID_7046),
        showDarkLightModeToggle: isFlagEnabled(VID_7425),
        showGlobalScript: isFlagEnabled(VID_5550),
        showSVAnalyticsMode: isFlagEnabled(VID_6410),
        upsellBanner: isFlagEnabled(VID_5175),
        lowerBandwidthUsage: isFlagEnabled(VID_7609),
        hideResumePlay: isFlagEnabled(VID_7344),
        statsRevamp: isFlagEnabled(VID_7155),
        columnsOnStats: isFlagEnabled(VID_5416),
        couponFlag: isFlagEnabled(VID_6665),
        accountSettingsRevamp: isFlagEnabled(VID_6649),
        addPlayGateCheckbox: isFlagEnabled(VID_8547),
        videoThumbnails: isFlagEnabled(VID_7449),
        svUnderConstruction: isFlagEnabled(VID_8106),
        svDiscardChanges: isFlagEnabled(VID_8102),
        analyticsPage: isFlagEnabled(VID_7932),
        svAnalytics: isFlagEnabled(VID_8474),
        newDashboard: isFlagEnabled(VID_7870),
        isImportedFrom: isFlagEnabled(VID_7613),
    }
}

export default useFeatureFlags
