import { useQuery } from 'react-query'
import { UserApiToken } from 'types/Customer'
import { USER_API_TOKEN_QUERY } from 'api/constants'
import { userAPI } from 'api/controllers'
import { enableQueryHelper } from 'utils'

export const useApiTokenQuery = (isSubUser?: boolean) => {
    return useQuery<UserApiToken, Error>(USER_API_TOKEN_QUERY, () => userAPI.getApiToken(), {
        enabled: enableQueryHelper() && isSubUser,
    })
}
