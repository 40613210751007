import { ChangeEvent, InputHTMLAttributes } from 'react'
import { Row as RowType, TableToggleRowsSelectedProps } from 'react-table'
import { Checkbox } from '@mui/material'

type SelectionProps<T extends object = {}> = Partial<TableToggleRowsSelectedProps> & {
    indeterminate?: boolean
    flatRows?: RowType<T>[]
    selectedFlatRows?: RowType<T>[]
    id?: string
}

type HandlerEvent = ChangeEvent<HTMLInputElement> & {
    nativeEvent: PointerEvent
}

export const Selection = <T extends object = {}>({
    id,
    flatRows,
    selectedFlatRows,
    onChange,
    ...rest
}: SelectionProps<T>) => {
    const checkboxId = `select-${id || 'all'}`

    const enableByIndexesRange = (start: number, end: number) => {
        const initial = { toDisable: [] as RowType<T>[], toEnable: [] as RowType<T>[] }
        const { toDisable, toEnable } =
            flatRows?.reduce((result, row, index) => {
                if (index >= start && index <= end) {
                    result.toEnable.push(row)
                } else {
                    result.toDisable.push(row)
                }

                return result
            }, initial) || initial

        toEnable.forEach((row) => row.toggleRowSelected(true))
        toDisable.forEach((row) => row.toggleRowSelected(false))
    }

    const findIndexById = (idToLookFor: string) => flatRows?.findIndex((r) => r.id === idToLookFor) || -1

    const handleChange = (event: HandlerEvent) => {
        if (!id || !(event.nativeEvent.shiftKey || event.nativeEvent.ctrlKey) || !selectedFlatRows || !flatRows) {
            return onChange?.(event)
        }

        const selectedRowIdx = findIndexById(id)

        if (selectedFlatRows.length) {
            const firstSelectedIdx = findIndexById(selectedFlatRows[0].id)
            const lastSelectedIdx = findIndexById(selectedFlatRows[selectedFlatRows.length - 1].id)

            return enableByIndexesRange(
                selectedRowIdx < firstSelectedIdx ? selectedRowIdx : firstSelectedIdx,
                selectedRowIdx < firstSelectedIdx ? lastSelectedIdx : selectedRowIdx,
            )
        }

        return enableByIndexesRange(0, selectedRowIdx)
    }

    return (
        <Checkbox
            sx={{ m: 0 }}
            checked={rest.checked ?? false}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(event as HandlerEvent)}
            id={checkboxId}
            inputProps={{ 'data-testid': checkboxId } as InputHTMLAttributes<HTMLInputElement>}
        />
    )
}
