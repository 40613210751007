/* eslint-disable  @typescript-eslint/no-explicit-any */
import isEmpty from 'lodash/isEmpty'

export type URLParams = {
    [key in URLParamKey]?: string | null
}

export type URLParamKey = 'utm_source' | 'utm_medium' | 'utm_campaign' | 'utm_content' | 'utm_term' | 'gclid' | 'fbclid'

export const VIDALYTICS_UTM = 'vidalytics_utm'

export const getMarketingParamsFromURL = (predefinedParams?: URLParamKey[]): URLParams | null => {
    const queryString = window.location.search
    const params = new URLSearchParams(queryString)

    // make URLSearchParams() case insensitive
    const searchParams = new URLSearchParams()
    for (const [name, value] of params) {
        searchParams.append(name.toLowerCase(), value)
    }

    const result: URLParams = {}
    const urlParams = predefinedParams?.length
        ? predefinedParams
        : ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'gclid', 'fbclid']

    for (const urlElement of urlParams) {
        if (searchParams.has(urlElement)) {
            const value = searchParams.get(urlElement)
            result[urlElement as URLParamKey] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : null
        }
    }

    return isEmpty(result) ? null : result
}

export const getMarketingParamsFromCookies = (
    cookies: { [x: string]: any },
    predefinedParams?: URLParamKey[],
): URLParams | null => {
    const paramsFromCookies = cookies[VIDALYTICS_UTM] as URLParams
    if (!paramsFromCookies) return null

    let result: URLParams = {}

    if (predefinedParams?.length) {
        for (const key of predefinedParams) {
            result[key as URLParamKey] = paramsFromCookies[key]
        }
    } else {
        result = paramsFromCookies
    }

    return result
}

export const getMarketingParamsAll = (
    cookies: {
        [x: string]: any
    },
    predefinedParams?: URLParamKey[],
): URLParams | null => {
    let paramsAll: URLParams | null = null
    const paramsFromURL = getMarketingParamsFromURL(predefinedParams)
    const paramsFromCookies = getMarketingParamsFromCookies(cookies, predefinedParams)

    if (paramsFromURL) {
        paramsAll = paramsFromURL
    } else if (paramsFromCookies) {
        paramsAll = paramsFromCookies
    }

    return paramsAll
}

export const objectToQueryString = (obj: { [key: string]: any }): string => {
    let queryString = ''
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (queryString.length > 0) {
                queryString += '&'
            }
            queryString += `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
        }
    }

    return queryString
}
