import { useQuery } from 'react-query'

import { Plan } from 'types/Customer'
import { userAPI } from 'api/controllers'
import { PLANS_QUERY } from 'api/constants'
import { useCustomerSubscriptionQuery } from './useCustomerSubscriptionQuery'

export const usePlansQuery = () => {
    const { data } = useCustomerSubscriptionQuery()
    const billingMode = data?.plan?.billingMode

    return useQuery<Plan[]>(PLANS_QUERY, () => userAPI.getPlans(), {
        select: (plans) => plans.filter((plan) => plan.billingMode === billingMode),
    })
}
