import { FormikContextType, useFormikContext } from 'formik'
import { cloneDeep, isEqual, once } from 'lodash'
import { createContext, ReactNode, useContext, useEffect, useRef } from 'react'

import { VidSettings } from 'api/contracts/smartVids/entities/video'
import { useUpdateSmartVidGlobalSettingsMutation } from 'api/mutations'
import { useSmartVidQuery, useVideoQuery } from 'api/queries'
import { Funnel } from 'types/Funnel'
import { VideoDetails } from 'types/Video'
import { useSmartVidPageContext } from '../contexts/smartVidPageContext'
import useSvIds from '../hooks/useSvIds'

interface ContextState<T> {
    smartVid?: Funnel
    video?: VideoDetails
    formik: FormikContextType<T | any>
    isLoading: boolean
    onChange: () => void
}

const createGlobalSettingsFormContext = once(<T,>() => createContext({} as ContextState<T>))
const useGlobalSettingsFormContext = <T,>() => useContext(createGlobalSettingsFormContext<T>())

interface GlobalSettingsFormProviderProps {
    children?: ReactNode
}

const GlobalSettingsFormProvider = ({ children }: GlobalSettingsFormProviderProps) => {
    const { smartVidId } = useSvIds()
    const formik = useFormikContext<{}>()

    const { data: smartVid, isLoading: isGlobalSettingsQueryLoading } = useSmartVidQuery(smartVidId)
    const { data: video, isLoading: isVideoQueryLoading } = useVideoQuery(smartVid?.videoGuid)

    const { editingDisabled } = useSmartVidPageContext()

    useEffect(() => {
        if (smartVid) {
            formik.resetForm({ values: smartVid.globalSettings })
        }
    }, [])

    const { mutate, isLoading: isUpdateGlobalSettingsMutationLoading } = useUpdateSmartVidGlobalSettingsMutation(
        String(smartVidId),
    )

    const valuesRef = useRef(formik.values)

    const timer = useRef<ReturnType<typeof setTimeout>>()

    valuesRef.current = formik.values

    const replaceNewlinesWithBr = (inputString: string) => {
        return inputString?.replace(/\n/g, '<br>')
    }

    const getUpdatedVideoDetails = (settings: VidSettings): VidSettings => {
        const background = settings.ui_color_background
        const foreground = settings.ui_color_foreground

        return {
            ...settings,
            ui_overlay_unmute_color_background: background,
            ui_overlay_unmute_color_foreground: foreground,
            ui_overlay_resume_color_background: background,
            ui_overlay_resume_color_foreground: foreground,
            ui_overlay_redirect_color_foreground: foreground,
            ui_overlay_redirect_color_background: background,
            ui_overlay_redirect_text: replaceNewlinesWithBr(settings?.ui_overlay_redirect_text),
            ui_overlay_resume_button_replay_text: replaceNewlinesWithBr(settings?.ui_overlay_resume_button_replay_text),
            ui_overlay_resume_button_resume_text: replaceNewlinesWithBr(settings?.ui_overlay_resume_button_resume_text),
            ui_overlay_resume_text: replaceNewlinesWithBr(settings?.ui_overlay_resume_text),
            ui_overlay_unmute_mobile_textBottom_text: replaceNewlinesWithBr(
                settings?.ui_overlay_unmute_mobile_textBottom_text,
            ),
            ui_overlay_unmute_mobile_textTop_text: replaceNewlinesWithBr(
                settings?.ui_overlay_unmute_mobile_textTop_text,
            ),
            ui_overlay_unmute_textBottom_text: replaceNewlinesWithBr(settings?.ui_overlay_unmute_textBottom_text),
            ui_overlay_unmute_textTop_text: replaceNewlinesWithBr(settings?.ui_overlay_unmute_textTop_text),
            ui_overlay_expire_textTop_text: replaceNewlinesWithBr(String(settings?.ui_overlay_expire_textTop_text)),
            ui_overlay_expire_textBottom_text: replaceNewlinesWithBr(
                String(settings?.ui_overlay_expire_textBottom_text),
            ),
            ui_controlBar_fullscreen_show:
                settings.ui_controlBar_fullscreen_customFullscreen_default_enabled ||
                settings.ui_controlBar_fullscreen_customFullscreen_mobile_enabled,
        }
    }

    const runColorDependency = (settings: VidSettings, prevSettings: VidSettings) => {
        const currentIsColorForce = settings.ui_color_force

        if (currentIsColorForce) {
            let currentUpdated = cloneDeep(settings)
            const prevIsColorForce = prevSettings.ui_color_force

            if (prevIsColorForce) {
                const prevColorSettings = {
                    ui_overlay_unmute_color_background: prevSettings.ui_overlay_unmute_color_background,
                    ui_overlay_unmute_color_foreground: prevSettings.ui_overlay_unmute_color_foreground,
                    ui_overlay_resume_color_background: prevSettings.ui_overlay_resume_color_background,
                    ui_overlay_resume_color_foreground: prevSettings.ui_overlay_resume_color_foreground,
                    ui_overlay_redirect_color_foreground: prevSettings.ui_overlay_redirect_color_foreground,
                    ui_overlay_redirect_color_background: prevSettings.ui_overlay_redirect_color_background,
                }

                const currentColorSettings = {
                    ui_overlay_unmute_color_background: settings.ui_overlay_unmute_color_background,
                    ui_overlay_unmute_color_foreground: settings.ui_overlay_unmute_color_foreground,
                    ui_overlay_resume_color_background: settings.ui_overlay_resume_color_background,
                    ui_overlay_resume_color_foreground: settings.ui_overlay_resume_color_foreground,
                    ui_overlay_redirect_color_foreground: settings.ui_overlay_redirect_color_foreground,
                    ui_overlay_redirect_color_background: settings.ui_overlay_redirect_color_background,
                }

                if (isEqual(prevColorSettings, currentColorSettings)) {
                    currentUpdated = getUpdatedVideoDetails(currentUpdated)
                } else {
                    currentUpdated.ui_color_force = false
                }
            } else {
                currentUpdated = getUpdatedVideoDetails(currentUpdated)
            }

            return currentUpdated
        }

        return settings
    }

    const globalSettingsFormProps = {
        globalSettings: smartVid?.globalSettings,
        video,
        formik,
        isLoading:
            isGlobalSettingsQueryLoading ||
            isUpdateGlobalSettingsMutationLoading ||
            isVideoQueryLoading ||
            editingDisabled,
        onChange: () => {
            clearTimeout(timer.current)

            if (!smartVid?.globalSettings || !video) return

            timer.current = setTimeout(() => {
                const vidSettings = runColorDependency(
                    {
                        ...smartVid.globalSettings,
                        ...valuesRef.current,
                    },
                    smartVid.globalSettings,
                )

                const payload = {
                    ...smartVid,
                    globalSettings: vidSettings,
                }

                mutate(payload)
            }, 500)
        },
    }

    useEffect(() => {
        return () => {
            clearTimeout(timer.current)
        }
    }, [])

    const GlobalSettingsFormContext = createGlobalSettingsFormContext()

    return (
        <GlobalSettingsFormContext.Provider value={globalSettingsFormProps}>
            {children}
        </GlobalSettingsFormContext.Provider>
    )
}

export { GlobalSettingsFormProvider, useGlobalSettingsFormContext }
