import { Form, Formik, FormikProps } from 'formik'
import { useParams } from 'react-router-dom'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import { THUMBNAILS_SECTION_INITIAL_VALUES } from 'design/pages/VidSettings/constants'
import { ThumbnailsSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { VideoDetails } from 'types/Video'
import { useVideoQuery } from 'api/queries'
import { GlobalSettingsFormProvider } from 'design/pages/SmartVid/GlobalSettings/globalSettingsFormContext'
import { useMemo } from 'react'
import { ThumbnailsContent } from './ThumbnailsContent'

interface Props {
    isSmartVidGlobalSettings?: boolean
}

const ThumbnailsSection = ({ resetForm, isSmartVidGlobalSettings }: FormikProps<ThumbnailsSectionFormData> & Props) => {
    const { videoGuid } = useParams()

    const initForm = (details: VideoDetails) => {
        resetForm({
            values: details.settings,
        })
    }

    useVideoQuery(videoGuid, {
        onSuccess: initForm,
    })

    const Provider = useMemo(
        () => (isSmartVidGlobalSettings ? GlobalSettingsFormProvider : SettingsSectionProvider),
        [isSmartVidGlobalSettings],
    )

    return (
        <Provider>
            <Form>
                <ThumbnailsContent />
            </Form>
        </Provider>
    )
}

const FormikWrapper = () => {
    return (
        <Formik<ThumbnailsSectionFormData>
            initialValues={THUMBNAILS_SECTION_INITIAL_VALUES}
            /* eslint-disable  @typescript-eslint/no-empty-function */
            onSubmit={() => {}}
        >
            {(props) => <ThumbnailsSection {...props} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
