import { agencyController } from 'api/controllers/agency.controller'
import { AGENCY_GET_CLIENTS, AGENCY_REVOKE_CANCEL_ACCOUNT } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { AgencyClientId } from 'api/contracts/agency/entities/agencyClient'

export const useRevokeAccountCancellationMutation = () => ({
    revokeAccountCancellation: useInvalidateMutation<undefined, AgencyClientId>(
        AGENCY_REVOKE_CANCEL_ACCOUNT,
        async (id: AgencyClientId) => agencyController.client.cancelAccount.revoke(id),
        { invalidate: [AGENCY_GET_CLIENTS] },
    ),
})
