import { ChangeEvent, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Stack from '@mui/material/Stack'
import { debounce as _debounce, noop as _noop } from 'lodash'

import { Input } from 'design/atoms/Form'
import ClosedCaptionsDeleteModal from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsDeleteModal'
import { useEditClosedCaptionsMutation } from 'api/mutations'
import { ActionNotifications } from 'design/pages/VidSettings/constants'
import { setActionNotification } from 'design/pages/VidSettings/VidSettings.slice'
import { useAppDispatch } from 'App'
import { useVideoQuery } from 'api/queries'

const TM = 1500
const TM_SAVED = 2000

type ClosedCaptionsInputProps = {
    title: string
    value: string
}

const ClosedCaptionsInput = ({ title, value }: ClosedCaptionsInputProps) => {
    const { videoGuid } = useParams()
    const dispatch = useAppDispatch()
    const { data } = useVideoQuery(videoGuid)
    const { mutateAsync: editClosedCaptionsMutateAsync } = useEditClosedCaptionsMutation()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleChange = useCallback(
        async (text: string) => {
            if (!data?.video || !text) {
                return
            }

            const file = new File([text], title, { type: 'text/plain' })
            dispatch(setActionNotification(ActionNotifications.saving))
            await editClosedCaptionsMutateAsync({ video: data?.video, file })
            dispatch(setActionNotification(ActionNotifications.saved))

            setTimeout(() => {
                dispatch(setActionNotification(ActionNotifications.empty))
            }, TM_SAVED)
        },
        [data?.video, title],
    )

    const handleChangeDebounced = _debounce(handleChange, TM)

    return (
        <Stack>
            <Formik initialValues={{ closedCaptionsInput: value }} onSubmit={_noop}>
                {(props) => (
                    <Form>
                        <Input
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const current = e?.currentTarget?.value?.trim()

                                if (!current) {
                                    return setIsModalOpen(true)
                                }

                                handleChangeDebounced(e?.currentTarget?.value)
                                return props.handleChange(e)
                            }}
                            name="closedCaptionsInput"
                            variant="outlined"
                            displayMaxLength
                            inputProps={{
                                value: props.values.closedCaptionsInput,
                            }}
                            minRows={8}
                            maxRows={28}
                            multiline
                        />
                    </Form>
                )}
            </Formik>
            <ClosedCaptionsDeleteModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
        </Stack>
    )
}

export default ClosedCaptionsInput
