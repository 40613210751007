import { endpoints } from 'api/constants'
import { CreateSmartVidRequest } from 'api/contracts/smartVids/endpoints/createSmartVid.contract'
import { CreateSmartVidStepRequest } from 'api/contracts/smartVids/endpoints/createSmartVidStep.contract'
import { UpdateSmartVidStepRequest } from 'api/contracts/smartVids/endpoints/updateSmartVidSteps.contract'
import { http } from 'api/utils/http.utils'
import { Id } from 'types'
import {
    CreatedFunnel,
    Funnel,
    ReplaceVideoPayload,
    // branches
    StepSettings,
    UpdateFunnelHeadlinePayload,
    VideoToFunnelPayload,
} from 'types/Funnel'
import { VideoDetails, VideoEmbed } from 'types/Video'
import { UpdateSmartVidRequest } from 'api/contracts/smartVids/endpoints/updateSmartVid.contract'

export const smartVidConroller = {
    getSmartVids: () => http.get<Funnel>('video/funnel'),
    getSmartVid: (id: Id) => http.get<Funnel>(`video/funnel/${id}`),
    getSmartVidEmbed: (id: Id) => http.get<VideoEmbed>(`video/funnel/${id}/embed`),
    getSmartVidSteps: (id: Id) => http.get<Funnel>(`video/funnel/${id}/all-branches`),
    deleteSmartVid: (id: Id) => http.delete(`video/smart-vids/${id}`),
    createSmartVid: (video: VideoToFunnelPayload): Promise<CreatedFunnel> =>
        http.post<VideoToFunnelPayload, CreatedFunnel>('video/funnel', video),
    replaceSmartVidVideo: (payload: ReplaceVideoPayload): Promise<CreatedFunnel> =>
        http.put<VideoToFunnelPayload, CreatedFunnel>(`video/funnel/${payload.id}`, payload.video),
    publishSmartVid: (id: Id) => http.post(`video/smart-vids/${id}/publish`),
    updateSmartVidHeadline: (funnelId: Id, payload: UpdateFunnelHeadlinePayload) =>
        http.put<UpdateFunnelHeadlinePayload>(`/video/funnel/${funnelId}`, payload),
    updateSmartVid: (funnelId: Id, payload: any | VideoDetails) =>
        http.put<any | VideoDetails>(`/video/funnel/${funnelId}`, payload),

    // branches
    getStepSettings: (videoId: Id) => http.get<StepSettings>(`/video/${videoId}/funnel/branches-settings`),
    updateStepSettings: (videoId: Id, payload: StepSettings) =>
        http.post<StepSettings>(`/video/${videoId}/funnel/branches-settings`, payload),
    deleteSmartVidStep: (id: Id, stepId: Id) => http.delete(`video/${id}/funnel/branch/${stepId}`),

    smartVid: {
        getById: (svId: Id) => http.get(endpoints.smartVids.byId(svId)),
        getList: () => http.get(endpoints.smartVids.list),
        getEmdeb: (svId: Id) => http.get(endpoints.smartVids.embed(svId)),
        create: (payload: CreateSmartVidRequest) => http.post(endpoints.smartVids.list, payload),
        getDetails: (svId: Id) => http.get(endpoints.smartVids.details(svId)),
        discard: (svId: Id) => http.get(endpoints.smartVids.discard(svId)),
        update: (svId: Id, payload: UpdateSmartVidRequest) => http.put(endpoints.smartVids.byId(svId), payload),
    },
    steps: {
        get: (svId: Id, vidId: Id) => http.get(endpoints.smartVids.step(svId, vidId)),
        update: ({ svId, vidId, step }: UpdateSmartVidStepRequest) =>
            http.put(endpoints.smartVids.step(svId, vidId), step),
        create: ({ svId, vidId, step }: CreateSmartVidStepRequest) =>
            http.post(endpoints.smartVids.step(svId, vidId), step),
        delete: (svId: Id, vidId: Id) => http.delete(endpoints.smartVids.step(svId, vidId)),
    },
}
