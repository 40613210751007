import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import { PRO_PLAN_FEATURES } from '../Conversions.constants'

import './index.scss'

export const ConversionsLockedContent = () => (
    <Box className="ConversionsLockedContent">
        <Typography className="title">Everything else that comes with Pro:</Typography>
        <Box className="features">
            <Box className="featuresColumnLeft">
                <ul>
                    {PRO_PLAN_FEATURES.slice(0, 1).map((item) => (
                        <li className="featureItem" key={item.title}>
                            <div className="featureItemWrapper">
                                <CheckIcon color="primary" />
                                {item.title}
                            </div>
                            {item.subfeatures && (
                                <ul className="subfeatureList">
                                    {item.subfeatures.map((item) => (
                                        <li className="subfeatureItem" key={item}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </Box>
            <Box className="featuresColumnRight">
                <ul>
                    {PRO_PLAN_FEATURES.slice(1, PRO_PLAN_FEATURES.length).map((item) => (
                        <li className="featureItem" key={item.title}>
                            <div className="featureItemWrapper">
                                <CheckIcon color="primary" />
                                {item.title}
                            </div>
                        </li>
                    ))}
                </ul>
            </Box>
        </Box>
    </Box>
)
