import axios, { AxiosRequestConfig } from 'axios'

import {
    SignInPayload,
    SignInWithGooglePayload,
    SignInWithMicrosoftPayload,
    SignUpPayload,
    ForgotPasswordPayload,
    ResetPasswordPayload,
    GoogleAccount,
} from 'types/Auth'
import { http } from 'api/utils/http.utils'

export const authAPI = {
    signIn: (challengeParam: string, payload: SignInPayload) =>
        http.post<SignInPayload>(`jwt/auth${challengeParam ? '?login_challenge=' + challengeParam : ''}`, payload),
    signUp: (payload: SignUpPayload) => http.post<SignUpPayload>('customer/sign-up/jwt', payload),
    signInWithGoogle: (challengeParam: string, payload: SignInWithGooglePayload) =>
        http.post<SignInWithGooglePayload>(
            `google/auth${challengeParam ? '?login_challenge=' + challengeParam : ''}`,
            payload,
        ),
    signInWithMicrosoft: (challengeParam: string, payload: SignInWithMicrosoftPayload) =>
        http.post<SignInWithMicrosoftPayload>(
            `microsoft/auth${challengeParam ? '?login_challenge=' + challengeParam : ''}`,
            payload,
        ),
    getGoogleInformation: (token: string) =>
        axios.get<GoogleAccount>(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        }),
    refreshToken: (config: AxiosRequestConfig) => http.get<AxiosRequestConfig>('jwt/refresh', config),
    getPlan: (planKey: string, queryParams = '') => http.get<string>(`plan/${planKey}${queryParams}`),
    verifyEmail: (email: string) => http.post<{ email: string }>('email/verify', { email }),
    forgotPassword: (payload: ForgotPasswordPayload) =>
        http.post<ForgotPasswordPayload>('customer/forgotten-password', payload),
    resetPasswordEmail: (token: string) => http.post<{ token: string }>('customer/reset-password-email', { token }),
    resetPassword: (payload: ResetPasswordPayload) =>
        http.post<ResetPasswordPayload>('customer/reset-password', payload),
}
