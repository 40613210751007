import { FolderAPI, FolderNameChangePayload } from 'types/Video'
import { videoAPI } from 'api/controllers'
import { VIDEO_FOLDERS_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useFolderNameChangeMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<FolderAPI, FolderNameChangePayload>(
        VIDEO_FOLDERS_QUERY,
        async (payload: FolderNameChangePayload) =>
            mutate(videoAPI.changeFolderName(payload), apiLocale.video.renameFolder),
    )
}
