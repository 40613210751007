import { useMemo } from 'react'

import { useVideosQuery, useCustomerSubscriptionQuery } from 'api/queries'

export const useUploadLimit = (considerQueriesStatus = true) => {
    const customerSubscriptionQuery = useCustomerSubscriptionQuery()
    const videosQuery = useVideosQuery({})

    const uploadLimitReached = useMemo(() => {
        if (customerSubscriptionQuery?.isLoading || videosQuery?.isLoading) return considerQueriesStatus

        if (!customerSubscriptionQuery?.data || !videosQuery?.data) return true

        if (customerSubscriptionQuery.data.videoSpaceLimit < 0 || !customerSubscriptionQuery.data.plan.isFree)
            return false

        return videosQuery.data.filter((v) => !v.isDemoVideo).length >= customerSubscriptionQuery.data.videoSpaceLimit
    }, [customerSubscriptionQuery, videosQuery, considerQueriesStatus])

    return { uploadLimitReached }
}
