import { Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import Skeleton from '@mui/material/Skeleton'
import { testIdFactory } from './AccountInformationItem.utils'

export interface AccountInformationItemProps {
    /** The descriptive label for the account information piece. */
    label: string

    /** The value or output related to the label. */
    data?: string

    isLoading: boolean

    skeletonWidth?: number
}

/**
 *  Used for rendering account information portions in the `AccountInformation` component.
 */
export const AccountInformationItem = ({
    label,
    data = '-',
    isLoading,
    skeletonWidth = 35,
}: AccountInformationItemProps) => {
    const testId = useMemo(() => testIdFactory(label), [label])

    return (
        <Stack direction="row" justifyContent="space-between" gap={2} data-testid={testId.container}>
            <Typography color="text.secondary" flexGrow={1} data-testid={testId.info.label}>
                {label}
            </Typography>
            <Typography fontWeight="bold" data-testid={testId.info.data}>
                {isLoading ? <Skeleton variant="text" width={skeletonWidth} /> : data}
            </Typography>
        </Stack>
    )
}
