import { locale } from 'locales'

export default {
    title: {
        billPastDue: 'Your account is locked because your bill is past due.',
        planExpired: 'Your account is locked because your plan has expired.',
    },
    message: 'Your videos are unavailable for playback.',
    cta: {
        chooseNewPlan: 'Choose a new plan to continue using Vidalytics.',
        updateBillingInfo: 'Update your billing information to reactivate your account.',
    },
    action: {
        settings: 'Settings',
        support: 'Support',
        signOut: locale.operations.auth.signOut,
    },
}
