import { useQueryClient } from 'react-query'

import { Conversion } from 'types/Conversion'
import { conversionAPI } from 'api/controllers'
import { CONVERSIONS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

type Payload = Conversion['guid'][]

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const useDeleteConversionMutation = () => {
    const queryClient = useQueryClient()
    const key = CONVERSIONS_QUERY
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Conversion[], Payload>(
        CONVERSIONS_QUERY,
        async (payload: Payload) => {
            const conversionsText = `onversion${payload.length > 1 ? 's' : ''}`

            return mutate(Promise.all(payload.map((guid) => conversionAPI.deleteConversion(guid))), {
                pending: `Deleting c${conversionsText}`,
                success: `C${conversionsText} deleted`,
                error: `Failed to delete c${conversionsText}`,
            })
        },
        {
            onMutate: async (guids) => {
                await queryClient.cancelQueries(key)
                const previousConversions = queryClient.getQueryData(key) as Conversion[]

                queryClient.setQueryData<Conversion[]>(key, () =>
                    previousConversions?.filter((conversion) => !guids.includes(conversion.guid)),
                )
            },
            invalidateOn: InvalidateOn.SETTLED,
        },
    )
}
