import { AccountMetric } from 'api/contracts/agency/entities/common'
import locale from './MetricCard.locale'

interface MetricMeta {
    label: string
    prefix: string
    postfix: string
}

export const meta: { [key in AccountMetric]: MetricMeta } = {
    avgRevenuePerPlay: {
        label: locale.avgRevenuePerPlay,
        prefix: '$',
        postfix: '',
    },
    conversionRate: {
        label: locale.conversionRate,
        prefix: '',
        postfix: '%',
    },
    conversion: {
        label: locale.conversions,
        prefix: '',
        postfix: '',
    },
    impressions: {
        label: locale.impressions,
        prefix: '',
        postfix: '',
    },
    revenue: {
        label: locale.revenue,
        prefix: '$',
        postfix: '',
    },
    totalPlays: {
        label: locale.totalPlays,
        prefix: '',
        postfix: '',
    },
}
