import { apiLocale } from 'api/api.locale'
import { DISCARD_SMART_VID_MUTATION, SMART_VID_DETAILS_QUERY, SMART_VID_STEP_QUERY } from 'api/constants'
import { DiscardSmartVidResponse } from 'api/contracts/smartVids/endpoints/discardSmartVid.contract'
import { ApiError } from 'api/contracts/utilitary/error'
import { smartVidConroller } from 'api/controllers'
import { Options, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { Id } from 'types'

export const useDiscardSmartVidMutation = (options?: Options<DiscardSmartVidResponse, Id, ApiError>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<DiscardSmartVidResponse, Id>(
        DISCARD_SMART_VID_MUTATION,
        async (id: Id) => mutate(smartVidConroller.smartVid.discard(id), apiLocale.smartVids.discard),
        {
            invalidate: [SMART_VID_DETAILS_QUERY, SMART_VID_STEP_QUERY],
            ...options,
        },
    )
}
