import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded'
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded'
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded'
import SmartAutoplaySection from './SmartAutoplaySection'
import PlayOptionsSection from './PlayOptionsSection'
import ExpireVideoSection from './ExpireVideoSection'

const usePlaybackSettingsMenu = () => [
    { label: 'Smart Autoplay', Icon: LightbulbRoundedIcon, Children: SmartAutoplaySection },
    { label: 'Play Options', Icon: PlayCircleFilledRoundedIcon, Children: PlayOptionsSection },
    {
        label: 'Expire Video',
        Icon: AccessTimeFilledRoundedIcon,
        Children: ExpireVideoSection,
    },
]

export default usePlaybackSettingsMenu
