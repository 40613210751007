import { locale } from 'locales'

export default {
    title: 'Edit title',
    cancel: locale.operations.generic.cancel,
    save: locale.operations.generic.save,
    minLength3: 'Min length is 3 characters',
    maxLength30: 'Max length is 30 characters',
    reqired: 'Required',
}
