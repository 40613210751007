import { Components, Theme } from '@mui/material'

/**
 * Component for standard variant of input
 */
export const MuiInput: Components<Omit<Theme, 'components'>>['MuiInput'] = {
    styleOverrides: {
        root: {
            marginTop: '0 !important',
        },
        input: ({ theme }) => ({
            padding: `${theme.spacing(2)} 0`,
        }),
    },
}
