import { locale } from 'locales'

export default {
    creditCard: locale.inputs.creditCard,
    user: locale.inputs.user,
    address: locale.inputs.address,
    tooltip: {
        whyDoIneedCard: 'Why do I need to add a card?',
        youCanSkip: "You don't. You can skip this step with the 'Skip' link.",
        recommendedThatYouAddCard:
            "However, it is highly recommended that you add a card, so your videos don't stop playing without notice for your viewers when your trial is over.",
        cancelAnytime: 'You can cancel anytime!',
    },
    buttons: {
        letMeIn: 'Let me in!',
        skip: 'Skip',
    },
}
