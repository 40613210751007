import { Dispatch, SetStateAction, useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { Box } from '@mui/material'

import { SettingsSectionProvider } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    NAME,
    TYPE,
    TIME,
    TAGS_SECTION_INITIAL_VALUES,
    validateVideoLengthSchema,
} from 'design/pages/VidSettings/constants'
import { TagsSectionFormData } from 'types/VidSettings'
import withErrorBoundary from 'design/molecules/WithErrorBoundary'
import { useParams } from 'react-router-dom'
import { useCreateTagMutation, useUpdateTagMutation } from 'api/mutations'
import { TagConfig } from 'types/VideoTag'
import * as yup from 'yup'
import { useVideoQuery } from 'api/queries'
import { useVidSettingsContext } from 'design/pages/VidSettings/vidSettingsContext'
import { GUID } from 'constants/api.constants'
import { TagsContent } from './TagsContent'

interface TagsSectionProps {
    formExpanded: boolean
    setFormExpanded: Dispatch<SetStateAction<boolean>>
}

const TagsSection = (props: TagsSectionProps) => {
    return (
        <SettingsSectionProvider>
            <Form>
                <Box className="TagsSection">
                    <TagsContent {...props} />
                </Box>
            </Form>
        </SettingsSectionProvider>
    )
}

const FormikWrapper = () => {
    const [formExpanded, setFormExpanded] = useState(false)
    const { videoGuid } = useParams()
    const { data: video } = useVideoQuery(videoGuid)
    const { setIntegrationsExist } = useVidSettingsContext()

    const createTagMutation = useCreateTagMutation()
    const updateTagMutation = useUpdateTagMutation()

    const TagsValidationSchema = yup.object({
        [NAME]: yup.string().max(300, 'Permitted max length is 300.').required('Tag name is required.'),
        [TIME]: validateVideoLengthSchema(video?.video?.length || '0'),
    })

    const onSubmit = async (values: TagsSectionFormData, formikHelpers: FormikHelpers<TagsSectionFormData>) => {
        event?.preventDefault()
        if (createTagMutation.isLoading || updateTagMutation.isLoading) return
        if (values[TYPE] === 'time' && !values[TIME]) {
            values.time = '00:00'
        }
        if (values[GUID]) {
            await updateTagMutation.mutateAsync(values as TagConfig)
        } else {
            const result = await createTagMutation.mutateAsync({
                videoGuid: String(videoGuid),
                tag: values as TagConfig,
            })
            if (result) {
                setIntegrationsExist(false)
            }
        }

        setFormExpanded(false)
        formikHelpers.resetForm()
    }
    return (
        <Formik<TagsSectionFormData>
            initialValues={TAGS_SECTION_INITIAL_VALUES}
            validationSchema={TagsValidationSchema}
            onSubmit={onSubmit}
        >
            {() => <TagsSection formExpanded={formExpanded} setFormExpanded={setFormExpanded} />}
        </Formik>
    )
}

export default withErrorBoundary(FormikWrapper)
