import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'

import { Modal } from 'design/templates/Modal'
import { useRemoveClosedCaptionsMutation } from 'api/mutations'
import { useVideoQuery } from 'api/queries'

import locale from './ClosedCaptionsDeleteModal.locale'

type ClosedCaptionsDeleteModalProps = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const ClosedCaptionsDeleteModal = ({ isOpen, setIsOpen }: ClosedCaptionsDeleteModalProps) => {
    const { videoGuid } = useParams()
    const { data } = useVideoQuery(videoGuid)
    const { mutateAsync: removeClosedCaptionsMutateAsync, isLoading } = useRemoveClosedCaptionsMutation()

    const handleDelete = useCallback(async () => {
        if (!data?.video) return

        try {
            await removeClosedCaptionsMutateAsync(data?.video)
        } catch (e) {
            Sentry.captureException(e)
        } finally {
            setIsOpen(false)
        }
    }, [data?.video])

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} stackProps={{ sx: { minHeight: 190 } }} width="sm">
            <Modal.Header onClose={() => setIsOpen(false)} />
            <Modal.Body>
                <Box sx={{ mt: '-66px', maxWidth: 300 }}>
                    <Typography variant="h6">{locale.title}</Typography>
                </Box>
            </Modal.Body>
            <Modal.Actions>
                <Button variant="text" onClick={() => setIsOpen(false)}>
                    {locale.cancel}
                </Button>
                <LoadingButton variant="contained" color="error" onClick={handleDelete} loading={isLoading}>
                    {locale.confirm}
                </LoadingButton>
            </Modal.Actions>
        </Modal>
    )
}

export default ClosedCaptionsDeleteModal
