import { UseTableCellProps, Row } from 'react-table'
import { Box, IconButton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'

import useSettingsLinkProps from 'design/pages/VidSettings/hooks/useSettingsLinkProps'
import {
    TITLE,
    LENGTH,
    IS_UNPUBLISHED,
    THUMBNAIL,
    DASHBOARD_SRC,
    PLAYS,
    IS_READY,
    FUNNEL,
    BREADCRUMB,
    ExpandedVideo,
} from 'design/pages/MyVids'
import { openModal, ModalType } from 'design/templates/Modal/ModalTypes/modal.slice'
import { ImgWithErrorHandler } from 'design/atoms/ImgWithErrorHandler'
import { Link } from 'design/atoms/Link'
import { getDateAgoWithRightTimezone } from 'utils'
import { useAppDispatch } from 'App'
import useUpgradeCallback from 'hooks/user/useUpgradeCallback'
import { COLUMN } from 'constants/table.constants'
import { RowActions } from '../RowActions/RowActions'
import style from './CustomContent.style'
import './index.scss'

type CustomContentProps = {
    cell: UseTableCellProps<object>
    row: Row<ExpandedVideo>
}

export function CustomContent({ cell, row: { original }, row }: CustomContentProps) {
    const settingsLinkProps = useSettingsLinkProps()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'))
    const upgradeCallback = useUpgradeCallback()
    const isUnpublished = original[IS_UNPUBLISHED]
    const thumbnailSrc = original[THUMBNAIL]?.[DASHBOARD_SRC]

    const openVideoEmbedCodeModal = upgradeCallback(() => {
        dispatch(openModal({ type: ModalType.VIDEO_EMBED_CODE, videoId: original.guid }))
    })

    return (
        <Box className="CustomContent" {...cell.getCellProps()}>
            <Box className="image-container">
                <ImgWithErrorHandler src={thumbnailSrc} />
                <Typography className="time" component="span">
                    {original[LENGTH]}
                </Typography>
            </Box>
            <Box className="details-container">
                <Box className="detailsWrapper">
                    <Typography component="span" className="breadcrumb">
                        {original[BREADCRUMB]}
                    </Typography>
                    <Stack gap={1}>
                        <Link to={settingsLinkProps.getLink(original.guid)} external={settingsLinkProps.external}>
                            <Typography component="span">
                                {isUnpublished && (
                                    <Typography className="unpublished asterisk" component="span">
                                        *
                                    </Typography>
                                )}
                                {original[TITLE]}
                                {isUnpublished && (
                                    <Typography className="unpublished text" component="span">
                                        (unpublished changes)
                                    </Typography>
                                )}
                            </Typography>
                        </Link>

                        <Typography className="details" component="span" sx={style.dotAfterDetails}>
                            <Typography component="span">
                                Updated {getDateAgoWithRightTimezone(original[COLUMN.DATE_UPDATED])}
                            </Typography>
                            {original[COLUMN.DATE_PUBLISHED] && (
                                <Typography component="span">
                                    Last published {getDateAgoWithRightTimezone(original[COLUMN.DATE_PUBLISHED])}
                                </Typography>
                            )}
                            <Typography component="span">
                                Created {getDateAgoWithRightTimezone(original[COLUMN.DATE_CREATED])}
                            </Typography>
                            <Typography className="plays" component="span">
                                {original[PLAYS]}
                                <VisibilityOutlinedIcon />
                            </Typography>
                            {Boolean(original[FUNNEL]) && (
                                <Tooltip arrow placement="top" title={`Used in ${original[FUNNEL]?.headline}`}>
                                    <Typography className="funnel" component="span">
                                        Smart Vid
                                        <TipsAndUpdatesOutlinedIcon />
                                    </Typography>
                                </Tooltip>
                            )}
                            {!original[IS_READY] && (
                                <Typography className="isReady" component="span">
                                    Processing
                                </Typography>
                            )}
                        </Typography>
                    </Stack>
                </Box>
                <IconButton onClick={openVideoEmbedCodeModal} sx={{ display: isMobile ? 'inline-flex' : 'none' }}>
                    <ShareOutlinedIcon />
                </IconButton>
            </Box>
            <RowActions row={row} cell={cell} />
        </Box>
    )
}
