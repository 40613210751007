import { SxProps, Theme } from '@mui/material'

const body: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
}

export default {
    body,
}
