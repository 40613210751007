import { buttonBaseClasses, checkboxClasses, SxProps, Theme } from '@mui/material'
import { gridClasses } from '@mui/x-data-grid-pro'

const container: SxProps<Theme> = {
    position: 'relative',
    flex: 1,
    mb: 4,
    maxHeight: 1,
}

const dataGrid =
    (disableHeader: boolean): SxProps<Theme> =>
    (theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        overflow: 'auto',

        [`&:has(.${gridClasses.overlayWrapper})`]: {
            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                color: disableHeader ? theme.palette.text.disabled : theme.palette.text.primary,
            },
            [`& .${gridClasses.columnHeader}`]: {
                pointerEvents: disableHeader ? 'none' : 'auto',
            },
        },

        [`& .${buttonBaseClasses.root}.${checkboxClasses.root}`]: {
            m: 0,
        },

        [`& .${gridClasses.actionsCell}`]: {
            alignItems: 'end',
            width: 1,
            [`& .${buttonBaseClasses.root}:is(:not([aria-label="More"]))`]: {
                opacity: 0,
            },
        },

        [`& .${gridClasses.row}`]: {
            '&.Mui-hovered': {
                [`.${gridClasses.actionsCell}`]: {
                    [`& .${buttonBaseClasses.root}`]: {
                        opacity: 1,
                    },
                },
            },
        },
        ['& .MuiDataGrid-filler']: {
            flexBasis: '100%',
        },

        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
        },
    })

export default {
    container,
    dataGrid,
}
