import { METRICS, metricsConfig } from 'constants/metrics.constants'

/**
 * Decorates specific metric value with unified prefix and postfix.
 *
 * @param {METRICS} metric - The metric to use for decorating the value.
 * @param {string} value - The value to be decorated.
 * @returns {string} - The decorated value.
 */
export const metricsDecorator = (metric: METRICS, value: string) => {
    return `${metricsConfig[metric].prefix}${value}${metricsConfig[metric].postfix}`
}
