export const NAME = 'name'
export const IP_ADDRESS = 'ipAddress'
export const DATE_CREATED = 'dateCreated'
export const GUID = 'guid'
export const IP_FILTER = 'ipFilter'

export const INITIAL_VALUES = {
    [NAME]: '',
    [IP_ADDRESS]: '',
    [DATE_CREATED]: null,
    [GUID]: null,
}
