import { authAPI } from 'api/controllers'
import { ForgotPasswordPayload, ForgotPasswordResponse } from 'types/Auth'
import { FORGOT_PASSWORD_QUERY } from 'api/constants'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useForgotPasswordMutation = () => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<ForgotPasswordResponse, ForgotPasswordPayload>(
        FORGOT_PASSWORD_QUERY,
        async (payload: ForgotPasswordPayload) =>
            mutate(authAPI.forgotPassword(payload), apiLocale.auth.forgotPassword),
    )
}
