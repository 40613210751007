import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import useFeatureFlags from 'hooks/system/useFeatureFlags'
import { useLayout } from 'hooks/utilities/useLayout'
import { HelpTooltip } from 'design/atoms/HelpTooltip'
import ClosedCaptionsSearch from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsSearch'
import ClosedCaptionsMenu from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsMenu'
import ClosedCaptionsZone from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsZone'
import ClosedCaptionsInput from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsInput'
import ClosedCaptionsZoneLoader from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsZoneLoader'
import useClosedCaptionsContent from 'design/organisms/ClosedCaptionsEditor/useClosedCaptionsContent'

import locale from './ClosedCaptionsEditor.locale'
import style from './ClosedCaptionsEditor.style'

export const CC_FILE_EXT = 'vtt'

const ClosedCaptionsEditor = () => {
    const { videoGuid } = useParams()
    const { isTablet } = useLayout()
    const [search, setSearch] = useState('')

    const { captionSearch } = useFeatureFlags()
    const { isLoading, name, content } = useClosedCaptionsContent(String(videoGuid))
    const hasContent = content !== null
    const hasSearch = hasContent && captionSearch

    return (
        <Card sx={style.root}>
            <Stack flexDirection="row" justifyContent="space-between" mb={4}>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography sx={{ fontSize: 'body2.fontSize' }} fontWeight={600}>
                        {locale.closedCaptions}
                    </Typography>
                    <HelpTooltip title={<Box>{locale.recommendation}</Box>} />
                </Stack>
                <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap={1} sx={{ flex: 1 }}>
                    {Boolean(!isTablet && hasSearch) && (
                        <Box width={1} pl={6} textAlign="right">
                            <ClosedCaptionsSearch onChange={setSearch} value={search} />
                        </Box>
                    )}
                    <ClosedCaptionsMenu noRename />
                </Stack>
            </Stack>
            {Boolean(isTablet && hasSearch) && (
                <Box mb={6}>
                    <ClosedCaptionsSearch onChange={setSearch} value={search} />
                </Box>
            )}
            {isLoading && <ClosedCaptionsZoneLoader text={locale.loading} />}
            {Boolean(!isLoading && !hasContent) && <ClosedCaptionsZone />}
            {Boolean(!isLoading && hasContent) && <ClosedCaptionsInput title={name || ''} value={content || ''} />}
        </Card>
    )
}

export default ClosedCaptionsEditor
