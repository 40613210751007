import { useState } from 'react'
import { NoRowsOverlayPropsOverrides } from '@mui/x-data-grid/models/gridSlotsComponentsProps'
import {
    DataGridPro,
    DataGridProProps,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridRowSelectionModel,
    useGridApiRef,
} from '@mui/x-data-grid-pro'
import { Stack, StackProps, useMediaQuery, useTheme } from '@mui/material'
import { omit as _omit } from 'lodash'

import { getSx } from 'styles/theme/utils'

import { BaseIconButton, SelectedAction, SelectedActions } from './components'
import { COLUMN_HEADER_HEIGHT } from './constants'
import { NoResultsOverlay } from './components/NoResultsOverlay/NoResultsOverlay'

import style from './DataGridTable.style'
import locale from './DataGridTable.locale'

export type Props = Pick<DataGridProProps, 'columns' | 'rows' | 'loading'> & {
    selectedActions?: SelectedAction[]
    pinnedColumnsOnMobile?: string[]
    noRowsConfig?: NoRowsOverlayPropsOverrides['noRows']
    propsContainer?: StackProps
    propsTable?: Partial<DataGridProProps>
}

export const DataGridTable = ({
    columns,
    rows,
    selectedActions,
    loading,
    pinnedColumnsOnMobile,
    noRowsConfig,
    propsContainer,
    propsTable,
}: Props) => {
    const apiRef = useGridApiRef()
    const { breakpoints } = useTheme()

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

    const disableHeader = rows.length === 0 && !loading

    const isMobile = useMediaQuery(breakpoints.down('tablet'))

    return (
        <Stack sx={getSx(style.container, propsContainer?.sx || {})} {..._omit(propsContainer, 'sx')}>
            <DataGridPro
                apiRef={apiRef}
                loading={loading}
                sx={style.dataGrid(disableHeader)}
                columns={columns}
                rows={rows}
                pinnedColumns={{
                    right: ['actions'],
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...((isMobile && pinnedColumnsOnMobile) || [])],
                }}
                slots={{
                    baseIconButton: BaseIconButton,
                    noRowsOverlay: NoResultsOverlay,
                }}
                slotProps={{
                    noRowsOverlay: {
                        noRows: noRowsConfig,
                    },
                    // TODO: [VID-8422] Update MUI Library to the latest version
                    // loadingOverlay: {
                    //     variant: 'skeleton',
                    //     noRowsVariant: 'skeleton',
                    // },
                }}
                localeText={{
                    actionsCellMore: locale.actionsCellMore,
                }}
                rowSelectionModel={rowSelectionModel}
                onRowSelectionModelChange={setRowSelectionModel}
                getRowId={(row) => row.id || row.guid}
                columnHeaderHeight={COLUMN_HEADER_HEIGHT}
                hideFooter
                disableRowSelectionOnClick
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                {...propsTable}
            />

            {Boolean(selectedActions) && (
                <SelectedActions
                    actions={selectedActions}
                    rowSelectionModel={rowSelectionModel}
                    onClearRowSelectionModel={() => setRowSelectionModel([])}
                />
            )}
        </Stack>
    )
}

export default DataGridTable
