import { UseMutationOptions } from 'react-query'

import { userAPI } from 'api/controllers'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'
import { IP_FILTERS_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { CreateIpFilterPayload } from 'types/Customer'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useCreateIpFilterMutation = (options?: UseMutationOptions<null, any, CreateIpFilterPayload>) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, CreateIpFilterPayload>(
        IP_FILTERS_QUERY,
        async (payload: CreateIpFilterPayload) =>
            mutate(userAPI.createIpFilter(payload), apiLocale.user.ipFilter.create),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
        },
    )
}
