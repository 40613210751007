import { blueGrey, red, orange, green, pink } from '@mui/material/colors'
import { PaletteOptions, alpha } from '@mui/material/styles'

export const paletteDark: PaletteOptions = {
    mode: 'dark',
    background: {
        surface: '#121212',
    },
    divider: 'rgb(255, 255, 255, 0.12)',
    common: {
        tertiaryOutlinedBorder: alpha('#AAB5C7', 0.5),
    },
    primary: {
        main: '#1059FB',
        dark: '#0445D7',
        light: '#6594FC',
        contrastText: alpha('#0C142D', 0.87),
    },
    accent: {
        main: '#FB5198',
        dark: pink[400],
        light: pink[200],
        contrastText: '#FFF',
    },
    secondary: {
        main: '#D5E0FB',
        dark: '#BDD1FE',
        light: '#E7EDFC',
        contrastText: alpha('#F3F5F8', 0.87),
    },
    tertiary: {
        main: blueGrey[100],
        dark: blueGrey[400],
        light: blueGrey[50],
        contrastText: alpha('#000', 0.87),
    },
    text: {
        primary: '#FFF',
        secondary: alpha('#FFF', 0.7),
        disabled: alpha('#FFF', 0.38),
    },
    action: {
        active: alpha('#FFF', 0.56),
        hover: alpha('#FFF', 0.08),
        selected: alpha('#FFF', 0.16),
        focus: alpha('#FFF', 0.12),
        disabled: alpha('#FFF', 0.38),
        disabledBackground: alpha('#FFF', 0.12),
    },
    error: {
        main: red[500],
        dark: red[700],
        light: red[300],
        contrastText: '#FFF',
    },
    warning: {
        main: orange[400],
        dark: orange[700],
        light: orange[300],
        contrastText: alpha('#000', 0.87),
    },
    info: {
        main: '#DEE6FC',
        dark: '#809FF5',
        light: '#E7EDFC',
        contrastText: alpha('#000', 0.87),
    },
    success: {
        main: green[400],
        dark: green[700],
        light: green[300],
        contrastText: alpha('#000', 0.87),
    },
}
