import { apiLocale } from 'api/api.locale'
import { CREATE_SMART_VID_STEP_MUTATION, SMART_VIDS_QUERY, VIDEOS_QUERY } from 'api/constants'
import {
    CreateSmartVidRequest,
    CreateSmartVidResponse,
} from 'api/contracts/smartVids/endpoints/createSmartVid.contract'
import { ApiError } from 'api/contracts/utilitary/error'
import { smartVidConroller } from 'api/controllers'
import { Options, useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'

export const useCreateSmartVidMutation = (
    options?: Options<CreateSmartVidResponse, CreateSmartVidRequest, ApiError>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<CreateSmartVidResponse, CreateSmartVidRequest>(
        CREATE_SMART_VID_STEP_MUTATION,
        async (payload: CreateSmartVidRequest) =>
            mutate(smartVidConroller.smartVid.create(payload), apiLocale.smartVids.create),
        {
            ...options,
            invalidate: [SMART_VIDS_QUERY, VIDEOS_QUERY],
        },
    )
}
