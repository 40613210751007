import { CUDEventType } from 'types/Video'

export interface VideoCallToAction {
    guid: string
    displayMode: VideoCallToActionDisplayMode
    htmlSelectorType: VideoCallToActionCustomHTMLSelectorType
    htmlSelector: string
    title: string
    link: string
    linkInNewWindow: boolean
    timeFrom: string
    timeTo: string
    type: CallToActionType
    color_background: string
    color_foreground: string
    colorHover_background: string
    colorHover_foreground: string
    shadow: boolean
    showToReturningViewers: boolean
}

export enum CallToActionType {
    exit = 'exit',
    time = 'time',
}

export enum VideoCallToActionDisplayMode {
    reserveSpace = 'reserveSpace',
    expandContainer = 'expandContainer',
    onTop = 'onTop',
    customHTML = 'customHTML',
}

export enum VideoCallToActionCustomHTMLSelectorType {
    class = 'class',
    id = 'id',
}

export enum CallToActionEventType {
    create,
    update,
    delete,
}

export interface CallToActionEvent {
    callToAction: VideoCallToAction
    event: CUDEventType
}
