import locale from './LockedInteractiveVideosModal.locale'

const {
    gbOfMonthlyBandwidth,
    smartInteractiveVideos,
    multipleUsers,
    videoHeatmaps,
    videoStatsSegments,
    compareView,
    unbrandedPlayer,
    tags,
    playGates,
    freeStorageForUpTo10Videos,
    moreBandwidthForMoreVideoPlays,
    liveChatForAnyHelpYouNeed,
} = locale.features.list

export const features = [
    gbOfMonthlyBandwidth,
    smartInteractiveVideos,
    multipleUsers,
    videoHeatmaps,
    videoStatsSegments,
    compareView,
    unbrandedPlayer,
    tags,
    playGates,
    freeStorageForUpTo10Videos,
    moreBandwidthForMoreVideoPlays,
    liveChatForAnyHelpYouNeed,
]
