export default {
    title: 'Unlock Your Free PREMIUM Trial to Get the Most Powerful Vidalytics Features to Boost Your Videos Conversions',
    description:
        'You’ll have 14 days to try all the best features in Vidalytics’ Premium Plan that thousands of marketers use to drive 8 figures in sales each and every month. Which have been proven to increase conversions by at least 25%.',
    features: {
        title: 'Everything that comes with this Premium:',
        list: {
            gbOfMonthlyBandwidth: '500 GB of Monthly Bandwidth',
            smartInteractiveVideos: 'Smart Interactive Videos',
            multipleUsers: 'Multiple Users',
            videoHeatmaps: 'Video Heatmaps',
            videoStatsSegments: 'Video Stats Segments',
            compareView: 'Compare View',
            unbrandedPlayer: 'Unbranded Player',
            tags: 'Tags',
            playGates: 'Play Gates',
            freeStorageForUpTo10Videos: 'Free Storage For Up To 10 Videos',
            moreBandwidthForMoreVideoPlays: '4x More Video Plays',
            liveChatForAnyHelpYouNeed: 'Live Chat for Any Help You Need',
        },
    },
    cta: {
        title: 'Free for 14 days',
        subtitle: 'Upgrade Now to Unlock this Feature',
        button: 'Start Your Free Trial',
    },
    awards: {
        title: '“You should definitely use Vidalytics for video hosting. When I switched over, it increased my conversions by 30%. This is why I use Vidalytics for all of my Video Sales Letters.”',
        name: 'Jon Benson',
        description: 'Billion Dollar Marketer & Inventor of the Video Sales Letter',
    },
    clients: {
        title: 'Used & Loved By',
    },
}
