import { UseMutationOptions } from 'react-query'
import { Funnel, UpdateFunnelHeadlinePayload } from 'types/Funnel'
import { smartVidConroller } from 'api/controllers'
import { UPDATE_FUNNEL_HEADLINE_MUTATION, SMART_VIDS_QUERY } from 'api/constants'
import { queryClient } from 'api/queries'
import { useInvalidateMutation } from 'api/hooks'
import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { apiLocale } from 'api/api.locale'

export const useUpdateSmartVidHeadlineMutation = (
    funnelId: string,
    options?: UseMutationOptions<Funnel, unknown, UpdateFunnelHeadlinePayload>,
) => {
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<Funnel, UpdateFunnelHeadlinePayload>(
        UPDATE_FUNNEL_HEADLINE_MUTATION,
        async (payload: UpdateFunnelHeadlinePayload) =>
            mutate(smartVidConroller.updateSmartVidHeadline(funnelId, payload), apiLocale.funnel.updateHeadline),
        {
            ...options,
            onSuccess: (response, variables, context) => {
                if (options?.onSuccess) {
                    options.onSuccess(response, variables, context)
                }

                queryClient.setQueryData([SMART_VIDS_QUERY, funnelId], response)

                const funnels = queryClient.getQueryData(SMART_VIDS_QUERY) as Funnel[]

                queryClient.setQueryData(
                    SMART_VIDS_QUERY,
                    funnels?.map((f) => (f.id === funnelId ? response : f)),
                )
            },
        },
    )
}
