import { locale } from 'locales'

const postfix = 'with Microsoft'

export default {
    signUp: `${locale.operations.auth.signUp} ${postfix}`,
    notification: {
        error: `${locale.messages.errors.generic}. ${locale.messages.callToAction.contactUs.forHelp}.`,
        dataUnavailable: 'Account information is not available. Please, enter data manually.',
    },
}
