import { UseMutationOptions, useQueryClient } from 'react-query'

import { useMutationToastDecorator } from 'hooks/utilities/useMutationToastDecorator'
import { SEGMENT_STATUS_QUERY, SEGMENT_KEY_QUERY } from 'api/constants'
import { InvalidateOn, useInvalidateMutation } from 'api/hooks'
import { SegmentKeyChangePayload } from 'types/Integrations'
import { integrationsAPI } from 'api/controllers'

export const useChangeSegmentIntegrationMutation = (
    options?: UseMutationOptions<null, unknown, SegmentKeyChangePayload>,
) => {
    const queryClient = useQueryClient()
    const { mutate } = useMutationToastDecorator()

    return useInvalidateMutation<null, SegmentKeyChangePayload>(
        SEGMENT_KEY_QUERY,
        async (payload: SegmentKeyChangePayload) =>
            mutate(integrationsAPI.changeSegmentKey(payload), {
                pending: 'Updating key',
                success: 'Key updated',
                error: 'Failed to update key',
            }),
        {
            ...options,
            invalidateOn: InvalidateOn.SUCCESS,
            onSuccess: (...args) => {
                queryClient.resetQueries(SEGMENT_STATUS_QUERY)
                options?.onSuccess?.(...args)
            },
        },
    )
}
