import z from 'zod'

export const dateRangeQueryParams = z.object({
    dateFrom: z.string().date(),
    dateTo: z.string().date(),
})

export const requestMeta = z.object({
    customerId: z.number().positive(),
    dateFrom: z.string().datetime(),
    dateTo: z.string().datetime(),
})
