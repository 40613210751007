import { useCallback } from 'react'
import { Box, Typography, Stack } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption'

import { useAppSelector } from 'App'
import ClosedCaptionsMenu from 'design/organisms/ClosedCaptionsEditor/ClosedCaptionsMenu'
import { selectVideoSettingsState } from 'design/pages/VidSettings/VidSettings.slice'
import { useSettingsSectionContext } from 'design/organisms/VidSettingsSidebar/settingsSectionContext'
import {
    UI_CLOSED_CAPTIONS_DEFAULT_ENABLED,
    UI_CLOSED_CAPTIONS_DEFAULT_DISPLAY,
    UI_CLOSED_CAPTIONS_MOBILE_ENABLED,
    UI_CLOSED_CAPTIONS_MOBILE_DISPLAY,
} from 'design/pages/VidSettings/constants'
import { Radio } from 'design/atoms/Form'
import { ClosedCaptionsSectionFormData } from 'types/VidSettings'
import { VidSettingsSidebarSection as Section } from 'design/organisms/VidSettingsSidebar/VidSettingsSidebarSection/VidSettingsSidebarSection'
import useClosedCaptionsContent from 'design/organisms/ClosedCaptionsEditor/useClosedCaptionsContent'
import useFeatureFlags from 'hooks/system/useFeatureFlags'

import locale from './ClosedCaptionsAdvancedContent.locale'
import style from './ClosedCaptionsAdvancedContent.style'

export enum ClosedCaptionsDisplayVariants {
    default = 'default',
    whenMuted = 'whenMuted',
    off = 'off',
}

export const options = [
    {
        value: ClosedCaptionsDisplayVariants.whenMuted,
        tooltip:
            'This also applies to Smart Autoplay when the video starts muted with the Smart Autoplay Message on top',
        label: 'Show when Muted',
    },
    {
        value: ClosedCaptionsDisplayVariants.default,
        label: 'On by Default',
    },
    {
        value: ClosedCaptionsDisplayVariants.off,
        label: 'Off by Default',
    },
]

const ClosedCaptionsAdvancedContent = () => {
    const {
        video,
        isLoading: isVideoLoading,
        formik: { values, setValues },
        onChange,
    } = useSettingsSectionContext<ClosedCaptionsSectionFormData>()
    const { isPlayerRendered } = useAppSelector(selectVideoSettingsState)
    const { isLoading: isClosedCaptionsLoading, name } = useClosedCaptionsContent(String(video?.video?.guid))
    const { disableNewCaptionSettings } = useFeatureFlags()
    const isLoading = isVideoLoading || isClosedCaptionsLoading
    const resultingOptions = disableNewCaptionSettings ? options.slice(1) : options

    const getDataToValue = (defaultDisplay: boolean, enabled: boolean) => {
        if (defaultDisplay) {
            return ClosedCaptionsDisplayVariants.whenMuted
        }

        if (enabled) {
            return ClosedCaptionsDisplayVariants.default
        }

        return ClosedCaptionsDisplayVariants.off
    }

    const getValueToData = (enabledKey: string, displayKey: string, value: ClosedCaptionsDisplayVariants) => {
        switch (value) {
            case ClosedCaptionsDisplayVariants.whenMuted:
                return {
                    [displayKey]: true,
                    [enabledKey]: false,
                }
            case ClosedCaptionsDisplayVariants.default:
                return {
                    [displayKey]: false,
                    [enabledKey]: true,
                }
            default:
                return {
                    [displayKey]: false,
                    [enabledKey]: false,
                }
        }
    }

    const desktopValue = getDataToValue(
        values[UI_CLOSED_CAPTIONS_DEFAULT_DISPLAY],
        values[UI_CLOSED_CAPTIONS_DEFAULT_ENABLED],
    )
    const mobileValue = getDataToValue(
        values[UI_CLOSED_CAPTIONS_MOBILE_DISPLAY],
        values[UI_CLOSED_CAPTIONS_MOBILE_ENABLED],
    )

    const handleChange = useCallback(
        (enabledKey: string, displayKey: string, value: string) => {
            if (!value) {
                setValues({
                    ...values,
                    ...getValueToData(enabledKey, displayKey, value as ClosedCaptionsDisplayVariants),
                })
            } else {
                setValues({
                    ...values,
                    ...getValueToData(enabledKey, displayKey, value as ClosedCaptionsDisplayVariants),
                })
            }
            onChange()
        },
        [values],
    )

    if (!name || !isPlayerRendered) {
        return (
            <Section mb={4}>
                <Box sx={style.info}>
                    <Stack gap={4} direction="row">
                        <InfoOutlinedIcon sx={{ color: 'info.main' }} />
                        <Box component="span" sx={{ textWrap: 'wrap' }}>
                            {isPlayerRendered ? locale.upload : locale.wait}
                        </Box>
                    </Stack>
                </Box>
            </Section>
        )
    }

    return (
        <Section mb={4}>
            <Box sx={style.container}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2} px={3} py="3px">
                    <Stack direction="row">
                        <ClosedCaptionIcon sx={{ color: 'action.active' }} />
                        <Typography variant="body2" sx={style.name}>
                            {name}
                        </Typography>
                    </Stack>
                    <ClosedCaptionsMenu noRename />
                </Stack>
            </Box>

            <Typography fontWeight="bold" mt={4} mb={2}>
                {locale.displayOnMobile}
            </Typography>
            <Radio
                onChange={(_, value) =>
                    handleChange(UI_CLOSED_CAPTIONS_MOBILE_ENABLED, UI_CLOSED_CAPTIONS_MOBILE_DISPLAY, value)
                }
                labelVariant="body2"
                name="ccDisplayOnMobileRadioGroup"
                options={resultingOptions}
                value={mobileValue}
                disabled={isLoading}
                controlled
            />
            <Typography fontWeight="bold" mt={6} mb={2}>
                {locale.displayOnDesktop}
            </Typography>
            <Radio
                onChange={(_, value) =>
                    handleChange(UI_CLOSED_CAPTIONS_DEFAULT_ENABLED, UI_CLOSED_CAPTIONS_DEFAULT_DISPLAY, value)
                }
                labelVariant="body2"
                name="ccDisplayOnDesktopRadioGroup"
                options={resultingOptions}
                value={desktopValue}
                disabled={isLoading}
                controlled
            />
        </Section>
    )
}

export default ClosedCaptionsAdvancedContent
