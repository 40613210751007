import { ChangeEvent, useState } from 'react'
import TextField from '@mui/material/TextField'
import { InputAdornment } from '@mui/material'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'

import locale from './ClosedCaptionsSearch.locale'

type ClosedCaptionsSearchProps = {
    value: string
    onChange: (value: string) => void
}

export const ClosedCaptionsSearch = ({ value, onChange }: ClosedCaptionsSearchProps) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <TextField
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e?.target?.value)}
            onFocus={() => setExpanded(true)}
            onBlur={() => !value && setExpanded(false)}
            placeholder={locale.search}
            value={value}
            sx={{
                width: expanded ? '100%' : 250,
                transition: 'width 0.3s ease',
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchOutlinedIcon />
                    </InputAdornment>
                ),
                notched: false,
            }}
        />
    )
}

export default ClosedCaptionsSearch
