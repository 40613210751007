import {
    RecentlyPlayedStats,
    TotalPlaysStats,
    ConversionsStats,
    TopConvertedVideoStats,
    StatisticsData,
    UrlParamsResponse,
    ResetStatsPayload,
} from 'design/pages/VidStats/types'
import { http } from 'api/utils/http.utils'
import { Id } from 'types'
import { AxiosRequestConfig } from 'axios'

const getStatsUrl = (path: string) => `${process.env.REACT_APP_STATS_API_URL}/${path}`

export const statsAPI = {
    getMostRecentlyPlayed: () => http.get<RecentlyPlayedStats[]>(getStatsUrl('video/statistics/most-recently-played')),
    getTotalPlays: () => http.get<TotalPlaysStats[]>(getStatsUrl('video/statistics/total-plays')),
    getAllConversions: () => http.get<ConversionsStats[]>(getStatsUrl('video/statistics/all-conversions')),
    getTopConvertedVideos: () =>
        http.get<TopConvertedVideoStats[]>(getStatsUrl('video/statistics/top-5-converted-videos')),
    getVideoStats: (videoGuid: Id, params: string) =>
        http.get<StatisticsData>(getStatsUrl(`video/statistics/video-data/${videoGuid}?${params}`)),
    getSmartVidStats: (svGuid: Id, params: string) =>
        http.get<StatisticsData>(getStatsUrl(`sv/${svGuid}/statistic?${params}`)),
    getStatsUrlParams: (videoGuid: Id) =>
        http.get<UrlParamsResponse>(getStatsUrl(`video/statistics/video-data/${videoGuid}/params-list`)),
    downloadVideoStats: (config?: AxiosRequestConfig) =>
        http.get<UrlParamsResponse>(getStatsUrl(`video-report`), config),
    resetVidStats: (videoGuid: Id, payload: ResetStatsPayload) =>
        http.post<ResetStatsPayload>(getStatsUrl(`video/statistics/video-data/${videoGuid}/clear`), payload),
}
