import { useQuery } from 'react-query'

import { dashboardController } from 'api/controllers'
import { DASHBOARD_GET_DISTRIBUTION_BY_DATE } from 'api/constants'
import {
    GetDistributionByDateQuery,
    GetDistributionByDateResponse,
} from 'api/contracts/dashboard/endpoints/getDistributionByDate.contract'

export const useGetDistributionByDateQuery = (query: GetDistributionByDateQuery) => {
    const getDistributionQuery = useQuery<GetDistributionByDateResponse>(
        [DASHBOARD_GET_DISTRIBUTION_BY_DATE, query.dateFrom, query.dateTo, query.type],
        () => dashboardController.getDistributionByDate(query),
    )

    return { distributionMetrics: getDistributionQuery.data, getDistributionQuery }
}
