import { once } from 'lodash'
import { createContext, Dispatch, ElementType, ReactNode, SetStateAction, useContext, useState } from 'react'
import { ColorPickerProps } from './ColorPicker'

interface ContextState {
    localColor: string
    localOpacity: string
    setLocalColor: Dispatch<SetStateAction<string>>
    setLocalOpacity: Dispatch<SetStateAction<string>>
}

const createColorPickerContext = once(() => createContext({} as ContextState))
const useColorPickerContext = () => useContext(createColorPickerContext())

type Props = {
    children?: ReactNode
}

const ColorPickerProvider = ({ children }: Props) => {
    const [localColor, setLocalColor] = useState('')
    const [localOpacity, setLocalOpacity] = useState('')

    const providerProps = {
        localColor,
        localOpacity,
        setLocalColor,
        setLocalOpacity,
    }

    const ColorPickerContext = createColorPickerContext()

    return <ColorPickerContext.Provider value={providerProps}>{children}</ColorPickerContext.Provider>
}

const withColorPickerProvider = (Content: ElementType) => (props: ColorPickerProps) => {
    return (
        <ColorPickerProvider>
            <Content {...props} />
        </ColorPickerProvider>
    )
}

export { ColorPickerProvider, useColorPickerContext, withColorPickerProvider }
