import { FC } from 'react'
import Typography from '@mui/material/Typography'
import { Button, Stack } from '@mui/material'
import { GridOverlayProps } from '@mui/x-data-grid/components/containers/GridOverlay'
import { NoRowsOverlayPropsOverrides } from '@mui/x-data-grid/models/gridSlotsComponentsProps'
import { GridNoRowsOverlay } from '@mui/x-data-grid-pro'
import { NoContent } from 'design/molecules/NoContent'

export const NoResultsOverlay: FC<Partial<GridOverlayProps & NoRowsOverlayPropsOverrides>> = ({ noRows }) => {
    if (!noRows) {
        return <GridNoRowsOverlay />
    }

    return (
        <Stack flex={1} height={1}>
            <NoContent
                containerSx={{ backgroundColor: 'transparent' }}
                icon={noRows.icon}
                message={
                    <Stack rowGap={4} alignItems="center">
                        <Typography variant="h6" fontWeight="700" textAlign="center" color="text.primary">
                            {noRows.title}
                        </Typography>

                        <Typography variant="h7" fontWeight="500" textAlign="center" color="text.primary">
                            {noRows.description}
                        </Typography>
                    </Stack>
                }
                action={
                    noRows.action && (
                        <Button
                            variant="contained"
                            disabled={noRows.action.disabled}
                            onClick={noRows.action.onClick}
                            startIcon={noRows.action.startIcon}
                        >
                            {noRows.action.children}
                        </Button>
                    )
                }
            />
        </Stack>
    )
}
