import { get, omit, omitBy } from 'lodash'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type RootState } from 'App'
import { BranchStashState, StashActionPayload, UpdateStashDisplayModalActionPayload } from './types'

const initialState: BranchStashState = {
    stashes: {},
}

export const funnelBranchStashSlice = createSlice({
    name: 'funnelBranchStash',
    initialState,
    reducers: {
        stashBranchForm: (state, { payload: { id, form, rootFunnelId } }: PayloadAction<StashActionPayload>) => {
            if (!id) {
                return
            }

            state.stashes = {
                ...state.stashes,
                [id]: {
                    form,
                    displayModal: true,
                    inited: false,
                    rootFunnelId,
                },
            }
        },
        setStatuses: (
            state,
            {
                payload: { id, displayModal, inited, rootFunnelId },
            }: PayloadAction<UpdateStashDisplayModalActionPayload>,
        ) => {
            if (!id) {
                return
            }

            const stash = state.stashes[id]

            if (!stash?.form) {
                return
            }

            state.stashes = {
                ...state.stashes,
                [id]: {
                    form: stash.form,
                    displayModal: displayModal ?? stash.displayModal,
                    inited: inited ?? stash.inited,
                    rootFunnelId: rootFunnelId ?? stash.rootFunnelId,
                },
            }
        },
        removeBranchFormStash: (state, { payload: id }: PayloadAction<string | undefined>) => {
            if (!id) {
                return
            }

            state.stashes = omit(state.stashes, id)
        },
        removeBranchFormStashByRootFunnelIds: (state, { payload: ids }: PayloadAction<(string | undefined)[]>) => {
            state.stashes = omitBy(state.stashes, (stash) => ids?.some((id) => stash?.rootFunnelId === id))
        },
    },
})

export const { stashBranchForm, setStatuses, removeBranchFormStash, removeBranchFormStashByRootFunnelIds } =
    funnelBranchStashSlice.actions

export const selectBranchStashById =
    (id?: string) =>
    (state: RootState): BranchStashState['stashes'][string] | undefined =>
        get(state, `funnelBranchStash.stashes.${id}`)

export * from './types'

export const funnelBranchStash = funnelBranchStashSlice.reducer
