import { linearProgressClasses, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

const progressBar: SxProps<Theme> = {
    borderRadius: 5,
    height: 10,
    backgroundColor: (theme) => theme.palette.background.paper,
    border: (theme) => `1px solid ${theme.palette.divider}`,

    [`& .${linearProgressClasses.determinate}`]: {
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 5,
    },

    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
    },
}

export default {
    progressBar,
}
