const useEventTrack = (eventName: string, metricValue: number | string | null = null) => {
    const SS = window.SS
    if (SS) {
        SS.Require(function () {
            SS.EventTrack.rp(eventName, metricValue)
        })
    }
}

export default useEventTrack
