export const DATA_UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']

export const transformBytes = (bytes: number | null = null, precision = 2): string => {
    if (bytes === null || bytes === 0) {
        return 'empty'
    }
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes) || Math.sign(bytes) !== 1) {
        return '?'
    }
    if (bytes === 1) {
        return '1 byte'
    }

    let unit = 0
    while (bytes >= 1024) {
        bytes /= 1024
        unit++
    }
    return parseFloat(bytes.toFixed(+precision)) + ' ' + DATA_UNITS[unit]
}

export const transformBytesToMB = (bytes: number, precision = 2) => {
    const MB = (bytes / (1024 * 1024)).toFixed(precision)
    return parseFloat(MB)
}

export const getExtensionByMimeType = (type: string) => {
    const mimeTypesToExt: Record<string, string> = {
        'video/mpeg': '.mpeg',
        'video/quicktime': '.mov',
        'video/x-matroska': '.mkv',
        'video/x-ms-wmv': '.wmv',
        'video/x-msvideo': '.avi',
        'video/mp4': '.mp4',
        'video/3gpp': '.3gp',
        'video/x-flv': '.flv',
    }

    return mimeTypesToExt[type] || type
}

export const downloadFile = (blob: Blob, fileTitle: string) => {
    const anchorElement: HTMLAnchorElement = document.createElement('a')

    anchorElement.href = URL.createObjectURL(blob)
    anchorElement.download = fileTitle
    anchorElement.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))

    anchorElement.parentNode?.removeChild(anchorElement)
}

export const getVideoDuration = ({ file, link }: { file?: Blob; link?: string }) => {
    if (!file && !link) {
        return 0
    }

    return new Promise<number>((resolve) => {
        try {
            const videoEl = document.createElement('video')
            videoEl.preload = 'metadata'
            videoEl.onloadedmetadata = function () {
                window.URL.revokeObjectURL(videoEl.src)

                resolve(videoEl.duration)
            }

            videoEl.onerror = function () {
                resolve(0)
            }

            videoEl.src = link || (file ? URL.createObjectURL(file) : '')
        } catch (error) {
            resolve(0)
        }
    })
}
