import { UseTableCellProps, Row } from 'react-table'
import classNames from 'classnames'

import { Box, Typography } from '@mui/material'

import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined'

import { RowActions } from 'design/pages/MyVids/VidsTable'
import { BREADCRUMB } from 'design/pages/MyVids'
import { Folder as FolderType } from 'types/Video'
import { Link } from 'design/atoms/Link'
import { InputFocusHandler } from 'types'
import { route } from 'constants/routes'
import EditableName, { EditableNameProps } from './EditableName'
import { AUTO_FOCUS, TITLE, VIDEO_COUNT } from './constants'

import style from './Folder.style'
import './index.scss'

type FolderProps = {
    cell: UseTableCellProps<object>
    row: Row<FolderType>
    handleEdit: (isFolderEditing: boolean) => void
}

export default function Folder({ cell, row: { original }, row, handleEdit }: FolderProps) {
    const handleBlur: InputFocusHandler = (event) => {
        handleEdit(false)

        const newTitle = event.target.value

        if (newTitle === original.title) return

        cell.column.onFolderNameChange?.({
            ...original,
            title: event.target.value,
        })
    }

    const handleIconEditClick: EditableNameProps['onLabelClick'] = (event) => {
        event.preventDefault()
        handleEdit(true)
    }

    return (
        <Box {...cell.getCellProps()} className="Folder">
            <Link to={route.video.byId({ guid: original.guid })} sx={style.link}>
                <Box className="icon" sx={style.icon}>
                    <FolderCopyOutlinedIcon color="primary" />
                </Box>
                <Box className="details-container">
                    <Typography component="span" className="breadcrumb">
                        {original[BREADCRUMB]}
                    </Typography>
                    <EditableName
                        initialName={original[TITLE]}
                        className={classNames('details-folder-edit', { isSelected: cell.row.isSelected })}
                        autoFocusEnabled={original[AUTO_FOCUS]}
                        onBlur={handleBlur}
                        onLabelClick={handleIconEditClick}
                    />

                    <Typography className="details" component="span">
                        <Typography component="span">
                            {original[VIDEO_COUNT]} video{original[VIDEO_COUNT] > 1 ? 's' : ''}
                        </Typography>
                    </Typography>
                </Box>
            </Link>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <RowActions row={row as any} cell={cell} folder />
        </Box>
    )
}
