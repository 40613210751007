import z from 'zod'

import { dateRangeQueryParams, requestMeta } from 'api/contracts/dashboard/entities/common'

export enum DISTRIBUTION_TYPE {
    TRAFFIC_SOURCE = 'trafficSource',
    DEVICE_TYPE = 'deviceType',
    LOCATION = 'location',
}

const request = {
    query: z.intersection(dateRangeQueryParams, z.object({ type: z.nativeEnum(DISTRIBUTION_TYPE) })),
} as const

const response = {
    body: z.object({
        request: z.intersection(requestMeta, z.object({ type: z.nativeEnum(DISTRIBUTION_TYPE) })),
        data: z.array(
            z.object({
                header: z.string(),
                value: z.number().positive(),
            }),
        ),
        total: z.number().positive(),
    }),
} as const

export const getDistributionMetricsContract = { request, response } as const

export type GetDistributionMetricsQuery = z.infer<typeof request.query>
export type GetDistributionMetricsResponse = z.infer<typeof response.body>
